export enum AptProductFamily {
    Commodity = 'Commodity',
    ExtraCommodity = 'ExtraCommodity',
    Manutenzione = 'Manutenzione',
    BeniFisici = 'Beni Fisici senza installazione',
    ServizioTecnico = 'Servizi con tecnico',
    VariazioneTecnica = 'Variazione Tecnica',
    Assicurazione = 'Assicurazione',
    Options = 'Options',
}
