import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError, TimeoutError } from 'rxjs';
import { catchError, map, take, timeout, finalize, switchMap, mergeMap } from 'rxjs/operators';
import { LoggerService } from './logger.service';
import { LoadingService } from './loading.service';
import { AppConfig } from '../../models/app/app-config';
import { environment } from '../../../../environments/environment';
import { ApiService, ConfigurationService, PlatformConstants } from '@congacommerce/core';
import { isNil } from 'lodash';
import { PrivateConfigurationService } from './private-configuration.service';
import { getTransactionId } from '../../functions/misc.functions';
import { OAuthService } from '../oauth/oauth-services';

@Injectable()
export class HttpRequestInterceptorService implements HttpInterceptor {
    private _privateConfig: AppConfig;
    constructor(
        private logger: LoggerService,
        private aptConfigService: ConfigurationService,
        private apiService: ApiService,
        private authService: OAuthService,
        private configSrv: PrivateConfigurationService
    ) {}

    /**
     * @return HttpEvent
     * @param request: HttpRequest
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.configInit();
        const showLoading = this.showLoading(request);
        const timeoutRequest = this._privateConfig?.httpRequestTimeout || 60000;

        if (showLoading) {
            LoadingService.show();
        }
        return this.addHeaders(request).pipe(
            take(1),
            mergeMap((req: HttpRequest<any>) => {
                return next.handle(req).pipe(
                    timeout(timeoutRequest),
                    catchError((error: HttpErrorResponse | TimeoutError | any) => {
                        if (
                            error &&
                            error.status === 0 &&
                            (error.url.indexOf('apttus') !== -1 || error.url.indexOf('congacloud') !== -1)
                        ) {
                            localStorage.removeItem(PlatformConstants.ACCESS_TOKEN);
                            return this.apiService.refreshToken().pipe(
                                switchMap((res) => {
                                    req = req.clone({
                                        headers: req.headers.set('Authorization', `Bearer ${res.accessToken}`),
                                    });
                                    return next.handle(req);
                                })
                            );
                        } else if (error instanceof TimeoutError) {
                            this.logger.error(
                                null,
                                `ERRORE DI TIMEOUT: la richiesta ${req.url} ha impiegato troppo tempo per rispondere`,
                                '',
                                !!this._privateConfig?.production
                            );
                        } else {
                            this.logger.error(
                                null,
                                'HTTP ERROR',
                                JSON.stringify(error),
                                error && this.canShowErrorToast(req.url)
                            );
                        }
                        return throwError(error);
                    }),
                    finalize(() => {
                        if (showLoading) {
                            LoadingService.hide();
                        }
                    })
                );
            })
        );
    }

    /**
     * @description: Aggiunge parametri all'header della richiesta
     * @return HttpRequest
     * @param request: HttpRequest
     * @param config: configurazione dell'applicazione
     */
    private addHeaders(request: HttpRequest<any>): Observable<HttpRequest<any>> {
        if (request.url.indexOf('i18') !== -1) {
            request = request.clone({
                setParams: {
                    t: `${Date.now()}`, // add timestamp to request to prevent cache
                },
            });
        } else if (request.url.startsWith(this._privateConfig?.endpoints.apiMgt.baseUrl)) {
            const obs$ = this.authService.getSilentMsalToken().pipe(
                map((token: string) => {
                    const req = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${token}`,
                            transactionId: getTransactionId(),
                            cpqtoken: localStorage.getItem('access_token'),
                            'Ocp-apim-subscription-key': this._privateConfig?.appKeys?.ocpApimSubscriptionKey,
                        },
                    });
                    return req;
                })
            );
            return obs$;
        } else if (request.url.indexOf('apttus') !== -1 || request.url.indexOf('congacloud') !== -1) {
            const accessToken = localStorage.getItem(PlatformConstants.ACCESS_TOKEN);
            const storefront = this.aptConfigService.get('storefront');
            if (!isNil(accessToken) && !request.headers.get('authorization')) {
                request = request.clone({
                    headers: request.headers.set('authorization', `Bearer ${accessToken}`),
                });
            }
            if (!isNil(storefront) && !request.headers.get('x-storefront')) {
                request = request.clone({
                    headers: request.headers.set('x-storefront', storefront),
                });
            }
        } else {
            request = request.clone({
                headers: request.headers
                    .delete('x-storefront', request.headers.get('x-storefront'))
                    .delete('authorization', request.headers.get('Authorization'))
                    .delete('x-account', request.headers.get('x-account')),
            });
        }
        return of(request);
    }

    /**
     * @description: Mostra un block ui di caricamento durante le request
     * @param request: richiesta http
     * @return: boolean
     */
    private showLoading(request: HttpRequest<any>): boolean {
        let isEgonUrl = false;
        if (this._privateConfig?.endpoints) {
            isEgonUrl =
                request.url.indexOf(this._privateConfig.endpoints.egon.baseUrl) !== -1 ||
                request.url.indexOf(this._privateConfig.endpoints.egonJson.baseUrl) !== -1;
        }

        const showLoading = !request.headers.has('no-loading');
        const isApi = request.url.indexOf('i18') === -1;
        const isEcommerceSdkRequest = request.url.indexOf(environment.endpoint) !== -1;
        const isAssetRequest = request.url.indexOf('/assets/') !== -1;

        return isApi && showLoading && !isEcommerceSdkRequest && !isEgonUrl && !isAssetRequest;
    }

    private configInit(): boolean {
        if (this.configSrv.configLoaded && !this._privateConfig) {
            this._privateConfig = this.configSrv.config;
        }
        return !!this._privateConfig;
    }

    /**
     * @description: Verifica se è possibile mostrare il toast di errore in base alla configurazione alla blacklist prevista contenuta nella configruazione hideToastErrorByEndpoint
     * @param endpoint: endpoint da verificare
     * @return: boolean
     */
    private canShowErrorToast(url: string): boolean {
        return (
            (this._privateConfig?.hideToastErrorByEndpoint || []).find((f) => url.indexOf(f) !== -1) === undefined &&
            !!this._privateConfig?.production
        );
    }
}
