import { AppState } from '../models/app-state';
import { createReducer, on } from '@ngrx/store';
import * as a from '../actions/app.actions';
import { Action } from '../../common/interfaces/action';

export const initialState: AppState = new AppState();

const reducer = createReducer(
    initialState,
    on(a.setConnectionStatus, (state, { status }) => {
        return { ...state, connectionStatus: status };
    }),
    on(a.setConnectionSpeed, (state, { speed }) => {
        return { ...state, connectionSpeed: speed };
    }),
    on(a.setApplicationLocation, (state, { location: applocation }) => {
        return { ...state, applicationLocation: applocation };
    }),
    on(a.setUrlHash, (state, { hash }) => {
        return { ...state, urlHash: hash };
    }),
    on(a.setCurrentPage, (state, { url }) => {
        return { ...state, currentPage: url };
    }),
    on(a.setGeoLocation, (state, { geolocation }) => {
        return { ...state, geoLocation: geolocation };
    }),
    on(a.setContactBarVisibility, (state, { v }) => {
        return { ...state, hasContactBar: v };
    }),
    on(a.setProgressBarVisibility, (state, { v }) => {
        return { ...state, hasProgressBar: v };
    }),
    on(a.setDevBarVisibility, (state, { v }) => {
        return { ...state, hasDevBar: v };
    }),
    on(a.setBOQuoteTab, (state, { t }) => {
        return { ...state, backOfficeQuoteTab: t };
    })
);

export function appReducer(state: AppState | undefined, action: Action): AppState {
    return reducer(state, action);
}
