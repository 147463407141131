import { Injectable } from '@angular/core';
import { LoggerService } from '../shared/logger.service';
import { D365Service } from '../d365/d365.service';
import { ApplicationLocationType } from '../../../store/models/app-state';
import { Store } from '@ngrx/store';
import { PrivateConfigurationService } from '../shared/private-configuration.service';
import { EglState } from '../../../store/reducers';
import { MsalService } from '@azure/msal-angular';
import { selectAgentInfo } from '../../../store/selectors/user.selectors';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { AuthenticationResult, RedirectRequest } from '@azure/msal-browser';
import { Observable } from 'rxjs';
import { LoadingService } from '../shared/loading.service';

@Injectable({
    providedIn: 'root',
})
export class OAuthService {
    constructor(
        private logger: LoggerService,
        private d365Service: D365Service,
        private store: Store<EglState>,
        private configSrv: PrivateConfigurationService,
        private msalSrv: MsalService
    ) {}

    /**
     * @description Esegue il login per SalesForce
     * @param appLocation Indica se stiamo usando l'applicazione da Web o Mobile
     * @returns void
     */
    oauthLogin(appLocation: ApplicationLocationType): void {
        const conf = this.configSrv.config;

        const url = conf.oauthBaseUrl;
        const clientId = conf.clientId;
        let redirectUri = conf.redirectUri.crm;
        if (appLocation && appLocation === ApplicationLocationType.Mobile) {
            redirectUri = conf.redirectUri.mobile;
        }

        const urlOAuth = `${url}${clientId}&redirect_uri=${encodeURI(
            location.protocol + redirectUri
        )}%2F&scope=&state=jsforce0.redirect.4sh0nvndizf`;
        this.logger.warn('Application Location is ' + appLocation);
        this.logger.warn('Authentication URL is ' + urlOAuth);
        this.d365Service.topNavigationRequest(urlOAuth);
    }

    /**
     * @description Esegue il single sign on per Azure
     * @returns Promise<AuthenticationResult>
     */
    msalLogin(): Promise<AuthenticationResult> {
        return this.store
            .select(selectAgentInfo)
            .pipe(
                filter((agentInfo) => !!agentInfo),
                take(1),
                mergeMap((agentInfo) => {
                    const loggedUser = this.msalSrv.instance.getAccountByUsername(agentInfo.DomainName);
                    const request: RedirectRequest = {
                        scopes: ['user.read'],
                        account: loggedUser,
                    };
                    return this.msalSrv.ssoSilent(request);
                }),
                LoadingService.loaderOperator()
            )
            .toPromise();
    }

    /**
     * @description Ottiene un token valido per le richieste da effettuare verso Azure
     * @returns Observable<string>
     */
    getSilentMsalToken(): Observable<string> {
        return this.store.select(selectAgentInfo).pipe(
            filter((agentInfo) => !!agentInfo),
            take(1),
            mergeMap((agentInfo) => {
                const loggedUser = this.msalSrv.instance.getAccountByUsername(agentInfo.DomainName);
                const request: RedirectRequest = {
                    scopes: ['user.read'],
                    account: loggedUser,
                };
                return this.msalSrv.acquireTokenSilent(request).pipe(map((authResp) => authResp.idToken));
            })
        );
    }
}
