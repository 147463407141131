import { FlowType } from '../../store/models/flow-type';

export const FLOW_TYPE_LABELS_MAP: {
    [key in FlowType]?: string;
} = {
    [FlowType.SwitchIn]: 'OPERATIVE_MODES.SWITCH_IN',
    [FlowType.SwitchInAmmVip]: 'OPERATIVE_MODES.SWITCH_IN_AMM_VIP',
    [FlowType.SwitchInAmmRecupero2A]: 'OPERATIVE_MODES.SWITCH_IN_AMM_RECUPERO',
    [FlowType.SwitchInPertinenza]: 'OPERATIVE_MODES.SWITCH_IN',
    [FlowType.SwitchInVoltura]: 'OPERATIVE_MODES.SWITCH_IN_VOLTURA',
    [FlowType.SwitchInAmmDelibera153]: 'OPERATIVE_MODES.SWITCH_IN_AMM_DEL153',
    [FlowType.SwitchInAmmRecupero2A]: 'OPERATIVE_MODES.SWITCH_IN_AMM_RECUPERO',
    [FlowType.SwitchInAmmRecupero2B]: 'OPERATIVE_MODES.SWITCH_IN_AMM_RECUPERO',
    [FlowType.SwitchInAmmRecupero2C]: 'OPERATIVE_MODES.SWITCH_IN_AMM_RECUPERO',

    [FlowType.CPCommerciale]: 'OPERATIVE_MODES.CHANGE_PROD_COMMERCIAL',
    [FlowType.CPDelibera]: 'OPERATIVE_MODES.CHANGE_PROD_RETROACTIVE',
    [FlowType.CPAmministrativo]: 'OPERATIVE_MODES.CHANGE_PROD_DELIBERATE',
    [FlowType.DomiciliazioneAttivazione]: 'OPERATIVE_MODES.DOMICILIATION_ATT',
    [FlowType.DomiciliazioneAttivazioneInserimentoIban]: 'OPERATIVE_MODES.DOMICILIATION_ATT',
    [FlowType.DomiciliazioneModifica]: 'OPERATIVE_MODES.DOMICILIATION_MOD',
    [FlowType.DomiciliazioneModificaInserimentoIban]: 'OPERATIVE_MODES.DOMICILIATION_MOD',
    [FlowType.DomiciliazioneRevoca]: 'OPERATIVE_MODES.DOMICILIATION_REV',
    [FlowType.Cessazione]: 'OPERATIVE_MODES.TERMINATION',
    [FlowType.Sospensione]: 'OPERATIVE_MODES.SUSPENSION',
    [FlowType.SospensioneAmministrativa]: 'OPERATIVE_MODES.SUSPENSION_ADMIN',
    [FlowType.RevocaSospensione]: 'OPERATIVE_MODES.SUSPENSION_CANCEL',
    [FlowType.VariazioneCommerciale]: 'OPERATIVE_MODES.VAR_COMM',
    [FlowType.ScontoStandalone]: 'OPERATIVE_MODES.SCONTO_STANDALONE',
    [FlowType.Interruzione]: 'OPERATIVE_MODES.INTERRUPTION',
    [FlowType.InterruzioneAmministrativa]: 'OPERATIVE_MODES.INTERRUPTION_ADMIN',
    [FlowType.RevocaInterruzione]: 'OPERATIVE_MODES.INTERRUPTION_CANCEL',
    // [FlowType.CambioProdotto]: 'OPERATIVE_MODES.CambioProdotto',
    [FlowType.Voltura]: 'OPERATIVE_MODES.VOLTURA',
    [FlowType.Attivazione]: 'OPERATIVE_MODES.ACTIVATION',
    [FlowType.AttivazioneGasConContatore]: 'OPERATIVE_MODES.ACTIVATION_GAS',
    [FlowType.AttivazioneGasSenzaContatore]: 'OPERATIVE_MODES.ACTIVATION_GAS',
    [FlowType.AttivazionePwrS01]: 'OPERATIVE_MODES.ACTIVATION_PWR',
    [FlowType.Riapertura]: 'OPERATIVE_MODES.RESUMING',
    [FlowType.RiaperturaAmministrativa]: 'OPERATIVE_MODES.RESUMING_ADMIN',
    [FlowType.RevocaRiapertura]: 'OPERATIVE_MODES.RESUMING_CANCEL',
    [FlowType.VariazioneTecnicaVerifiche]: 'OPERATIVE_MODES.VARIAZIONE_TECNICA_VERIFICHE',
    [FlowType.VariazioneTecnicaLavori]: 'OPERATIVE_MODES.VARIAZIONE_TECNICA_LAVORI',
    [FlowType.CA1]: 'OPERATIVE_MODES.CA1',
    [FlowType.CA1AMM]: 'OPERATIVE_MODES.CA1_AMMINISTRATIVA',
    [FlowType.CA2]: 'OPERATIVE_MODES.CA2',
    [FlowType.CA2AMM]: 'OPERATIVE_MODES.CA2_AMMINISTRATIVA',
    [FlowType.CA3]: 'OPERATIVE_MODES.CA3',
    [FlowType.CA3AMM]: 'OPERATIVE_MODES.CA3_AMMINISTRATIVA',
    [FlowType.CA4]: 'OPERATIVE_MODES.CA4',
    [FlowType.CA4AMM]: 'OPERATIVE_MODES.CA4_AMMINISTRATIVA',
    [FlowType.CA5]: 'OPERATIVE_MODES.CA5',
    [FlowType.CA5AMM]: 'OPERATIVE_MODES.CA5_AMMINISTRATIVA',
    [FlowType.CA7]: 'OPERATIVE_MODES.CA7_MOTIVI_DIVERSI',
    [FlowType.CA7AMM]: 'OPERATIVE_MODES.CA7_MOTIVI_DIVERSI_AMMINISTRATIVA',
    [FlowType.CAE1]: 'OPERATIVE_MODES.CAE1',
    [FlowType.CAE1AMM]: 'OPERATIVE_MODES.CAE1_AMMINISTRATIVA',
    [FlowType.CAE3]: 'OPERATIVE_MODES.CAE3',
    [FlowType.CAE3AMM]: 'OPERATIVE_MODES.CAE3_AMMINISTRATIVA',
    [FlowType.CAE4]: 'OPERATIVE_MODES.CAE4',
    [FlowType.CAE4AMM]: 'OPERATIVE_MODES.CAE4_AMMINISTRATIVA',
    [FlowType.CAE5]: 'OPERATIVE_MODES.CAE5',
    [FlowType.CAE5AMM]: 'OPERATIVE_MODES.CAE5_AMMINISTRATIVA',
    [FlowType.CAE7]: 'OPERATIVE_MODES.CAE7',
    [FlowType.CAE7AMM]: 'OPERATIVE_MODES.CAE7_AMMINISTRATIVA',
    [FlowType.CAE8]: 'OPERATIVE_MODES.CAE8_MOTIVI_DIVERSI',
    [FlowType.CAE8AMM]: 'OPERATIVE_MODES.CAE8_MOTIVI_DIVERSI_AMMINISTRATIVA',
    [FlowType.CAE8RIFVOLT]: 'OPERATIVE_MODES.CAE8_RIFIUTO_VOLTURA',
    [FlowType.CAE8RIFVOLTAMM]: 'OPERATIVE_MODES.CAE8RIFVOLT_RIFIUTO_VOLTURA_AMMINISTRATIVA',
};
