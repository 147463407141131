import { Component } from '@angular/core';

@Component({
    selector: 'egl-backoffice-outlet',
    template: `<main class="backoffice-outlet">
        <router-outlet></router-outlet>
    </main>`,
})
export class BoOutletComponent {
    constructor() {}
}
