import { Injectable } from '@angular/core';
import { FeatureToggleService } from './feature-toggle.service';
import { PrivateConfigurationService } from './private-configuration.service';
import { Questions } from './troubleshooting-values.service';

export enum TroubleshootingTemplates {
    ATTIVAZIONE = 'ATTIVAZIONE',
    GENERICI = 'GENERICI',
}

@Injectable()
export class TroubleshootingQuestionsService {
    constructor(private featureToggleSrv: FeatureToggleService) {}
    public QUESTIONS: TroubleshootingQuestionsTemplates = {
        [TroubleshootingTemplates.ATTIVAZIONE]: [
            {
                labelId: 'HAS_METER',
                value: Questions.HAS_METER,
                visible: Questions.ANY,
            },
            {
                labelId: 'IS_ACTIVE_PWR',
                value: Questions.IS_ACTIVE,
                visible: Questions.POWER + Questions.HAS_METER,
            },
            {
                labelId: 'IS_ACTIVE_GAS',
                value: Questions.IS_ACTIVE,
                visible: Questions.GAS + Questions.HAS_METER,
            },
            {
                labelId: 'IS_EGL',
                value: Questions.IS_EGL,
                visible: Questions.IS_ACTIVE,
            },
            {
                labelId: 'IS_CONNECTED_GAS',
                subLabelId: 'IS_CONNECTED_GAS',
                value: Questions.IS_CONNECTED,
                visible: (answer, formValues) =>
                    (answer & Questions.GAS) === Questions.GAS && formValues[Questions.HAS_METER] === 0,
            },
            {
                labelId: 'NEVER_USED',
                value: Questions.NEVER_USED,
                visible: (answer, formValues) =>
                    (!!formValues[Questions.HAS_METER] && formValues[Questions.IS_ACTIVE] === 0) ||
                    ((answer & Questions.GAS) === Questions.GAS &&
                        formValues[Questions.HAS_METER] === 0 &&
                        !!formValues[Questions.IS_CONNECTED]),
            },
            {
                labelId: 'IS_CONNECTED_PWR',
                subLabelId: 'IS_CONNECTED_PWR',
                value: Questions.IS_CONNECTED,
                visible: (answer, formValues) =>
                    (answer & Questions.POWER) === Questions.POWER && formValues[Questions.HAS_METER] === 0,
            },
            {
                labelId: 'MODIFIED_WIRING',
                value: Questions.MODIFIED_WIRING,
                visible: (answer, formValues) =>
                    ((!!formValues[Questions.HAS_METER] && formValues[Questions.IS_ACTIVE] === 0) ||
                        (formValues[Questions.HAS_METER] === 0 && !!formValues[Questions.IS_CONNECTED])) &&
                    formValues[Questions.NEVER_USED] === 0,
            },
        ],
        [TroubleshootingTemplates.GENERICI]: [
            {
                labelId: 'IS_ACTIVE_PWR',
                value: Questions.IS_ACTIVE,
                visible: Questions.POWER,
            },
            {
                labelId: 'IS_EGL_PWR',
                value: Questions.IS_EGL,
                visible: Questions.POWER + Questions.IS_ACTIVE,
            },
            {
                labelId: 'ACCOUNT_OWNERSHIP_PWR',
                value: Questions.ACCOUNT_OWNERSHIP,
                visible: Questions.POWER + Questions.IS_ACTIVE,
            },
            {
                labelId: 'IS_BASEMENT_1',
                value: Questions.IS_BASEMENT,
                visible: (answer, formValues) => answer & Questions.POWER && formValues[Questions.IS_ACTIVE] === 0,
            },
            {
                labelId: 'IS_ACTIVE_GAS',
                value: Questions.IS_ACTIVE,
                visible: Questions.GAS,
            },
            {
                labelId: 'IS_EGL_GAS',
                value: Questions.IS_EGL,
                visible: Questions.GAS + Questions.IS_ACTIVE,
            },
            {
                labelId: 'ACCOUNT_OWNERSHIP_GAS',
                value: Questions.ACCOUNT_OWNERSHIP,
                visible: Questions.GAS + Questions.IS_ACTIVE,
            },
            {
                labelId: 'IS_BASEMENT_2',
                value: Questions.IS_BASEMENT,
                visible: (answer, formValues) =>
                    answer & Questions.POWER &&
                    !!formValues[Questions.IS_ACTIVE] &&
                    !!formValues[Questions.ACCOUNT_OWNERSHIP] &&
                    formValues[Questions.IS_EGL] === 0 &&
                    this.featureToggleSrv.isSwitchInE2EEnabled,
            },
        ],
    };
}

export type TroubleshootingQuestionsTemplates = {
    [key in TroubleshootingTemplates]?: TroubleshootingQuestion[];
};

export type TroubleshootingQuestion = {
    value: number;
    visible: number | ((answer: number, formValues: TroubleshootingFormValues) => boolean);
} & (
    | { label: string; labelId?: never; subLabel?: string; subLabelId?: never }
    | { labelId: string; label?: never; subLabelId?: string; subLabel?: never }
);

export type TroubleshootingFormValues = {
    [key in Questions]?: number;
};
