import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationLocationType } from '../../../../store/models/app-state';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { RoutesPaths } from '../../../config/routes-paths';
import { AgentInfo } from '../../../models/user/agent';
import { RouterService } from '../../../services/shared/router.service';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { UserService } from '@congacommerce/ecommerce';
import { LoggerService } from '../../../services/shared/logger.service';
import { CacheService, PlatformService } from '@congacommerce/core';
import { selectApplicationLocation } from '../../../../store/selectors/app.selectors';
import { selectAgentInfo, selectCurrentVirtualAgent } from '../../../../store/selectors/user.selectors';
import { convertSegmentD365toApt } from '../../../functions/remap.functions';
import { EglUserService } from '../../../services/apttus/tables/user/egl-user.service';
import { D365Service } from '../../../services/d365/d365.service';
import { PrivateConfigurationService } from '../../../services/shared/private-configuration.service';
import { EglState } from '../../../../store/reducers';

@Component({
    selector: 'egl-user-info',
    templateUrl: './user-info.component.html',
    styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    segment: string;
    initials: string;
    channel$: Observable<string>;

    constructor(
        private router: RouterService,
        private store: Store<EglState>,
        private userService: UserService,
        private pltService: PlatformService,
        private cacheSrv: CacheService,
        private logger: LoggerService,
        private eglUserSvc: EglUserService,
        private d365Srv: D365Service,
        private configSrv: PrivateConfigurationService
    ) {}

    ngOnInit(): void {
        this.mapProps();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    mapProps(): void {
        this.channel$ = this.store
            .select(selectCurrentVirtualAgent)
            .pipe(map((data) => data.VirtualAgency?.Channel?.Name));

        this.subscriptions.push(
            this.store
                .select(selectAgentInfo)
                .pipe(filter((x) => !!x?.Agent))
                .subscribe((x: AgentInfo) => {
                    this.segment = convertSegmentD365toApt(x.UserConfiguration.LastUsedCustomerSegment);
                    this.initials = this.setInitials(x.Agent.Name);
                })
        );

        combineLatest([
            this.store.select(selectAgentInfo),
            this.userService.me(),
            this.store.select(selectApplicationLocation),
        ])
            .pipe(
                take(1),
                switchMap(([agentInfo, user, appLocation]) =>
                    this.eglUserSvc.getFederationId(user?.Id).pipe(
                        map((federation) => ({
                            federationId: federation.egl_federationidentifier__c,
                            agentInfo: agentInfo,
                            userName: user?.Name,
                            appLocation: appLocation,
                            tabletWarningPage: this.configSrv.config.tabletForceLogoutWarningPage,
                            appConfig: this.configSrv.config,
                        }))
                    )
                ),
                filter(
                    (res) =>
                        res?.agentInfo?.DomainName &&
                        res?.federationId &&
                        res?.agentInfo?.DomainName?.toUpperCase() !== res?.federationId?.toUpperCase()
                ),
                switchMap((res) => {
                    // Se da tablet piuttosto che redirezionare sulla pagina di oauth redireziono l'utenza su una pagina
                    // contenente le istruzioni per disconnettere.
                    // E' fix temporanea per problema loop in login da tablet #39453
                    const crmUri = res.appConfig.redirectUri.crm.split('/')[2];
                    if (res.appLocation === ApplicationLocationType.Mobile) {
                        const redirectUriMobile = `${res.appConfig.oauthBaseUrl}${
                            res.appConfig.clientId
                        }&redirect_uri=${encodeURI(
                            location.protocol + res.appConfig.redirectUri.mobile
                        )}%2F&scope=&state=jsforce0.redirect.4sh0nvndizf`;
                        this.d365Srv.topNavigationRequest(
                            `${res.tabletWarningPage}?crmUri=https://${crmUri}&redirectUri=${btoa(redirectUriMobile)}`
                        );
                        return of(null);
                    }
                    // L'utente si è disconnesso da D365 e si è loggato con un'altra utenza
                    // Forzo il logout per aggiornare il token di sessione
                    this.logger.warn('Forcing logout: D365 user <> of Apttus user');
                    return this.pltService.logout().pipe(
                        switchMap(() => this.cacheSrv.clear()),
                        map(() => this.eglUserSvc.forceLogin()),
                        take(1)
                    );
                })
            )
            .subscribe(() => {});
    }

    openSettings(): void {
        this.router.navigate([RoutesPaths.Settings]);
    }

    /**
     * Return initials of Name and Surname
     * @returns initials of Name and Surname of current agent
     */
    setInitials(completeName: string): string {
        if (!completeName) return '';
        const initialsArray: string[] = completeName.replace(/[^a-zA-Z- ]/g, '').match(/\b(\w)/g);
        return initialsArray.slice(0, 2).join('').toUpperCase();
    }
}
