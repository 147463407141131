import { CurrencyPipe, DatePipe, DecimalPipe, JsonPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Regex } from '../config/regex';

type AutoFormatHandledType =
    | 'string'
    | 'number'
    | 'bigint'
    | 'currency'
    | 'boolean'
    | 'undefined'
    //    | 'symbol'
    //    | 'function'
    | 'Date'
    | 'Moment'
    | 'object'
    | 'i18n';

@Pipe({
    name: 'autoFormat',
})
export class AutoFormatPipe implements PipeTransform {
    DATA_TYPE_MAP: {
        [key in AutoFormatHandledType]: (value: any) => string;
    } = {
        string: (value: string) => value,
        number: (value: number | string) => this.decimalPipe.transform(value),
        bigint: (value: number) => this.decimalPipe.transform(value),
        currency: (value: number | string) =>
            typeof value === 'number'
                ? this.currencyPipe.transform(value)
                : this.translateService.instant('COMMON.NOT_AVAILABLE'),
        boolean: (value: boolean) => this.translateService.instant(`COMMON.${value ? 'YES' : 'NO'}`),
        undefined: (value: undefined | null) => this.translateService.instant('COMMON.EMPTY'),
        Date: (value: Date) => this.datePipe.transform(moment(value).toDate(), 'shortDate'),
        Moment: (value: moment.Moment) =>
            value.isValid()
                ? this.datePipe.transform(value.toDate(), 'shortDate')
                : this.DATA_TYPE_MAP.undefined(value),
        object: (value: object) => this.jsonPipe.transform(value),
        i18n: (value: string) => this.translateService.instant(value),
    };

    constructor(
        private decimalPipe: DecimalPipe,
        private currencyPipe: CurrencyPipe,
        private datePipe: DatePipe,
        private translateService: TranslateService,
        private jsonPipe: JsonPipe
    ) {}

    transform(value: any, customType?: 'currency') {
        const type = customType || this.getValueType(value);
        return this.DATA_TYPE_MAP[type](value);
    }

    private isAutoFormatHandledType(type: any): type is AutoFormatHandledType {
        return !['function', 'symbol'].includes(type);
    }

    private getValueType(value: any): AutoFormatHandledType {
        let type = typeof value;
        let autoFormatType: AutoFormatHandledType = this.isAutoFormatHandledType(type) ? type : 'undefined';

        if (type === 'object') {
            if (value instanceof Date) {
                autoFormatType = 'Date';
            } else if (moment.isMoment(value)) {
                autoFormatType = 'Moment';
            } else if (value === null) {
                autoFormatType = 'undefined';
            }
        } else if (type === 'string') {
            if (/^[\d.,]+$/.test(value)) {
                autoFormatType = 'number';
            } else if (/^[A-Z\d]+(?:_[A-Z\d]+)*(?:\.[A-Z\d]+(?:_[A-Z\d]+)*)*$/.test(value)) {
                autoFormatType = 'i18n';
            } else if ((value || '').trim() === '') {
                autoFormatType = 'undefined';
            } else if (Regex.iso8601.test(value)) {
                autoFormatType = 'Date';
            }
        }
        return autoFormatType;
    }
}
