import { Expose, Type } from 'class-transformer';
import { CartItem, ProductAttributeValue } from '@congacommerce/ecommerce';
import { EglProductExtended } from '../product/egl-product-extended';
import { AptCommodityTypeOfSale } from '../../../../enums/apttus/apt-commodity-typeof-sale';

export class EglCartItemExtended extends CartItem {
    constructor() {
        super();
    }

    @Expose({
        name: 'Apttus_Config2__ProductId__r',
    })
    @Type(() => EglProductExtended)
    Product: EglProductExtended;
    AttributeValue: EglProductAttributeValue;
}

export class EglProductAttributeValue extends ProductAttributeValue {
    constructor() {
        super();
    }

    egl_Address_Of_Residence__c: string;
    egl_commodity_supplytype__c: AptCommodityTypeOfSale;
    egl_POD__c: string;
    egl_PDR__c: string;
}
