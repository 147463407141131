import { EgonResponse } from '../../modules/switch-in/order-entry/models/egon-response';
import { Indirizzo } from '../../modules/switch-in/order-entry/models/indirizzi';

export const getFullAddressStringFromObj = (val: EgonResponse | Indirizzo, isEgonResponse?: boolean): string => {
    if (val && isEgonResponse) {
        const egon = val as EgonResponse;
        return getFullAddressStringFromVals('', egon.street, egon.number, egon.city, egon.province_code, egon.zipcode);
    }

    if (val) {
        const ind = val as Indirizzo;
        return getFullAddressStringFromVals(ind.toponomastica, ind.via, ind.civico, ind.comune, ind.prov, ind.cap);
    }

    return '';
};

export const getFullAddressStringFromVals = (
    prefix: string,
    street: string,
    number: string,
    city: string,
    provinceCode: string,
    cap: string
): string => {
    if (!street || !city) return;
    const street1 = street ? `${street},` : null;
    const number1 = number ? `${number},` : null;

    const addr = [prefix, street1, number1, cap, city, provinceCode].filter((x) => !!x).map((x) => x.trim());
    return addr.join(' ').toUpperCase();
};
