export enum AptSalesProcess {
    SwitchIn = 'SWITCH IN',
    SwitchInAmmVip = 'SWITCH-IN AMM. - VIP',
    SwitchInAmmRecupero = 'SWIN AMM. - RECUPERO',
    SwitchInAmmDelibera153 = 'SWIN AMM. - RITORNO DA DEL153',
    AttivazioneSemplice = 'ATTIVAZIONE SEMPLICE',
    VariazioneCommerciale = 'VARIAZIONE COMMERCIALE',
    CambioProdotto = 'CAMBIO PRODOTTO',
    CambioProdottoAmministrativo = 'CAMBIO PRODOTTO AMMINISTRATIVO',
    CambioProdottoDaDelibera = 'CAMBIO PRODOTTO DA DELIBERA',
    Cessazione = 'CESSAZIONE',
    SwitchOut = 'SWITCH OUT',
    Sospensione = 'SOSPENSIONE',
    VolturaConSwitchIn = 'VOLTURA CON SWITCH',
    Subentro = 'SUBENTRO',
    Attivazione = 'ATTIVAZIONE',
    ModificaDomiciliazione = 'MODIFICADOMICILIAZIONE',
    RevocaDomiciliazione = 'REVOCADOMICILIAZIONE',
    AttivazioneDomiciliazione = 'ATTIVAZIONEDOMICILIAZIONE',
    VolturaConCambioFornAmmVip = 'VOLTURA CON CAMBIO FORN. AMM. - VIP',
    VolturaConCambioFornAmmRecupero = 'VOLTURA CON CAMBIO FORN. AMM- RECUPERO',
    VariazioneTecnicaLavori = 'VARIAZIONE TECNICA LAVORI',
    VariazioneTecnicaVerifiche = 'VARIAZIONE TECNICA VERIFICHE',
    ScontoStandalone = 'SCONTO STAND ALONE',
}
export enum AptSaleabilityOperationType {
    SwitchIn = 'Switch-In',
    Attivazione = 'Attivazione',
    CambioProdotto = 'Cambio Prodotto',
    Voltura = 'Voltura',
    CambioContratto = 'Cambio Contratto',
    Subentro = 'Subentro',
    Cessione = 'Cessione',
    CambioProdottoDaDelibera = 'Cambio Prodotto da Delibera',
    CambioProdottoAmministrativo = 'Cambio Prodotto Amministrativo',
    Verifica = 'Verifica',
    Lavori = 'Lavori',
    DomiciliazioneStandAlone = 'Domiciliazione stand alone',
    VariazioneCommerciale = 'Variazione Commerciale',
    ScontoStandalone = 'Sconto Stand Alone',
    Nessuno = '',
}
