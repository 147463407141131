<div class="modal-content">
    <div class="modal-header mb-3">
        <egl-rectangle-icon *ngIf="showIcon" [icon]="icon"></egl-rectangle-icon>
        <h5 class="modal-title">
            <strong>{{ title | translate }}</strong>
        </h5>
        <button class="border border-dark" aria-label="Close" (click)="onCloseBtn()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div
        class="modal-body"
        [ngClass]="alignLeft ? 'text-left' : 'text-center'"
        [innerHtml]="description"
    ></div>
    <br />
    <div class="egl-modal-footer">
        <div class="" [ngClass]="txtBtnDx && txtBtnSx ? 'text-left' : 'text-center'" *ngIf="txtBtnSx">
            <button
                type="button"
                class="btn-modal blue btn-sm"
                data-dismiss="modal"
                (click)="onBtnSxClick()"
                [innerHtml]="txtBtnSx | translate"
            ></button>
        </div>
        <div class="text-center" *ngIf="txtBtnCn">
            <button
                type="button"
                class="btn-modal blue btn-sm"
                data-dismiss="modal"
                (click)="onBtnMdClick()"
                [innerHtml]="txtBtnCn | translate"
            ></button>
        </div>
        <div class="" [ngClass]="txtBtnDx && txtBtnSx ? 'text-right' : 'text-center'" *ngIf="txtBtnDx">
            <button
                type="button"
                class="btn-modal btn-sm"
                (click)="onBtnDxClick()"
                [innerHtml]="txtBtnDx | translate"
            ></button>
        </div>
    </div>
</div>
