import { Pipe, PipeTransform } from '@angular/core';
import {
    TroubleshootingFormValues,
    TroubleshootingQuestion,
} from '../../services/shared/troubleshooting-questions.service';

@Pipe({
    name: 'troubleshooter',
})
export class TroubleshooterPipe implements PipeTransform {
    transform(
        questions: TroubleshootingQuestion[],
        questionResult: number,
        values: TroubleshootingFormValues
    ): TroubleshootingQuestion[] {
        return questions.filter((question) => this.isVisible(question, questionResult, values));
    }

    isVisible(question: TroubleshootingQuestion, questionResult: number, values: TroubleshootingFormValues): boolean {
        return typeof question.visible === 'function'
            ? question.visible(questionResult, values || {})
            : (question.visible & questionResult) === question.visible;
    }
}
