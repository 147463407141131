<!-- <div class="this-container">
    <div class="layer-1">
        --<i [ngClass]="icon" class="icon-img" aria-hidden="true"></i>--
        <span class="icon-img" [innerHTML]="icon"></span>
    </div>
    <div class="layer-2" [style.background-color]="color"></div>
</div> -->

<p class="rect-icon__container">
    <span [innerHTML]="icon" class="rect-icon"></span>
</p>
