import { AptPartnerships } from '../../modules/common/partnership/enums/apt-partnerships';
import { SalesProcessPartNumber } from '../../store/models/order-entry-state';
import {
    ACTIVATION_GAS_FLOW_TYPES,
    ACTIVATION_POWER_FLOW_TYPES,
    D365_OPERATION_MODE,
    FLOW_PART_NUMBER_MAP,
    PROCESS_OPERATION_MAPPINGS,
    SIEBEL_SDR_COMMODITY_MAP,
} from '../map/mapping.config';
import { AptCommodityTypeOfSale } from '../enums/apttus/apt-commodity-typeof-sale';
import { AptCustomerType } from '../enums/apttus/apt-customer-type';
import { AptDoiConfirmModeType } from '../enums/apttus/apt-doi-confirm-mode-type';
import { AptSaleabilityOperationType, AptSalesProcess } from '../enums/apttus/apt-sales-process';
import { D365AgentProfileType } from '../enums/d365/d365-agent-profile-type';
import { D365AgentType } from '../enums/d365/d365-agent-type';
import { D365ConfirmationType } from '../enums/d365/d365-confirmation-type';
import { D365CustomerSegment } from '../enums/d365/d365-customer-segment';
import { D365OperationMode } from '../enums/d365/d365-operation-mode';
import { CONFIRMATION_TYPE_MAP } from '../map/confirmation-type-map';
import { isFlowTypeActivation } from './verifications.functions';
import { FlowType } from '../../store/models/flow-type';

export const flowTypeToD365OperationMode = (flowType: FlowType): D365OperationMode => {
    if (isFlowTypeActivation(flowType)) {
        return D365OperationMode.Attivazione;
    }
    return D365_OPERATION_MODE[flowType] || D365OperationMode.SwitchIn;
};

export const getAgentD365toApt = (type: D365AgentType | D365AgentProfileType): string => {
    switch (type) {
        case D365AgentType.Agent:
            return 'Agente';
        case D365AgentType.Backoffice:
            return 'Backoffice';
        case D365AgentType.TeamLeader:
            return 'Team leader';

        default:
            return '';
    }
};

export const convertSegmentD365toApt = (segment: D365CustomerSegment): AptCustomerType => {
    if (segment === D365CustomerSegment.Microbusiness) {
        return AptCustomerType.Microbusiness;
    }
    return AptCustomerType.Residenziale;
};

export const convertSegmentAptToD365 = (segment: AptCustomerType): D365CustomerSegment => {
    if (segment === AptCustomerType.Microbusiness) {
        return D365CustomerSegment.Microbusiness;
    }
    return D365CustomerSegment.Residential;
};

export const aptConfermationTypeToD365 = (confirmType: AptDoiConfirmModeType): D365ConfirmationType => {
    const d365ConfirmType = CONFIRMATION_TYPE_MAP[confirmType];
    if (!d365ConfirmType) {
        console.error(null, 'ConfirmationType', 'Tipologia non gestita', null, true);
    }
    return d365ConfirmType;
};

export const d365ConfermationTypeToApt = (confirmType: D365ConfirmationType): AptDoiConfirmModeType => {
    const [aptConfirmType] = Object.entries(CONFIRMATION_TYPE_MAP).find(([, d365]) => confirmType === d365);
    if (!aptConfirmType) {
        console.error(null, 'ConfirmationType', 'Tipologia non gestita', null, true);
    }
    return aptConfirmType as AptDoiConfirmModeType;
};

export const aptSalesProcessToFlowType = (salesProcess: AptSalesProcess): FlowType =>
    salesProcessOrOperationTypeToFlowType(salesProcess);

export const flowTypeToAptSalesProcess = (salesProcess: FlowType): AptSalesProcess =>
    operationTypeOrFlowTypeToSalesProcess(salesProcess);
/**
 * @description Esegue una mappatura dei valori del PAV "egl_partnership__c" verso una label adatta alla GUI
 * @param partnership Il valore raw della partnership proveniente dal PAV
 * @returns Label adatta all'utilizzo sull'interfaccia grafica. Il valore è da parsare usando il TranslateService
 */
export const aptPartnershipToString = (partnership: AptPartnerships): string => {
    switch (partnership) {
        case AptPartnerships.NO_PARTNERSHIP:
            return 'PARTNERSHIP.VALUE_LABELS.NO_PARTNERSHIP';
        case AptPartnerships.FASTWEB:
            return 'PARTNERSHIP.VALUE_LABELS.FASTWEB';
        default:
            return null;
    }
};

export const salesProcessOrFlowTypeToOperationType = (
    salesProcessOrFlowType: AptSalesProcess | FlowType
): AptSaleabilityOperationType => {
    const operationType =
        (salesProcessOrFlowType &&
            ((Object.entries(PROCESS_OPERATION_MAPPINGS).find(([operationType, flowTypeSalesProcess]) =>
                Object.entries(flowTypeSalesProcess).some(([flowType, salesProcess]) =>
                    [flowType].concat(salesProcess).includes(salesProcessOrFlowType)
                )
            ) || [])[0] as AptSaleabilityOperationType)) ||
        null;
    if (!operationType) {
        console.error(`salesProcessOrFlowTypeToOperationType - no mapping found for ${salesProcessOrFlowType}`);
    }
    return operationType;
};

export const operationTypeOrFlowTypeToSalesProcess = (
    operationTypeOrFlowType: AptSaleabilityOperationType | FlowType
): AptSalesProcess => {
    const salesProcess =
        (operationTypeOrFlowType &&
            Object.entries(PROCESS_OPERATION_MAPPINGS).reduce(
                (foundSalesProcess: AptSalesProcess, [operationType, flowTypeSalesProcess]) =>
                    foundSalesProcess ||
                    [].concat(
                        (Object.entries(flowTypeSalesProcess).find(
                            ([flowType]) =>
                                operationType === operationTypeOrFlowType || flowType === operationTypeOrFlowType
                        ) || [])[1]
                    )[0],
                null
            )) ||
        null;
    if (!salesProcess) {
        console.error(`operationTypeOrFlowTypeToSalesProcess - no mapping found for ${operationTypeOrFlowType}`);
    }
    return salesProcess;
};

export const salesProcessOrOperationTypeToFlowType = (
    salesProcessOrOperationType: AptSalesProcess | AptSaleabilityOperationType
): FlowType => {
    const fType =
        (salesProcessOrOperationType &&
            Object.entries(PROCESS_OPERATION_MAPPINGS).reduce(
                (foundFlowType: FlowType, [operationType, flowTypeSalesProcess]) =>
                    foundFlowType ||
                    ((Object.entries(flowTypeSalesProcess).find(
                        ([flowType, salesProcess]) =>
                            operationType === salesProcessOrOperationType ||
                            [].concat(salesProcess).includes(salesProcessOrOperationType)
                    ) || [])[0] as FlowType),
                null
            )) ||
        null;

    if (!fType) {
        console.error(`salesProcessOrOperationTypeToFlowType - no mapping found for ${salesProcessOrOperationType}`);
    }
    return fType;
};

export const flowTypeToPartNumber = (flowType: FlowType): SalesProcessPartNumber =>
    FLOW_PART_NUMBER_MAP[flowType] || null;

export const flowTypeToCommodityType = (flowType: FlowType): AptCommodityTypeOfSale => {
    return ACTIVATION_GAS_FLOW_TYPES.includes(flowType)
        ? AptCommodityTypeOfSale.Gas
        : ACTIVATION_POWER_FLOW_TYPES.includes(flowType)
        ? AptCommodityTypeOfSale.Power
        : null;
};

export const getSiebelSdrDescription = (flowType: FlowType): string => SIEBEL_SDR_COMMODITY_MAP[flowType];

export interface VendorCodeMap {
    vendorCode: string;
    companyName: string;
    vendorId: string;
}

/**
 * @description Gas vendors map list
 * @param vendorCode Codice Distributore NETA - da inviare per il servizio che fornisce i costi
 * @param vendorId Codice Distributore LineaPDR - servizio dati fornitura (PDR/Matricola)
 * @param companyName Ragione sociale del fornitore
 */
const GAS_VENDOR_CODES_MAP: VendorCodeMap[] = [
    {
        vendorCode: 'G00088',
        companyName: 'ITALGAS RETI S.p.A.',
        vendorId: '00489490011',
    },
    {
        vendorCode: 'G01596',
        companyName: 'TOSCANA ENERGIA S.p.A.',
        vendorId: '05608890488',
    },
    {
        vendorCode: 'G00102',
        companyName: "METANO SANT'ANGELO LODIGIANO S.p.A.",
        vendorId: '00757920152',
    },
    {
        vendorCode: 'G01603',
        companyName: 'UMBRIA DISTRIBUZIONE GAS S.p.A.',
        vendorId: '01356930550',
    },
];

export const getProviderInfo = (codeIdName: string): VendorCodeMap =>
    GAS_VENDOR_CODES_MAP.find(({ vendorCode, vendorId, companyName }) =>
        [vendorCode, vendorId, companyName].some(
            (value) => value.toLowerCase() === (codeIdName || '').trim().toLowerCase()
        )
    );
