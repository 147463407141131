export enum RoutesPaths {
    Settings = 'settings',
    SettingsNoBack = 'SettingsNoBack',
    Dashboard = 'dashboard',
    DashboardInitial = '/?v',
    SearchResult = 'dashboard/search-results',
    SearchResultFull = 'search-results/:field/:value',
    ProductDetail = ':id',
    ProductConfigurationFull = 'configuration/:id',
    ProductListCategory = 'category',
    ProductListCategoryFull = 'category/:categoryName',
    ProductDetailCartItem = ':id/:cartItem',
    Carts = 'carts',
    CartActive = 'carts/active',
    AllProducts = 'allproducts',
    Search = 'search/:query',
    TroubleShooting = 'troubleshooting/',
    TroubleShootingCommodity = 'troubleshooting/commodity',
    TroubleShootingCommodityGas = 'troubleshooting/commodity/gas',
    TroubleShootingCommodityPower = 'troubleshooting/commodity/power',
    TroubleShootingIvass = 'troubleshooting/ivass',
    TroubleShootingManutenzione = 'troubleshooting/manutenzione',
    OrderEntry = 'order-entry/',
    ModalitaAcquisizione = 'order-entry/dati-anagrafici/modalita-acquisizione',
    FormaAnagraficaMb = 'order-entry/dati-anagrafici/microbusiness-master-data',
    DatiGenerici = 'order-entry/dati-anagrafici/dati-generici',
    OcrBollettaUpload = 'order-entry/ocr-bolletta-upload',
    DatiContatto = 'order-entry/dati-anagrafici/dati-contatto',
    Indirizzi = 'order-entry/dati-prodotto/indirizzi',
    DatiTecniciAmministrativi = 'order-entry/dati-prodotto/dati-tecnici-amministrativi',
    DatiPod = 'order-entry/dati-prodotto/dati-pod',
    DatiPdr = 'order-entry/dati-prodotto/dati-pdr',
    DecretoCasa = 'order-entry/dati-prodotto/decreto-casa',
    KoCreditCheck = 'order-entry/dati-prodotto/ko-credit-check',
    PotenzaImpegnata = 'order-entry/dati-prodotto/potenza-impegnata',

    ConsumoGas = 'order-entry/dati-prodotto/consumo-gas',
    ConsumoGasMicrobusiness = 'order-entry/dati-prodotto/consumo-gas-mb',
    ConsumoLuce = 'order-entry/dati-prodotto/consumo-luce',
    CodiceAteco = 'order-entry/dati-prodotto/codice-ateco',
    DatiPagamento = 'order-entry/dati-pagamento',

    UploadDocumento = 'order-entry/upload-documento',
    InserimentoManualeDocumento = 'order-entry/inserimento-manuale-documento',

    SelezionaDocumento = 'order-entry/seleziona-documento',
    Riepilogo = 'order-entry/riepilogo',
    PrivacyDatiPersonali = 'order-entry/privacy/dati-personali',
    PrivacyConsensi = 'order-entry/privacy/consensi',
    SceltaFirma = 'order-entry/firma/scelta-firma',
    FirmaPlico = 'order-entry/firma/plico',
    TipoEsecuzione = 'order-entry/firma/tipo-esecuzione',
    FirmaVocalOrder = 'order-entry/firma/vocal-order/:backToBO/:contractCode/:channel/:quoteId/:cartId/:telephone',
    FirmaPreviaStampa = 'order-entry/firma/previa-stampa',
    FirmaDoiFull = 'order-entry/firma/doi-full',
    FirmaGrafometrica = 'order-entry/firma/grafometrica',
    FirmaCartacea = 'order-entry/firma/contratto-cartaceo',
    FirmaOk = 'order-entry/firma/ok',
    SceltaContatto = 'order-entry/scelta-contatto',
    VerificaCodice = 'order-entry/verifica-codice/:both/:smsCode/:emailCode/:code',
    ThankYouPage = 'thank-you-page',
    Login = 'login',
    ULogin = '/u/login',
    LeadRedirect = 'leadredirect',
    Error401 = 'errors/error-401',
    Error500 = 'errors/error-500',
    BackOffice = 'back-office',
    QuoteList = 'quote-list',
    QuoteListOld = 'quote-list-old',
    QuoteDetail = 'quote-list-old/:id',
    QuoteDetailCard = 'quote-list/:id',
    OrderDetailCard = 'order/:id',
    OrderDetailCardFromQuote = 'quote/order/:quoteid',
    UploadMp3 = 'upload-mp3',
    AssetDetail = 'asset/detail',
    // CP
    CP = 'cp/',
    CPDeliberaResume = 'cp/delibera/resume',
    CPAdminResume = 'cp/amministrativo/resume',
    CPDeliberaThankYouPage = 'cp/delibera/thank-you-page/:firstName/:lastName',
    // CP COMMERCIALE
    CPProductList = 'cp/allproducts/:assetId',
    CPProductDetails = 'cp/allproducts/productdetails/:assetId/:productId',
    CPProductConfiguration = 'cp/allproducts/productconfig/:id/:cartItem',
    // MANUTENZIONE
    Offline = 'errors/offline',
    // CESSAZIONE
    TerminationCart = 'termination/cart/:cartId',
    TerminationCosts = 'termination/costs',
    TerminationMortisCausa = 'termination/mortis-causa',
    TerminationContacts = 'termination/contacts',
    TerminationDate = 'termination/date-term',
    TerminationAppointment = 'termination/appointment',
    TerminationRecap = 'termination/recap',

    //PARTNERSHIPS
    PsFastwebPersonalData = 'order-entry/partnership/fastweb',
    PsFastwebActiveContracts = 'order-entry/partnership/fastweb/active-contract',

    // SOSPENSIONE
    SuspensionBasePath = 'suspension',
    SuspensionForm = 'suspension/suspension-form/:pdf/:accountNumber/:isAdministrative',
    SuspensionCancelForm = 'suspension/suspension-cancel-form/:pdf/:accountNumber',
    SuspensionThankYouPage = 'suspension/suspension-thank-you-page',

    // INTERRUZIONE
    InterruptionBasePath = 'interruption',
    InterruptionForm = 'interruption/interruption-form/:pdf/:accountNumber/:isAdministrative',
    InterruptionCancelForm = 'interruption/interruption-cancel-form/:pdf/:accountNumber',
    InterruptionThankYouPage = 'interruption/interruption-thank-you-page',

    // RIAPERTURA
    ResumingBasePath = 'resuming',
    ResumingForm = 'resuming/resuming-form/:pdf/:accountNumber/:isAdministrative',
    ResumingCancelForm = 'resuming/resuming-cancel-form/:pdf/:accountNumber',
    ResumingThankYouPage = 'resuming/resuming-thank-you-page',

    // CESSAZIONE AMMINISTRATIVA
    AdminTerminationBasePath = 'admin-termination',
    AdminTermination = 'admin-termination/:pdf/:accountNumber/:template',
    AdminTerminationThankYouPage = 'admin-termination/thank-you-page',

    // STATUS
    StatusCancellation = 'status/cancellation',

    //DOMICILIAZIONE
    Domiciliation = 'domiciliation',
    DomiciliationResume = 'domiciliation/resume',

    //SCONTI
    DiscountList = 'sconti/:accountId',

    // ATTIVAZIONE SEMPLICE
    TroubleShootingAttivazione = 'order-entry/troubleshooting/attivazione',
    DatiPdrAttivazione = 'order-entry/dati-pdr-matricola',
    AttivazioneTipologiaImmobile = 'order-entry/attivazione/tipologia-immobile',
    AttivazioneDataAppuntamento = 'order-entry/attivazione/presa-appuntamento',
    AttivazioneDatiAppuntamento = 'order-entry/attivazione/dati-appuntamento',
    CostiAttivazione = 'order-entry/costi-attivazione',
    ConsumoGasAttivazione = 'order-entry/dati-prodotto/consumo-gas-act-mb',

    // Variazione tecnica verifiche
    VariazioneTecnica = 'variazione-tecnica',

    // CARICATORE MASSIVO
    BulkOrderBasePath = 'bulk-order',
    BulkOrderMain = 'main',
    BulkOrderUpload = 'upload',
    BulkOrderFileContent = 'file-content/:id',
    BulkOrderThankYouPage = 'thank-you-page',
}

/**
 * Return a URL with params.
 * ex: cp/allproducts/:assetId => cp/allproducts/1223
 * @param path
 * @param params
 */
export const urlBuilder = (path: string, params: string[]): string => {
    if (path) {
        const baseUrl = path.split('/:')[0];
        const paramsS = params.join('/');
        if (paramsS) {
            return `${baseUrl}/${paramsS}`;
        }
    }
    return path;
};

export const urlJoiner = (v: string[]): string => {
    return v ? v.join('/') : '';
};
