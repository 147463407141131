/* tslint:disable:variable-name */
import { AField, AObject, ATable } from '@congacommerce/core';
import { Type } from 'class-transformer';
import { EglQuoteLineItemLight } from './egl-quote-line-item-light';
import { EglAccountLight } from '../user/egl-account-light';
import { EglContactLight } from '../user/egl-contact-light';
import { EglAccountLocationLight } from '../user/egl-account-location-light';
import { EglUserLight } from '../user/egl-user-light';
import { AptSignatureMode } from '../../../../enums/apttus/apt-signature-mode';
import { EglPaymentTool } from '../domiciliation/egl-payment-tool';
import { ArrayType } from '../../../../functions/decorators';
import { AptSalesProcess } from '../../../../enums/apttus/apt-sales-process';
import { AptConfirmMode } from '../../../../enums/apttus/apt-confirm-mode';
import { AptMigrationMasterCode } from '../../../../enums/apttus/apt-master-migration-code';

@ATable({
    sobjectName: 'Apttus_Proposal__Proposal__c',
    aqlName: null,
})
export class EglQuoteLight extends AObject {
    constructor() {
        super();
    }
    //////////////////////////////////////////////////////////// OTB

    @AField({ soql: 'Name' })
    Name: string;

    @AField({ soql: 'Proposal_Name' })
    Proposal_Name: string;

    @AField({ soql: 'AccountId' })
    AccountId: string;

    @AField({ soql: 'Apttus_Proposal__Account__r', aql: 'Account', expand: 'deep' })
    @Type(() => EglAccountLight)
    Account: EglAccountLight;

    @AField({ soql: 'Primary_ContactId' })
    Primary_ContactId: string;

    @AField({ soql: 'Apttus_Proposal__Primary_Contact__r', expand: 'deep' })
    @Type(() => EglContactLight)
    Primary_Contact: EglContactLight;

    @AField({ soql: 'BillToAccountId' })
    BillToAccountId: string;

    @AField({ soql: 'ShipToAccountId' })
    ShipToAccountId: string;

    @AField({ soql: 'LocationId' })
    LocationId: string;

    @AField({ soql: 'Apttus_QPConfig__LocationId__r', expand: 'deep' })
    @Type(() => EglAccountLocationLight)
    Location: EglAccountLocationLight;

    @AField({ soql: 'AutoActivateOrder' })
    AutoActivateOrder: boolean;

    @AField({ soql: 'PriceListId' })
    PriceListId: string;

    @AField({ soql: 'CurrencyIsoCode' })
    CurrencyIsoCode: string;

    @AField({ soql: 'Approval_Stage' })
    Approval_Stage: string;

    @AField({ soql: 'OwnerId' })
    OwnerId: string;

    @AField({
        soql: 'Owner',
        expand: 'deep',
    })
    @Type(() => EglUserLight)
    Owner: EglUserLight;

    @AField({
        soql: 'Apttus_Proposal__R00N70000001yUfBEAU__r',
        expand: 'shallow',
    })
    @ArrayType(() => EglQuoteLineItemLight)
    R00N70000001yUfBEAU: Array<EglQuoteLineItemLight>;

    get QuoteLineItems(): Array<EglQuoteLineItemLight> {
        return this.R00N70000001yUfBEAU;
    }

    //////////////////////////////////////////////////////////// END OTB

    @AField({ soql: 'egl_quote_status' })
    egl_quote_status: string;

    @AField({ soql: 'egl_iscustomercommodity' })
    egl_iscustomercommodity = false;

    @AField({ soql: 'egl_quote_substatus' })
    egl_quote_substatus: string;

    @AField({ soql: 'egl_acquistion_mode' })
    egl_acquistion_mode = 'Attiva(On-line)';

    @AField({ soql: 'egl_contract_code' }) // codice plico
    egl_contract_code: string;

    @AField({ soql: 'egl_sales_process' })
    egl_sales_process: AptSalesProcess;

    @AField({ soql: 'egl_sales_process_legacy' })
    egl_sales_process_legacy: string;

    // @AField({ soql: 'egl_customer_type' })
    // egl_customer_type: AptCustomerType;

    @AField({ soql: 'egl_createdon' })
    egl_createdon = new Date();

    @AField({ soql: 'egl_commodity_pricelist' })
    egl_commodity_pricelist = 'Base';

    //////////////////////////////////////////////////////////// CREDIT CHECK
    @AField({ soql: 'egl_cribis_status' })
    egl_cribis_status: string;

    @AField({ soql: 'egl_backlist_status' })
    egl_backlist_status: string;

    @AField({ soql: 'egl_blacklist_2_status' })
    egl_blacklist_2_status: string;

    @AField({ soql: 'egl_whitelist_status' })
    egl_whitelist_status: string;

    @AField({ soql: 'egl_blacklist_3_status' })
    egl_blacklist_3_status: string;

    @AField({ soql: 'egl_credit_check' })
    egl_credit_check: string;

    @AField({ soql: 'egl_sdi_status' })
    egl_sdi_status: string;

    @AField({ soql: 'egl_scipafi' })
    egl_scipafi: string;

    @AField({ soql: 'egl_unsolved' })
    egl_unsolved: string;

    @AField({ soql: 'egl_unsolved_fc_vat' })
    egl_unsolved_fc_vat: string;

    @AField({ soql: 'egl_unsolved_iban' })
    egl_unsolved_iban: string;
    //////////////////////////////////////////////////////////// END CREDIT CHECK

    //////////////////////////////////////////////////////////// CHANNEL AND AGENCY

    @AField({ soql: 'egl_agency_code' }) // Codice Agenzia
    egl_agency_code: string;

    @AField({ soql: 'egl_sales_channel' }) // Canale di vendita
    egl_sales_channel: string;

    @AField({ soql: 'egl_system_source' }) // Sistema Sorgente
    egl_system_source = 'SalesApp';

    @AField({ soql: 'egl_user_code' }) // Codice Utente
    egl_user_code: string;

    @AField({ soql: 'egl_date_sign_RUI_agency' }) // Data Iscrizione RUI Agenzia
    egl_date_sign_RUI_agency: string;

    @AField({ soql: 'egl_sales_agent' }) // Agente
    egl_sales_agent: string;

    @AField({ soql: 'egl_employee_firstname' }) // Nome Addetto
    egl_employee_firstname: string;

    @AField({ soql: 'egl_intermediary_firstname' }) // Nome Intermediario
    egl_intermediary_firstname: string;

    @AField({ soql: 'egl_seat_name' }) // Nome Sede Operativa
    egl_seat_name: string;

    @AField({ soql: 'egl_Operational_seats' }) // Nome Sede Operativa
    egl_Operational_seats: string;

    @AField({ soql: 'egl_mail_seat' }) // Email Sede Operativa
    egl_mail_seat: string;

    @AField({ soql: 'egl_agency_mail' }) // Email Agenzia
    egl_agency_mail: string;

    @AField({ soql: 'egl_number_sign_RUI_agency' }) // N° Iscrizione RUI Agenzia
    egl_number_sign_RUI_agency: string;

    @AField({ soql: 'egl_Pec_seat' }) // PEC Sede Operativa
    egl_Pec_seat: string;

    @AField({ soql: 'egl_company_name' }) // Ragione Sociale Agenzia
    egl_company_name: string;

    @AField({ soql: 'egl_address_Agency' }) // Indirizzo Agenzia
    egl_address_Agency: string;

    @AField({ soql: 'egl_system_origin' }) // Sistema di Origine
    egl_system_origin: string;

    @AField({ soql: 'egl_instore_sale' }) // Vendita in Negozio
    egl_instore_sale: string;

    @AField({ soql: 'egl_employee_lastname' }) // Cognome Addetto
    egl_employee_lastname: string;

    @AField({ soql: 'egl_intermediary_lastname' }) // Cognome Intermediario
    egl_intermediary_lastname: string;

    @AField({ soql: 'egl_salesapp_code' }) // Codice SalesApp
    egl_salesapp_code: string;

    @AField({ soql: 'egl_date_sign_RUI_intermediary' }) // Data Iscrizione RUI Intermediario
    egl_date_sign_RUI_intermediary: string;

    @AField({ soql: 'egl_address_seat' }) // Indirizzo Sede Operativa
    egl_address_seat: string;

    @AField({ soql: 'egl_number_sign_intermediary' }) // N° Iscrizione RUI Intermediario
    egl_number_sign_intermediary: string;

    @AField({ soql: 'egl_pec_agency' }) // PEC Agenzia
    egl_pec_agency: string;

    @AField({ soql: 'egl_phone_agency' }) // Telefono Agenzia
    egl_phone_agency: string;

    @AField({ soql: 'egl_phone_seat' }) // Telefono Sede Operativa
    egl_phone_seat: string;

    @AField({ soql: 'egl_sales_agent_blacklist' }) // Telefono Sede Operativa
    egl_sales_agent_blacklist: boolean;

    //////////////////////////////////////////////////////////// END CHANNEL AND AGENCY

    //////////////////////////////////////////////////////////// FIRMA & CONFERMA
    // /*

    @AField({ soql: 'egl_signature_mode' }) //
    egl_signature_mode: AptSignatureMode;

    @AField({ soql: 'egl_sendon' }) // DataOra Invio
    egl_sendon = new Date();

    @AField({ soql: 'egl_doi_status' }) // DOI Status
    egl_doi_status: string;

    @AField({ soql: 'egl_checkcall_blocking' }) // CheckCall Bloccante
    egl_checkcall_blocking = false;

    @AField({ soql: 'egl_signed_date' }) // Data Firma Offerta
    egl_signed_date: string;

    @AField({ soql: 'egl_doi_cancel_reason' }) // DOI Cancel Reason
    egl_doi_cancel_reason: string;

    @AField({ soql: 'egl_confirm_mode' }) // Modalità di conferma (Check-Call | Check-DOI)
    egl_confirm_mode: AptConfirmMode;

    @AField({ soql: 'egl_doi_check_date' }) // DOI check data esito
    egl_doi_check_date: string;

    @AField({ soql: 'egl_doi_check_status' }) // DOI check status
    egl_doi_check_status: string;

    @AField({ soql: 'egl_doi_return_type' }) // DOI con cui ha interagito ( numero cellulare o indirizzo email)
    egl_doi_return_type: string;

    @AField({ soql: 'egl_doi_date' }) // Data di interazione DOI
    egl_doi_date: string;

    //////////////////////////////////////////////////////////// FIRMA & CONFERMA

    //////////////////////////////////////////////////////////// PRIVACY

    @AField({ soql: 'egl_privacy_marketanalysis' }) // Consensi Analisi Ricerche
    egl_privacy_marketanalysis = true;

    @AField({ soql: 'egl_privacy_self_initiative' }) // Consensi Iniziative EGL
    egl_privacy_self_initiative = true;

    @AField({ soql: 'egl_privacy_thirdparties_initiative' }) // Consensi Iniziative Terzi
    egl_privacy_thirdparties_initiative = true;

    @AField({ soql: 'egl_privacy_personal_data' }) // Privacy Dati Personali
    egl_privacy_personal_data = true;

    //////////////////////////////////////////////////////////// END PRIVACY

    //////////////////////////////////////////////////////////// DOCUMENTS

    @AField({ soql: 'egl_document_type' }) // tipo documento
    egl_document_type: string;

    @AField({ soql: 'egl_document_realease' }) // data rilascio documento
    egl_document_realease: string;

    @AField({ soql: 'egl_document_code' }) // Numero documento
    egl_document_code: string;

    @AField({ soql: 'egl_doc_issuing_body' }) // Ente emittente documento
    egl_doc_issuing_body: string;

    @AField({ soql: 'egl_real_estate_ownership' }) // Titolo immobile
    egl_real_estate_ownership: string;

    //////////////////////////////////////////////////////////// END DOCUMENTS

    //////////////////////////////////////////////////////////// ACTION

    @AField({ soql: 'egl_mp3_vocal_order' }) // MP3 VO
    egl_mp3_vocal_order: string;

    //////////////////////////////////////////////////////////// END ACTION

    //////////////////////////////////////////////////////////// MP3

    @AField({ soql: 'egl_mp3_sent' }) // MP3 CARICATO
    egl_mp3_sent = false;

    @AField({ soql: 'egl_mp3_date' }) // DATA CARICAMENTO MP3
    egl_mp3_date: Date;

    // */
    //////////////////////////////////////////////////////////// END MP3

    @AField({ soql: 'egl_orderentrydata' }) // REDUX STATE
    egl_orderentrydata: string;

    @AField({ soql: 'egl_storage_plico' })
    egl_storage_plico: string;

    @AField({ soql: 'egl_credit_check_frmla' }) // ESITO CREDIT CHECK
    egl_credit_check_frmla: string;

    @AField({ soql: 'egl_esito_precheck' }) // ESITO CREDIT CHECK
    egl_esito_precheck: string;

    @AField({ soql: 'egl_flowunlock' })
    egl_flowunlock = false;

    @AField({ soql: 'egl_Virtual_Agent_Code' }) // CODICE AGENTE VIRTUALE
    egl_Virtual_Agent_Code = '';

    @AField({ soql: 'egl_Co_Code' }) // CODICE CO
    egl_Co_Code = '';

    @AField({ soql: 'egl_leadid' }) // LEAD ID
    egl_leadid: string;

    @AField({ soql: 'egl_sales_channel_desc' })
    egl_sales_channel_desc: string;

    @AField({ soql: 'egl_delivery_channel' })
    egl_delivery_channel: string;

    //////////////////////////////////////////////////////////// BACK OFFICE

    @AField({ soql: 'egl_BOWelcomeCall' })
    egl_BOWelcomeCall = false;

    @AField({ soql: 'egl_BONotes' })
    egl_BONotes: string;

    @AField({ soql: 'egl_bo_validate' })
    egl_bo_validate: string;

    @AField({ soql: 'egl_BOReason' })
    egl_BOReason: string;

    //////////////////////////////////////////////////////////// DATI UTENTE

    @AField({ soql: 'egl_user_profile' }) // TIPOLOGIA AGENTE
    egl_user_profile: string;

    @AField({ soql: 'egl_address_registered_office' })
    egl_address_registered_office: string;

    @AField({ soql: 'egl_codice_allegato_rds' })
    egl_codice_allegato_rds: string;

    @AField({ soql: 'egl_codice_allegato_1' })
    egl_codice_allegato_1: string;

    @AField({ soql: 'egl_signed_datetime' })
    egl_signed_datetime: Date;

    // Campi capagana lead
    @AField({ soql: 'egl_campaign_id' })
    egl_campaign_id: string;

    @AField({ soql: 'egl_campaign_name' })
    egl_campaign_name: string;

    @AField({ soql: 'egl_campaign_code' })
    egl_campaign_code: string;

    @AField({ soql: 'egl_campaign_type_code' })
    egl_campaign_type_code: string;

    // Campi in caso di CF errato
    @AField({ soql: 'egl_previous_fiscalcode' })
    egl_previous_fiscalcode: string;

    @AField({ soql: 'egl_customer_code' })
    egl_customer_code: string;

    @AField({ soql: 'egl_retry_doi' })
    egl_retry_doi: number;

    @AField({ soql: 'egl_doi_details' })
    egl_doi_details: string;

    @AField({ soql: 'egl_cancellation_date' })
    egl_cancellation_date: Date;

    @AField({ soql: 'egl_decline_date' })
    egl_decline_date: Date;

    @AField({ soql: 'egl_confirm_date' })
    egl_confirm_date: Date;

    @AField({ soql: 'egl_dalay_date' })
    egl_dalay_date: Date;

    @AField({ soql: 'egl_code_NewDom' })
    egl_code_NewDom: string;

    @AField({ soql: 'egl_Effective_date__c' })
    egl_Effective_date__c: string;

    @AField({ soql: 'egl_immediate_effect__c' })
    egl_immediate_effect__c: boolean;

    @AField({ soql: 'egl_delayed_insertion' })
    egl_delayed_insertion: boolean;

    @AField({ soql: 'egl_selling_order_number__c' })
    egl_selling_order_number__c: string;

    @AField({ soql: 'egl_favorite_communication_channel__c' })
    egl_favorite_communication_channel__c: string;

    @AField({ soql: 'egl_mortis_causa__c' })
    egl_mortis_causa__c: boolean;

    @AField({ soql: 'egl_old_Email__c' })
    egl_old_Email__c: string;

    @AField({ soql: 'egl_old_MobilePhone__c' })
    egl_old_MobilePhone__c: string;

    @AField({ soql: 'egl_telephone__c' })
    egl_telephone__c: string;

    @AField({ soql: 'egl_email__c' })
    egl_email__c: string;

    @AField({ soql: 'egl_attachmentToArchive__c' })
    egl_attachmentToArchive: boolean;

    @AField({ soql: 'egl_payment_tool__c' })
    @AField({
        soql: 'egl_payment_tool__c',
        expand: 'deep',
    })
    @Type(() => EglPaymentTool)
    egl_payment_tool__c: EglPaymentTool;

    @AField({ soql: 'egl_contact_telephone1__c' })
    egl_contact_telephone1__c: string;

    @AField({ soql: 'egl_contact_telephone2__c' })
    egl_contact_telephone2__c: string;

    @AField({ soql: 'egl_migration_mastercode' })
    egl_migration_mastercode: AptMigrationMasterCode;
}
