export class BaseApexApiResponse {
    status: StatusApexResponse;
    result: string;
    errorMessage?: string;
    constructor(status: StatusApexResponse = StatusApexResponse.Failed) {
        this.status = status;
    }
}

export enum StatusApexResponse {
    Success = 'OK',
    Failed = 'KO',
}
