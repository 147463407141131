import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoginGuard, GuestGuard } from '@congacommerce/ecommerce';
import { OrderEntryOutletComponent } from './routing-outlet/order-entry-outlet.component';
import { RoutesPaths } from './common/config/routes-paths';
import { BoOutletComponent } from './routing-outlet/bo-outlet.component';
import { PageNotFoundComponent } from './errors/page-not-found/page-not-found.component';
import { ActivationCartResolver } from './modules/common/products/services/activationcart.service';

@NgModule({
    imports: [
        RouterModule.forRoot(
            [
                {
                    path: 'u',
                    loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginModule),
                    canActivate: [GuestGuard],
                    // data: {
                    //     redirectUrl: '',
                    // },
                },
                {
                    path: '',
                    runGuardsAndResolvers: 'always',
                    canActivate: [LoginGuard],
                    data: {
                        redirectUrl: RoutesPaths.ULogin,
                    },
                    children: [
                        {
                            path: RoutesPaths.BackOffice,
                            component: BoOutletComponent,
                            loadChildren: () =>
                                import('./modules/back-office/back-office.module').then((m) => m.BackOfficeModule),
                        },
                        {
                            path: 'bulk-order',
                            component: BoOutletComponent,
                            loadChildren: () =>
                                import('./modules/bulk-order/bulk-order.module').then((m) => m.BulkOrderModule),
                        },
                        {
                            path: 'asset',
                            component: BoOutletComponent,
                            loadChildren: () =>
                                import('./modules/asset-product/asset.module').then((m) => m.AssetModule),
                        },
                        {
                            path: '',
                            component: OrderEntryOutletComponent,
                            runGuardsAndResolvers: 'always',
                            resolve: [ActivationCartResolver],
                            children: [
                                {
                                    path: '',
                                    redirectTo: RoutesPaths.Dashboard,
                                    pathMatch: 'full',
                                },
                                {
                                    path: '',
                                    loadChildren: () =>
                                        import('./modules/settings/settings.module').then((m) => m.SettingsModule),
                                },
                                {
                                    path: '',
                                    loadChildren: () => import('./errors/errors.module').then((m) => m.ErrorsModule),
                                },
                                {
                                    path: RoutesPaths.LeadRedirect,
                                    loadChildren: () =>
                                        import('./modules/switch-in/lead-redirect/lead-redirect.module').then(
                                            (m) => m.LeadRedirectModule
                                        ),
                                },
                                {
                                    path: RoutesPaths.Dashboard,
                                    loadChildren: () =>
                                        import('./modules/switch-in/dashboard/dashboard.module').then(
                                            (m) => m.DashboardModule
                                        ),
                                },
                                {
                                    path: RoutesPaths.AllProducts,
                                    runGuardsAndResolvers: 'always',
                                    loadChildren: () =>
                                        import('./modules/switch-in/products/products.module').then(
                                            (m) => m.ProductsModule
                                        ),
                                },
                                {
                                    path: RoutesPaths.Search,
                                    loadChildren: () =>
                                        import('./modules/switch-in/products/products.module').then(
                                            (m) => m.ProductsModule
                                        ),
                                    data: { title: 'Search' },
                                },
                                {
                                    path: RoutesPaths.Carts,
                                    loadChildren: () =>
                                        import('./modules/common/carts/carts.module').then((m) => m.CartsModule),
                                },

                                {
                                    path: '',
                                    loadChildren: () =>
                                        import(
                                            './modules/activation/troubleshooting/troubleshooting-attivazione.module'
                                        ).then((m) => m.TroubleshootingAttivazioneModule),
                                },
                                {
                                    path: '',
                                    loadChildren: () =>
                                        import(
                                            './modules/switch-in/order-entry/steps/01-dati-anagrafici/dati-anagrafici.module'
                                        ).then((m) => m.DatiAnagraficiModule),
                                },
                                {
                                    path: '',
                                    loadChildren: () =>
                                        import(
                                            './modules/switch-in/order-entry/steps/02-dati-prodotto/dati-prodotto.module'
                                        ).then((m) => m.DatiProdottoModule),
                                },
                                {
                                    path: RoutesPaths.VariazioneTecnica,
                                    loadChildren: () =>
                                        import('./modules/switch-in/technical-changes/technical-changes.module').then(
                                            (m) => m.TechnicalChangesModule
                                        ),
                                },
                                {
                                    path: '',
                                    loadChildren: () =>
                                        import('./modules/activation/dati-prodotto-attivazione.module').then(
                                            (m) => m.DatiProdottoAttivazioneModule
                                        ),
                                },
                                {
                                    path: '',
                                    loadChildren: () =>
                                        import(
                                            './modules/activation/components/tipologia-immobile/tipologia-immobile.module'
                                        ).then((m) => m.TipologiaImmobileModule),
                                },
                                {
                                    path: '',
                                    loadChildren: () =>
                                        import(
                                            './modules/activation/activation-appointment/presa-appuntamento.module'
                                        ).then((m) => m.PresaAppuntamentoModule),
                                },
                                {
                                    path: '',
                                    loadChildren: () =>
                                        import(
                                            './modules/common/order-entry/steps/03-dati-pagamento/dati-pagamento.module'
                                        ).then((m) => m.DatiPagamentoModule),
                                },
                                {
                                    path: '',
                                    loadChildren: () =>
                                        import(
                                            './modules/common/order-entry/steps/04-acquisizione-documento/acquisizione-documento.module'
                                        ).then((m) => m.AcquisizioneDocumentoModule),
                                },
                                {
                                    path: '',
                                    loadChildren: () =>
                                        import('./modules/common/order-entry/steps/05-riepilogo/riepilogo.module').then(
                                            (m) => m.RiepilogoModule
                                        ),
                                },
                                {
                                    path: '',
                                    loadChildren: () =>
                                        import('./modules/common/order-entry/steps/06-firma/firma.module').then(
                                            (m) => m.FirmaModule
                                        ),
                                },
                                {
                                    path: '',
                                    loadChildren: () =>
                                        import(
                                            './modules/common/order-entry/steps/07-verifica-contatto/verifica-contatto.module'
                                        ).then((m) => m.VerificaContattoModule),
                                },
                                {
                                    path: '',
                                    loadChildren: () =>
                                        import(
                                            './modules/common/order-entry/steps/08-thank-you-page/thank-you-page.module'
                                        ).then((m) => m.ThankYouPageModule),
                                },
                                {
                                    path: '',
                                    loadChildren: () =>
                                        import(
                                            './modules/common/order-entry/steps/06-firma/privacy/privacy.module'
                                        ).then((m) => m.PrivacyModule),
                                },
                                {
                                    path: '',
                                    loadChildren: () =>
                                        import(
                                            './modules/change-product/delibera-amministrativo/cp-delibera-admin.module'
                                        ).then((m) => m.CpDeliberaAdminModule),
                                },
                                {
                                    path: '',
                                    loadChildren: () =>
                                        import('./modules/common/partnership/partnership.module').then(
                                            (m) => m.PartnershipModule
                                        ),
                                },
                                {
                                    path: '',
                                    loadChildren: () =>
                                        import(
                                            './modules/change-product/commerciale/products/cp-comm-products.module'
                                        ).then((m) => m.CpCommProductsModule),
                                },
                                {
                                    path: '',
                                    loadChildren: () =>
                                        import('./modules/termination/termination.module').then(
                                            (m) => m.TerminationModule
                                        ),
                                },
                                {
                                    path: '',
                                    loadChildren: () =>
                                        import('./modules/status/status.module').then((m) => m.StatusModule),
                                },
                                {
                                    path: '',
                                    loadChildren: () =>
                                        import('./modules/domiciliation/domiciliation.module').then(
                                            (m) => m.DomiciliationModule
                                        ),
                                },
                                {
                                    path: '',
                                    loadChildren: () =>
                                        import('./modules/morosity/morosity.module').then((m) => m.MorosityModule),
                                },
                                {
                                    path: '**', // Wildcard route for a 404 page
                                    redirectTo: RoutesPaths.Dashboard,
                                },
                            ],
                        },
                    ],
                },
            ],
            {
                // useHash: environment.hashRouting,
                scrollPositionRestoration: 'enabled',
                relativeLinkResolution: 'legacy',
            }
        ),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
