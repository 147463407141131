import { Injectable } from '@angular/core';
import { FlowType } from '../../../store/models/flow-type';

export enum Questions {
    ANY = 0b0000000000,
    // 'Luce?'
    POWER = 0b0000000001,
    // 'Gas?'
    GAS = 0b0000000010,
    // 'Il cliente ha il contatore?'
    HAS_METER = 0b0000000100,
    // 'La tua fornitura è attiva?'
    IS_ACTIVE = 0b0000001000,
    // 'Il fornitore è Eni gas e luce?'
    IS_EGL = 0b0000010000,
    // 'Il contratto è intestato a te?'
    ACCOUNT_OWNERSHIP = 0b0000100000,
    // 'Il cliente intende attivare la fornitura per una pertinenza?'
    IS_BASEMENT = 0b0001000000,
    // 'È allacciato alla rete?'
    // "C'è l'attacco per il contatore (mensola)?"
    IS_CONNECTED = 0b0010000000,
    // "L'impianto è stato recentemente costruito e mai utilizzato?"
    NEVER_USED = 0b0100000000,
    // "Il cliente ha modificato l'impianto esistente?"
    MODIFIED_WIRING = 0b1000000000,
}

@Injectable()
export class TroubleshootingValuesService {
    constructor() {
        // PER FAVORE NON AGGIUNGERE IL FLOWTYPE! NON HA SENSO!
    }

    public readonly salesProcessesQuestions: SalesProcessesQuestionsType = {
        [FlowType.SwitchIn]: (answer) => answer >> 2 === (Questions.IS_ACTIVE + Questions.ACCOUNT_OWNERSHIP) >> 2,
        [FlowType.Attivazione]: (answer) => answer >> 2 === Questions.ANY || answer >> 2 === Questions.IS_BASEMENT >> 2,

        [FlowType.CPCommerciale]: (answer) =>
            answer >> 2 === (Questions.IS_ACTIVE + Questions.ACCOUNT_OWNERSHIP + Questions.IS_EGL) >> 2,
        [FlowType.SwitchInVoltura]: (answer) => answer >> 2 === Questions.IS_ACTIVE >> 2,

        [FlowType.Voltura]: (answer) =>
            [Questions.ANY, Questions.HAS_METER >> 2].includes(
                (answer ^ (Questions.IS_ACTIVE + Questions.IS_EGL)) >> 2
            ),
        [FlowType.AttivazioneGasConContatore]: (answer) => answer === Questions.GAS + Questions.HAS_METER,
        [FlowType.AttivazioneGasSenzaContatore]: (answer) => answer === Questions.GAS + Questions.IS_CONNECTED,
        [FlowType.AttivazioneA40]: (answer) =>
            answer === Questions.GAS + Questions.IS_CONNECTED + Questions.NEVER_USED ||
            answer === Questions.GAS + Questions.HAS_METER + Questions.NEVER_USED ||
            answer === Questions.GAS + Questions.HAS_METER + Questions.MODIFIED_WIRING,
        [FlowType.AttivazionePN1]: (answer) => answer === Questions.GAS,
        [FlowType.AttivazionePwrS01]: (answer) => answer === Questions.POWER + Questions.HAS_METER,
        [FlowType.AttivazioneN02]: (answer) => answer === Questions.POWER,
        [FlowType.AttivazionePwrA01]: (answer) =>
            answer === Questions.POWER + Questions.HAS_METER + Questions.NEVER_USED ||
            answer === Questions.POWER + Questions.IS_CONNECTED ||
            answer === Questions.POWER + Questions.HAS_METER + Questions.MODIFIED_WIRING,
        [FlowType.SwitchInPertinenza]: (answer) =>
            answer >> 2 === (Questions.IS_ACTIVE + Questions.ACCOUNT_OWNERSHIP + Questions.IS_BASEMENT) >> 2,
    };

    public matchSalesProcess(answer: number): FlowType {
        return typeof answer !== 'number'
            ? null
            : ((Object.entries(this.salesProcessesQuestions).find(([, matcher]) => matcher(answer)) ||
                  [])[0] as FlowType) || null;
    }
}

export type SalesProcessesQuestionsType = {
    [key in FlowType]?: (Number) => boolean;
};
