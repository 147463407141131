import * as _ from 'lodash';
import { GetAgentFromCrmResponse } from '../../models/d365/get-agent-from-crm-response';
import { AgentInfo, VirtualAgent } from '../../models/user/agent';
import { LoggerService } from '../shared/logger.service';
export class D365ResponseBuilder {
    constructor(private logger: LoggerService) {}
    agentInfoResponseToStore(d365Res: GetAgentFromCrmResponse): AgentInfo {
        const res = _.clone(d365Res);
        if (res?.VirtualAgents) {
            if (!res.Agent.Current) {
                res.Agent.Current = res.VirtualAgents[0].VirtualAgentId;
            }
            res.VirtualAgents.forEach((element: VirtualAgent) => {
                element.IsCurrentSelected = element.VirtualAgentId === res.Agent.Current;
            });
        } else {
            debugger;
            this.logger.error(`No VirtualAgents. RESP: ${JSON.stringify(res?.Response || 'NULL')}`);
        }
        return res as unknown as AgentInfo;
    }
}
