export enum AptQuoteStatus {
    Cancellato = 'CANCELLATO',
    InAttesaFirma = 'IN ATTESA FIRMA',
    Firmato = 'FIRMATO',
    InAttesaConferma = 'IN ATTESA CONFERMA',
    Confermato = 'CONFERMATO',
    DaGestire = 'DA GESTIRE',
    DaValidareBo = 'DA VALIDARE BO',
    Rifiutato = 'RIFIUTATO',
    Sospeso = 'SOSPESO',
    Completato = 'COMPLETATO',

    InGestione = 'IN GESTIONE',
    InCompilazione = 'IN COMPILAZIONE',
}

// Cancellato
// In attesa firma
// Firmato
// In attesa conferma
// Confermato
// Da gestire
// Da validare BO
// Rifiutato
// Sospeso
// Completato
