import { Injectable, Injector } from '@angular/core';
import { ACondition, AObject, AObjectService } from '@congacommerce/core';
import {
    AssetLineItem,
    Cart,
    CartItem,
    CartItemService,
    LineStatus,
    OrderLineItem,
    ProductAttributeValue,
    QuoteLineItem,
} from '@congacommerce/ecommerce';
import { of, combineLatest, throwError, zip } from 'rxjs';
import { mergeMap, map, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { ClassType } from 'class-transformer/ClassTransformer';
import { EglAssetLineItem } from '../../../../common/models/apttus/tables/user/egl-asset-line-item';

@Injectable({ providedIn: 'root' })
export class EglCartItemService extends CartItemService {
    getCartItemsForAsset(cartItem, context) {
        let _thisCartItem = cartItem;
        if (_.isNil(cartItem))
            return throwError(
                new Error(
                    'You must provide a cart item with a related asset generated from the amend or configure asset API'
                )
            );
        return zip(
            this.getOptionsForItem(_.get(cartItem, 'AssetLineItem'), null),
            this.getOptionsForItem(cartItem, context)
        ).pipe(
            map(([assetItems, cartItems]) => {
                // Map the asset lines
                if (_thisCartItem.LineStatus === LineStatus.Upgrade) {
                    return cartItems;
                } else {
                    return _.concat(
                        _.map(
                            assetItems,
                            // To a new cart item
                            (asset) => {
                                const existing = _.find(
                                    cartItems,
                                    (i) => _.get(i, 'AssetLineItemId') === _.get(asset, 'Id')
                                );
                                if (!_.isNil(existing)) return existing;
                                else {
                                    // Line copies the details of an asset line item to a new cart item ommiting the default Salesforce fields
                                    const attributeValue = _.assign(
                                        new ProductAttributeValue(),
                                        _.omit(
                                            _.pick(_.get(asset, 'AttributeValue'), _.keys(new ProductAttributeValue())),
                                            AObjectService.defaultFields
                                        )
                                    );
                                    const newCartItem = _.assign(
                                        new CartItem(),
                                        _.omit(_.pick(asset, _.keys(new CartItem())), AObjectService.defaultFields)
                                    );
                                    newCartItem.AttributeValue = attributeValue;
                                    newCartItem.AssetLineItem = _.cloneDeep(asset);
                                    newCartItem.AssetLineItemId = _.get(asset, 'Id');
                                    newCartItem.LineStatus = LineStatus.Existing;
                                    return newCartItem;
                                }
                            }
                        ),
                        _.filter(cartItems, (i) => _.isNil(_.get(i, 'AssetLineItem'))),
                        _.filter(
                            cartItems,
                            (i) =>
                                _.get(i, 'LineStatus') === LineStatus.Upgrade &&
                                _.get(i, 'LineType') !== 'Product/Service'
                        )
                    );
                }
            })
        );
    }

    getOptionsForItem(item, relatedTo) {
        // If the item is a cart item and has a related asset

        //TODO: per EglAssetLineItem torna service diversi nonostante nel getAObjectServiceForType vengono trovati 2 servizi.
        //      c'è un problema nel getAObjectServiceForType perchè tra i 2 servizi trovati ritorna sempre l'ultimo e non viene effettuato nessun'ordinamento

        //const service = this.metadataService.getAObjectServiceForType(item);

        const service = this.injector.get(AObjectService);
        service.setType(EglAssetLineItem);

        if (service && item) {
            let field = 'ConfigurationId';
            if (item instanceof AssetLineItem) field = 'BusinessObjectId';
            else if (item instanceof QuoteLineItem) field = 'Proposal';
            else if (item instanceof OrderLineItem) field = 'OrderId';
            const item$ = service.get([_.get(item, 'Id')]).pipe(map((res) => res[0]));
            return !_.isNil(relatedTo) && item instanceof CartItem && relatedTo instanceof Cart
                ? of(
                      _.filter(_.get(relatedTo, 'LineItems', []), (i) =>
                          _.isEqual(_.get(i, 'LineNumber'), item.LineNumber)
                      )
                  )
                : item$.pipe(
                      mergeMap((i) => {
                          if (_.get(i, field)) {
                              return service.where([
                                  new ACondition(service.type, 'LineNumber', 'Equal', i.LineNumber),
                                  new ACondition(service.type, field, 'Equal', _.get(i, field)),
                              ]);
                          } else {
                              return of([i]);
                          }
                      })
                  );
        } else return of(null);
    }
}
