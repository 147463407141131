import { Product, ProductOptionGroup } from '@congacommerce/ecommerce';
import { Expose, Type } from 'class-transformer';

export class EglProductExtended extends Product {
    constructor() {
        super();
        this.egl_Product_Code = null;
        this.egl_Product_Code = null;
        this.ProductType = null;
        this.UniqueProductCode = null;
        this.egl_shortdescription = null;
        this.egl_subtitle = null;
        this.egl_sellingpoint1_desc = null;
        this.egl_sellingpoint2_desc = null;
        this.egl_sellingpoint3_desc = null;
        this.egl_sellingpoint4_desc = null;
        this.egl_advantage1_title = null;
        this.egl_advantage1_desc = null;
        this.egl_advantage2_title = null;
        this.egl_advantage2_desc = null;
        this.egl_SKU = null;
    }

    @Expose({ name: 'Name' })
    Name: string;

    @Expose({ name: 'Family' })
    Family: string;

    @Expose({ name: 'Apttus_Config2__ExpirationDate__c' })
    ExpirationDate: Date;

    @Expose({ name: 'Apttus_Config2__ProductType__c' })
    ProductType: string;

    @Expose({ name: 'egl_Product_Code__c' })
    egl_Product_Code: string;

    ProductCode: string;

    @Expose({ name: 'UniqueProductCode' })
    UniqueProductCode: string;

    @Expose({ name: 'egl_shortdescription__c' })
    egl_shortdescription: string;

    @Expose({ name: 'egl_subtitle__c' })
    egl_subtitle: string;

    @Expose({ name: 'egl_sellingpoint1_desc__c' })
    egl_sellingpoint1_desc: string;

    @Expose({ name: 'egl_sellingpoint2_desc__c' })
    egl_sellingpoint2_desc: string;

    @Expose({ name: 'egl_sellingpoint3_desc__c' })
    egl_sellingpoint3_desc: string;

    @Expose({ name: 'egl_sellingpoint4_desc__c' })
    egl_sellingpoint4_desc: string;

    @Expose({ name: 'egl_advantage1_title__c' })
    egl_advantage1_title: string;

    @Expose({ name: 'egl_advantage1_desc__c' })
    egl_advantage1_desc: string;

    @Expose({ name: 'egl_advantage2_title__c' })
    egl_advantage2_title: string;

    @Expose({ name: 'egl_advantage2_desc__c' })
    egl_advantage2_desc: string;

    @Expose({ name: 'egl_SKU__c' })
    egl_SKU: string;

    @Expose({ name: 'egl_unique_product_code__c' })
    egl_unique_product_code: string;

    @Expose({ name: 'egl_highlight_desc__c' })
    egl_highlight_desc: string;

    @Expose({ name: 'egl_is_expired__c' })
    egl_is_expired: boolean;

    @Expose({ name: 'Apttus_Config2__OptionGroups__r' })
    @Type(() => ProductOptionGroup)
    OptionGroups: Array<ProductOptionGroup>;

    @Expose({ name: 'egl_IVASS_01__c' })
    egl_IVASS_01: string;

    @Expose({ name: 'egl_IVASS_02__c' })
    egl_IVASS_02: string;

    @Expose({ name: 'egl_IVASS_03__c' })
    egl_IVASS_03: string;

    @Expose({ name: 'egl_IVASS_04__c' })
    egl_IVASS_04: string;

    @Expose({ name: 'egl_IVASS_05__c' })
    egl_IVASS_05: string;

    @Expose({ name: 'egl_floor__c' })
    egl_floor: string;

    @Expose({ name: 'egl_privacy_InsuranceCommunication__c' })
    egl_privacy_InsuranceCommunication: string;

    @Expose({ name: 'egl_privacy_InsuranceCommunication2__c' })
    egl_privacy_InsuranceCommunication2: string;

    @Expose({ name: 'egl_Privacy_Zurich_01__c' })
    egl_Privacy_Zurich_01: string;

    @Expose({ name: 'egl_Privacy_Zurich_02__c' })
    egl_Privacy_Zurich_02: string;

    @Expose({ name: 'egl_Privacy_Zurich_03__c' })
    egl_Privacy_Zurich_03: string;

    @Expose({ name: 'egl_Privacy_Zurich_04__c' })
    egl_Privacy_Zurich_04: string;

    @Expose({ name: 'egl_selling_order_number__c' })
    egl_selling_order_number: string;

    @Expose({ name: 'egl_combined_sale_insurance__c' })
    egl_combined_sale_insurance: string;

    @Expose({ name: 'egl_promotion__c' })
    egl_promotion: string;
}
