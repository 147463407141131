import { FullState } from '../../../../../store/models/full-state';
import { OrderEntryState, ProductInfo } from '../../../../../store/models/order-entry-state';
import { AptProductType } from '../../../../enums/apttus/apt-product-type';
import { D365CustomerSegment } from '../../../../enums/d365/d365-customer-segment';
import { cleanObj } from '../../../../functions/misc.functions';
import { KeyValue } from '../../../../models/apttus/request-response/cart-to-quote-request';
import { ProductAttributeValues } from '../attribute-factory';

export class AttributeValues implements ProductAttributeValues {
    readonly OPTION_OR_TECHNICAL_PRODUCT = [
        AptProductType.Undefined,
        AptProductType.Accessorio,
        AptProductType.Sconti,
        AptProductType.ScontoStandAloneGas,
        AptProductType.ScontoStandAloneLuce,
        AptProductType.Servizio,
    ];
    protected state: FullState;
    protected currentProduct: ProductInfo;
    protected attr: AttrKeyValue = {};
    protected get orderEntry(): OrderEntryState {
        return this.state?.orderEntry;
    }

    keyValue(): KeyValue[] {
        return planeObjToKeyValue(this.attr || []);
    }

    constructor(state: FullState, currentProduct: ProductInfo) {
        this.state = state;
        this.currentProduct = currentProduct;
        this.attributesMapping();
    }
    attributesMapping(): void {
        // base mapping
        const isMicrobusiness = this.state?.user?.cartSegment === D365CustomerSegment.Microbusiness;
        const { activation, partnership, indirizzi, numeroDomiciliazione } = this.state.orderEntry;
        this.attr['egl_partnership__c'] = this.currentProduct?.partnership;
        this.attr['egl_partner_subscriber_taxcode__c'] = partnership?.fw?.taxVatCode;
        this.attr['egl_partner_accountnumber__c'] = partnership?.fw?.accountNumber;
        this.attr['egl_partner_customer_cluster__c'] = partnership?.fw?.cluster;
        this.attr['egl_selling_order_number__c'] = this.currentProduct?.configuration?.orderNumber;
        this.attr['egl_code_NewDom'] = numeroDomiciliazione;
        this.attr['egl_propertyType__c'] = activation?.propertyTypeSelected;
        this.attr['egl_Appointment_check__c'] = activation?.actiAppointment?.presence;

        if (isMicrobusiness) {
            this.attr['egl_commercial_branch__c'] = this.orderEntry.atecoMb?.attivita;
            this.attr['egl_Commodity_Sector__c'] = this.orderEntry.atecoMb?.settore;
            this.attr['egl_Customer_ATECO_Code__c'] =
                this.orderEntry.atecoMb?.codeAtecoPower || this.orderEntry.atecoMb?.codeAtecoGas;
        } else {
            this.attr['egl_isresidential__c'] = !this.OPTION_OR_TECHNICAL_PRODUCT.includes(
                this.currentProduct?.productType
            )
                ? indirizzi?.stessoIndirizzoResidenza
                : null;
        }
    }
}

const planeObjToKeyValue = (obj: any): KeyValue[] => {
    const cleanAttibutes = cleanObj(obj);
    return Object.entries(cleanAttibutes)?.map(([key, value]) => ({ key, value: value.toString() }));
};

export interface AttrKeyValue {
    [key: string]: string | number | boolean;
}
