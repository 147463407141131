import { AObjectService } from '@congacommerce/core';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import * as _ from 'lodash';
import { EglUser, EglUserQuery } from '../../../../models/apttus/tables/user/egl-user';
import { CommonProvider } from '../../../../providers/common-provider';
import { OAuthService } from '../../../oauth/oauth-services';
import { Store } from '@ngrx/store';
import { selectApplicationLocation } from '../../../../../store/selectors/app.selectors';
import { ApplicationLocationType } from '../../../../../store/models/app-state';

@Injectable({
    providedIn: 'root',
})
export class EglUserService extends AObjectService {
    aObject: any;
    commonPrv = this.injector.get(CommonProvider);
    oauthSrv = this.injector.get(OAuthService);
    store = this.injector.get(Store);

    /**
     * @description Recupera, attraverso query diretta verso apttus,  il federation ID e altri dati necessari per la gestione del login
     * @param userId Id dell'utente
     * @returns Osservabile contenente egl_federationidentifier__c, Name e Id
     */
    getFederationId(userId: string): Observable<EglUser> {
        const query = `select Id, egl_federationidentifier__c, Name from User where Id = '${userId}'`;
        const obs = this.commonPrv.query<EglUserQuery>(query.replace(' ', '+'));
        return obs.pipe(
            filter((res: EglUserQuery) => !!res && res.totalSize === 1),
            map((res: EglUserQuery) => res.records[0])
        );
    }

    /**
     * @description Forza il login
     */
    forceLogin(): void {
        this.store
            .select(selectApplicationLocation)
            .pipe(take(1))
            .subscribe((applicationLocationType: ApplicationLocationType) => {
                this.oauthSrv.oauthLogin(applicationLocationType);
            });
    }
}
