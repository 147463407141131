import { EventEmitter, Injectable } from '@angular/core';
import { EglState } from '../../../../store/reducers';
import { Store } from '@ngrx/store';
import { selectUserOrderState } from '../../../../store/selectors/common.selectors';
import { catchError, distinctUntilChanged, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import _, { isEqual } from 'lodash';
import { LoggerService } from '../../shared/logger.service';
import { CommonProvider } from '../../../providers/common-provider';
import { AgentInfo } from '../../../models/user/agent';
import { Firma, OrderEntryState, QuoteStateModel } from '../../../../store/models/order-entry-state';
import { Lead } from '../../../models/user/lead';
import { AptCustomerType } from '../../../enums/apttus/apt-customer-type';
import { Contact } from '../../../models/user/contact';
import { isJsonString, jsonTryParse } from '../../../functions/misc.functions';
import { setFirma, setOrderEntryState, setQuoteStateModel } from '../../../../store/actions/order-entry.actions';
import { setUserState } from '../../../../store/actions/user.actions';
import { convertSegmentAptToD365 } from '../../../functions/remap.functions';
import { AptQuoteStatus } from '../../../enums/apttus/apt-quote-status';
import { AptQuoteSubStatus } from '../../../enums/apttus/apt-quote-sub-status';
import { MD5 } from 'crypto-js';
import { FlowType } from '../../../../store/models/flow-type';

@Injectable({
    providedIn: 'root',
})
export class EglSalesupStateService {
    constructor(private store: Store<any>, private logger: LoggerService, private commonPrv: CommonProvider) {}

    /**Emette un oggetto che contiene l'esito del salvataggio dello state su Apttus e la sorgente che ha invocato il salvataggio*/
    emitterStateSaved = new EventEmitter<StateSavedModel>();
    private hashLastSavedState: string;
    private saveStateEventEmitter = new EventEmitter<{ cartId: string; callingSource?: string }>();
    private saveStateSub: Subscription;
    private saveStateObs$ = this.saveStateEventEmitter.pipe(
        mergeMap((event) => this.saveSupState(event?.cartId, event?.callingSource))
    );

    saveSupState(cartId: string, callingSource?: string): Observable<boolean> {
        return of({ cartId, callingSource }).pipe(
            tap((event) => {
                if (!event?.cartId) {
                    throw new Error('cart id was null in saveSupState');
                }
            }),
            mergeMap((event) =>
                this.store.select(selectUserOrderState).pipe(
                    take(1),
                    map((state: EglState) => {
                        const copyState = _.cloneDeep(state);
                        if (state?.user?.agentInfo) {
                            copyState.user.agentInfo = Object.assign(new AgentInfo(), {
                                Agency: state.user.agentInfo.Agency,
                                Agent: state.user.agentInfo.Agent,
                            });
                        }
                        return {
                            copyState,
                            cartId: event.cartId,
                            callingSource: event.callingSource,
                        };
                    })
                )
            ),
            switchMap(({ copyState, cartId, callingSource }) => {
                const serializedState = JSON.stringify(copyState);
                const hashState = MD5(serializedState).toString();
                if (hashState !== this.hashLastSavedState) {
                    return this.commonPrv.saveSalesUpState(cartId, serializedState).pipe(
                        tap(() => {
                            this.hashLastSavedState = hashState;
                            this.emitterStateSaved.emit({ saved: true, source: callingSource });
                        }),
                        map(() => true),
                        catchError((e) => {
                            this.logger.error(null, 'Unmanaged error during saving SalesUP state', e, false);
                            this.emitterStateSaved.emit({ saved: false, source: callingSource });
                            return of(false);
                        })
                    );
                } else {
                    this.emitterStateSaved.emit({ saved: true, source: callingSource });
                    return of(true);
                }
            }),
            catchError((e) => {
                this.logger.warn(e, false, null, e);
                return of(false);
            })
        );
    }

    /**
     * @description: salva lo state della SalesUP nella tabella su SalesForce usando APEX API
     * @param cartId: id del carrello a cui è associato lo state
     * @param callingSource: La sorgente che sta invocando il salvataggio
     */
    saveSupStateEvent(cartId: string, callingSource?: string): void {
        if (!this.saveStateSub) {
            this.saveStateSub = this.saveStateObs$.subscribe();
        }
        this.saveStateEventEmitter.emit({ cartId, callingSource });
    }

    getSupStateByCartId(cartId: string): Observable<EglState> {
        return this.commonPrv.getSalesUpState(cartId).pipe(map((res) => jsonTryParse<EglState>(res?.State)));
    }

    restoreSupStateByCartId(
        cartId: string,
        defaultVals?: {
            orderEntry?: OrderEntryState;
            contact?: Contact;
            cartSegment?: AptCustomerType;
            lead?: Lead;
        }
    ): Observable<EglState> {
        return this.getSupStateByCartId(cartId).pipe(
            map((res) => {
                if (res) {
                    this.logger.info(`State found for cart ${cartId}. Dispatcing...`);
                    return this.dispatchState(res, defaultVals, cartId);
                } else {
                    this.logger.warn(`State for cart ${cartId} not found`);
                    return null;
                }
            }),
            catchError(() => {
                this.logger.error(`Error to retrieve salesUpState ${cartId}`);
                return of(null);
            })
        );
    }

    dispatchState(
        s: EglState | string,
        defaultVals?: {
            orderEntry?: OrderEntryState;
            contact?: Contact;
            cartSegment?: AptCustomerType;
            lead?: Lead;
        },
        cartId?: string
    ): EglState {
        let state: EglState;
        if (typeof s === 'string' && isJsonString(s)) {
            state = jsonTryParse<EglState>(s);
        } else {
            state = s as EglState;
        }
        try {
            this.store.dispatch(setOrderEntryState({ s: state.orderEntry || defaultVals?.orderEntry }));
            this.store.dispatch(
                setUserState({
                    s: {
                        ...state.user,
                        contact: state?.user?.contact || defaultVals?.contact,
                        lead: state?.user?.lead || defaultVals?.lead,
                        cartSegment: state?.user?.cartSegment || convertSegmentAptToD365(defaultVals?.cartSegment),
                    },
                })
            );

            /* TODO: rimuovere. da aggiungere lato backend in apttus durante la creazioen dello state */
            if (
                state?.orderEntry?.flowType === FlowType.VariazioneCommerciale ||
                state?.orderEntry?.flowType === FlowType.ScontoStandalone
            ) {
                this.store.dispatch(
                    setQuoteStateModel({
                        s: new QuoteStateModel(AptQuoteStatus.Confermato, AptQuoteSubStatus.AttesaSottomissioneOrdine),
                    })
                );
                this.store.dispatch(setFirma({ payload: { signedDate: new Date() } as Firma }));
            }

            this.logger.info(`State for cart ${cartId} dispatced`);
            return state;
        } catch (error) {
            this.logger.error(`error during dispatching state`, '', error);
            return null;
        }
    }
}

interface StateSavedModel {
    saved: boolean;
    source: string;
}
