import { Injectable } from '@angular/core';
import { CartItem, CartService } from '@congacommerce/ecommerce';
import { of } from 'rxjs';
import { take, map, tap, retryWhen, delay, switchMap, filter } from 'rxjs/operators';
import { map as _map, get, set, isNil } from 'lodash';
import { apttusResponseIronHand } from '../../../functions/misc.functions';
import { EglCartExtended } from '../../../models/apttus/tables/cart/egl-cart-extended';
import { EglCartItemExtended } from '../../../models/apttus/tables/cart/egl-cart-item-extended';

@Injectable({ providedIn: 'root' })
export class EglCartService extends CartService {
    eglCartLookups = `AccountId,PriceListId,OrderId,Proposald,Account.PriceListId,Account.OwnerId,ShipToAccountId,BillToAccountId`;
    eglCartChildren = `SummaryGroups,Applied_Rule_Infos,AppliedRuleActionInfos`;
    EGL_STORAGE_KEY = 'local-cart';
    refreshCart() {
        let attempts = 0;
        return this.userService
            .isLoggedIn()
            .pipe(
                switchMap((loggedIn) => {
                    const cart$ = this.apiService.get(
                        `/carts/${CartService.getCurrentCartId()}?lookups=${this.eglCartLookups}&children=${
                            this.eglCartChildren
                        }&alias=false`,
                        this.type,
                        false
                    );
                    const obsv$ = cart$.pipe(
                        switchMap((cart) => {
                            if (!isNil(get(cart, 'Id')))
                                return this.getEglCartLineItems(cart.Id).pipe(
                                    map((lines: EglCartItemExtended[]) => {
                                        lines = lines.map((lineItem) =>
                                            apttusResponseIronHand<EglCartItemExtended>(lineItem, ['AttributeValue'])
                                        );
                                        set(cart, 'LineItems', lines);
                                        return cart;
                                    })
                                );
                            else return of(cart);
                        })
                    );
                    return obsv$.pipe(
                        map((cart) => {
                            if (loggedIn && !isNil(get(cart, 'Id'))) CartService.setCurrentCartId(get(cart, 'Id'));
                            else if (isNil(cart) && localStorage.getItem(this.EGL_STORAGE_KEY)) {
                                CartService.deleteLocalCart();
                                this.refreshCart();
                            }
                            if (
                                get(cart, 'IsPricePending') === true &&
                                this.configService.get('pricingMode') !== 'turbo'
                            ) {
                                if (attempts <= this.configService.get('cartRetryLimit', 3)) {
                                    throw new Error('Cart Pending');
                                }
                            }
                            return cart;
                        }),
                        retryWhen((errors) =>
                            errors.pipe(
                                tap((e) => {
                                    if (e.message === 'Cart Pending') attempts += 1;
                                    else throw e;
                                }),
                                delay(500)
                            )
                        )
                    );
                }),
                take(1),
                map((cart) => apttusResponseIronHand<EglCartExtended>(cart, ['LineItems']))
            )
            .subscribe((c) => this.publish(c));
    }

    getEglCartLineItems(cartId) {
        if (!isNil(cartId)) {
            return this.apiService.post(
                '/Apttus_Config2__LineItem__c/query',
                {
                    alias: false,
                    conditions: [
                        {
                            field: 'ConfigurationId',
                            filterOperator: 'Equal',
                            value: cartId,
                        },
                    ],
                    lookups: [
                        {
                            field: 'Product',
                            children: [
                                {
                                    field: 'OptionGroups',
                                },
                            ],
                        },
                        {
                            field: 'Option',
                            children: [
                                {
                                    field: 'OptionGroups',
                                },
                            ],
                        },
                        {
                            field: 'ProductOption',
                        },
                        {
                            field: 'AttributeValue',
                        },
                        {
                            field: 'AssetLineItem',
                        },
                        {
                            field: 'PriceListItem',
                        },
                    ],
                    children: [
                        {
                            field: 'AdjustmentLineItems',
                        },
                    ],
                },
                CartItem,
                null,
                false
            );
        } else {
            return of(null);
        }
    }

    getMyCart(pending = false) {
        return this['state'].pipe(
            filter((c) => !isNil(c)),
            map((cart: EglCartExtended) => {
                const remappedCart = apttusResponseIronHand<EglCartExtended>(cart, ['LineItems']);
                remappedCart.LineItems = (cart.LineItems || []).map((lineItem) =>
                    apttusResponseIronHand<EglCartItemExtended>(lineItem, ['AttributeValue'])
                );
                return remappedCart;
            })
        );
    }
}
