import { Injectable } from '@angular/core';
import { ApiService } from '../services/shared/api.service';
import { EgonResponse } from '../../modules/switch-in/order-entry/models/egon-response';
import { EgonRequestCity, EgonRequestStreetNumber, EgonRequestZip } from '../models/egon/egon-request';
import { EgonResponseCity, EgonResponseNumber, EgonResponseZip } from '../models/egon/egon-response';
import { EgonNormalizedResponse } from '../models/egon/egon-normalization-response';
import { Observable } from 'rxjs';
import { BaseProvider, EgonApi, EgonJsonApi } from './base-provider';
import { PrivateConfigurationService } from '../services/shared/private-configuration.service';

@Injectable()
export class EgonProvider extends BaseProvider {
    constructor(private api: ApiService, protected configSrv: PrivateConfigurationService) {
        super(configSrv);
    }

    suggest(query: string, type: string, onlyIta: boolean = true): Observable<EgonResponse[]> {
        const params: any = {};
        params.app_key = this.egonAppKey;
        params.query = query;

        if (onlyIta) {
            params.restrict_level = 'country';
            params.restrict_id = '38000000001'; // egon id to country = ITA
        }

        const request = `Suggest${type.charAt(0).toUpperCase()}${type.slice(1)}`;

        return this.api.getAsync<EgonResponse[]>(this.getEgonApiUrl(EgonApi[request]), null, params);
    }

    searchByZip(zipCode: string): Observable<EgonResponseZip> {
        const params = new EgonRequestZip(zipCode, this.appConfig.appKeys.egonUser, this.appConfig.appKeys.egonPsw);
        return this.api.getAsync<EgonResponseZip>(this.getEgonJsonApiUrl(EgonJsonApi.SearchByZip), null, params);
    }

    searchByCity(city: string): Observable<EgonResponseCity> {
        const params = new EgonRequestCity(city, this.appConfig.appKeys.egonUser, this.appConfig.appKeys.egonPsw);
        return this.api.getAsync<EgonResponseCity>(this.getEgonJsonApiUrl(EgonJsonApi.SearchByCity), null, params);
    }

    searchByStreetNumber(number: number, streetCode: number): Observable<EgonResponseNumber> {
        const params = new EgonRequestStreetNumber(
            number,
            streetCode,
            this.appConfig.appKeys.egonUser,
            this.appConfig.appKeys.egonPsw
        );
        return this.api.getAsync<EgonResponseNumber>(
            this.getEgonJsonApiUrl(EgonJsonApi.SearchByStreetNumber),
            null,
            params
        );
    }

    egonNormalize(egonResponse: EgonResponse): Observable<EgonNormalizedResponse> {
        const params: any = {};
        params.CDXISO = 'ITA';
        if (egonResponse.city) {
            params.DSXDPT = egonResponse.province;
        }
        if (egonResponse.city) {
            params.DSXCNL = egonResponse.city;
        }
        if (egonResponse.street) {
            params.DSXVIA = egonResponse.street;
        }
        if (egonResponse.number) {
            params.NRPNUMCIV = egonResponse.number;
        }
        params.USER = this.appConfig.appKeys.egonUser;
        params.PASW = this.appConfig.appKeys.egonPsw;

        return this.api.getAsync<EgonNormalizedResponse>(this.getEgonJsonApiUrl(EgonJsonApi.Normalize), null, params);
    }
}
