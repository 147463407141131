import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { RoutesPaths } from '../../config/routes-paths';
import { Store } from '@ngrx/store';
import { setDevBarVisibility } from '../../../store/actions/app.actions';
import { AppState } from '../../../store/models/app-state';
import { of, Subscription } from 'rxjs';
import { PrivateConfigurationService } from '../../services/shared/private-configuration.service';
import { AptSalesProcess } from '../../../common/enums/apttus/apt-sales-process';
import { EglState } from '../../../store/reducers';
import { setFlowType } from '../../../store/actions/order-entry.actions';
import { setCartSegment, setIsSiebelCustomer } from '../../../store/actions/user.actions';
import { EglModalCustomComponent } from '../modals/egl-modal-custom/egl-modal-custom.component';
import { D365CustomerSegment } from '../../enums/d365/d365-customer-segment';
import { selectFlowType } from '../../../store/selectors/order-entry.selectors';
import { SegoeMDL2Font } from '../../enums/shared/segoe-mdl2-font';
import { LoadingService } from '../../services/shared/loading.service';
import { getRandomHash } from '../../functions/misc.functions';
import { selectRawState } from '../../../store/selectors/common.selectors';
import { delay, take } from 'rxjs/operators';
import { FLOW_TYPE_LABELS_MAP } from '../../map/flow-type-labels-map';
import { FlowType } from '../../../store/models/flow-type';

@Component({
    selector: 'egl-dev-bar',
    templateUrl: './dev-bar.component.html',
    styleUrls: ['./dev-bar.component.scss'],
})
export class DevBarComponent implements OnInit, OnDestroy {
    domainEnabled: boolean;
    visible: boolean;
    routes: Array<any>;
    operativeModes: Array<AptSalesProcess>;
    flowTypes: FlowType[] = [];
    url = location.href;
    enabledDomains: string[] = [];
    subs: Subscription[] = [];
    inListDomains: boolean;
    currentFlowType: FlowType;
    homeIcon = SegoeMDL2Font.Home;
    localhost: boolean;
    readonly easterDevInp = `hdd-d-bar-${getRandomHash()}`;
    @ViewChild('moreConfigModal', { static: false }) moreConfigModal: EglModalCustomComponent;

    constructor(
        private router: Router,
        private store: Store<AppState>,
        private configSrv: PrivateConfigurationService,
        private loadingSrv: LoadingService
    ) {}

    ngOnInit(): void {
        this.enabledDomains = this.configSrv.config.devbarDomains;
        this.initComponent();
    }

    ngOnDestroy(): void {
        (this.subs || []).forEach((s) => s.unsubscribe());
    }

    initComponent(): void {
        this.inListDomains = (this.enabledDomains || []).includes(location.origin);
        this.localhost = location.href.indexOf('localhost') !== -1;
        if (this.localhost || this.inListDomains) {
            this.domainEnabled = true;
            this.routes = Object.values(RoutesPaths);
            this.flowTypes = Object.values(FlowType);
            this.operativeModes = [AptSalesProcess.SwitchIn, AptSalesProcess.Attivazione];
            this.setVisibiltyBar(true);

            this.subs.push(
                this.router.events.subscribe((event) => {
                    if (event instanceof NavigationStart) {
                        this.url = `${location.origin}${event.url}`;
                    }
                })
            );
        }

        this.subs.push(
            this.store.select(selectFlowType).subscribe((f) => {
                this.currentFlowType = f;
            })
        );
    }

    onChangeRoute(event: any): void {
        this.router.navigate([event.target.value]);
    }

    onGoBtn(useLocation: boolean): void {
        if (useLocation) {
            if (this.url !== location.href) {
                location.href = new URL(this.url).toString();
            } else {
                location.reload();
            }
        } else {
            if (this.url !== location.href) {
                this.router.navigate([this.url.replace(location.origin, '')]);
            }
        }
    }

    setVisibiltyBar(visible: boolean, timeout?: number): void {
        this.visible = visible;
        this.store.dispatch(setDevBarVisibility({ v: this.visible }));

        if (timeout > 0) {
            setTimeout(() => {
                this.visible = false;
                this.store.dispatch(setDevBarVisibility({ v: false }));
            }, timeout * 1000);
        }
    }

    showMoreConfig(): void {
        this.moreConfigModal.toggle();
        setTimeout(() => {
            if (this.moreConfigModal.isOpen) {
                const modal: any = document.getElementsByClassName('egl-modal-with-footer__container');
                modal[0].style.zIndex = 9999999999999;
            }
        });
    }

    onChangeIsSiebelCustomer(values: any): void {
        this.store.dispatch(setIsSiebelCustomer({ a: values.currentTarget.checked }));
    }

    onChangeIsMicroBusiness(values: any): void {
        this.store.dispatch(
            setCartSegment({
                payload: values.currentTarget.checked
                    ? D365CustomerSegment.Microbusiness
                    : D365CustomerSegment.Residential,
            })
        );
    }

    onChangeFlowType(flowType: FlowType): void {
        this.store.dispatch(setFlowType({ payload: flowType }));
    }

    goToHome(): void {
        this.router.navigate([RoutesPaths.Dashboard]);
    }

    forceConfigurationsReload(): void {
        this.configSrv.forceReload();
    }

    onHideBlockUI(): void {
        LoadingService.toggle();
    }

    logSupState(): void {
        this.store
            .select(selectRawState)
            .pipe(take(1))
            .subscribe((s) => console.log(s));
    }

    test(): void {
        this.moreConfigModal.hide();
        of('test')
            .pipe(LoadingService.loaderOperator(), delay(5000), LoadingService.loaderOperator('test'))
            .subscribe();
    }

    public get opModeLabelKey(): string {
        return FLOW_TYPE_LABELS_MAP[this.currentFlowType];
    }
}
