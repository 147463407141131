import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserState } from '../../../store/models/user-state';
import { setProgressBarVisibility } from '../../../store/actions/app.actions';
import { Dictionary } from '../../interfaces/dictionary';
import { ItemMap } from '../../models/app/item-map';
import { progressRouteMap } from '../../../modules/switch-in/order-entry/config/progress-route-map';

@Component({
    selector: 'egl-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit, OnDestroy {
    currentStepDescription = '';
    currentStepPercent = 100;
    isHidden = true;
    subscription: Subscription;
    progressMap: Dictionary<ItemMap>;
    //currentStepBigIcon: string;

    constructor(private router: Router, private store: Store<{ user: UserState }>) {}

    ngOnInit() {
        this.progressMap = progressRouteMap();
        this.setPropsByUrl(this.router.url);
        this.subscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.setPropsByUrl(event.url);
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    setPropsByUrl(url: string): void {
        if (url && url.length > 1) {
            url = url.substr(1); // rimuovo il primo carattere --> /
        }
        const splitted = url.split(';');
        const splittedQuery = url.split('?');
        if (splitted.length > 1) {
            url = splitted[0]; // ignoro i parametri querystring
        } else if (splittedQuery.length > 1) {
            url = splittedQuery[0]; // ignoro i parametri querystring --> ?
        }

        const currRoute = this.progressMap[url];
        const wasHidden = this.isHidden;
        if (currRoute) {
            this.isHidden = false;
            this.currentStepPercent = currRoute.percent;
            this.currentStepDescription = currRoute.label;
            //this.currentStepBigIcon = currRoute.bigIcon;
        } else {
            this.isHidden = true;
        }
        if (wasHidden !== this.isHidden) {
            this.store.dispatch(setProgressBarVisibility({ v: !this.isHidden }));
        }
    }
}
