import { on, On } from '@ngrx/store';
import { OrderEntryState } from '../models/order-entry-state';
import * as a from '../actions/partnership.actions';

export const partnershipsAssociations: On<OrderEntryState>[] = [
    on(a.setFastwebTaxVatCode, (state, { code }) => ({
        ...state,
        partnership: {
            ...state.partnership,
            fw: {
                ...state.partnership.fw,
                taxVatCode: code,
            },
        },
    })),
    on(a.setFastwebContractInfo, (state, { cluster, accountNumber }) => ({
        ...state,
        partnership: {
            ...state.partnership,
            fw: {
                ...state.partnership.fw,
                accountNumber,
                cluster,
            },
        },
    })),
    on(a.setEglPartnershipData, (state, { data }) => ({
        ...state,
        partnership: {
            ...state.partnership,
            egl: data,
        },
    })),
];
