import { CurrencyPipe, DatePipe, DecimalPipe, JsonPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AutoFormatPipe } from './auto-format.pipe';
import { TruncatePipe } from './truncate.pipe';
@NgModule({
    imports: [TranslateModule.forChild()],
    exports: [AutoFormatPipe, TruncatePipe],
    declarations: [AutoFormatPipe, TruncatePipe],
    providers: [DecimalPipe, CurrencyPipe, DatePipe, JsonPipe, AutoFormatPipe, TruncatePipe],
})
export class CommonPipesModule {}
