import { Injectable } from '@angular/core';
import { LoggerService } from '../shared/logger.service';
import { select, Store } from '@ngrx/store';
import { ApplicationLocationType, AppState } from '../../../store/models/app-state';
import { UserState } from '../../../store/models/user-state';
import {
    OrderEntryState,
    PowerConsumption,
    TipoEsecuzione,
} from '../../../store/models/order-entry-state';
import {
    setAgentInfo,
    setContact,
    setCustomerInfo,
    setIsSiebelCustomer,
    setLastUsedCustomerSegment,
    setLead,
    setVirtualAgentAgencyCurrentBranch,
} from '../../../store/actions/user.actions';
import { setApplicationLocation, setGeoLocation } from '../../../store/actions/app.actions';
import { setPassaggioRapido } from '../../../store/actions/order-entry.actions';
import { AgencyBranchForMonitoring, AgentInfo, VirtualAgent, VirtualAgentsForBoList } from '../../models/user/agent';
import { CodicePlico } from '../../../modules/common/order-entry/models/codice-plico';
import { UtilityService } from '../shared/utility.service';
import { DataEsecuzione } from '../../../modules/common/order-entry/models/data-esecuzione';
import { D365ResponseBuilder } from './d365.response.mapper';
import { LeadTopic } from '../../models/d365/lead-topic';
import { D365ConfirmationType } from '../../enums/d365/d365-confirmation-type';
import { combineLatest, from, fromEvent, iif, Observable, Observer, of, zip } from 'rxjs';
import { catchError, filter, finalize, map, mergeMap, share, take, tap, timeout } from 'rxjs/operators';
import { LeadDataForInterest } from '../../models/d365/lead-interest-data';
import { CreateLeadFromInterestsResponse } from '../../models/d365/create-lead-from-interest-response';
import { CheckTypeResponse } from '../../models/d365/get-check-type-response';
import { AgencyBranch } from '../../models/d365/agency-branch';
import { BaseD365Response } from '../../interfaces/base-d365-response';
import { D365CustomerSegment } from '../../enums/d365/d365-customer-segment';
import {
    ChannelMarketConfiguration,
    ChannelMarketConfigurationResponse,
} from '../../models/d365/channel-market-configuration';
import { D365ChannelCode } from '../../enums/d365/d365-channel-code';
import { Lead } from '../../models/user/lead';
import * as _ from 'lodash';
import { VoltageLevel } from '../../models/d365/voltage-level';
import { LegalForm } from '../../models/d365/legal-form';
import { LookupFilter } from '../../models/d365/lookup-filter';
import { SelectFileResponse } from '../../models/d365/select-file-response';
import { KnowledgeMaterialListItem } from '../../models/d365/knowledge-material-list-response';
import { D365SignatureType } from '../../enums/d365/d365-signature-type';
import { D365OperationMode } from '../../enums/d365/d365-operation-mode';
import { selectAgentInfo, selectContactLead, selectCurrentVirtualAgent } from '../../../store/selectors/user.selectors';
import { Contact } from '../../models/user/contact';
import { selectFullState } from '../../../store/selectors/common.selectors';
import { CustomerType } from '../../enums/shared/customer-type';
import { CustomerInfo } from '../../models/user/customer';
import { AppInsightsService } from '../shared/app-insights.service';
import { AssetDetailResponse } from '../../../modules/change-product/models/asset-detail.response';
import { CreditCheckService } from '../shared/credit.check.service';
import { AgentSearchResponse } from '../../models/d365/agent-search-response';
import { AgencyBranchSearchResponse } from '../../models/d365/agency-branch-response';
import { D365PostMessage } from '../../models/app/d365-postmessage';
import { PhonePrefix } from '../../models/d365/phone-prefix';
import { PrivateConfigurationService } from '../shared/private-configuration.service';
import { ConfigurationConstants } from '../../config/configuration-constants';
import { NewsContent, NewsListItem } from '../../models/d365/news';
import { FeatureToggleService } from '../shared/feature-toggle.service';
import { D365AccountMigrated } from '../../enums/d365/d365-account-migrated';
import { getRandomHash } from '../../functions/misc.functions';
import { FetchXmlParams, FetchXmlQuery, getFetchXmlQuery } from './fetch-xml-query/fetch-xml-manager';
import { CheckActiveSurveyRequest, CheckActiveSurveyResponse } from '../shared/survey.service';
import { NavigateToRequest } from '../../models/d365/navigate-to-request';
import { NavigateToResponse } from '../../models/d365/navigate-to-response';
import { GetCampaignListResponse } from '../../models/d365/getcampaignlist-response';
@Injectable({
    providedIn: 'root',
})
export class D365Service {
    private observable: Observable<any> = fromEvent(window, 'message').pipe(
        filter((evt: MessageEvent) => evt?.data?.type !== 'ACTION'),
        map((evt: MessageEvent) => evt?.data),
        share()
    );

    constructor(
        private logger: LoggerService,
        private store: Store<{ app: AppState; user: UserState; orderEntry: OrderEntryState }>,
        private utilitySrv: UtilityService,
        private appInsightsSrv: AppInsightsService,
        private ccSrv: CreditCheckService,
        private configSrv: PrivateConfigurationService,
        private toggleService: FeatureToggleService
    ) {
        this.subscribeAutomaticListeners();
    }

    private DISPATCHER_MAP = {
        applocationresponse: (content) =>
            this.store.dispatch(setApplicationLocation({ location: content.appLocation })),
        agentinforequest: (content) =>
            this.store.dispatch(
                setAgentInfo({ a: new D365ResponseBuilder(this.logger).agentInfoResponseToStore(content) })
            ),
        contactresponse: (content) => this.store.dispatch(setContact({ c: content.queryresult, fcrm: true })),
        leadresponse: (content) => this.store.dispatch(setLead({ l: content.queryresult, fcrm: true })),
        geolocationrespponse: (content) => this.store.dispatch(setGeoLocation({ geolocation: content.position })),
    };

    private subscribeAutomaticListeners() {
        this.observable
            .pipe(filter((response) => response?.type in this.DISPATCHER_MAP))
            .subscribe(({ type, content }) => this.DISPATCHER_MAP[type](content));
    }

    private asyncPostMessage<S>(message: D365PostMessage, stringifiedResponseField?: string): Observable<S> {
        const overrideContentType =
            typeof message?.content === 'object' || (message && typeof message === 'object' && !message.content);
        const id = getRandomHash() + new Date().getTime() + (Math.random() * 10 ** 8).toString(36);
        const originalType = (overrideContentType ? message?.content?.type : null) || '';
        const type = overrideContentType ? `${originalType}${id}` : message?.type;

        parent.window.postMessage(
            {
                ...(message || {}),
                content: overrideContentType
                    ? {
                        ...(message?.content || {}),
                        type,
                    }
                    : message?.content,
            },
            '*'
        );

        return this.observable.pipe(
            filter(
                (response) =>
                    ([message?.type, (message?.type).replace('request', 'response')].includes(response?.type) &&
                        !response?.content?.type) ||
                    [response?.type, response?.content?.type].includes(type)
            ),
            timeout(30 * 1000),
            // tap((response) => {
            //     console.log('asyncPostMessage: ', response);
            // }),
            map(({ content }) =>
                typeof content === 'object' && 'type' in content ? { ...content, type: originalType } : content
            ),
            map((content) => {
                if (content) {
                    let stringifiedData: string;
                    if (typeof content === 'string' && !stringifiedResponseField) {
                        stringifiedData = content;
                    } else if (
                        typeof content === 'object' &&
                        stringifiedResponseField &&
                        typeof content[stringifiedResponseField] === 'string'
                    ) {
                        stringifiedData = content[stringifiedResponseField];
                    }
                    if (stringifiedData) {
                        try {
                            return JSON.parse(stringifiedData);
                        } catch { }
                    }
                }
                return content;
            }),
            catchError((err) => {
                console.error(err);
                return of(null);
            }),
            take(1)
        );
    }

    updateCurrentVirtualAgent(currentUserId: string, virtualAgentId: string): Promise<any> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: { UserGuid: currentUserId, VirtualAgentGuid: virtualAgentId },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_setcurrentvirtualagent',
                    parameterTypes: {
                        UserGuid: { typeName: 'Edm.String', structuralProperty: 1 },
                        VirtualAgentGuid: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage(message).toPromise();
    }

    updateRecord(entity: string, entityGuid: string, data: any, responseType?: string) {
        const message: D365PostMessage = {
            type: 'updaterecordrequest',
            content: {
                entity,
                guid: entityGuid,
                data,
                type: responseType || `${entity}updateresponse`,
            },
        };
        return this.asyncPostMessage<void>(message);
    }

    openCreationFormOnCrm(entity: string, formId: string) {
        const formOption = { entityName: entity, formId };
        const message: D365PostMessage = { type: 'xrmnavigation', content: JSON.stringify(formOption) };
        return this.asyncPostMessage(message).toPromise();
    }

    openViewFormOnCrm(entity: string, entityGuid: any, formId: string) {
        const formOption = { entityName: entity, formId, entityId: entityGuid };
        const message: D365PostMessage = { type: 'xrmnavigation', content: JSON.stringify(formOption) };
        return this.asyncPostMessage(message).toPromise();
    }

    openViewFormOnCrmWithParameters(entity: string, entityGuid: any, formId: string, parameters: any) {
        const content = {
            formOptions: { entityName: entity, formId, entityId: entityGuid },
            parameters,
        };
        const message: D365PostMessage = { type: 'xrmnavigationwithparameters', content: JSON.stringify(content) };
        return this.asyncPostMessage(message).toPromise();
    }

    getLead(userGuid: string, customerSegment?: number): Promise<Lead> {
        const start = new Date().getTime();

        return from(this.retrieveRecordAsync('lead', userGuid, ConfigurationConstants.leadFields))
            .pipe(
                map((res) => res?.queryresult || {}),
                mergeMap((lead) =>
                    from(
                        this.retrieveRecordAsync(
                            'campaign',
                            lead.campaignid_value,
                            ConfigurationConstants.campaignFields
                        )
                    ).pipe(
                        map((response) => response?.campaign || {}),
                        map((campaign) => ({ lead, campaign }))
                    )
                ),
                tap(({ lead: { egl_tag } }) => {
                    this.logger.warn(`Trovato tag base lista con valore ${egl_tag}`);
                }),
                map(
                    ({
                        lead: {
                            telephone1,
                            telephone2,
                            address2_postalcode,
                            address2_city,
                            address2_line1,
                            address2_stateorprovince,
                            campaignId,
                            campaignName,
                            campaignCode,
                            campaignTypeCode,
                            egl_customersegmentcode,
                            ...lead
                        },
                        campaign: { campaignid, name, egl_code, egl_campaigntypecode },
                    }) =>
                    ({
                        ...lead,
                        mobilephone: telephone1,
                        originalTelephone1: telephone1,
                        originalTelephone2: telephone2,
                        address1_postalcode: address2_postalcode,
                        address1_city: address2_city,
                        address1_line1: address2_line1,
                        address1_stateorprovince: address2_stateorprovince,
                        campaignId: campaignid || campaignId,
                        campaignName: name || campaignName,
                        campaignCode: egl_code || campaignCode,
                        campaignTypeCode: egl_campaigntypecode || campaignTypeCode,
                        egl_customersegmentcode: (egl_customersegmentcode ||
                            customerSegment) as D365CustomerSegment,
                    } as Lead)
                ),
                tap(() => {
                    const end = new Date().getTime();
                    this.appInsightsSrv.logMetric(`get-lead`, (end - start) / 1000);
                })
            )
            .toPromise();
    }

    getContact(userGuid: string): Promise<Contact> {
        const start = new Date().getTime();
        return this.getAccountInfo(userGuid)
            .pipe(
                map((customer) =>
                    Object.assign(customer || ({} as CustomerInfo), {
                        CustomerType: CustomerType.Account,
                    })
                ),
                tap((customer) => {
                    this.store.dispatch(setCustomerInfo({ c: customer }));
                    this.ccSrv.mapD365CreditCheckData(
                        customer.CreditCheckOutcome,
                        customer.TaxCode || customer.VatCode
                    );
                }),
                map(
                    ({
                        Name,
                        FirstName,
                        LastName,
                        TaxCode,
                        Email,
                        Toponomastica,
                        Address,
                        StreetNumber,
                        Province,
                        PostalCode,
                        CustomerCode,
                        City,
                        Telephone1,
                        Telephone2,
                        CustomerAccountCode,
                        PrimaryContactId,
                        CustomerSegment,
                        VatCode,
                        EglMigration,
                    }) =>
                        Object.assign(new Contact(), {
                            name: Name,
                            firstname: FirstName,
                            lastname: LastName,
                            egl_taxcode: TaxCode,
                            emailaddress1: Email,
                            address1_line1: `${Toponomastica || ''} ${Address || ''} ${StreetNumber || ''}`.trim(),
                            address1_stateorprovince: Province,
                            address1_postalcode: PostalCode,
                            egl_customercode: CustomerCode,
                            address1_city: City,
                            mobilephone: Telephone1,
                            telephone1: Telephone2,
                            accountid: userGuid,
                            egl_customeraccountcode: CustomerAccountCode,
                            contactid: PrimaryContactId,
                            egl_customersegmentcode: CustomerSegment,
                            egl_vatcode: VatCode,
                            EglMigration: this.toggleService.isSwitchInE2EEnabled ? EglMigration : null,
                        })
                ),
                tap((contact) => {
                    if (this.toggleService.isSwitchInE2EEnabled) {
                        this.store.dispatch(
                            setIsSiebelCustomer({ a: contact.EglMigration === D365AccountMigrated.SIEBEL })
                        );
                    }
                    this.store.dispatch(setContact({ c: contact, fcrm: true }));
                }),
                finalize(() => {
                    const end = new Date().getTime();
                    this.appInsightsSrv.logMetric(`get-contact`, (end - start) / 1000);
                })
            )
            .toPromise();
    }

    getCodicePlico(
        channel: string,
        signatureTypeCode: D365SignatureType,
        operationModeCode: D365OperationMode
    ): Promise<CodicePlico> {
        const start = new Date().getTime();
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    Channel: channel,
                    SignatureTypeCode: signatureTypeCode,
                    OperativeModeCode: operationModeCode,
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_generatecontractcode',
                    parameterTypes: {
                        Channel: { typeName: 'Edm.String', structuralProperty: 1 },
                        SignatureTypeCode: { typeName: 'Edm.Int32', structuralProperty: 1 },
                        OperativeModeCode: { typeName: 'Edm.Int32', structuralProperty: 1 },
                    },
                },
            },
        };
        return this.asyncPostMessage<CodicePlico>(message)
            .pipe(
                finalize(() => {
                    const end = new Date().getTime();
                    this.appInsightsSrv.logMetric(`get-codice-plico`, (end - start) / 1000);
                })
            )
            .toPromise();
    }

    openCamera(responseType?: string) {
        if (!responseType) {
            responseType = 'takePicture';
        }

        const message: D365PostMessage = {
            type: 'opencamerarequest',
            content: {
                type: responseType || 'takePicture',
            },
        };
        this.asyncPostMessage(message);
    }

    topNavigationRequest(url: string) {
        const message = {
            type: 'topnavigationrequest',
            content: JSON.stringify(url),
        };
        return this.asyncPostMessage(message);
    }

    getVirtualAgentsBo(agcode: string): Promise<VirtualAgentsForBoList> {
        const type = 'virtualagentsresponse' + getRandomHash();
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                outparameter: 'ResponseJson',
                request: { RequestVirtualAgentCode: agcode },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_getvirtualagentsforbo',
                    parameterTypes: {
                        RequestVirtualAgentCode: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage<VirtualAgentsForBoList>(message).toPromise();
    }

    getCampaignRecords(customerCode: string): Observable<any[]> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    CustomerCode: customerCode,
                    Arrears: false,
                    DiscountFlag: true,
                    Depth: 1,
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_getcampaignlist',
                    parameterTypes: {
                        CustomerCode: { typeName: 'Edm.String', structuralProperty: 1 },
                        Arrears: { typeName: 'Edm.Boolean', structuralProperty: 1 },
                        DiscountFlag: { typeName: 'Edm.Boolean', structuralProperty: 1 },
                        Depth: { typeName: 'Edm.Int32', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage<GetCampaignListResponse>(message).pipe(
            mergeMap((res) => {
                if (res.Result == '001' && !_.isNil(res.Response)) {
                    var records = JSON.parse(res.Response)['response']['BodyResponse']['Records'];
                    if (records.length > 0) records[0]['CODICE_SCONTO'] = 'EP-0000551';
                    if (records.length > 1) records[1]['CODICE_SCONTO'] = 'EP-0000564';
                    return of(records);
                }
                return of([]);
            })
        );
    }

    getNewsList(): Promise<NewsListItem[]> {
        const type = 'newsList' + getRandomHash();
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {},
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_news_retrievevalidnews',
                    parameterTypes: {},
                },
            },
        };
        return this.asyncPostMessage<NewsListItem[]>(message, 'ValidNewsList').toPromise();
    }

    getAvailableSurvey(request: CheckActiveSurveyRequest): Promise<CheckActiveSurveyResponse> {
        const type = 'activeSurvey' + getRandomHash();
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                type,
                request: request,
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_survey_retrievesurveyforentrypointanduser',
                    parameterTypes: {
                        EntryPoint: { typeName: 'Edm.String', structuralProperty: 1 },
                        VirtualAgentId: { typeName: 'Edm.String', structuralProperty: 1 },
                        CustomerTaxVatCode: { typeName: 'Edm.String', structuralProperty: 1 },
                        ProfileType: { typeName: 'Edm.Int32', structuralProperty: 1 },
                        CampaignId: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage<CheckActiveSurveyResponse>(message).toPromise();
    }

    getNewsContent(newsId: string): Promise<NewsContent> {
        const type = 'newsContent' + getRandomHash();
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: { NewsId: newsId },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_news_getbyid',
                    parameterTypes: {
                        NewsId: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };

        const endpoint = this.configSrv.get('d365.url');
        return this.asyncPostMessage<NewsContent>(message)
            .pipe(
                map(({ NewsDescription, ...res }) => ({
                    ...res,
                    NewsDescription: (NewsDescription || '').replace(
                        /src="\/api\/data/g,
                        'src="' + endpoint + '/api/data'
                    ),
                }))
            )
            .toPromise();
    }

    verifyTelephoneNumber(
        tSubject: string,
        tToPhoneNumber: string,
        tTemplate?: string,
        tToId?: string,
        tEntityName?: string,
        tSubstitutionArguments?: string
    ): Promise<any> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    Subject: tSubject,
                    ToPhoneNumber: tToPhoneNumber,
                    TemplateId: tTemplate,
                    ToId: tToId,
                    ToEntityName: tEntityName,
                    SubstitutionArguments: tSubstitutionArguments,
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_sms_createandsend',
                    parameterTypes: {
                        Subject: { typeName: 'Edm.String', structuralProperty: 1 },
                        ToPhoneNumber: { typeName: 'Edm.String', structuralProperty: 1 },
                        TemplateId: { typeName: 'Edm.String', structuralProperty: 1 },
                        ToId: { typeName: 'Edm.String', structuralProperty: 1 },
                        ToEntityName: { typeName: 'Edm.String', structuralProperty: 1 },
                        SubstitutionArguments: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage(message).toPromise();
    }

    verifyEmailAddress(
        tSubject: string,
        tBody: string,
        tToEmail: string,
        tTemplate?: string,
        tToId?: string,
        tEntityName?: string,
        tSubstitutionArguments?: any
    ): Promise<any> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    Subject: tSubject,
                    Body: tBody,
                    ToEmail: tToEmail,
                    TemplateId: tTemplate,
                    ToId: tToId,
                    ToEntityName: tEntityName,
                    SubstitutionArguments: JSON.stringify(tSubstitutionArguments),
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_email_createandsend',
                    parameterTypes: {
                        Subject: { typeName: 'Edm.String', structuralProperty: 1 },
                        Body: { typeName: 'Edm.String', structuralProperty: 1 },
                        ToEmail: { typeName: 'Edm.String', structuralProperty: 1 },
                        ToId: { typeName: 'Edm.String', structuralProperty: 1 },
                        ToEntityName: { typeName: 'Edm.String', structuralProperty: 1 },
                        SubstitutionArguments: { typeName: 'Edm.String', structuralProperty: 1 },
                        TemplateId: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage(message).toPromise();
    }

    // formato data "2020-04-14"
    getExecutionDate(
        apttusQuoteCode: string,
        customerSegment: string,
        channelCode: string,
        signatureType: string,
        confirmationType: string,
        immediateExecution: boolean,
        digitalCommunication: boolean,
        signatureDate: string,
        operativeMode: D365OperationMode,
        letterDate: string
    ): Promise<DataEsecuzione> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    ApttusQuoteCode: apttusQuoteCode,
                    CustomerSegment: customerSegment,
                    ChannelCode: channelCode,
                    SignatureType: signatureType,
                    ConfirmationType: confirmationType,
                    ImmediateExecution: immediateExecution ? '1' : '0',
                    DigitalCommunication: digitalCommunication ? '1' : '0',
                    SignatureDate: signatureDate,
                    OperativeModeCode: operativeMode,
                    LetterDate: letterDate,
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_getactivationdate',
                    parameterTypes: {
                        ApttusQuoteCode: { typeName: 'Edm.String', structuralProperty: 1 },
                        CustomerSegment: { typeName: 'Edm.String', structuralProperty: 1 },
                        ChannelCode: { typeName: 'Edm.String', structuralProperty: 1 },
                        SignatureType: { typeName: 'Edm.String', structuralProperty: 1 },
                        ConfirmationType: { typeName: 'Edm.String', structuralProperty: 1 },
                        ImmediateExecution: { typeName: 'Edm.String', structuralProperty: 1 },
                        DigitalCommunication: { typeName: 'Edm.String', structuralProperty: 1 },
                        SignatureDate: { typeName: 'Edm.String', structuralProperty: 1 },
                        OperativeModeCode: { typeName: 'Edm.Int32', structuralProperty: 1 },
                        LetterDate: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };

        if (this.toggleService.isSwitchInE2EEnabled) {
            Object.defineProperty(message.content.request, 'ProcessStep', {
                value: 'OE',
                writable: true,
                enumerable: true,
            });
            Object.defineProperty(message.content.requestmetadata.parameterTypes, 'ProcessStep', {
                value: { typeName: 'Edm.String', structuralProperty: 1 },
                writable: true,
                enumerable: true,
            });
        }

        return this.asyncPostMessage<DataEsecuzione>(message).toPromise();
    }

    startGeolocation(): void {
        const message: D365PostMessage = {
            type: 'geolocationrequest',
        };
        this.asyncPostMessage(message).toPromise();
    }

    retrieveRecordAsync(
        entity: string,
        entityGuid: string,
        fields: Array<string>,
        responseType?: string
    ): Promise<any> {
        const type = (responseType ? responseType : `${entity}response`) + getRandomHash();
        const message: D365PostMessage = {
            type: 'retrieve',
            content: {
                entity,
                guid: entityGuid,
                fields,
                type,
            },
        };
        return this.asyncPostMessage(message).toPromise();
    }

    retrieveMultipleRecordsAsync<S>(
        entity: string,
        filter: string,
        fields: string[],
        responseType?: string
    ): Observable<S[]> {
        const message: D365PostMessage = {
            type: 'retrievemultiple',
            content: {
                entity,
                filter,
                fields,
                type: responseType || `${entity}response`,
            },
        };

        return this.asyncPostMessage<{ queryresult: [] }>(message).pipe(
            // Retrieving query results
            map((res) => (res?.queryresult || []) as S[]),
            // Mapping out only given fields
            map((records) =>
                fields
                    ? records.map((record) =>
                        fields.reduce(
                            (aggr, field) => ({
                                ...aggr,
                                [field]: record[field],
                            }),
                            {} as S
                        )
                    )
                    : records
            )
        );
    }

    requestApplicationLocationAsync(): Observable<ApplicationLocationType> {
        const message: D365PostMessage = { type: 'applocationrequest' };
        return this.asyncPostMessage<{ appLocation: ApplicationLocationType }>(message).pipe(
            map(({ appLocation }) => appLocation)
        );
    }

    // Ricerca del lead e autoaasegnazione dello stesso in caso di esito positivo
    // leadId -> guid del lead su crm
    // leadCode -> Codice Lead
    // currentUserId -> Guid dell'utente corrente - OBBLIGATORIO
    // uno dei due campi leadId e leadCode è necessario per la action, il campo non utilizzato va passato come ""
    leadSearch(
        leadId: string,
        leadCode: string,
        currentUserId: string,
        currentVirtualAgent: VirtualAgent
    ): Promise<any> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    LeadId: leadId,
                    LeadCode: leadCode,
                    CurrentUserId: currentUserId,
                    CurrentVirtualAgentId: currentVirtualAgent.VirtualAgentId,
                    CurrentVirtualAgencyId: currentVirtualAgent.VirtualAgencyId,
                    CurrentVirtualAgencyChannelCanAutoAssign:
                        currentVirtualAgent.VirtualAgency.Channel.CanAutoAssignLead,
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_lead_autoassign',
                    parameterTypes: {
                        LeadId: { typeName: 'Edm.String', structuralProperty: 1 },
                        LeadCode: { typeName: 'Edm.String', structuralProperty: 1 },
                        CurrentUserId: { typeName: 'Edm.String', structuralProperty: 1 },
                        CurrentVirtualAgentId: { typeName: 'Edm.String', structuralProperty: 1 },
                        CurrentVirtualAgencyId: { typeName: 'Edm.String', structuralProperty: 1 },
                        CurrentVirtualAgencyChannelCanAutoAssign: { typeName: 'Edm.Boolean', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage(message).toPromise();
    }

    getAgentInfoAsync(): Observable<AgentInfo> {
        const message: D365PostMessage = {
            type: 'agentinforequest',
        };

        return this.store.select(selectAgentInfo).pipe(
            take(1),
            mergeMap((res: AgentInfo) => (res ? of(res) : this.asyncPostMessage<AgentInfo>(message)))
        );
    }

    // Recupero informazioni account, prende in input un guid dynamics es: 1f3a074f-6085-ea11-a812-000d3a20f252
    // Le informazioni dell'account sono all'interno dell'oggetto di risposta nell'oggetto ResponseJson
    getAccountInfo(accountGuid: string): Observable<CustomerInfo> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    RequestGuid: accountGuid,
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_getaccount',
                    parameterTypes: {
                        RequestGuid: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage<CustomerInfo>(message, 'ResponseJson');
    }

    // leadToCustomerInfo(lead: any): CustomerInfo {
    //     const customerInfo: CustomerInfo = new CustomerInfo();
    //     customerInfo.CustomerType = CustomerType.Lead;
    //     customerInfo.FirstName = lead.firstname;
    //     customerInfo.LastName = lead.lastname;
    //     customerInfo.Telephone1 = lead.telephone1;
    //     customerInfo.Email = lead.emailaddress1;
    //     customerInfo.Privacy1 = lead.egl_privacy1;
    //     customerInfo.Privacy2 = lead.egl_privacy2;
    //     customerInfo.Privacy3 = lead.egl_privacy3;
    //     customerInfo.TaxCode = lead.egl_taxcode;

    //     return customerInfo;
    // }

    // Esitazione del LEAD
    // leadGuid -> guid dell'utente
    // codicePlico -> codicePlico
    setCampaignResponseCreateOk(
        leadGuid: string,
        codicePlico: string,
        timestamp: string,
        telephone?: string
    ): Promise<any> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    LeadId: leadGuid,
                    DocumentCode: codicePlico,
                    Telephone: telephone,
                    Timestamp: JSON.stringify(timestamp),
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_campaignresponse_createok',
                    parameterTypes: {
                        LeadId: { typeName: 'Edm.String', structuralProperty: 1 },
                        DocumentCode: { typeName: 'Edm.String', structuralProperty: 1 },
                        Telephone: { typeName: 'Edm.String', structuralProperty: 1 },
                        Timestamp: { typeName: 'Edm.DateTime', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage(message).toPromise();
    }

    // Creazione lead da interesse
    // prende in input
    // l'id del current user,
    // l'id del virtual agent,
    // array di stringe degli interessi (es: GAS E LUCE, ASSOCURAZIONI),
    // oggettino LeadData
    // restituisce la seguente risposta, all'interno di CreatedLeads viene ritornato un array di guid dei lead creati su CRM
    // {
    // "@odata.context": "https://egl-ev-d1.crm4.dynamics.com/api/data/v9.0/$metadata#Microsoft.Dynamics.CRM.egl_createleadfrominterestsResponse",
    // "Result": "OK",
    // "ErrorMessage": null,
    // "CreatedLeads": "[\"65251496-e08a-ea11-a811-000d3a3a705d\",\"e1b81a9c-e08a-ea11-a811-000d3a3a705d\"]"
    // }
    createLeadFromInterest(
        currentUserId: string,
        CurrentVirtualAgentId: string,
        interestList: string[],
        leadData: LeadDataForInterest
    ): Promise<CreateLeadFromInterestsResponse> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    CurrentUserId: currentUserId,
                    CurrentVirtualAgentId,
                    InterestList: JSON.stringify(interestList),
                    LeadData: JSON.stringify(leadData),
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_createleadfrominterests',
                    parameterTypes: {
                        CurrentUserId: { typeName: 'Edm.String', structuralProperty: 1 },
                        CurrentVirtualAgentId: { typeName: 'Edm.String', structuralProperty: 1 },
                        InterestList: { typeName: 'Edm.String', structuralProperty: 1 },
                        LeadData: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage<CreateLeadFromInterestsResponse>(message).toPromise();
    }

    // Chiamata al servizio recupero metodi di verifica volontà
    // https://dev.azure.com/DevOps-Applications-EGL/Front%20End%20Eni/_wiki/wikis/Front-End-Eni.wiki/447/Action-per-recupero-metodi-di-verifica-volont%C3%A0
    /**
     * @description: Chiamata al servizio recupero metodi di verifica volontà, utilizzato anche per il recupero dei metodi di firma
     * @param channelCode: Codice Canale es: AGE, TLO, ecc
     * @param signatureType: Tipo di firma es: '100000000', i valori della mappatura si trovano in D365SignatureType
     * @param HasInsurance: Presenza di un assicurazione a carrello
     * @param agencyCode: GUID agenzia
     * @param agentCode: GUID agente
     * @return: CheckTypeResponse
     */
    getCheckType(
        channelCode: string,
        signatureType: string,
        HasInsurance: string,
        agencyCode: string,
        agentCode: string,
        operationMode: D365OperationMode
    ): Promise<CheckTypeResponse> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    ChannelCode: channelCode,
                    SignatureType: signatureType,
                    HasInsurance,
                    AgencyCode: agencyCode,
                    AgentCode: agentCode,
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_getchecktype',
                    parameterTypes: {
                        ChannelCode: { typeName: 'Edm.String', structuralProperty: 1 },
                        SignatureType: { typeName: 'Edm.String', structuralProperty: 1 },
                        HasInsurance: { typeName: 'Edm.String', structuralProperty: 1 },
                        AgencyCode: { typeName: 'Edm.String', structuralProperty: 1 },
                        AgentCode: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };
        if (operationMode) {
            message.content.request['OperativeModeCode'] = operationMode;
            message.content.requestmetadata.parameterTypes['OperativeModeCode'] = {
                typeName: 'Edm.Int32',
                structuralProperty: 1,
            };
        }

        return this.asyncPostMessage<CheckTypeResponse>(message).toPromise();
    }

    // Recupera lista degli interessi attualmente caricati su d365
    getLeadTopic(): Promise<LeadTopic[]> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {},
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_getleadtopic',
                    parameterTypes: {},
                },
            },
        };

        return this.asyncPostMessage<LeadTopic[]>(message, 'LeadTopicList').toPromise();
    }

    // Recupero sede operativa storicizzata
    // prende in input il virtualagentid e la data della richiesta
    // restituisce l'agencybranch a
    getAgencyBranch(virtualAgent: VirtualAgent, date: string, dispatch?: boolean): Promise<AgencyBranch> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    VirtualAgentId: virtualAgent.VirtualAgentId,
                    Date: date,
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_virtualagent_getagencybranch',
                    parameterTypes: {
                        VirtualAgentId: { typeName: 'Edm.String', structuralProperty: 1 },
                        Date: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage<AgencyBranch>(message)
            .pipe(
                tap((res) => {
                    if (res?.Result !== 'OK') {
                        this.logger.warn(
                            `Non è stata trovata una sede operativa per l'agente ${virtualAgent.AgentCode} per la data ${date}`,
                            false
                        );
                    }
                })
            )
            .toPromise();
    }

    // Verifica del codice plico inserito
    // esempio risposta KO
    // "Result": "KO",
    // "ErrorMessage": "Il formato codice plico fornito non coincide con quanto presente a sistema per i seguenti parametri: Channel Teleselling outbound, SignatureTypeCode 100000004, OperativeModeCode 100000004"
    // esempio risposta OK
    // "Result": "OK",
    // "ErrorMessage": ""
    // contractCode->Codice Plico
    // signatureTypeCode-> tipo di firma
    // OperationModeCode-> modalità operativa

    checkCodicePlico(
        contractCode: string,
        channel: string,
        signatureTypeCode: D365SignatureType,
        operationModeCode: D365OperationMode
    ): Promise<BaseD365Response> {
        const type = 'checkcontractcoderesponse' + getRandomHash();
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    ContractCode: contractCode,
                    Channel: channel,
                    SignatureTypeCode: signatureTypeCode.toString(),
                    OperativeModeCode: operationModeCode.toString(),
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_validatecontractcode',
                    parameterTypes: {
                        ContractCode: { typeName: 'Edm.String', structuralProperty: 1 },
                        Channel: { typeName: 'Edm.String', structuralProperty: 1 },
                        SignatureTypeCode: { typeName: 'Edm.String', structuralProperty: 1 },
                        OperativeModeCode: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage<BaseD365Response>(message).toPromise();
    }

    // Action di recuperoconfigurazione Canale
    // Channel è channelCode, es: TLI
    getChannelMarketConfiguration(
        channel: string | D365ChannelCode,
        customerSegment: D365CustomerSegment
    ): Promise<ChannelMarketConfigurationResponse> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    Channel: channel,
                    Market: +customerSegment,
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_channelmarketconfiguration_getconfiguration',
                    parameterTypes: {
                        Channel: { typeName: 'Edm.String', structuralProperty: 1 },
                        Market: { typeName: 'Edm.Int32', structuralProperty: 1 },
                    },
                },
            },
        };

        return combineLatest([
            this.store.pipe(take(1), select(selectFullState)),
            this.asyncPostMessage<ChannelMarketConfigurationResponse>(message),
        ])
            .pipe(
                map(([state, res]) => ({
                    agentInfo: _.clone(state?.user?.agentInfo),
                    res,
                    canSkipExecution:
                        (res?.ChannelMarketConfiguration
                            ? (JSON.parse(res.ChannelMarketConfiguration) as ChannelMarketConfiguration)
                            : null
                        )?.MustSkipImmediateExecution === 1,
                })),
                map(({ res, agentInfo, canSkipExecution }) => ({
                    res,
                    agentInfo: res
                        ? ({
                            ...(agentInfo || {}),
                            VirtualAgents: (agentInfo?.VirtualAgents || []).map((virtualAgent) => ({
                                ...(virtualAgent || {}),
                                VirtualAgency: {
                                    ...(virtualAgent?.VirtualAgency || {}),
                                    Channel: {
                                        ...(virtualAgent?.VirtualAgency?.Channel || {}),
                                        CanSkipExecution: canSkipExecution,
                                    },
                                },
                            })),
                        } as AgentInfo)
                        : null,
                    passaggioRapido: canSkipExecution
                        ? Object.assign(new TipoEsecuzione(), {
                            passaggioRapidoCommodity: false,
                            passaggioRapidoManutenzione: false,
                        })
                        : null,
                })),
                tap(({ passaggioRapido, agentInfo }) => {
                    if (passaggioRapido) {
                        this.store.dispatch(setPassaggioRapido({ payload: passaggioRapido }));
                    }
                    if (agentInfo) {
                        this.store.dispatch(setAgentInfo({ a: agentInfo }));
                    }
                }),
                map(({ res }) => res)
            )
            .toPromise();
    }

    getSkipConfiguration(): void {
        zip(this.store.select(selectCurrentVirtualAgent), this.store.select(selectContactLead))
            .pipe(take(1))
            .subscribe((res: any[]) => {
                if (res) {
                    let channelCode: D365ChannelCode = null;
                    let customerSegment: D365CustomerSegment = null;

                    if (res[0]) {
                        const virtualAgent: VirtualAgent = res[0];
                        channelCode = virtualAgent.VirtualAgency.Channel.Code;
                    }

                    if (res[1]) {
                        if (res[1].contact) {
                            const customerAccount: Contact = res[1].contact as Contact;
                            customerSegment = customerAccount.egl_customersegmentcode;
                        }
                        if (res[1].lead) {
                            const customerLead: Lead = res[1].lead as Lead;
                            customerSegment = customerLead.egl_customersegmentcode;
                        }

                        customerSegment =
                            customerSegment === null || customerSegment === undefined
                                ? D365CustomerSegment.Residential
                                : customerSegment;
                        if (channelCode === null) {
                            this.logger.error('', 'Canale o Segmento di mercato non valorizzati');
                            return;
                        }

                        this.getChannelMarketConfiguration(channelCode, customerSegment);
                    }
                }
            });
    }

    setUserConfiguration(userId: string, lastCustomerSegment: D365CustomerSegment): Promise<boolean> {
        const userConfiguration = {
            UserId: userId,
            LastUsedCustomerSegment: lastCustomerSegment,
        };
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    UserConfiguration: JSON.stringify(userConfiguration),
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_systemuserconfiguration_setuserconfiguration',
                    parameterTypes: {
                        UserConfiguration: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage<BaseD365Response>(message)
            .pipe(
                map((res) => res?.Result !== 'OK' && res?.ErrorMessage),
                tap((errorMessage) => {
                    if (errorMessage) {
                        this.logger.error(null, "Errore nell'impostazione del mercato dell'agente", errorMessage, true);
                    }
                    this.store.dispatch(setLastUsedCustomerSegment({ s: lastCustomerSegment }));
                }),
                map((errorMessage) => !errorMessage)
            )
            .toPromise();
    }

    setVirtualAgentConfiguration(virtualAgentId: string, isInStore: boolean): Promise<boolean> {
        const VirtualAgentConfiguration = {
            VirtualAgentIdString: virtualAgentId,
            IsInStore: isInStore,
        };
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    ConfigurationJsonString: JSON.stringify(VirtualAgentConfiguration),
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_setvirtualagentconfiguration',
                    parameterTypes: {
                        ConfigurationJsonString: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage<BaseD365Response>(message)
            .pipe(
                map((res) => res?.Result !== 'OK' && res?.ErrorMessage),
                tap((errorMessage) => {
                    if (errorMessage) {
                        this.logger.error(
                            null,
                            "Errore nell'impostazione della configurazione dell'agente virtuale",
                            errorMessage,
                            true
                        );
                    }
                }),
                map((errorMessage) => !errorMessage)
            )
            .toPromise();
    }

    openLookupObject(
        entity: string,
        fields: string[] = [],
        multiSelect: boolean = false,
        viewId?: string[],
        searchText: string = '',
        lookupFilters?: LookupFilter[]
    ): Promise<any> {
        if (!viewId) {
            viewId = ['00000000-0000-0000-0000-000000000000'];
        }

        const message: D365PostMessage = {
            type: 'openlookupobject',
            content: {
                entity,
                fields,
                multiselect: multiSelect,
                viewid: viewId,
                searchtext: searchText,
                filters: lookupFilters,
            },
        };

        return this.asyncPostMessage(message).toPromise();
    }

    getLegalForm(): Promise<LegalForm[]> {
        return this.retrieveMultipleRecordsAsync<LegalForm>('egl_accountlegalform', 'statecode eq 0', [
            'egl_name',
            'egl_code',
        ]).toPromise();
    }

    // Retrieve dei valori di tensione
    getVoltageLevel(): Promise<VoltageLevel[]> {
        return this.retrieveMultipleRecordsAsync<{ egl_name: string; egl_code: string }>(
            'egl_voltagelevel',
            'statecode eq 0',
            ['egl_name', 'egl_code']
        )
            .pipe(map((results) => results.map(({ egl_code, egl_name }) => new VoltageLevel(egl_code, +egl_name))))
            .toPromise();
    }

    // retrieve di potenza utilizzo
    getPowerConsumption(): Promise<PowerConsumption[]> {
        return this.retrieveMultipleRecordsAsync('egl_powerconsumption', 'statecode eq 0', [
            'egl_name',
            'egl_code',
            'egl_pd2transcoding',
        ])
            .pipe(
                map((results) =>
                    results.map(
                        ({ egl_code, egl_name, egl_pd2transcoding }) =>
                            new PowerConsumption(+egl_name, egl_code, +egl_pd2transcoding)
                    )
                )
            )
            .toPromise();
    }

    // getSalesUpConfigAsync(): Observable<any> {
    //      //     const message: D365PostMessage = {
    //         type: 'executeaction',
    //         content: {
    //             type: t,
    //             request: {
    //                 KeyName: t,
    //             },
    //             requestmetadata: {
    //                 boundParameter: null,
    //                 operationType: 0,
    //                 operationName: 'egl_privateconfiguration_getbykey',
    //                 parameterTypes: {
    //                     KeyName: { typeName: 'Edm.String', structuralProperty: 1 },
    //                 },
    //             },
    //         },
    //     };
    //     if (environment.configOverride?.privateConfiguration) {
    //         this.getLocalPrivateConfiguration();
    //     } else {
    //         this.sendPostMessage(message);
    //     }
    //     return this.observable.pipe(filter((data: any) => data.type === t));
    // }

    /**
     * Recupera la configurazione dell'app dal file (indicato in environment.configOverride.privateConfiguration) ed emette un evento
     * che verrà recuperato da createPostMessageListener() e salvato nello store
     */
    // getLocalPrivateConfiguration(): void {
    //     this.api.getLocalFile(environment.configOverride.privateConfiguration).subscribe(
    //         (response) => {
    //             const configEvent = new MessageEvent('message', {
    //                 data: {
    //                     content: {
    //                         Value: JSON.stringify(response),
    //                     },
    //                     type: 'SalesUp.Config',
    //                 },
    //             });
    //             window.dispatchEvent(configEvent);
    //         },
    //         (error) => {
    //             this.logger.error(`File locale di configurazione non trovato all' indirizzo: ${error.url}`);
    //         }
    //     );
    // }

    /**
     * Download dei file attraverso api nativa di dynamics365
     * @param base64File
     * @param filename with extenction
     * @param mime
     * @param saveFile open or save file
     */
    downloadFile(base64File: string | ArrayBuffer | null, filename: string, mime: string, saveFile: boolean) {
        const message: D365PostMessage = {
            type: 'downloadfile',
            content: {
                file: {
                    fileContent: base64File,
                    fileSize: 0,
                    mimeType: mime,
                    fileName: filename,
                },
                openFileOptions: {
                    openMode: saveFile ? 2 : 1,
                },
            },
        };

        this.asyncPostMessage(message);
    }

    retrieveMultipleRecordsFetchAsync(query: FetchXmlQuery, params?: FetchXmlParams): Observable<{ queryresult: [] }> {
        const { entity, fetchxml } = getFetchXmlQuery(query, params);
        const message: D365PostMessage = {
            type: 'retrievemultiplefetch',
            content: {
                entity,
                fetchxml,
            },
        };
        return this.asyncPostMessage<{ queryresult: [] }>(message);
    }

    getToponyms(): Observable<string[]> {
        return this.retrieveMultipleRecordsAsync('egl_toponym', 'statecode eq 0', ['egl_name']).pipe(
            map((result) => result.map(({ egl_name }) => egl_name))
        );
    }

    /**
     * Mostra un iFrame che consente di scattare una foto oppure selezionare un file da device (utilizza api Xrm.Device.captureImage per scattare una foto).
     * https://docs.microsoft.com/en-us/powerapps/developer/model-driven-apps/clientapi/reference/xrm-device/captureimage
     * @cOption capture avvia solo la CAMERA, pickFile avvia solo la ricerca in dispositovo.
     * in caso di paramentro vuoto scelta attraverso modale
     */
    deviceSelectFile(cOption?: 'capture' | 'pickFile', cAccept?: string): Promise<[SelectFileResponse]> {
        const message: D365PostMessage = {
            type: 'selectFile',
            content: {
                option: cOption,
                accept: cAccept,
            },
        };
        return this.asyncPostMessage<[SelectFileResponse]>(message).toPromise();
    }

    /**
     * Restituisce la lista dei materiali formativi visibili all'utente selezionato
     * @param currentUserId identificativo agente connesso
     */
    getKnowledgeMaterialList(
        currentUserId: string,
        segment: D365CustomerSegment
    ): Promise<KnowledgeMaterialListItem[]> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: { userid: currentUserId, customerSegmentCode: segment },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_knowledgematerial_getlist',
                    parameterTypes: {
                        userid: { typeName: 'Edm.String', structuralProperty: 1 },
                        customerSegmentCode: { typeName: 'Edm.Int32', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage<KnowledgeMaterialListItem[]>(message, 'dataJson').toPromise();
    }

    /**
     * Restituisce il base64 di un determinato file formativo presente su d365
     * @param id identificativo documento restituito dalla 'getKnowledgeMaterialList'
     */
    downloadKnowledgeMaterial(id: string): Promise<SelectFileResponse> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: { knowledgematerialid: id },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_knowledgematerial_downloadfile',
                    parameterTypes: {
                        knowledgematerialid: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };
        return this.asyncPostMessage<SelectFileResponse>(message)
            .pipe(
                map(
                    (res) =>
                        res && {
                            fileContent: res.fileContent,
                            fileName: res.fileName,
                            fileSize: res.fileSize,
                            mimeType: res.mimeType,
                        }
                )
            )
            .toPromise();
    }

    /**
     * Restituisce la lista dei materiali formativi visibili all'utente selezionato
     * @param currentUserId identificativo agente connesso
     */
    getAssetStructure(assetIntegrationId: string): Promise<AssetDetailResponse> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: { AssetIntegrationId: assetIntegrationId },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_getassetstructure',
                    parameterTypes: {
                        AssetIntegrationId: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage<AssetDetailResponse>(message, 'AssetStructure').toPromise();
    }

    getAgentList(query: string, maxNumber?: number): Promise<AgentSearchResponse> {
        const type = 'agentSearchBO';
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    MaxRecords: maxNumber || 20,
                    FilterAgent: query,
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_agent_search',
                    parameterTypes: {
                        FilterAgent: { typeName: 'Edm.String', structuralProperty: 1 },
                        MaxRecords: { typeName: 'Edm.Int32', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage<AgentSearchResponse>(message, 'AgentSearch').toPromise();
    }

    getAgencyBranchList(query: string, maxNumber?: number): Promise<AgencyBranchSearchResponse> {
        const type = 'agencyBranchSearchBO';
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    MaxRecords: maxNumber || 20,
                    FilterAgencyBranch: query,
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_agencybranch_search',
                    parameterTypes: {
                        FilterAgencyBranch: { typeName: 'Edm.String', structuralProperty: 1 },
                        MaxRecords: { typeName: 'Edm.Int32', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage<AgencyBranchSearchResponse>(message, 'AgencyBranchSearchResult').toPromise();
    }

    /**

     * @description Recupera da D365 la lista dei prefissi telefonici internazionali (+39, +34, ...)
     * @returns Promise<PhonePrefix>
     */
    getInternationalPhonePrefixes(): Promise<PhonePrefix[]> {
        return this.retrieveMultipleRecordsAsync<PhonePrefix>('egl_countrycallingcodes', 'statecode eq 0', [
            'egl_callingcode',
            'egl_country',
            'egl_countrycode',
        ]).toPromise();
    }

    /**
     * Setta la sede operativa offerta dell'agente virtuale selezionato
     * @param virtualAgentId id dell'agente virtuale
     * @param agencyBranchId id della sede operativa offerta
     * @returns Promise<boolean> restutuisce true se l'api di salvataggio ha funzionato correttamente
     */
    setCurrentAgencyBranch(virtualAgentId: string, agencyBranch: AgencyBranchForMonitoring): Promise<boolean> {
        const type = 'setcurrentagencybranch' + getRandomHash();

        const message = {
            type: 'executeaction',
            content: {
                request: {
                    VirtualAgentId: virtualAgentId,
                    AgencyBranchId: agencyBranch?.AgencyBranchId,
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_setvirtualagentcurrentagencybranch',
                    parameterTypes: {
                        VirtualAgentId: { typeName: 'Edm.String', structuralProperty: 1 },
                        AgencyBranchId: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage<BaseD365Response>(message)
            .pipe(
                map((res) => res?.Result !== 'OK' && res?.ErrorMessage),
                tap((errorMessage) => {
                    if (errorMessage) {
                        this.logger.error(
                            null,
                            "Errore nell'impostazione della sede operativa offerta dell'agente",
                            errorMessage,
                            true
                        );
                    } else {
                        this.store.dispatch(setVirtualAgentAgencyCurrentBranch({ payload: agencyBranch }));
                    }
                }),
                map((errorMessage) => !errorMessage)
            )
            .toPromise();
    }

    /**
     * Chiama l'api nativa navigateTo di d365, documentazione microsoft: https://docs.microsoft.com/en-us/powerapps/developer/model-driven-apps/clientapi/reference/xrm-navigation/navigateto
     * @param req id dell'agente virtuale
     * @returns Promise<boolean> restituisce se la modale è stata aperta correttamente
     */
    async navigateTo(req: NavigateToRequest): Promise<boolean> {
        const type = 'navigateTo' + getRandomHash();
        var content = req;
        content['type'] = type;

        const message: D365PostMessage = { type: 'navigateTo', content };

        return this.asyncPostMessage<NavigateToResponse>(message)
            .pipe(
                map((res) => res?.navigationOk),
                tap((resultOk) => {
                    if (!resultOk) {
                        this.logger.error(null, "Errore nell'apertura della modale", null, true);
                    }
                })
            )
            .toPromise();
    }
}
