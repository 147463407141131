import CodiceFiscale from 'codice-fiscale-js';
import * as moment from 'moment';

/**
 * Ricalcola il checkDigit a partire da una PIVA di input.
 * Restituisce True/False se il checkDigit calcolato risultata
 * uguale a quello dell'PIVA di input
 * @param pIva
 */
export const isPivaValid = (pIva: string): boolean => {
    if (!pIva || pIva.length !== 11) {
        return false;
    }

    const validi = '0123456789';
    for (let i = 0; i < 11; i++) {
        if (validi.indexOf(pIva.charAt(i)) === -1) {
            return false;
        }
    }

    let s = 0;
    for (let i = 0; i <= 9; i += 2) {
        s += pIva.charCodeAt(i) - '0'.charCodeAt(0);
    }

    for (let i = 1; i <= 9; i += 2) {
        let c = 2 * (pIva.charCodeAt(i) - '0'.charCodeAt(0));
        if (c > 9) {
            c = c - 9;
        }
        s += c;
    }
    if ((10 - (s % 10)) % 10 !== pIva.charCodeAt(10) - '0'.charCodeAt(0)) {
        return false;
    }
    return true;
};

/**
 * Restituisce True/False se è possibile effettuare il reverse del CF, e quindi il CF è valido
 * @param cfCode cf da validare
 */
export const isCfValidLite = (cfCode: string): boolean => {
    return reverseCf(cfCode) ? true : false;
};

/**
 * Verifica di COERENZA tra dati inseriti e CF ed età consentita
 * @param params valori di validazione
 * @param fullCheck se TRUE check sull'interno CF, se FALSE solo i primi 6 caratteri
 * @return isValid: true se cf valido o { isValid: false; reason: ReasonDescription}
 */
export const isCfValid = (
    params: CfValidation,
    fullCheck: boolean
): { isValid: boolean; reason: ReasonDescription } => {
    let isValid = false;
    let cfRversed;
    let reason = null;

    if ((cfRversed = reverseCf(params.cfCode))) {
        if (isAgeRangeOk(cfRversed)) {
            // verifico che il reverse sia OK
            const isItalianCf = !isNoItalianCf(params.cfCode);
            const cfCalculated = calculateCF(params, fullCheck);
            if (cfCalculated) {
                if (isItalianCf && fullCheck) {
                    isValid = params.cfCode.toUpperCase() === cfCalculated;
                    reason = isValid ? ReasonDescription.ALL_CHECKS_OK : ReasonDescription.GENERIC_ERROR;
                } else {
                    isValid = params.cfCode.slice(0, 6).toUpperCase() === cfCalculated.slice(0, 6);
                    reason = isValid ? ReasonDescription.ALL_CHECKS_OK : ReasonDescription.GENERIC_ERROR;
                }
            }
        } else {
            reason = ReasonDescription.AGE_RANGE_KO;
        }
    }
    return { isValid: isValid, reason: reason };
};

/**
 * Restituisce True se il CF contine la lettera Z nell'undicesima posizione
 * @param codFisc CF da verificare
 */
export const isNoItalianCf = (codFisc: string): boolean => {
    if (codFisc && codFisc.length > 11) {
        return codFisc.charAt(11).toUpperCase() === 'Z';
    }
    return false;
};

/**
 * Dato un CF di input restituisce un oggetto contenente i valori calcolati attraverso
 * il calcolo inverso del CF. Resituisce Null in caso di CF errato.
 * @param codFisc CF di input
 */

export const reverseCf = (codFisc: string): IOutputCodiceFiscaleObj => {
    try {
        return (codFisc?.trim().length === 16 && CodiceFiscale.computeInverse(codFisc.trim().toUpperCase())) || null;
    } catch (error) {
        // in caso di eccezione la libreria del reverse non è riuscita ad
        // effettuare il reverse del CF
        return null;
    }
};

const calculateCF = (inp: CfValidation, fullCheck: boolean): string => {
    try {
        const isItalianCf = !isNoItalianCf(inp.cfCode);
        if (inp.firstname && inp.lastname) {
            let birtDatas = {
                birtDay: 12,
                birtMonth: 7,
                birtYear: 1957,
                birthPlace: 'Napoli',
                gender: 'M',
            };

            if (isItalianCf && fullCheck) {
                const date = inp.birtDate;
                birtDatas = {
                    birtDay: date.getDate(),
                    birtMonth: date.getMonth() + 1,
                    birtYear: date.getFullYear(),
                    birthPlace: inp.birthPlace,
                    gender: inp.gender,
                };
            }

            const cfPayload: IInputCodiceFiscaleObj = {
                name: inp.firstname,
                surname: inp.lastname,
                gender: birtDatas.gender,
                day: birtDatas.birtDay,
                month: birtDatas.birtMonth,
                year: birtDatas.birtYear,
                birthplace: birtDatas.birthPlace,
            };

            if (isItalianCf && fullCheck) {
                cfPayload.birthplaceProvincia = inp.birthProvince;
            }

            return CodiceFiscale.compute(cfPayload);
        }
        return null;
    } catch (e) {
        return null;
    }
};

/**
 * @description Valuta se la persona con codice fiscale in cf ha tra 18 e 100 anni
 * @param cf: oggetto anagrafica ottenuto da un codice fiscale
 * @return boolean: true se anagrafica tra 18 e 100 anni
 */
export const isAgeRangeOk = (cf: IOutputCodiceFiscaleObj): boolean => {
    if (cf && cf.birthday) {
        const today = moment().startOf('day');
        const minDate = moment(today).subtract(18, 'y');
        const maxDate = moment(today).subtract(100, 'y').add(1, 'd');
        let userDate = moment(cf.birthday);

        if (userDate.diff(today) > 0) {
            userDate = moment(userDate).subtract(100, 'y');
        }

        return userDate.diff(minDate) <= 0 && userDate.startOf('day').diff(maxDate.startOf('day')) >= 0;
    }
    return false;
};

export interface CfValidation {
    cfCode: string;
    firstname: string;
    lastname: string;
    birtDate?: Date;
    birthPlace?: string;
    birthProvince?: string;
    gender?: 'M' | 'F';
}

export interface IInputCodiceFiscaleObj {
    name: string;
    surname: string;
    gender: string;
    day: number;
    month: number;
    year: number;
    birthplace: string;
    birthplaceProvincia?: string; // Optional
}

interface IOutputCodiceFiscaleObj {
    name: string;
    surname: string;
    gender: string;
    day: number;
    month: number;
    year: number;
    birthplace: string; // in caso di estero restituisce la Nazione
    birthplaceProvincia: string; // in caso di estero restituisce EE
    cf?: string;
    birthday?: string;
}

export enum ReasonDescription {
    ALL_CHECKS_OK = 'ALL_CHECKS_OK',
    AGE_RANGE_KO = 'AGE_RANGE_KO',
    GENERIC_ERROR = 'GENERIC_ERROR',
}
