import { AptPartnerships } from '../../modules/common/partnership/enums/apt-partnerships';
import { ProductInfo } from '../../store/models/order-entry-state';
import { AptProductType } from '../enums/apttus/apt-product-type';
import { ACTIVATION_FLOW_TYPES, ACTIVATION_SALES_PROCESSES, SWITCH_IN_FLOW_TYPES } from '../map/mapping.config';
import { AptSalesProcess } from '../enums/apttus/apt-sales-process';
import { AptCommodityTypeOfSale } from '../enums/apttus/apt-commodity-typeof-sale';
import { D365SignatureType } from '../enums/d365/d365-signature-type';
import { FlowType } from '../../store/models/flow-type';

export const containsProductGas = (prods: AptProductType[]): boolean => {
    return [AptProductType.ProdottoCommodityGas, AptProductType.TariffaCommodityGas].some(
        (x) => prods?.indexOf(x) >= 0
    );
};

export const containsProductPower = (prods: AptProductType[]): boolean => {
    return [AptProductType.TariffaCommodityLuce, AptProductType.ProdottoCommodityLuce].some(
        (x) => prods?.indexOf(x) >= 0
    );
};

export const containsProductInsurance = (prods: AptProductType[]): boolean => {
    return [AptProductType.AssicurazioneAncillare, AptProductType.AssicurazioneIntermediata].some(
        (x) => prods?.indexOf(x) >= 0
    );
};

export const containsProductMaintenance = (prodTypes: AptProductType[]): boolean => {
    return prodTypes.includes(AptProductType.Manutenzione);
};

export const isProductRcDanni = (prodInfo: ProductInfo): boolean => {
    return (
        prodInfo?.nome?.toLowerCase().indexOf('rc danni') >= 0 &&
        prodInfo?.productType === AptProductType.AssicurazioneIntermediata
    );
};

export const containsCommodity = (prodTypes: AptProductType[]): boolean => {
    return containsProductGas(prodTypes) || containsProductPower(prodTypes);
};

export const isChangeProduct = (flowType: FlowType): boolean => (flowType || FlowType.SwitchIn).startsWith('CP_');

/**
 * @description Controlla se in InfoProdotti esistono commodity con l'opzione partnership attiva
 * @param products Le informazioni dei prodotti nel cart
 * @param commodityType Il tipo di commodity da controllare
 * @returns True => Esistono commodity del tipo indicato che hanno l'opzione partnership | false => Non esistono commodity del tipo indicato che hanno l'opzione partnership
 */
export const containsCommodityWithPartnership = (products: ProductInfo[], commodityType: AptCommodityTypeOfSale) => {
    const matrixTypes: { [key in AptCommodityTypeOfSale]: AptProductType[] } = {
        [AptCommodityTypeOfSale.Power]: [AptProductType.ProdottoCommodityLuce, AptProductType.TariffaCommodityLuce],
        [AptCommodityTypeOfSale.Gas]: [AptProductType.ProdottoCommodityGas, AptProductType.TariffaCommodityGas],
        [AptCommodityTypeOfSale.Dual]: [
            AptProductType.ProdottoCommodityLuce,
            AptProductType.TariffaCommodityLuce,
            AptProductType.ProdottoCommodityGas,
            AptProductType.TariffaCommodityGas,
        ],
    };

    return (
        (products || []).filter(
            (p) =>
                (matrixTypes[commodityType] || []).indexOf(p.productType) !== -1 &&
                p.partnership &&
                p.partnership.toUpperCase() !== AptPartnerships.NO_PARTNERSHIP
        ).length > 0
    );
};

export const isFlowTypeVoltura = (flowType: FlowType) =>
    [FlowType.Voltura, FlowType.SwitchInVoltura].includes(flowType);

export const isFlowTypeSwitchIn = (flowType: FlowType) => SWITCH_IN_FLOW_TYPES.includes(flowType);

export const isFlowTypeActivation = (flowType: FlowType) => ACTIVATION_FLOW_TYPES.includes(flowType);

export const isSalesProcessActivation = (salesProcess: AptSalesProcess) =>
    ACTIVATION_SALES_PROCESSES.includes(salesProcess);

export const isDomiciliationStandAlone = (flowType: FlowType) => flowType.startsWith('DOM_');

export const isAdministrativeSwitchIn = (flowType: FlowType) =>
    [
        FlowType.SwitchInAmmVip,
        FlowType.SwitchInAmmRecupero2A,
        FlowType.SwitchInAmmRecupero2B,
        FlowType.SwitchInAmmRecupero2C,
        FlowType.SwitchInAmmDelibera153,
    ].includes(flowType);

export const isAdministrativeSwitchInWithCeasedAsset = (flowType: FlowType) =>
    [FlowType.SwitchInAmmRecupero2A, FlowType.SwitchInAmmDelibera153].includes(flowType);

export const isAdministrativeSwitchInWithoutAsset = (flowType: FlowType) =>
    [FlowType.SwitchInAmmVip, FlowType.SwitchInAmmRecupero2B, FlowType.SwitchInAmmRecupero2C].includes(flowType);

export const isNoPlico = (flowType: FlowType) =>
    [FlowType.Cessazione, FlowType.DomiciliazioneModifica, FlowType.DomiciliazioneRevoca].includes(flowType);

export const isVariazioneCommerciale = (flowType: FlowType): boolean => flowType === FlowType.VariazioneCommerciale;

export const isVariazioneTecnica = (flowType: FlowType): boolean =>
    [FlowType.VariazioneTecnicaVerifiche, FlowType.VariazioneTecnicaLavori].includes(flowType);

export const isCessazione = (flowType: FlowType): boolean => flowType === FlowType.Cessazione;
/**
 * Controlla e indica se il flowtype passato in ingresso rientra tra quelli che non necessitano di un carrello SalesForce
 * @param flowType
 * @returns True => Il FlowType rientra tra quelli che non hanno necessità di avere un cart SalesForce altrimenti False
 */
export const flowTypeHasNoCart = (flowType: FlowType): boolean =>
    [
        FlowType.Sospensione,
        FlowType.SospensioneAmministrativa,
        FlowType.Interruzione,
        FlowType.InterruzioneAmministrativa,
        FlowType.Riapertura,
        FlowType.RiaperturaAmministrativa,
        FlowType.RevocaSospensione,
        FlowType.RevocaInterruzione,
        FlowType.RevocaRiapertura,
        FlowType.CA1,
        FlowType.CA1AMM,
        FlowType.CA2,
        FlowType.CA2AMM,
        FlowType.CA3,
        FlowType.CA3AMM,
        FlowType.CA4,
        FlowType.CA4AMM,
        FlowType.CA5,
        FlowType.CA5AMM,
        FlowType.CA7,
        FlowType.CA7AMM,
        FlowType.CAE1,
        FlowType.CAE1AMM,
        FlowType.CAE3,
        FlowType.CAE3AMM,
        FlowType.CAE4,
        FlowType.CAE4AMM,
        FlowType.CAE5,
        FlowType.CAE5AMM,
        FlowType.CAE7,
        FlowType.CAE7AMM,
        FlowType.CAE8,
        FlowType.CAE8AMM,
        FlowType.CAE8RIFVOLT,
        FlowType.CAE8RIFVOLTAMM,
    ].includes(flowType);
