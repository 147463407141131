import { createAction, props } from '@ngrx/store';
import { boActionString as c } from './action-types';
import { GestioneConditions } from '../../modules/back-office/models/gestione-filter-list';
import { MonitoraggioConditions } from '../../modules/back-office/models/monitoraggio-filter-list';
import { CarrelliFilterList } from '../../modules/back-office/models/carrelli-filter-list';
import { AptSalesProcess } from '../../common/enums/apttus/apt-sales-process';
import { IGenericBreadcrumbFilter } from '../../modules/back-office/quote-list/components/generic-breadcrumb-filter/generic-breadcrumb-filter.component';
import { Tabs } from '../../common/enums/shared/tabs';

export const setMonitoraggioFilterBar = createAction(c.SET_MON_FILTER_BAR, props<{ p: MonitoraggioConditions }>());
export const setSelectedTab = createAction(c.SET_SELECTED_TAB, props<{ p: 'MON' | 'CART' | 'GEST' }>());
export const setGestioneFilterBar = createAction(c.SET_GEST_FILTER_BAR, props<{ p: GestioneConditions }>());
export const setCarrelliFilterBar = createAction(c.SET_CART_FILTER_BAR, props<{ p: CarrelliFilterList }>());
export const setSelectedOpMode = createAction(c.SET_SELECTED_OP_MODE, props<{ p: AptSalesProcess }>());
export const setAgentFilter = createAction(c.SET_AGENT_FILTER, props<{ p: IGenericBreadcrumbFilter[] }>());
export const setAgencyBranchFilter = createAction(
    c.SET_AGENCY_BRANCH_FILTER,
    props<{ p: IGenericBreadcrumbFilter[] }>()
);
export const resetSearchFilters = createAction(c.RESET_SEARCH_FILTERS, props<{ p: Tabs }>());
export const setBOQuoteId = createAction(c.SET_BO_QUOTE_ID, props<{ p: string }>());

//TODO Lorenzo - Vedi se esiste una soluzione più elegante
export const setMonitoraggioFiltersAndOpMode = createAction(
    c.SET_MON_FILTERS_OP_MODE,
    props<{ f: MonitoraggioConditions; opMode: AptSalesProcess }>()
);
