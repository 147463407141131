export class EgonRequestZip {
    LST = 'LstZip';
    CDXZIP: string;
    MAXLIVLOC = 8;
    TPXEXT = 'L';
    USER: string;
    PASW: string;
    WPXISO = 'ITA';
    constructor(zipCode: string, user: string, pasw: string) {
        this.CDXZIP = zipCode;
        this.USER = user;
        this.PASW = pasw;
    }
}

export class EgonRequestCity {
    LST = 'LstCnl';
    DSXCNL: string;
    USER: string;
    PASW: string;
    WPXISO = 'ITA';
    constructor(city: string, user: string, pasw: string) {
        this.DSXCNL = city;
        this.USER = user;
        this.PASW = pasw;
    }
}

export class EgonRequestStreetNumber {
    LST = 'LstCiv';
    CDPOBJ: number;
    NRPNUMCIV: number;
    USER: string;
    PASW: string;
    WPXISO = 'ITA';
    constructor(number: number, streetCode: number, user: string, pasw: string) {
        this.CDPOBJ = streetCode;
        this.NRPNUMCIV = number;
        this.USER = user;
        this.PASW = pasw;
    }
}
