import { AttributeValues } from './base-attribute-values';

export class ServiceAttributeValues extends AttributeValues {
    attributesMapping(): void {
        super.attributesMapping();

        // GAS & POWER
        this.attr['egl_supply_class__c'] = this.orderEntry?.supplyClass;
        // gas
        this.attr['egl_PDR__c'] = this.orderEntry?.pdr;
        this.attr['egl_typeofusage__c'] = this.orderEntry?.gasConsumption?.typeOfUse;

        // POWER
        this.attr['egl_POD__c'] = this.orderEntry?.pod;
        this.attr['egl_Required_Power__c'] = this.orderEntry?.requiredPower;
        // this.attr['egl_power_typesofusage__c'] = this.orderEntry?.powerTypeOfUse;
    }
}
