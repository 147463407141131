export const appUserString = {
    SET_USER_STATE: '[USER] set-user-state',
    SET_CONTACT: '[USER] set-contact',
    SET_LEAD: '[USER] set-lead',
    SET_AGENT_INFO: '[USER] set-agent-info',
    SET_CUSTOMER_INFO: '[USER] set-customer-info',
    SET_SALEABILITY_JSON_CACHE: '[SALEABILITY] set-saleability-json-cache',
    SET_HISTORIZED_AGENCY_BRANCH: '[USER] set-historized-agency-branch',
    RESET_USER_STATE: '[USER] reset-user-state',
    SET_LAST_USED_CUSTOMER_SEGMENT: '[USER] set-last-used-customer-segment',
    SET_CART_SEGMENT: '[USER] set-cart-segment',
    SET_VIRTUAL_AGENT_CURRENT_AGENCY_BRANCH: '[USER] set-virtual-agent-current-agency-branch',
    SET_IS_SIEBEL_CUSTOMER: '[USER] set-is-customer-siebel',
};

export const appActionString = {
    SET_APP_CONFIG: '[APP] set-app-config',
    SET_CONTACT_BAR_VISIBILITY: '[APP] set-contact-bar-visibility',
    SET_PROGRESS_BAR_VISIBILITY: '[APP] set-progress-bar-visibility',
    SET_DEV_BAR_VISIBILITY: '[APP] set-dev-bar-visibility',
    CONNECTION_CHANGED: '[APP] connection-changed',
    CONNECTION_SPEED: '[APP] connection-speed',
    APPLICATION_LOCATION: '[APP] application-location',
    SET_CURRENT_PAGE: '[APP] set-current-page',
    SET_URL_HASH: '[APP] set-url-hash',
    SET_GEO_LOCATION: '[APP] set-geo-location',
    SET_BO_QUOTE_TAB: '[APP] set-bo-quote-tab',
    SET_DEVICE_BY_U_AGENT: '[APP] set-device',
};

export const orderEntryActionString = {
    SET_ORDER_ENTRY_STATE: '[ORDER_ENTRY] set-order-entry-state',
    SET_CART_INFO: '[ORDER_ENTRY] set-cart-info',
    SET_CART_ID: '[ORDER_ENTRY] set-cart-id',
    SET_DATI_FORNITURA: '[ORDER_ENTRY] set-dati-fornitura',
    SET_CREDIT_CHECK_RESULT: '[ORDER_ENTRY] set-credit-check-result',
    SET_POD: '[ORDER_ENTRY] set-pod',
    SET_PDR: '[ORDER_ENTRY] set-pdr',
    SET_POWER_CONSUMPTION: '[ORDER_ENTRY] set-power-consumption',
    SET_POWER_HOME_DROPDOWN_OPTION: '[ORDER_ENTRY] set-power-home-dropdown-option',
    SET_GAS_CONSUMPTION: '[ORDER_ENTRY] set-gas-consumption',
    SET_GAS_HOME_DROPDOWN_OPTION: '[ORDER_ENTRY] set-gas-home-dropdown-option',
    SET_TITOLARITA_IMMOBILE: '[ORDER_ENTRY] set-titolarita-immobile',
    SET_DATI_PAGAMENTO: '[ORDER_ENTRY] set-dati-pagamento',
    SET_FOTO_DOCUMENTI: '[ORDER_ENTRY] set-foto-documenti',
    SET_FIRMA: '[ORDER_ENTRY] set-firma',
    SET_MP3_INFO: '[ORDER_ENTRY] set-mp3-info',
    SET_PDF_PLICO_INFO: '[ORDER_ENTRY] set-pdf-plico-info',
    SET_PRODUCT_DATA: '[ORDER_ENTRY] set-product-data',
    SET_NUMERO_PLICO: '[ORDER_ENTRY] set-numero-plico',
    SET_PASSAGGIO_RAPIDO: '[ORDER_ENTRY] set-passaggio-rapido',
    SET_EXISTING_SUPPLIES: '[ORDER_ENTRY] set-existents-supplies',
    SET_ACTIVE_SUPPLIES: '[ORDER_ENTRY] set-active-supplies',
    SET_PRIVACY_TRAT_PERS: '[ORDER_ENTRY] set-privacy-tratt-person',
    SET_QUOTE_STATE_MODEL: '[ORDER_ENTRY] set-quote-state-model',
    SET_VERIFICA_CONTATTO: '[ORDER_ENTRY] set-verifica-contatto',
    RESET_ORDER_ENTRY: '[ORDER_ENTRY] reset-order-entry',
    SET_AGENT_BRANCH: '[ORDER_ENTRY] set-agent-branch',
    SET_MARKED_AS_FRAUD: '[ORDER_ENTRY] set-marked-ad-fraud',
    INIT_SALES: '[ORDER_ENTRY] init-sales',
    INIT_SIGN: '[ORDER_ENTRY] init-sign',
    SET_SKIP_POD: '[ORDER_ENTRY] set-skip-pod',
    SET_SKIP_PDR: '[ORDER_ENTRY] set-skip-pdr',
    SET_SKIP_ATECO: '[ORDER_ENTRY] set-skip-ateco',
    SET_CONTRACT_REQ: '[ORDER_ENTRY] set-contract-request',
    SET_WINBACK: '[ORDER_ENTRY] set-winback',
    SET_WINBACKTYPE: '[ORDER_ENTRY] set-winback-type',
    SET_PLICO_URL: '[ORDER_ENTRY] set-plico-url',
    SET_ANAGRAFICA_MB: '[ORDER_ENTRY] set-anagrafica-mb',
    SET_SEGMENT_IDS: '[ORDER_ENTRY] set-segment-ids',
    SET_OCR_DATA: '[ORDER_ENTRY] set-ocr-data',
    SET_ATECO_MB: '[ORDER_ENTRY] set-ateco-mb',
    SET_POTENZA_IMPEGNATA_MB: '[ORDER_ENTRY] set-potenza-impegnata-mb',
    SET_CART_SEGMENT: '[ORDER_ENTRY] set-cart-segment',
    SET_FATT_ELETT: '[ORDER_ENTRY] set-fatturazione-elettr',
    SET_TROUBLESHOOTING_POWER_DONE: '[ORDER_ENTRY] set-troubleshooting-power-done',
    SET_TROUBLESHOOTING_GAS_DONE: '[ORDER_ENTRY] set-troubleshooting-gas-done',
    SET_IMPERSONATED_AGENT: '[ORDER_ENTRY] set-impersonated-agent',
    SET_INVALID_CF: '[ORDER_ENTRY] set-invalid-cf',
    SET_MODAL_CREDITCHECK: '[ORDER_ENTRY] set-modal-creditcheck',
    SET_FLOW_TYPE: '[ORDER_ENTRY] set-flow-type',
    SET_SALES_PROCESS: '[ORDER_ENTRY] set-sales-process',
    SET_OPERATION_TYPE: '[ORDER_ENTRY] set-operation-type',
    SET_ASSET_ID: '[ORDER_ENTRY] set-asset-id',
    SET_NUMERO_DOMICILIAZIONE: '[ORDER_ENTRY] set-numero-domiciliazione',
    SET_CREDIT_CHECK_STATUS: '[ORDER_ENTRY] set-credit-check-status',
    SET_INSOLUTO_NDS: '[ORDER_ENTRY] set-insoluto-nds',
    SET_TAX_VAT_STATUS: '[ORDER_ENTRY] set-tax-vat-status',
    SET_SCORE_CARD_STATUS: '[ORDER_ENTRY] set-score-card-status',
    SET_SCORECARD_CALL_IN_PROGRESS: '[ORDER_ENTRY] set-scorecard-call-in-progress',
    SET_ESITO_CREDIT_CHECK_STATUS: '[ORDER_ENTRY] set-esito-credit-check-status',
    SET_PAYMENT_SCORE: '[ORDER_ENTRY] set-payment-score',
    SET_CEASE_REASON_CODE: '[ORDER_ENTRY] set-cease-reason-code',
    SET_IBAN_INSOLUTO_NDS: '[ORDER_ENTRY] set-iban-insoluto-nds',
    SET_DEFERRED_SALE_STATUS: '[ORDER_ENTRY] set-deferred-sale-status',
    SET_IS_MORTIS_CAUSA: '[ORDER_ENTRY] set-is-mortis-causa',
    SET_TERMINATION_CART_ID: '[ORDER_ENTRY] set-termination-cart-id',
    SET_TERMINATION_DATE: '[ORDER_ENTRY] set-termination-date',
    SET_SELF_CERTIFICATION: '[ORDER_ENTRY] set-self-certification',
    SET_TERMINATION_TERM_CONTACTS: '[ORDER_ENTRY] set-termination-term-contacts',
    SET_TERMINATION_APPOINTMENT: '[ORDER_ENTRY] set-termination-appointment',
    SET_TERMINATION_COSTS: '[ORDER_ENTRY] set-termination-costs',
    SET_AGENCY_BRANCH_FOR_MONITORING: '[ORDER_ENTRY] set-agency-branch-for-monitoring',
    SET_IVASS_ANSWERS: '[ORDER_ENTRY] set-ivass-answers',
    SET_INSURANCE_DUMMY_PRODUCT_ID: '[ORDER_ENTRY] set-insurance-prod-id',
    SET_DOMICILIATION_STAND_ALONE: '[ORDER_ENTRY] set-domiciliation-stand-alone',
    SET_ADMINISTRATIVE_TECHNICAL_DATA: '[ORDER_ENTRY] set-administration-technical-data',
    SET_POD_ACTIVATION_INFO: '[ORDER_ENTRY] set-pod-activation-info',
    SET_POTENCY_ACTIVATION: '[ORDER_ENTRY] set-potency-activation',
    SET_PDR_ACTIVATION_INFO: '[ORDER_ENTRY] set-pdr-activation-info',
    SET_QUOTE_ID: '[ORDER_ENTRY] set-quote-id',
    SET_COSTS: '[ORDER_ENTRY] set-costs',
    SET_PROPERTY_TYPE_SELECTED: '[ORDER_ENTRY] set-property-type-selected',
    SET_ACTIVATION_DATE: '[ORDER_ENTRY] set-activation-date',
    SET_COMBINED_SALE_QUOTE_ID: '[ORDER_ENTRY] set-combined-sale-quote-id',
    SET_COMBINED_SALE: '[ORDER_ENTRY] set-combined-sale',
    SET_COMMODITY_CART_ID: '[ORDER_ENTRY] set-commodity-cart-id',
    SET_COUPON: '[ORDER_ENTRY] set-coupon',
    SET_INCIDENT: '[ORDER_ENTRY] set-incident',
};

export const boActionString = {
    SET_MON_FILTER_BAR: '[BO] set-monit-filter-bar',
    SET_GEST_FILTER_BAR: '[BO] set-gest-filter-bar',
    SET_SELECTED_TAB: '[BO] set-selected-tab',
    SET_CART_FILTER_BAR: '[BO] set-cart-filter-bar',
    SET_SELECTED_OP_MODE: '[BO] set-selected-op-mode',
    SET_AGENT_FILTER: '[BO] set-agent-filter',
    SET_AGENCY_BRANCH_FILTER: '[BO] set-agency-branch-filter',
    RESET_MON_FILTER_BAR: '[BO] reset-monit-filter-bar',
    RESET_GEST_FILTER_BAR: '[BO] reset-gest-filter-bar',
    RESET_SEARCH_FILTERS: '[BO] reset-search-filters',
    SET_MON_FILTERS_OP_MODE: '[BO] set-monit-filters-and-op-mode',
    SET_BO_QUOTE_ID: '[BO] set-bo-quote-id',
};

export const partnershipActionString = {
    SET_FASTWEB_TAX_VAT_CODE: '[PARTNERSHIP] set-fastweb-tax-vat-code',
    SET_FASTWEB_CONTRACT_INFO: '[PARTNERSHIP] set-fastweb-contract-info',
    SET_EGL_PARTNERSHIP_DATA: '[PARTNERSHIP] set-egl-partnership-data',
};
