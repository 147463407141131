import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    setCeaseReasonCode,
    setCreditCheckStatus,
    setIbanInsolutoNDS,
    setPaymentScore,
    setTaxVatStatus,
} from '../../../store/actions/order-entry.actions';
import { EglState } from '../../../store/reducers';
import { CreditCheckStatus, TaxVatDetails } from '../../../store/models/order-entry-state';
import { EsitiData, TaxVatResult } from '../../models/app/recupera-dati-salesup.response';
import { D365CreditCheckOutcome } from '../../models/user/customer';
import * as moment from 'moment';
import { LoggerService } from './logger.service';
import { AptPaymentInstrument } from '../../enums/apttus/apt-payment-instrument';
import { selectTipoPagamento } from '../../../store/selectors/order-entry.selectors';

@Injectable({
    providedIn: 'root',
})
export class CreditCheckService {
    private paymentType: AptPaymentInstrument;

    constructor(private store: Store<EglState>, private logger: LoggerService) {
        this.mapStateToProps();
    }

    private mapStateToProps(): void {
        this.store.select(selectTipoPagamento).subscribe((p) => {
            this.paymentType = p.paymentType;
        });
    }

    /**
     * @description Imposta nello state applicativo i dati relativi al nuovo credit check recuperati dalla "recuperaDatiSalesUp" (API) e "getaccount" (D365)
     * @param ccData Gli esiti dei controlli
     * @param code Il codice fiscale o la PIva associato al credit check appena effettuato
     * @returns void
     */
    setCreditCheckData(ccData: EsitiData, code: string, checkDate?: moment.Moment): void {
        if (!checkDate) {
            checkDate = moment();
        }
        const {
            whitelist,
            blacklist,
            blacklist_2: blacklist2,
            blacklist_3: newBlacklist,
        } = ccData.creditCheck.dettaglio;
        const { callScorecard, esito: esitoCC, codiceErrore: errorCodeCC } = ccData.creditCheck;
        const { esito: esitoNds, descrizioneEsito: unsolvedNds, codiceErrore: errorCodeNds } = ccData.insolutoNDS;
        const {
            importo_medio_fatture: avgInvoiceAmount,
            residuo_nds: residualNds,
            insoluto_zuora_cf: unsolvedTaxCodeNds,
        } = ccData.insolutoNDS.dettaglio || {};

        //Questa transcodifica mi tocca farla qua, perchè modificare il valore restituito dalla RecuperaDatiSUP potrebbe provare degli impatti anche su altre funzionalità (D365 e Apttus)...
        let unsolvedTaxCodeNdsResult = unsolvedNds;
        if (unsolvedNds === 'NON DISPONIBILE') {
            unsolvedTaxCodeNdsResult = 'DA FARE';
        }

        const creditCheckStatus: CreditCheckStatus = {
            canProceed: esitoCC && esitoNds,
            errorCode: errorCodeCC || errorCodeNds,
            code,
            ccDetails: {
                blacklist,
                blacklist2,
                newBlacklist,
                whitelist,
                unsolvedNds,
                avgInvoiceAmount,
                residualNds,
                unsolvedTaxCodeNds, //Soglia numerica
                unsolvedTaxCodeNdsResult, //Esito insoluto NDS sul CF/P.IVA
            },
            callScorecard,
            scorecardCallInProgress: false,
            checkDate: checkDate,
        };
        this.store.dispatch(setCreditCheckStatus({ s: creditCheckStatus }));
    }

    /**
     * @description Imposta nello state applicativo i dati relativi allo skipCreditCheck
     * @returns void
     */
    setSkippedCreditCheckData() {
        const creditCheckStatus: CreditCheckStatus = {
            canProceed: true,
            errorCode: '',
            code: '',
            ccDetails: {
                blacklist: '',
                blacklist2: '',
                newBlacklist: '',
                whitelist: 'OK',
                unsolvedNds: '',
                avgInvoiceAmount: '',
                residualNds: '',
                unsolvedTaxCodeNds: '',
                unsolvedTaxCodeNdsResult: '',
            },
            callScorecard: false,
            scorecardCallInProgress: false,
            checkDate: null,
        };
        this.store.dispatch(setCreditCheckStatus({ s: creditCheckStatus }));
    }

    /**
     * @description Imposta nello state applicativo gli esiti dei controlli credit check relativi al codice fiscale e PIva
     * @param data Il risultato dei controlli SCIPAFI e SDI
     * @returns void
     */
    setTaxVatData(data: TaxVatResult): void {
        //Scipafi è alternativo a SDI. Se è presente Scipafi vuol dire che SDI non era disponibile.
        const taxVatData: TaxVatDetails = {
            scipafi: data?.source === 'SCIPAFI' ? data?.dettaglio : null,
            sdi: data?.source === 'SDI' ? data?.dettaglio : null,
        };
        this.store.dispatch(setTaxVatStatus({ s: taxVatData }));
    }

    /**
     * @description Esegue la mappatura dei dati credit check dalla response di D365 al formato "recuperaDatiSalesUp" e setta il risultato nello state
     * @param ccData I dati del credit check e insoluto NDS
     * @param code Il codice fiscale o partiva iva associato al controlo credit check
     * @returns void
     */
    mapD365CreditCheckData(ccData: D365CreditCheckOutcome, code: string): void {
        if (!ccData?.CreditCheck || !ccData?.UnpaidNds) {
            return;
        }
        const {
            CreditCheckResult,
            CreditCheckCallScoreCard,
            CreditCheckErrorCode,
            CreditCheckBlacklist1,
            CreditCheckBlacklist2,
            CreditCheckBlacklist3,
            CreditCheckWhitelist,
        } = ccData.CreditCheck;
        const {
            UnpaidNdsErrorcode,
            UnpaidNdsResultDescription,
            UnpaidNdsResult,
            UnpaidNdsUnpaidZuoraCf,
            UnpaidNdsUnpaidAverageZuoraInvoice,
            UnpaidNdsUnpaidZuoraNds,
        } = ccData.UnpaidNds;
        const esiti: EsitiData = {
            creditCheck: {
                esito: CreditCheckResult,
                callScorecard: CreditCheckCallScoreCard,
                codiceErrore: CreditCheckErrorCode,
                dettaglio: {
                    blacklist: CreditCheckBlacklist1,
                    blacklist_2: CreditCheckBlacklist2,
                    blacklist_3: CreditCheckBlacklist3,
                    whitelist: CreditCheckWhitelist,
                },
            },
            insolutoNDS: {
                esito: UnpaidNdsResult,
                codiceErrore: UnpaidNdsErrorcode,
                descrizioneEsito: UnpaidNdsResultDescription,
                dettaglio: {
                    insoluto_zuora_cf: UnpaidNdsUnpaidZuoraCf,
                    importo_medio_fatture: UnpaidNdsUnpaidAverageZuoraInvoice,
                    residuo_nds: UnpaidNdsUnpaidZuoraNds,
                },
            },
        };
        const checkDate = moment(
            ccData.CreditCheck.CreditCheckLastUpdateFromSiebel || ccData.UnpaidNds.UnpaidNdsLastUpdateFromSiebel,
            null,
            'it'
        );
        this.setCreditCheckData(esiti, code, checkDate);
    }

    /**
     * @description Imposta nello state il valore "paymentScore" (Se il valore è null lo imposto su ALTRO)
     * @param paymentScore Il valore del paymentScore
     * @returns void
     */
    setPaymentScore(paymentScore: string): void {
        this.logger.warn(`Valore paymentScore recuperato: '${paymentScore}'`);
        if (!paymentScore) {
            this.logger.warn('Valore paymentScore nullo. Lo imposto su ALTRO');
            paymentScore = 'ALTRO';
        }
        this.store.dispatch(setPaymentScore({ p: paymentScore }));
    }
    /**
     * @description Imposta nello state il valore "cessazioneMorosita"
     * @param code Il valore della cessazione morosità
     * @returns void
     */
    setCeaseReasonCode(code: string): void {
        this.logger.warn(`Valore cessazioneMorosita recuperato: '${code}'`);
        this.store.dispatch(setCeaseReasonCode({ c: code }));
    }

    /**
     * @description Controlla se il metodo di pagamento è "Bollettino" e nel caso imposta l'esito dell'insoluto NDS dell'iban a "NON NECESSARIO".
     * Altrimenti sbianca il campo
     * @returns void
     */
    prepopulateUnsolvedIbanNds(): void {
        const unsolvedIban = this.paymentType === AptPaymentInstrument.Bollettino ? 'NON NECESSARIO' : undefined; //Se sto pagando con bollettino, imposto l'esito insoluto NDS iban a NON NECESSARIO
        this.store.dispatch(setIbanInsolutoNDS({ i: unsolvedIban }));
    }
}
