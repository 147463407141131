import { appReducer } from './app.reducer';
import { userReducer } from './user.reducer';
import { orderEntryReducer } from './order-entry.reducer';
import { UserState } from '../models/user-state';
import { OrderEntryState } from '../models/order-entry-state';
import { AppState } from '../models/app-state';
import { boReducer } from './bo.reducer';

export interface EglState {
    app?: AppState;
    user: UserState;
    orderEntry: OrderEntryState;
}

export const reducers = {
    app: appReducer,
    user: userReducer,
    orderEntry: orderEntryReducer,
    bo: boReducer,
};
