import { EventEmitter, Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AppConfig } from '../../models/app/app-config';
import { D365PostMessage } from '../../models/app/d365-postmessage';
import * as _ from 'lodash';
import { environment } from '../../../../environments/environment';
import { writeImportantErrorLog, writeImportantInfoLog } from '../../functions/misc.functions';

@Injectable({
    providedIn: 'root',
})
export class PrivateConfigurationService {
    private _eventHandler$: Observable<AppConfig>;
    private readonly _keyMessage = 'SalesUp.Config';
    private _configuration: AppConfig;
    configLoaded = false;
    loadConfigCompleted = new EventEmitter<AppConfig>();

    constructor() {
        if (_.isEmpty(this._configuration)) {
            this.loadConfiguration();
        }
    }

    get config(): AppConfig {
        if (_.isEmpty(this._configuration)) {
            writeImportantErrorLog('Configuration was not ready. Data could be wrong!');
        }
        return this._configuration;
    }

    get(key: string[] | string, defaultValue?: any): any {
        return _.get(this._configuration, key, defaultValue);
    }

    forceReload(): void {
        this.loadConfiguration();
    }

    private loadConfiguration() {
        this._eventHandler$ = fromEvent(window, 'message').pipe(
            filter((evt: MessageEvent) => evt.data.type === this._keyMessage),
            map((evt: MessageEvent) => evt.data),
            map((message) => {
                if (message?.content) {
                    if (message.content.localConfiguration) {
                        writeImportantInfoLog('Loaded Local Configuration');
                    }
                    return JSON.parse(message.content.SalesUpConfig) as AppConfig;
                }
                return null;
            })
        );

        this._eventHandler$.pipe(take(1)).subscribe((c) => {
            this._configuration = c;
            this.configLoaded = true;
            this.loadConfigCompleted.emit(this._configuration);
        });
        this.getSalesUpConfig();
    }

    private getSalesUpConfig(): void {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                type: this._keyMessage,
                request: {},
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_privateconfiguration_getsalesupconfiguration',
                    parameterTypes: {},
                },
            },
        };

        if (environment.configOverride?.privateConfiguration) {
            this.getLocalPrivateConfiguration();
        } else {
            parent.window.postMessage(message, '*');
        }
    }

    /**
     * Recupera la configurazione dell'app dal file (indicato in environment.configOverride.privateConfiguration) ed emette un evento
     */
    private getLocalPrivateConfiguration(): void {
        fetch(environment.configOverride.privateConfiguration)
            .then((response) => response.json())
            .then(
                (data) => {
                    const configEvent = new MessageEvent('message', {
                        data: {
                            content: {
                                Value: JSON.stringify(data),
                                localConfiguration: true,
                            },
                            type: this._keyMessage,
                        },
                    });
                    window.dispatchEvent(configEvent);
                },
                (error) => {
                    console.error(`File: '${error.url}' not found`);
                }
            );
    }
}
