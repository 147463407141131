export abstract class Regex {
    public static readonly cfPIVA =
        /^(?:(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z])|(?:\d{11})$/i;
    public static readonly cell = /^3\d{8,10}$/;
    public static readonly secondaryPhone = /^\d{9,11}$/;
    public static readonly email = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
    public static readonly letterOnly = /^[a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ\']*$/;
    public static readonly numberOnly = /^[0-9]*$/;
    public static readonly twoDecimalNumbers = /^\d+([,\.]{1}\d{1,2})?$/;
    public static readonly cap = /^[0-9]{5}$/;
    public static readonly letterAndSpace =
        /^[a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ\' ]*$/;
    public static readonly letterNumbersSpace =
        /^[a-zA-Z0-9àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ\' ]*$/;
    public static readonly letterNumbers =
        /^[a-zA-Z0-9àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ\']*$/;
    public static readonly pod = /^it[0-9]{3}e[0-9]{7,8}[a0-9]?$/i;
    public static readonly iban = /^(?:IT|SM)\d{2}[A-Z]\d{22}$/i;
    public static readonly numberWithoutZeros = /^0{0,2}[1-9]\d*$/;
    public static readonly numberStartingThree = /^(?:3)\d+$/;
    public static readonly italianDate = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;
    public static readonly piva = /^[0-9]{11}$/;
    public static readonly atecoCode = /^[0-9]{2}\.[0-9]{2}\.[0-9]{2}$/;
    public static readonly codDestinatario = /^[A-Za-z0-9]{7}$/;
    public static readonly cf =
        /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i;
    public static readonly domiciliationInitRoute = /^domiciliation(?:\/([a-zA-Z0-9_\-\.]+)){3}/;
    public static readonly iso8601 =
        /^(?:[12][0-9]{3}(?:-(?:(?:0[1-9]|1[0-2])|(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d)|(?:0[469]|11)-30|(?:0[13578]|1[02])-3[01])(?:T(?:[01]\d|2[0-3])(?::[0-5]\d(?::[0-5]\d(\.\d{3})?)?(?:Z|[-+](?:[01]\d|2[0-3])(?::?[0-5]\d)?)?)?)?))?)$/;
}
