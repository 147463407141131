import { BoState } from '../models/bo-state';
import { createReducer, on } from '@ngrx/store';
import * as boA from '../actions/bo.actions';
import { AptSalesProcess } from '../../common/enums/apttus/apt-sales-process';
import { Action } from '../../common/interfaces/action';

export const initialState: BoState = {
    monitoraggioFilter: undefined,
    gestioneFilter: undefined,
    carrelliFilter: undefined,
    selectedTab: 'MON',
    selectedOpMode: AptSalesProcess.SwitchIn,
    selectedQuoteId: undefined,
};

const reducer = createReducer(
    initialState,
    on(boA.setMonitoraggioFilterBar, (state, { p }) => {
        return { ...state, monitoraggioFilter: p };
    }),
    on(boA.setGestioneFilterBar, (state, { p }) => {
        return { ...state, gestioneFilter: p };
    }),
    on(boA.setCarrelliFilterBar, (state, { p }) => {
        return { ...state, carrelliFilter: p };
    }),
    on(boA.setSelectedTab, (state, { p }) => {
        return { ...state, selectedTab: p };
    }),
    on(boA.setSelectedOpMode, (state, { p }) => {
        return { ...state, selectedOpMode: p };
    }),
    on(boA.setAgentFilter, (state, { p }) => {
        return {
            ...state,
            monitoraggioFilter: {
                ...state.monitoraggioFilter,
                agent: p,
            },
            gestioneFilter: {
                ...state.gestioneFilter,
                agent: p,
            },
        };
    }),
    on(boA.setAgencyBranchFilter, (state, { p }) => {
        return {
            ...state,
            monitoraggioFilter: {
                ...state.monitoraggioFilter,
                agencyBranch: p,
            },
            gestioneFilter: {
                ...state.gestioneFilter,
                agencyBranch: p,
            },
        };
    }),
    on(boA.resetSearchFilters, (state, { p }) => ({
        ...new BoState(p),
    })),
    on(boA.setMonitoraggioFiltersAndOpMode, (state, { f, opMode }) => {
        return { ...state, monitoraggioFilter: f, selectedOpMode: opMode };
    }),
    on(boA.setBOQuoteId, (state, { p }) => {
        return { ...state, selectedQuoteId: p };
    })
);

export function boReducer(state: BoState | undefined, action: Action): BoState {
    return reducer(state, action);
}
