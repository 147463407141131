import { Injectable } from '@angular/core';
import { UtilityService } from './utility.service';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageFileService {
    private localStorage;

    constructor(private utilitySrv: UtilityService) {
        this.localStorage = window.localStorage;
    }

    /**
     * @description: Recupera il file dal local storage
     * @param: fileName, nome del file da recuperare nello storage
     * @return: File
     */
    getFileFromLocalStorage(fileName: string): File {
        const localStorageEntry = localStorage.getItem(`file_${fileName}`);

        if (!localStorageEntry) {
            return null;
        }

        let localStorageFile = JSON.parse(localStorageEntry) as LocalStorageFile;
        const fileArrayBuffer = this.utilitySrv.base64ToArrayBuffer(localStorageFile.base64);
        const fileObject = this.utilitySrv.arrayBufferToFileObj(
            fileArrayBuffer,
            localStorageFile.name,
            localStorageFile.type
        );

        if (!fileObject) {
            return null;
        }

        return fileObject;
    }

    /**
     * @description: Salva il file nel local storage
     * @param: fileName, nome del file da salvare nello storage
     * @param: file, oggetto File
     * @return: void
     */
    async setFileToLocalStorage(fileName: string, file: File): Promise<boolean> {
        let localStorageFile = new LocalStorageFile();
        localStorageFile.base64 = await this.utilitySrv.fileToBase64(file);
        localStorageFile.type = file.type;
        localStorageFile.name = file.name;

        localStorage.setItem(`file_${fileName}`, JSON.stringify(localStorageFile));
        return false;
    }

    /**
     * @description: Rimuove dal local storage un file singolo
     * @return: void
     */
    removeFileFromLocalStorage(fileName: string): void {
        this.localStorage.removeItem(`file_${fileName}`);
    }

    /**
     * @description: Verifica se il file esiste nel local storage
     * @param: fileName, nome del file da salvare nello storage
     * @return: true se esiste, false se non esiste
     */
    fileExistsInLocalStorage(fileName: string): boolean {
        const localStorageProperties = Object.keys(this.localStorage);
        let fileFound = false;
        localStorageProperties.forEach((p) => {
            if (p && p === `file_${fileName}`) {
                fileFound = true;
            }
        });
        return fileFound;
    }

    /**
     * @description: Rimuove dal local storage tutte le entry relative ai file
     * @return: void
     */
    clearLocalStorageFromFiles(): void {
        const localStorageProperties = Object.keys(this.localStorage);
        localStorageProperties.forEach((p) => {
            if (p && p.indexOf('file_') !== -1) {
                this.localStorage.removeItem(p);
            }
        });
    }
}

export class LocalStorageFile {
    base64: string;
    type: string;
    name: string;
}
