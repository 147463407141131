import { D365AccountMigrated } from '../../enums/d365/d365-account-migrated';
import { D365CustomerSegment } from '../../enums/d365/d365-customer-segment';

export class Contact {
    contactid: string;
    firstname: string;
    lastname: string;
    name: string;
    egl_taxcode: string;
    emailaddress1?: string;
    address1_line1?: string; // Via
    address1_city?: string; // Città
    address1_postalcode?: string; // CAP
    address1_stateorprovince?: string; // Provincia
    telephone1?: string; // Telefono
    mobilephone?: string; // Cellulare
    preferredcontactmethodcode?: number;
    egl_customercode?: string; // Codice cliente
    birtDate?: Date;
    birthPlace?: string;
    nationality?: string;
    gender?: string;
    birthProvince: string;
    birthCountry: string;
    accountid: string;
    egl_customeraccountcode: string;
    egl_customersegmentcode: D365CustomerSegment;
    egl_vatcode: string;
    telephone2: string;
    isCustomer: boolean;
    startActivation: Date;
    isFormerCustomer: boolean;
    prefixMobilephone: string;
    prefixTelephone1: string;
    prefixTelephone2: string;
    preferredContact: string;
    EglMigration: D365AccountMigrated;
    billingAccountId?: string;

    constructor() {
        this.isFormerCustomer = false;
        this.isCustomer = false;
    }
}
