/* tslint:disable:variable-name */
import { AField, AObject, ATable } from '@congacommerce/core';

@ATable({
    sobjectName: 'egl_salesupstate__c',
    aqlName: null,
})
export class EglSalesupState extends AObject {
    constructor(cartId?: string, state?: string) {
        super();
        this.Name = cartId;
        this.egl_state = state;
    }

    @AField({ soql: 'Name' }) // Cart Id
    Name: string;

    @AField({ soql: 'egl_state' }) // SalesUP State
    egl_state: string;
}
