import { Injectable } from '@angular/core';
import { ChargeType, ProductService } from '@congacommerce/ecommerce';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { switchMap, map, filter, take } from 'rxjs/operators';
import { get, set, first, map as _map, filter as _filter, forEach, isNil, defaultTo } from 'lodash';
import * as _moment from 'moment';
import { MemoizeAll } from 'lodash-decorators';
import { EglProductExtended } from '../../../../models/apttus/tables/product/egl-product-extended';

@Injectable({
    providedIn: 'root',
})
export class EglProductService extends ProductService {
    @MemoizeAll()
    fetch(id) {
        console.warn('Inside custom Product Service', id);
        const subject = new BehaviorSubject(null);
        this.plService
            .getPriceListId()
            .pipe(
                switchMap((priceListId) => {
                    const productRes$ = this.turboApiService.get(
                        `/pricing/dataadmin/turbo/search/pricelists/${priceListId}/products/${id}`
                    );
                    const categories$ = this.categoryService.getCategoriesByProductId(id);
                    return combineLatest([productRes$, categories$]);
                }),
                switchMap(([productRes, categories]) => {
                    const product = plainToClass(EglProductExtended, get(productRes, 'Entity'), {
                        excludeExtraneousValues: true,
                    });
                    set(product, 'Categories', categories);
                    return this.translatorService.translateData([product]);
                }),
                map(first),
                take(1)
            )
            .subscribe((product) => subject.next(product));
        return subject.pipe(filter((r) => r != null));
    }

    filterInvalidProducts(products) {
        let productList = [];
        if (get(products, 'length') > 0) {
            const today = _moment(new Date()).valueOf();
            productList = _filter(products, (p) => {
                const pliList = defaultTo(
                    _filter(
                        get(p, 'PriceLists', []),
                        (item) =>
                            item.ChargeType === ChargeType.StandardPrice || item.ChargeType === ChargeType.Subscription
                    ),
                    [get(p, 'PriceLists[0]')]
                );
                const isProductExpired =
                    (!isNil(p.ExpirationDate) && p.ExpirationDate < today) ||
                    (!isNil(p.EffectiveDate) && p.EffectiveDate > today);
                let isPliExpired = true;
                forEach(pliList, (pli) => {
                    if (
                        pli &&
                        !isNil(pli.ListPrice) &&
                        (isNil(pli.ExpirationDate) || pli.ExpirationDate > today) &&
                        (isNil(pli.EffectiveDate) || pli.EffectiveDate < today)
                    ) {
                        isPliExpired = false;
                    }
                });
                if (!isProductExpired && !isPliExpired) {
                    return p;
                }
            });
        }
        return productList;
    }
}
