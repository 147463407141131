import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { selectOrderEntryState, selectQuoteId } from '../../../store/selectors/order-entry.selectors';
import { OrderEntryState } from '../../../store/models/order-entry-state';
import { UserState } from '../../../store/models/user-state';
import { selectCurrentVirtualAgent, selectUserState } from '../../../store/selectors/user.selectors';
import { RoutesPaths } from '../../config/routes-paths';
import { NavigateToRequest } from '../../models/d365/navigate-to-request';
import { D365Service } from '../d365/d365.service';
import { D365AgentProfileType } from '../../enums/d365/d365-agent-profile-type';
import { BaseD365Response } from '../../interfaces/base-d365-response';
import { VirtualAgent } from '../../models/user/agent';
import { LoggerService } from './logger.service';
import { selectBOQuoteId } from '../../../store/selectors/bo.selectors';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SurveyService {
    subscriptions: Subscription[] = [];
    user: UserState;
    orderEntry: OrderEntryState;
    currentVirtualAgent: VirtualAgent;
    activeSurveyGuid: string;
    entryPoint: EntryPoint;

    constructor(
        private d365Service: D365Service,
        private store: Store,
        private translateSrv: TranslateService,
        private loggerService: LoggerService
    ) {
        this.mapStateToProps();
    }

    mapStateToProps(): void {
        this.subscriptions.push(
            this.store.select(selectUserState).subscribe((state: UserState) => {
                this.user = state;
            })
        );

        this.subscriptions.push(
            this.store.select(selectOrderEntryState).subscribe((state: OrderEntryState) => {
                this.orderEntry = state;
            })
        );

        this.subscriptions.push(
            this.store.select(selectCurrentVirtualAgent).subscribe((agentInfo: VirtualAgent) => {
                this.currentVirtualAgent = agentInfo;
            })
        );
    }

    /**
     *  Metodo che indica se c'è una survey attiva da visualizzare per un determinato endpoint
     *  @param endpoint: Indica l'endpoint per cui sto controllando la presenza di una survey
     *  @returns boolean
     */
    async showSurveyButton(endpoint: string): Promise<boolean> {
        let userPrivacyCanProceed: boolean = true;
        let existActiveSurvey: boolean;
        let request = {
            VirtualAgentId: this.currentVirtualAgent?.VirtualAgentId,
            ProfileType: this.user.agentInfo?.Type,
        } as CheckActiveSurveyRequest;
        switch (true) {
            // Home
            case endpoint.includes(RoutesPaths.Dashboard) || endpoint.includes(RoutesPaths.DashboardInitial):
                request.EntryPoint = EntryPoint.Home;

                existActiveSurvey = await this.checkActiveSurveyOnD365(request);

                break;
            // Catalog
            case endpoint.includes(RoutesPaths.AllProducts):
                userPrivacyCanProceed = this.user?.contact?.accountid ? this.user?.customerInfo?.Privacy2 : true;
                if (userPrivacyCanProceed) {
                    request.EntryPoint = EntryPoint.Catalog;

                    const contactOrLead = this.user?.lead || this.user?.contact;
                    request.CustomerTaxVatCode = contactOrLead
                        ? contactOrLead.egl_vatcode || contactOrLead.egl_taxcode
                        : null;

                    existActiveSurvey = await this.checkActiveSurveyOnD365(request);
                }

                break;
            // Thank You Page
            case endpoint.includes(RoutesPaths.ThankYouPage):
                userPrivacyCanProceed = this.orderEntry?.privacyTrattDatiPers?.AnalisiMercato;
                if (userPrivacyCanProceed) {
                    request.EntryPoint = EntryPoint.TYP;
                    request.CustomerTaxVatCode = this.user?.contact?.egl_vatcode || this.user?.contact?.egl_taxcode;

                    existActiveSurvey = await this.checkActiveSurveyOnD365(request);
                }

                break;
            // BackOffice
            case endpoint.includes(RoutesPaths.BackOffice) && !endpoint.includes(RoutesPaths.QuoteList):
                request.EntryPoint = EntryPoint.BO;

                existActiveSurvey = await this.checkActiveSurveyOnD365(request);

                break;
            // Quote Detail
            case endpoint.includes(RoutesPaths.BackOffice) && endpoint.includes(RoutesPaths.QuoteList):
                userPrivacyCanProceed = this.user?.customerInfo?.Privacy2;
                if (userPrivacyCanProceed) {
                    request.EntryPoint = EntryPoint.QuoteDetail;
                    request.CustomerTaxVatCode = this.user?.customerInfo?.VatCode || this.user?.customerInfo?.TaxCode;

                    existActiveSurvey = await this.checkActiveSurveyOnD365(request);
                }

                break;
            default:
                return false;
        }

        const show = userPrivacyCanProceed && existActiveSurvey;
        if (!show) {
            this.activeSurveyGuid = undefined;
        }

        return show;
    }

    /**
     * Metodo per chiamare
     * @param request la request da inviare a D365
     * @returns boolean indica la presenza o meno di survey attiva per l'entrypoint richiesto
     */
    async checkActiveSurveyOnD365(request: CheckActiveSurveyRequest): Promise<boolean> {
        const response = await this.d365Service?.getAvailableSurvey(request);

        if (response?.Result != 'OK') {
            this.loggerService.error('Check Active Survey', 'Error in checkActiveSurvey call', response.ErrorMessage);
            return false;
        }

        this.activeSurveyGuid = response.SurveyId;
        return Boolean(response.SurveyId);
    }

    /**
     *  Metodo che chiama la survey attiva
     */
    openSurvey(entrypoint: string): Promise<boolean> {
        let params: {
            etn: Etn;
            id: string;
            customerTaxVatCode?: string;
            surveyId?: string;
        };

        switch (true) {
            // Home || BO
            case entrypoint.includes(RoutesPaths.Dashboard) || entrypoint.includes(RoutesPaths.DashboardInitial):
            case entrypoint.includes(RoutesPaths.BackOffice) && !entrypoint.includes(RoutesPaths.QuoteList):
                params = {
                    etn: Etn.agent,
                    id: this.user?.agentInfo?.Agent?.AgentId,
                };
                break;
            // Catalog
            case entrypoint.includes(RoutesPaths.AllProducts):
                if (this.user?.contact?.accountid) {
                    params = {
                        etn: Etn.account,
                        id: this.user?.contact?.accountid,
                        customerTaxVatCode: this.user?.contact?.egl_vatcode || this.user?.contact?.egl_taxcode,
                    };
                } else if (this.user?.lead?.leadid) {
                    params = {
                        etn: Etn.lead,
                        id: this.user?.lead?.leadid,
                        customerTaxVatCode: this.user?.lead?.egl_vatcode || this.user?.lead?.egl_taxcode,
                    };
                } else {
                    params = {
                        etn: Etn.anonimo,
                        id: 'anonymous-' + new Date().getTime(),
                    };
                }
                break;
            // TYP
            case entrypoint.includes(RoutesPaths.ThankYouPage):
                if (this.user?.contact?.accountid) {
                    params = {
                        etn: Etn.account,
                        id: this.user?.contact?.accountid,
                        customerTaxVatCode: this.user?.contact?.egl_vatcode || this.user?.contact?.egl_taxcode,
                    };
                } else if (this.user?.lead?.leadid) {
                    params = {
                        etn: Etn.lead,
                        id: this.user?.lead?.leadid,
                        customerTaxVatCode: this.user?.lead?.egl_vatcode || this.user?.lead?.egl_taxcode,
                    };
                } else {
                    let quoteId;
                    this.store
                        .select(selectQuoteId)
                        .pipe(take(1))
                        .subscribe((qId: string) => {
                            quoteId = qId;
                        });
                    params = {
                        etn: Etn.proposal,
                        id: quoteId,
                        customerTaxVatCode: this.user?.contact?.egl_vatcode || this.user?.contact?.egl_taxcode,
                    };
                }
                break;
            // QuoteDetail
            case entrypoint.includes(RoutesPaths.QuoteList):
                let quoteId;
                this.store
                    .select(selectBOQuoteId)
                    .pipe(take(1))
                    .subscribe((qId: string) => {
                        quoteId = qId;
                    });
                params = {
                    etn: Etn.proposal,
                    id: quoteId,
                    customerTaxVatCode: this.user?.customerInfo?.VatCode || this.user?.customerInfo?.TaxCode,
                };
                break;
        }

        params.surveyId = this.activeSurveyGuid;

        const request: NavigateToRequest = {
            pageInput: {
                pageType: 'webresource',
                webresourceName: 'egl_/pages/egl_survey/fill.html',
                data: JSON.stringify(params),
            },
            navigationOptions: {
                target: 2,
                width: { value: 80, unit: '%' },
                height: { value: 80, unit: '%' },
                position: 1,
                title: this.translateSrv?.instant('SURVEY.GENERAL_TITLE'),
            },
        };
        return this.d365Service.navigateTo(request);
    }
}

export class CheckActiveSurveyRequest {
    EntryPoint: EntryPoint;
    VirtualAgentId: string;
    CustomerTaxVatCode?: string;
    ProfileType: D365AgentProfileType;
}

export class CheckActiveSurveyResponse extends BaseD365Response {
    SurveyId: string;
}

enum EntryPoint {
    TYP = '006',
    Home = '002',
    Catalog = '005',
    BO = '007',
    QuoteDetail = '008',
}

enum Etn {
    account = 'account',
    lead = 'lead',
    agent = 'egl_agent',
    proposal = 'Apttus_Proposal__Proposal__c',
    anonimo = 'anonymous',
}
