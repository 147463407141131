import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import {
    TranslatorLoaderService,
    CommerceModule,
    ConstraintRuleService,
    ProductOptionService,
    AttributeRuleService,
    CartItemService,
    ClientConstraintRuleService,
    UserService,
    ProductService,
    CartService,
} from '@congacommerce/ecommerce';
import { TableModule, ApttusModalModule, IconModule } from '@congacommerce/elements';
import { OrderEntryOutletComponent } from './routing-outlet/order-entry-outlet.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Locale data
import { AboGuard } from './common/services/apttus/guard/abo.guard';
import { ConfigureGuard } from './common/services/apttus/guard/configure.guard';
import { RouteGuard } from './common/services/apttus/guard/route.guard';
import { ConstraintRuleGuard } from './common/services/apttus/guard/constraint-rule.guard';
import { MonitoringErrorHandler } from './common/services/shared/monitoring-error.handler';
import { HttpRequestInterceptorService } from './common/services/shared/http-request-interceptor.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppInsightsService } from './common/services/shared/app-insights.service';
import { LoggerService } from './common/services/shared/logger.service';
import { D365Service } from './common/services/d365/d365.service';
import { AppInitializer } from './app-initializer';
import { ConnectionService } from 'ng-connection-service';
import { ComponentModule } from './common/components/component.module';
import { StoreModule } from '@ngrx/store';
import { reducers } from './store/reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ToastrModule } from 'ngx-toastr';
import { LoadingService } from './common/services/shared/loading.service';
import { LocalStorageFileService } from './common/services/shared/local-storage-file.service';
import { EglProductOptionService } from './common/services/apttus/utility/egl-product-option.service';
import { EglAttributeRuleService } from './modules/common/products/services/egl-attribute-rule.service';
import { MsalModule } from '@azure/msal-angular';
import { EglCartItemService } from './common/services/apttus/utility/egl-cart-item.service';
import { EglClientConstraintRuleService } from './common/services/apttus/utility/egl-client-constraint-rule.service';
import { BoOutletComponent } from './routing-outlet/bo-outlet.component';
import { AptUserService } from './common/services/apttus/tables/user/apt-user.service';
import { PrivateConfigurationService } from './common/services/shared/private-configuration.service';
import { RouterService } from './common/services/shared/router.service';
import { DragonRouterService } from './common/services/shared/override/dragon-router.service';
import { BulkOrderOutletComponent } from './routing-outlet/bulk-outlet.component';
import { BrowserCacheLocation, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { EglProductService } from './common/services/apttus/tables/product/egl-product.service';
import { EglCartService } from './common/services/apttus/utility/egl-cart.service';
import { ToponymsService } from './common/services/shared/toponyms.service';
import { TelemetryMetricService } from './common/services/app/telemetry-metric.service';

@NgModule({
    declarations: [AppComponent, OrderEntryOutletComponent, BoOutletComponent, BulkOrderOutletComponent],
    imports: [
        MsalModule.forRoot(
            new PublicClientApplication({
                auth: {
                    clientId: environment.auth.clientId,
                    authority: `https://login.microsoftonline.com/${environment.auth.tenantId}`,
                    redirectUri: `${environment.auth.redirectUri}`,
                    postLogoutRedirectUri: `${environment.auth.redirectUri}`,
                },
                cache: {
                    cacheLocation: BrowserCacheLocation.LocalStorage,
                    storeAuthStateInCookie: false,
                },
            }),
            {
                interactionType: InteractionType.Redirect,
                authRequest: {
                    scopes: ['user.read'],
                },
            },
            {
                interactionType: InteractionType.Redirect,
                protectedResourceMap: null,
            }
        ),
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CommerceModule.forRoot(environment),
        TranslateModule.forRoot({
            loader: { provide: TranslateLoader, useClass: TranslatorLoaderService },
        }),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: false }),
        TableModule,
        ComponentModule,
        ApttusModalModule,
        ToastrModule.forRoot(),
        IconModule,
        StoreModule.forRoot(reducers, {
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            },
        }),
        HttpClientModule,
        StoreDevtoolsModule.instrument({
            name: 'EGL.SalesApp',
            maxAge: 25,
            logOnly: environment.production,
        }),
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: AppInitializer,
            deps: [
                D365Service,
                LoggerService,
                Injector,
                AppInsightsService,
                PrivateConfigurationService,
                TelemetryMetricService,
            ],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptorService,
            multi: true,
        },
        {
            provide: LOCALE_ID,
            deps: [TranslateService],
            useFactory: (translateService) => translateService.currentLang || 'it',
        },
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: 'EUR',
        },
        {
            provide: ErrorHandler,
            useClass: MonitoringErrorHandler,
        },
        {
            provide: ProductOptionService,
            useClass: EglProductOptionService,
        },
        {
            provide: CartService,
            useClass: EglCartService,
        },
        {
            provide: CartItemService,
            useClass: EglCartItemService,
        },
        {
            provide: ClientConstraintRuleService,
            useClass: EglClientConstraintRuleService,
        },
        {
            provide: AttributeRuleService,
            useClass: EglAttributeRuleService,
        },
        {
            provide: UserService,
            useClass: AptUserService,
        },
        {
            provide: RouterService,
            useClass: DragonRouterService,
        },
        {
            provide: ProductService,
            useClass: EglProductService,
        },
        D365Service,
        ConnectionService,
        LoggerService,
        LoadingService,
        RouteGuard,
        ConstraintRuleGuard,
        ConfigureGuard,
        AboGuard,
        HttpRequestInterceptorService,
        LocalStorageFileService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
