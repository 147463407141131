import * as moment from 'moment';
import { Moment } from 'moment';
import { AptProductType } from '../enums/apttus/apt-product-type';
import { v4 as uuidv4 } from 'uuid';
import { AptLineStatus } from '../enums/apttus/apt-line-status';
/**
 * Transform multiline string into single line string
 * @param string input string
 * @returns single line string
 */
export const singleLineString = (string: string): string => {
    // Split on newlines.
    const lines = string.split(/(?:\r\n|\n|\r)/);

    // Rip out the leading whitespace.
    return lines
        .map((line) => line.replace(/^\s+/gm, ''))
        .join(' ')
        .trim();
};

/**
 * Return true/false if the input string is a valid JSON
 * @param str
 * @returns boolean
 */
export const isJsonString = (str: string) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export const jsonTryParse = <T>(str: string) => {
    try {
        return JSON.parse(str) as T;
    } catch (e) {
        return null;
    }
};

/**
 * @description Dati due codici fiscali restituisce 100 se sono identici, altrimenti un numero più basso da 0 a 100
 * @param oldCf Vecchio codice fiscale
 * @param newCf Nuovo codice fiscale
 * @returns number Percentuale di somiglianza dei due codici fiscali
 */
export const calculateTaxCodeAffinity = (oldCf: string, newCf: string): number => {
    if ((oldCf || '').length < 16 || (newCf || '').length < 16) {
        return -1;
    }
    let lettersCollisions = 0;
    for (let i = 0; i < oldCf.length; i++) {
        if (oldCf[i] === newCf[i]) {
            lettersCollisions++;
        }
    }
    return Math.round((lettersCollisions * 100) / 16);
};

/**
 * Generic toLocalDateString. Return a formatted string based on Locale format
 * @param date
 * @returns string in locale format
 */

export const toLocaleDateString = (date: Date | string): string => {
    if (date instanceof Date) {
        return date.toLocaleDateString();
    }
    if (typeof date === 'string') {
        return new Date(date).toLocaleDateString();
    }
    return;
};

export const writeImportantInfoLog = (message: string) => {
    console.info(`%c${message}`, 'background: red; color: yellow; font-size: x-large');
};

export const writeImportantErrorLog = (message: string) => {
    console.error(`%c${message}`, 'background: red; color: yellow; font-size: x-large');
};

export const activationCartFromUrl = (): string => {
    const urlParams = new URLSearchParams(window.location.search);
    return (urlParams.get('acart') || '').split('?v')[0];
};

/**
 * @description: Restituisce un guid univoco
 * @return string
 */
export const getTransactionId = (): string => {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        let r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

export const newGuid = (): string => {
    return uuidv4();
};

export const getRandomHash = (): string => {
    return `#${Math.floor(Math.random() * 32000)}`;
};

export const parsePrice = (value: string | number): number => {
    const parsedValue = typeof value === 'number' ? value : parseFloat(value);
    return isNaN(parsedValue) || value === null || typeof value === 'undefined' ? null : parsedValue;
};

export const parseToDate = (value: string | Moment | Date): Date => {
    if (!value) return;

    if (typeof value === 'string') {
        return moment(value).toDate();
    }

    if (moment.isMoment(value)) {
        return value.toDate();
    }
    return value;
};

export const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * max);
};
/**
 * clean object deep clean, delete null, empty undefined properties, arrays, objects
 * @param obj
 * @param config
 * @author Ricupero ft Lombardi
 * @returns <object>
 */
export const cleanObj = <T>(
    obj: Object,
    config: { delete?: boolean; keepEmptyObjects?: boolean; slimDownArray?: boolean; keepEmptyStrings?: boolean } = {}
): Partial<T> => {
    config.delete = config.delete !== false; //Default: true
    config.keepEmptyObjects = config.keepEmptyObjects === true; //Default: false
    config.slimDownArray = config.slimDownArray !== false; //Default: true
    config.keepEmptyStrings = config.keepEmptyStrings === true; //Default: false

    for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
            if (typeof obj[p] === 'undefined' || obj[p] === null || (obj[p] === '' && !config.keepEmptyStrings)) {
                if (config.delete) {
                    delete obj[p];
                } else {
                    obj[p] = undefined;
                }
            } else if (typeof obj[p] === 'object' && !(obj[p] instanceof Date)) {
                obj[p] = cleanObj(obj[p]);
                if (
                    config.delete &&
                    !config.keepEmptyObjects &&
                    ((Array.isArray(obj[p]) && obj[p].length === 0) || Object.keys(obj[p]).length === 0)
                ) {
                    delete obj[p];
                }
            }
        }
    }
    if (Array.isArray(obj) && config.slimDownArray) {
        for (let i = obj.length - 1; i >= 0; i--) {
            if (typeof obj[i] === 'undefined') {
                obj.splice(i, 1);
            }
        }
    }
    return obj as T;
};

/**
 * @author Ricupero ft. Fadda
 * @description in memory of Mario Brega
 * Lorenzo: Uso il forEach invece del reduce perchè ho bisogno che venga restituito il "vecchio" oggetto in input invece di crearne uno nuovo.
 * All'interno delle logiche del SDK vengono controllate il tipo delle instanze e quindi creando un nuovo oggetto plain, non funzionerebbe
 */
export const apttusResponseIronHand = <T>(inputValue: T, skipProps: string[] = []): T => {
    if (typeof inputValue === 'object' && !!inputValue) {
        Object.entries(inputValue).forEach(([key, value]) => {
            const newKey = key.replace(/(^Apttus_Config2__)|(__c$)/g, '');
            inputValue[newKey] =
                skipProps.indexOf(key) === -1
                    ? Array.isArray(value)
                        ? value.map((arrayValue) => apttusResponseIronHand(arrayValue))
                        : apttusResponseIronHand(value)
                    : value;
            if (key !== newKey) {
                delete inputValue[key];
            }
        });
    }
    return inputValue;
};
type AptProductForFiltering = Partial<{
    LineStatus: AptLineStatus;
    egl_cartdetails_visibility: boolean;
    ProductType: AptProductType;
}>;
type AptLineItemForFiltering = Partial<
    AptProductForFiltering & {
        productType: AptProductType;
        Option: AptProductForFiltering;
        Product: AptProductForFiltering;
    }
>;

/**
 * @description: Filtra i cart item rimuovendo quelli associati ad elementi tecnici da non visualizzare nel carrello
 * @param cartItems: L'array dei cart items da filtrare
 * @return: Array di cart items filtrati
 */
export const filterTechAssetItems = (item: any): boolean =>
    ((typedItem) =>
        ![AptProductType.FornituraGas, AptProductType.FornituraLuce].includes(
            typedItem?.Product?.ProductType ||
                typedItem?.Option?.ProductType ||
                typedItem?.productType ||
                typedItem?.ProductType
        ))(item as AptLineItemForFiltering);

export const filterActivePackageProducts = (item: any): boolean => {
    const typedItem = item as AptLineItemForFiltering;
    return (
        ![typedItem?.Option?.LineStatus, typedItem?.LineStatus, typedItem?.Product?.LineStatus].includes(
            AptLineStatus.Cancelled
        ) &&
        ![
            typedItem?.Option?.egl_cartdetails_visibility,
            typedItem?.Product?.egl_cartdetails_visibility,
            typedItem?.egl_cartdetails_visibility,
        ].includes(false) &&
        filterTechAssetItems(typedItem)
    );
};

/**
 * @description: Filtra i cart item rimuovendo quelli associati ad elementi di sconto standalone
 * @param cartItems: L'array dei cart items da filtrare
 * @return: Array di cart items filtrati
 */
export const filterDiscountItems = (item: any): boolean =>
    ((typedItem) =>
        ![AptProductType.ScontoStandAloneGas, AptProductType.ScontoStandAloneLuce].includes(
            typedItem?.Product?.ProductType ||
                typedItem?.Option?.ProductType ||
                typedItem?.productType ||
                typedItem?.ProductType
        ))(item as AptLineItemForFiltering);

export const compareFunOrderAsc = (valueA: string, valueB: string) => {
    const stringA = valueA.toUpperCase();
    const stringB = valueB.toUpperCase();
    if (stringA < stringB) {
        return -1;
    }
    if (stringA > stringB) {
        return 1;
    }
    return 0;
};
