import { Component, Input, OnInit } from '@angular/core';
import { SegoeMDL2Font } from '../../enums/shared/segoe-mdl2-font';

@Component({
    selector: 'egl-rectangle-icon',
    templateUrl: './rectangle-icon.component.html',
    styleUrls: ['./rectangle-icon.component.scss'],
})
export class RectangleIconComponent implements OnInit {
    @Input() icon: string;
    @Input() color: string;
    constructor() {}

    ngOnInit(): void {
        if (this.icon === undefined) {
            this.icon = SegoeMDL2Font.Play;
        }
    }
}
