import { FullState } from '../../../../store/models/full-state';
import { ProductInfo } from '../../../../store/models/order-entry-state';
import { AptCommodityTypeOfSale } from '../../../enums/apttus/apt-commodity-typeof-sale';
import { AptProductType } from '../../../enums/apttus/apt-product-type';
import { KeyValue } from '../../../models/apttus/request-response/cart-to-quote-request';
import { AttributeValues } from './attribute-factory-specializations/base-attribute-values';
import { GasAttributeValues } from './attribute-factory-specializations/gas-attribute-values';
import { PowerAttributeValues } from './attribute-factory-specializations/power-attribute-values';
import { ServiceAttributeValues } from './attribute-factory-specializations/service-attribute-values';

export interface ProductAttributeValues {
    keyValue(): KeyValue[];
}

export abstract class AttributeFactory {
    static getAttributes(state: FullState, currentProduct: ProductInfo): ProductAttributeValues {
        if (
            currentProduct?.powerOrGas === AptCommodityTypeOfSale.Gas ||
            currentProduct?.productType === AptProductType.FornituraGas ||
            currentProduct?.productType === AptProductType.ScontoStandAloneGas
        ) {
            return new GasAttributeValues(state, currentProduct);
        }
        if (
            currentProduct?.powerOrGas === AptCommodityTypeOfSale.Power ||
            currentProduct?.productType === AptProductType.FornituraLuce ||
            currentProduct?.productType === AptProductType.ScontoStandAloneLuce
        ) {
            return new PowerAttributeValues(state, currentProduct);
        }
        if (currentProduct?.productType === AptProductType.Servizio) {
            return new ServiceAttributeValues(state, currentProduct);
        }
        return new AttributeValues(state, currentProduct);
    }
}
