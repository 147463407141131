import { GeoLocation } from '../../common/models/app/geo-location';

export class AppState {
    connectionStatus = 'ONLINE';
    connectionSpeed?: string;
    applicationLocation?: ApplicationLocationType;
    currentPage = '';
    urlHash?: string;
    geoLocation?: GeoLocation;
    hasContactBar?: boolean;
    hasProgressBar?: boolean;
    hasDevBar?: boolean;
    backOfficeQuoteTab?: string;
}

export enum ApplicationLocationType {
    Mobile = 'Mobile',
    Web = 'Web',
}
