import { BaseApiResponse } from '../../../../common/interfaces/base-api-response';

export class CheckPdfResponse extends BaseApiResponse {
    response?: Response;
}

class Response {
    esito: EsitoResponse;
    modalitaOperativa?: string;
    descrizioneEsito?: string;
}

export enum EsitoResponse {
    OK = 'OK',
    KO = 'KO',
}
