import { FullState } from '../../../../../store/models/full-state';
import { OrderEntryState, ProductInfo } from '../../../../../store/models/order-entry-state';
import { Regex } from '../../../../config/regex';
import { AptPowerVoltage } from '../../../../enums/apttus/apt-power-voltage';
import { AttributeValues } from './base-attribute-values';

export class PowerAttributeValues extends AttributeValues {
    constructor(state: FullState, currentProduct: ProductInfo) {
        super(state, currentProduct);
    }
    attributesMapping(): void {
        super.attributesMapping();
        const {
            pod,
            selectFornitureAttiveSelezionate,
            powerConsumption,
            potenzaImpegnataMb,
            activation,
            administrativeTechnicalData,
        } = this.orderEntry;

        this.attr['egl_POD__c'] = pod;
        this.attr['egl_switchoutdate__c'] = selectFornitureAttiveSelezionate?.find(
            (f) => f.podPdr === pod
        )?.dataPresuntoSwitchOut;
        this.attr['egl_power_consumption__c'] = powerConsumption?.value || potenzaImpegnataMb?.consumoInpt;
        this.attr['egl_power_consumption_declared__c'] = powerConsumption?.declared || potenzaImpegnataMb?.consumoInpt;
        this.attr['egl_Required_Power__c'] =
            potenzaImpegnataMb?.potenzaImpegnataCmb ||
            potenzaImpegnataMb?.potenzaImpInputManuale ||
            activation?.podActivationInfo?.nuovaPotSel ||
            activation?.podActivationInfo?.ultimaPotDisp;
        this.attr['egl_Available_Power__c'] =
            potenzaImpegnataMb?.potenzaDisponibileInp ||
            potenzaImpegnataMb?.potenzaImpInputManuale ||
            activation?.podActivationInfo?.ultimaPotDisp ||
            administrativeTechnicalData?.power?.potenzaDisponibile;
        this.attr['egl_voltage__c'] = getPowerVoltage(this.orderEntry);
        this.attr['egl_Engaged_Power__c'] = administrativeTechnicalData?.power?.potenzaImpegnata;
    }
}

const getPowerVoltage = ({
    potenzaImpegnataMb,
    activation,
    administrativeTechnicalData,
}: OrderEntryState): AptPowerVoltage => {
    // Retrieving value from possible inputs
    const srcTension = (
        potenzaImpegnataMb?.livelloTensione ||
        activation?.podActivationInfo?.tensione ||
        administrativeTechnicalData?.power?.tensione ||
        ''
    ).trim();
    // Checking if value is a voltage number
    if (Regex.numberOnly.test(srcTension) || !srcTension) {
        // Retrieving numeric part
        let floatTension = parseFloat(srcTension || '');
        // Handling default voltage
        if (!floatTension || isNaN(floatTension)) {
            floatTension = 220;
        }
        // Returning value + " V"
        return `${floatTension} V` as AptPowerVoltage;
    }
    // Returing non-numeric values for picklist
    return srcTension as AptPowerVoltage;
};
