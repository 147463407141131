import { Injectable, Injector } from '@angular/core';
import {
    AssetLineItem,
    Product,
    CartItem,
    OrderLineItem,
    ProductAttributeValue,
    ProductOptionService,
    QuoteLineItem,
} from '@congacommerce/ecommerce';
import { of, combineLatest, Observable } from 'rxjs';
import { filter as rfilter, map, switchMap, take } from 'rxjs/operators';
import { get, find, isNil, set, isEmpty, cloneDeep, first, filter, defaultTo, map as _map, remove } from 'lodash';
import { CacheService, PlatformService, MetadataService, ConfigurationService } from '@congacommerce/core';
import { EglProductService } from '../tables/product/egl-product.service';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class EglProductOptionService extends ProductOptionService {
    constructor(
        cacheService: CacheService,
        platformService: PlatformService,
        metadataService: MetadataService,
        configurationService: ConfigurationService,
        injector: Injector,
        private eglProductService: EglProductService
    ) {
        super(cacheService, platformService, metadataService, configurationService, injector);
    }
    getProductOptionTree(
        productId,
        relatedTo?: CartItem | QuoteLineItem | AssetLineItem | OrderLineItem,
        applyFilter?: 'none' | 'items' | 'changes',
        changes?: Array<CartItem>,
        inputBundleProduct?: Product
    ) {
        return combineLatest([
            inputBundleProduct
                ? of(inputBundleProduct)
                : this.eglProductService.fetch(productId).pipe(rfilter((prod: Product) => !!prod)),
            this.cartService.getMyCart(),
            this.productAttributeValueService.describe(),
        ]).pipe(
            take(1),
            switchMap(([product, cart, metadata]) => {
                if (product) {
                    const productAttributeGroupList = _map(get(product, 'AttributeGroups', []), 'AttributeGroup');
                    set(
                        product,
                        'ProductAttributeMatrixViews',
                        filter(get(product, 'ProductAttributeMatrixViews'), (r) => r.Active)
                    );
                    remove(get(product, 'ProductAttributeMatrixViews'), (r) => {
                        const expiredate = moment(r.AttributeValueMatrix.ExpirationDate).format('YYYY-MM-DD');
                        return moment(expiredate).isBefore(moment(new Date()).format('YYYY-MM-DD'));
                    });
                    const translatedAttributeGroups$ = this.translatorService.translateData(productAttributeGroupList);
                    let cartItems$ = this.cartItemService.getCartItemsForProduct(product, 1, cart);
                    if (!isEmpty(changes)) {
                        cartItems$ = of(changes);
                    } else if (!isNil(get(relatedTo, 'AssetLineItem'))) {
                        cartItems$ = this.cartItemService.getCartItemsForAsset(relatedTo as CartItem, cart);
                    } else if (!isNil(relatedTo)) {
                        cartItems$ = this.cartItemService.getOptionsForItem(relatedTo, cart) as Observable<CartItem[]>;
                    }
                    return combineLatest([cartItems$, translatedAttributeGroups$]).pipe(
                        map((res) => {
                            const bundleProduct = cloneDeep(product);
                            const cartItem = find(first(res), (i) => get(i, 'LineType') === 'Product/Service');
                            const attrValue = isNil(changes)
                                ? defaultTo(
                                      get(relatedTo, 'AttributeValue', get(cartItem, 'AttributeValue')),
                                      new ProductAttributeValue()
                                  )
                                : get(cartItem, 'AttributeValue');
                            set(cartItem, 'AttributeValue', attrValue);
                            bundleProduct.set('item', cloneDeep(cartItem));
                            return this.groupOptionGroups(bundleProduct, attrValue, first(res), applyFilter, metadata);
                        })
                    );
                } else {
                    return of(null);
                }
            })
        );
    }
}
