import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AptSalesProcess } from '../../common/enums/apttus/apt-sales-process';
import { BoCommonFilters } from '../../modules/back-office/models/bo-common-filters';
import { GestioneConditions } from '../../modules/back-office/models/gestione-filter-list';
import { BoState } from '../models/bo-state';

const getSelectFeature = createFeatureSelector<BoState>('bo');
export const selectBoState = createSelector(getSelectFeature, (state: BoState) => state);
export const selectOpMode = createSelector(getSelectFeature, (state: BoState) => state.selectedOpMode);
const selectMonitoraggioFilters = createSelector(getSelectFeature, (state: BoState) => state.monitoraggioFilter);

const selectGestioneFilters = createSelector(getSelectFeature, (state: BoState) => state.gestioneFilter);
export const selectGestioneFilterBar = createSelector(
    selectGestioneFilters,
    selectOpMode,
    (conds: GestioneConditions, operativeMode: AptSalesProcess) => ({
        conds,
        operativeMode,
    })
);
export const selectCarrelliFilterBar = createSelector(getSelectFeature, (state: BoState) => state.carrelliFilter);
export const selectCurrentTab = createSelector(getSelectFeature, (state: BoState) => state.selectedTab);

const selectCommonCondition = createSelector(getSelectFeature, (state: BoState): BoCommonFilters => {
    const filters = state.monitoraggioFilter || state.gestioneFilter;
    return {
        agencyBranch: filters?.agencyBranch,
        agent: filters?.agent,
    };
});
export const selectAgentFilter = createSelector(selectCommonCondition, (state) => state.agent);
export const selectAgencyFilter = createSelector(selectCommonCondition, (state: BoCommonFilters) => state.agencyBranch);
export const selectMonitoraggioFilterBar = createSelector(
    selectMonitoraggioFilters,
    selectOpMode,
    (conds, operativeMode) => ({
        conds,
        operativeMode,
    })
);
export const selectBOQuoteId = createSelector(getSelectFeature, (state: BoState) => state.selectedQuoteId);
