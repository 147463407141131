export enum D365ChannelCode {
    AgenteSingolo = 'AGS',
    Agenzia = 'AGE',
    Dealer = 'DEA',
    Flagship = 'FLA',
    NegoziESE = 'ESE',
    AgenziaMicrobusiness = 'AGM',
    TelesellingInbound = 'TLI',
    TelesellingOutbound = 'TLO',
    WebAssistito = 'WAS',
    ComparatoreWeb = 'CWE',
    InsurancePartnerTeam = 'IPT',
    WebPull = 'WPU',
}

export enum ChannelCodeInverse {
    AGS = 'Agente Singolo',
    AGE = 'Agenzia',
    DEA = 'Dealer',
    FLA = 'Flagship',
    ESE = 'Negozi ESE',
    AGM = 'Agenzia Microbusiness',
    TLI = 'Teleselling Inbound',
    TLO = 'Teleselling Outbound',
    WAS = 'Web Assistito',
    CWE = 'Comparatore Web',
    IPT = 'Insurance Partner Team',
    WPU = 'Web Pull',
}
