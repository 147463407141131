import * as fromUser from './user.selectors';
import * as fromOrder from './order-entry.selectors';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PartnerShipInfo } from '../../modules/common/partnership/models/partnership-info';
import { DatiAnagraficiMB, OrderEntryState } from '../models/order-entry-state';
import { D365CustomerSegment } from '../../common/enums/d365/d365-customer-segment';
import { SelectContactLead } from '../models/select-contact-lead';
import { AptLineStatus } from '../../common/enums/apttus/apt-line-status';
import { AptPartnerships } from '../../modules/common/partnership/enums/apt-partnerships';

const orderEntryFeature = createFeatureSelector<OrderEntryState>('orderEntry');
export const selectPartnerShip = createSelector(orderEntryFeature, (state: OrderEntryState) => state.partnership);
export const selectFastwebTaxVatCode = createSelector(
    selectPartnerShip,
    fromUser.selectCartSegment,
    fromUser.selectContactLead,
    fromOrder.selectAnagraficaMB,
    (
        partnership: PartnerShipInfo,
        cartSegment: D365CustomerSegment,
        contactLead: SelectContactLead,
        mbData: DatiAnagraficiMB
    ) => {
        if (partnership?.fw?.taxVatCode) {
            return partnership?.fw?.taxVatCode;
        }
        if (cartSegment === D365CustomerSegment.Residential) {
            return (contactLead?.contact || contactLead?.lead)?.egl_taxcode;
        }
        return mbData?.piva || (contactLead?.contact || contactLead?.lead)?.egl_vatcode;
    }
);
export const selectFastwebPartnershipData = createSelector(selectPartnerShip, (ps: PartnerShipInfo) => ps?.fw);
export const selectEglPartnershipData = createSelector(selectPartnerShip, (ps: PartnerShipInfo) => ps?.egl);

export const selectEnabledPartnerships = createSelector(
    fromOrder.selectProductsInfo,
    (prods) =>
        prods
            .filter(
                (p) =>
                    p.status !== AptLineStatus.Cancelled &&
                    p.partnership &&
                    p.partnership?.toUpperCase() !== AptPartnerships.NO_PARTNERSHIP
            )
            .map((p) => p.partnership)
            .filter((partnership, index, self) => self.indexOf(partnership) === index)
            .sort((a, b) => a.localeCompare(b)) || []
);
