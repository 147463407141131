import { AptProductType } from '../../common/enums/apttus/apt-product-type';

export class SelectProductTypes {
    allTypes: AptProductType[];
    productsCount: number;
    containsHealthProducts: boolean;
    containsPower: boolean;
    containsGas: boolean;
    containsCommodity: boolean;
    containsInsurance: boolean;
    containsOnlyInsurance: boolean;
    containsMaintenance: boolean;
    containsProductsAtNew: boolean;
    containsGasWithPartnership: boolean;
    containsPowerWithPartnership: boolean;
    containsRcDanni: boolean;
    constructor() {
        this.allTypes = [];
    }
}
