import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { setContactBarVisibility } from '../../../store/actions/app.actions';
import { D365Service } from '../../services/d365/d365.service';
import { ModalComponent } from '../modal/modal.component';
import { ApttusService } from '../../services/apttus/apttus.service';
import { TranslateService } from '@ngx-translate/core';
import { take, map, mergeMap } from 'rxjs/operators';
import { selectHasContactBar } from '../../../store/selectors/app.selectors';
import { selectContactLead } from '../../../store/selectors/user.selectors';
import { EglState } from '../../../store/reducers';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { selectFlowType } from '../../../store/selectors/order-entry.selectors';
import { PrivateConfigurationService } from '../../services/shared/private-configuration.service';
import { isFlowTypeSwitchIn } from '../../functions/verifications.functions';
import { Lead } from '../../models/user/lead';
import { FLOW_TYPE_LABELS_MAP } from '../../map/flow-type-labels-map';
import { FlowType } from '../../../store/models/flow-type';

@Component({
    selector: 'egl-contact-bar',
    templateUrl: './contact-bar.component.html',
    styleUrls: ['./contact-bar.component.scss'],
})
export class ContactBarComponent implements OnInit, OnDestroy {
    name: string;
    surname: string;
    subscriptions: Subscription[] = [];
    leadId = '';
    @ViewChild('leadResponseModal', { static: true }) leadResponseModal: TemplateRef<ModalComponent>;
    modalRef: BsModalRef;

    modalDescription: string;
    modalTitle: string;
    iconColor: boolean;
    txtBtnSx: string;
    txtBtnDx: string;
    isVisible: boolean;
    leadFormGUID: string;
    private flowType: FlowType;
    public flowTypeLabel: String;

    constructor(
        private store: Store<EglState>,
        private d365Service: D365Service,
        private apttusService: ApttusService,
        private translate: TranslateService,
        private modalService: BsModalService,
        private configSrv: PrivateConfigurationService
    ) {}

    ngOnDestroy(): void {
        this.subscriptions.forEach((x) => x.unsubscribe());
    }

    ngOnInit() {
        this.subscriptions = [
            combineLatest([
                this.store.select(selectFlowType).pipe(
                    map((flowType) => ({
                        flowType,
                        flowTypeKey: FLOW_TYPE_LABELS_MAP[flowType],
                    })),
                    mergeMap(({ flowType, flowTypeKey }) =>
                        this.translate.get(flowTypeKey).pipe(
                            map((flowTypeLabel) => (flowTypeLabel === flowTypeKey ? null : flowTypeLabel)),
                            map((flowTypeLabel) => ({
                                flowType,
                                flowTypeLabel,
                            }))
                        )
                    )
                ),
                this.store.select(selectContactLead).pipe(
                    map(
                        ({ contact, lead, customerInfo }) =>
                            (contact ||
                                lead ||
                                (customerInfo
                                    ? {
                                          firstname: customerInfo.Name,
                                          lastname: customerInfo.LastName,
                                          leadid: null,
                                      }
                                    : null)) as Lead
                    )
                ),
            ])
                .pipe(
                    map(([{ flowType, flowTypeLabel }, contact]) => ({
                        flowType,
                        flowTypeLabel,
                        contact,
                    }))
                )
                .subscribe(({ flowType, flowTypeLabel, contact }) => {
                    this.flowType = flowType;
                    this.flowTypeLabel = flowTypeLabel;

                    this.name = (contact?.firstname || '').trim();
                    this.surname = (contact?.lastname || '').trim();
                    this.leadId = contact?.leadid;

                    this.store.dispatch(
                        setContactBarVisibility({
                            v: !!this.name || !!this.surname || (!isFlowTypeSwitchIn(flowType) && flowTypeLabel),
                        })
                    );
                }),
        ];
        this.setModalText();
        this.leadFormGUID = this.configSrv.config.d365.leadFormGUID;
    }

    private setModalText(): void {
        this.modalTitle = this.translate.instant('LEAD_RESPONSE.TITLE');
        this.modalDescription = this.translate.instant('LEAD_RESPONSE.MESSAGE');
        this.txtBtnSx = this.translate.instant('LEAD_RESPONSE.CANCEL');
        this.txtBtnDx = this.translate.instant('LEAD_RESPONSE.CONTINUE');
    }

    openModal(): void {
        if (this.flowType === FlowType.SwitchIn) {
            this.modalRef = this.modalService.show(this.leadResponseModal);
        }
    }

    async openLead(): Promise<void> {
        this.d365Service.openViewFormOnCrmWithParameters('lead', this.leadId, this.leadFormGUID, {
            egl_opencampaignresponseform: 'true',
        });
    }

    async esitaLead(): Promise<void> {
        this.apttusService.onCreateNewCartCompleted.pipe(take(1)).subscribe(async (activatedCartId: string) => {
            if (activatedCartId) {
                await this.openLead();
            }
        });
        this.apttusService.clearAndCreateNewCart(null);
    }

    public get contactName(): string {
        return `${this.name || ''} ${this.surname || ''}`.trim().toLowerCase();
    }

    public isBarVisible = this.store.select(selectHasContactBar);
}
