import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, delay, finalize, map, retry, share, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    constructor(private httpClient: HttpClient) {}

    private pendingCalls: {
        [key: string]: Observable<any>;
    } = {};

    getAsync<T>(
        url: string,
        params?: any,
        queryParams?: any,
        retry?: number,
        reqOpts?: any,
        isUseTimestamp?: boolean
    ): Observable<any> {
        url += queryParams ? ApiService.getQueryParameters(queryParams) : '';
        if (!reqOpts) {
            reqOpts = {
                params: new HttpParams(),
            };
        }

        // Support easy query params for GET requests
        if (params) {
            reqOpts.params = new HttpParams();
            // tslint:disable-next-line:forin
            for (const k in params) {
                reqOpts.params.set(k, params[k]);
            }
        }
        const callId = '[GET]' + url + JSON.stringify(reqOpts || {});
        this.pendingCalls[callId] =
            this.pendingCalls[callId] ||
            this.httpClient.get<T>(url, reqOpts).pipe(
                share(),
                finalize(() => {
                    delete this.pendingCalls[callId];
                })
            );

        return this.pendingCalls[callId];
    }

    postAsync<T>(url: string, body: any, delaying: number = 0, reqOpts?: any, retryCount: number = 0): Observable<T> {
        const callId = '[POST]' + url + JSON.stringify(body || {}) + JSON.stringify(reqOpts || {});
        this.pendingCalls[callId] =
            this.pendingCalls[callId] ||
            this.httpClient.post<T>(url, body, reqOpts).pipe(
                delay(delaying),
                retry(retryCount),
                catchError((x) => of(x)),
                finalize(() => {
                    delete this.pendingCalls[callId];
                })
            );
        return this.pendingCalls[callId];
    }

    putAsync<T>(url: string, body: any, delaying: number = 0, reqOpts?: any, retryCount: number = 0): Observable<T> {
        const callId = '[PUT]' + url + JSON.stringify(body || {}) + JSON.stringify(reqOpts || {});
        this.pendingCalls[callId] =
            this.pendingCalls[callId] ||
            this.httpClient.put<T>(url, body, reqOpts).pipe(
                delay(delaying),
                retry(retryCount),
                catchError((x) => of(x)),
                finalize(() => {
                    delete this.pendingCalls[callId];
                })
            );
        return this.pendingCalls[callId];
    }

    deleteAsync<T>(url: string, queryParams: Object, delaying: number = 0, retryCount: number = 0): Observable<T> {
        const reqOpts = {
            params: new HttpParams(),
        };
        for (const k in queryParams) {
            reqOpts.params = reqOpts.params.set(k, queryParams[k]);
        }
        const callId = '[DELETE]' + url + JSON.stringify(reqOpts || {});
        this.pendingCalls[callId] =
            this.pendingCalls[callId] ||
            this.httpClient.delete<T>(url, reqOpts).pipe(
                delay(delaying),
                retry(retryCount),
                catchError((x) => of(x)),
                finalize(() => {
                    delete this.pendingCalls[callId];
                })
            );
        return this.pendingCalls[callId];
    }

    async getBlobFile<T>(path: string): Promise<any> {
        return await this.httpClient.get(path, { responseType: 'blob' }).toPromise();
    }

    /**
     * @description Esegue una chiamata REST di tipo PATCH
     * @returns Observable<HttpResponse<T>>
     */
    patchAsync<T>(
        endpoint: string,
        api: string,
        action: string,
        body: any,
        reqOpts?: any,
        delaying: number = 0,
        retryCount: number = 0
    ): Observable<HttpResponse<T>> {
        const url = `${endpoint}/${api}/${action}`;
        if (!reqOpts) {
            reqOpts = {
                observe: 'response',
            };
        }
        return this.httpClient.patch<T>(url, body, reqOpts).pipe(
            delay(delaying),
            retry(retryCount),
            map((res: HttpResponse<T>) => {
                return res;
            })
        );
    }

    get httpClientInstance(): HttpClient {
        return this.httpClient;
    }

    /**
     * @description Legge un file locale
     * @param path indica il path del file da leggere
     * @returns Observable<any>
     */
    getLocalFile(path: string): Observable<any> {
        return this.httpClient.get(path);
    }

    static getQueryParameters(params: any): string {
        let uri = '';
        for (const k in params) {
            uri += (uri === '' ? '?' : '&') + `${k}=${params[k]}`;
        }
        return uri;
    }
}
