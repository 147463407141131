/* tslint:disable:variable-name */
import { AField, AObject, ATable } from '@congacommerce/core';
import { AptAddressType } from '../../../../enums/apttus/apt-address-type';

@ATable({
    sobjectName: 'Product2',
    aqlName: null,
})
export class EglProductLight extends AObject {
    constructor() {
        super();
        this.ExpirationDate = null;
        this.Name = null;
        this.Family = null;
        this.ProductType = null;
        this.ProductCode = null;
        this.egl_shortdescription = null;
        this.egl_subtitle = null;
        this.egl_sellingpoint1_desc = null;
        this.egl_sellingpoint2_desc = null;
        this.egl_sellingpoint3_desc = null;
        this.egl_sellingpoint4_desc = null;
        this.egl_advantage1_title = null;
        this.egl_advantage1_desc = null;
        this.egl_advantage2_title = null;
        this.egl_advantage2_desc = null;
        this.egl_address_type = null;
    }

    @AField({ soql: 'Name' })
    Name: string;

    @AField({ soql: 'Family' })
    Family: string;

    @AField({ soql: 'ExpirationDate' })
    ExpirationDate: Date;

    @AField({ soql: 'ProductType' })
    ProductType: string;

    @AField({ soql: 'ProductCode' })
    ProductCode: string;

    @AField({ soql: 'egl_unique_product_code' })
    egl_unique_product_code: string;

    @AField({ soql: 'egl_shortdescription' })
    egl_shortdescription: string;

    @AField({ soql: 'egl_subtitle' })
    egl_subtitle: string;

    @AField({ soql: 'egl_sellingpoint1_desc' })
    egl_sellingpoint1_desc: string;

    @AField({ soql: 'egl_sellingpoint2_desc' })
    egl_sellingpoint2_desc: string;

    @AField({ soql: 'egl_sellingpoint3_desc' })
    egl_sellingpoint3_desc: string;

    @AField({ soql: 'egl_sellingpoint4_desc' })
    egl_sellingpoint4_desc: string;

    @AField({ soql: 'egl_advantage1_title' })
    egl_advantage1_title: string;

    @AField({ soql: 'egl_advantage1_desc' })
    egl_advantage1_desc: string;

    @AField({ soql: 'egl_advantage2_title' })
    egl_advantage2_title: string;

    @AField({ soql: 'egl_advantage2_desc' })
    egl_advantage2_desc: string;

    @AField({ soql: 'egl_address_type' })
    egl_address_type: AptAddressType;

    @AField({ soql: 'egl_privacy_required' })
    egl_privacy_required: boolean;

    @AField({ soql: 'egl_is_expired' })
    egl_is_expired: boolean;

    @AField({ soql: 'Status' })
    Status: string;
}
