import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AppInsightsService } from './app-insights.service';
import { ToastrService } from 'ngx-toastr';
import { Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class LoggerService {
    constructor(private appInsightsSrv: AppInsightsService, private injector: Injector) {}

    info(msg: string, showAlert?: boolean, ...optionalParams: any[]): void {
        console.info(`[EGL]: ${msg}`, ...optionalParams);
        this.appInsightsSrv.logTrace('Technical Log', { msg });
        if (showAlert && this.toastrSrv) {
            const title = this.translateSrv.instant('COMMON.INFO_ALERT');
            this.toastrSrv.info(msg, title);
        }
    }
    warn(msg: string, showAlert?: boolean, properties?: { [key: string]: any }, ...optionalParams: any[]): void {
        console.warn(`[EGL]: ${msg}`, ...optionalParams);
        this.appInsightsSrv.logTrace('Technical Log', { ...properties, msg });
        if (showAlert && this.toastrSrv) {
            const title = this.translateSrv.instant('COMMON.INFO_ALERT');
            this.toastrSrv.warning(msg, title);
        }
    }
    error(title?: string, msg?: string, error?: any, showAlert?: boolean, ...optionalParams: any[]): void {
        title = title || 'Attenzione';
        msg = msg || 'Si è verificato un errore';
        let fullMsg = `${title}: ${msg}`;
        fullMsg +=
            error instanceof HttpErrorResponse
                ? ` ERROR STATUS: ${error.status} - MESSAGE: ${error.message}`
                : this.getErrorString(error);
        this.appInsightsSrv.logTrace('Technical Error Log', { msg: fullMsg });
        if (showAlert && this.toastrSrv) {
            this.toastrSrv.error(msg, title);
        }
        console.error(`[EGL]: ${fullMsg}`, ...optionalParams);
    }

    private get toastrSrv(): ToastrService {
        return this.injector.get(ToastrService);
    }

    public get translateSrv(): TranslateService {
        return this.injector.get(TranslateService);
    }

    private getErrorString(error: any) {
        return error ? ` - ${error}` : '';
    }
}
