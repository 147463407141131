export abstract class ConfigurationConstants {
    public static readonly leadFields = [
        'leadid',
        'firstname',
        'lastname',
        'telephone1',
        'mobilephone',
        'emailaddress1',
        'egl_productdetails',
        'egl_code',
        'egl_taxcode',
        'egl_privacy1',
        'egl_privacy2',
        'egl_privacy3',
        '_parentcontactid_value',
        '_parentaccountid_value',
        'egl_customercode',
        'egl_customeraccountcode',
        'telephone2',
        'address1_line1',
        'address1_city',
        'address1_postalcode',
        'address1_stateorprovince',
        'egl_customersegmentcode',
        'address2_postalcode',
        'address2_city',
        'address2_stateorprovince',
        'address2_line1',
        'egl_vatcode',
        '_campaignid_value',
        'egl_tag',
    ];

    public static readonly campaignFields = ['name', 'campaignid', 'egl_code', 'egl_campaigntypecode'];
}
