/* tslint:disable:variable-name */
import { AField, AObject, ATable } from '@congacommerce/core';

@ATable({
    sobjectName: 'Apttus_Config2__AccountLocation__c',
    aqlName: null,
})
export class EglAccountLocationLight extends AObject {
    constructor() {
        super();
    }

    @AField({ soql: 'Name' })
    Name: string;

    @AField({ soql: 'City' })
    City: string;

    @AField({ soql: 'State' })
    State: string;

    @AField({ soql: 'Street' })
    Street: string;

    @AField({ soql: 'PostalCode' })
    PostalCode: string;

    @AField({ soql: 'egl_billingaccount_address_line2' })
    egl_billingaccount_address_line2: string;

    @AField({ soql: 'egl_billingaccount_address_prefix' })
    egl_billingaccount_address_prefix: string;

    @AField({ soql: 'egl_billingaccount_paymentmethod' })
    egl_billingaccount_paymentmethod: string;

    @AField({ soql: 'egl_billingaccount_iban' })
    egl_billingaccount_iban: string;

    @AField({ soql: 'egl_billingaccount_fullname' })
    egl_billingaccount_fullname: string;

    @AField({ soql: 'egl_bankaccount_holder_fiscalcode' })
    egl_bankaccount_holder_fiscalcode: string;
}
