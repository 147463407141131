export class Indirizzi {
    indirizzoFornitura?: Indirizzo;
    indirizzoSpedizione: Indirizzo;
    indirizzoComunicazioni?: Indirizzo;
    indirizzoResidenza?: Indirizzo;
    stessoIndirizzoResidenza: boolean;
    stessoIndirizzoComunicazioni: boolean;
    stessoIndirizzoSpedizione: boolean;
    isSedeLegale: boolean;
    isResidenceAddressChanged: boolean;

    constructor() {
        this.indirizzoFornitura = new Indirizzo();
        this.indirizzoSpedizione = new Indirizzo();
        this.indirizzoComunicazioni = new Indirizzo();
        this.indirizzoResidenza = new Indirizzo();
        this.isSedeLegale = false;
        this.stessoIndirizzoResidenza = true;
        this.stessoIndirizzoComunicazioni = true;
        this.stessoIndirizzoSpedizione = true;
        this.isResidenceAddressChanged = false;
    }
}

export class Indirizzo {
    toponomastica?: string;
    codiceEgon?: number; // codice Egon civico
    codiceEgonStreet?: number; // codice Egon della strada
    via?: string;
    civico?: string;
    comune?: string;
    prov?: string;
    cap?: string;
    nomeCompleto?: string;
    certificato = true;
    country?: string;
    region?: string;
    iso3?: string;
    level?: string;
    province?: string;
    city?: string;
    codiceIstatProvincia?: string; //TODO DA CANCELLARE
    codiceIstatComune?: string;
    codiceIstat?: string; //TODO DA CANCELLARE
}
