/* tslint:disable:variable-name */
import { AField, AObject, ATable } from '@congacommerce/core';
import { AptBillType } from '../../../../enums/apttus/apt-bill-type';
import { AptPaymentInstrument } from '../../../../enums/apttus/apt-payment-instrument';
import { AptCommodityTypeOfSale } from '../../../../enums/apttus/apt-commodity-typeof-sale';
import { AptPaymentFrequency } from '../../../../enums/apttus/apt-payment-frequency';
import { AptPaymentType } from '../../../../enums/apttus/apt-payment-type';

@ATable({
    sobjectName: 'Apttus_Config2__ProductAttributeValue__c',
})
export class EglCartPAVLight extends AObject {
    constructor() {
        super();
    }

    @AField({ soql: 'egl_Invoice_Shipping_Method__c' }) // modalita spedizione
    egl_Invoice_Shipping_Method__c: AptBillType = null;

    @AField({ soql: 'egl_Payment_Instrument__c' })
    egl_Payment_Instrument__c: AptPaymentInstrument = null;

    @AField({ soql: 'egl_IVASS_01__c' })
    egl_IVASS_01__c: string;

    @AField({ soql: 'egl_IVASS_02__c' })
    egl_IVASS_02__c: string;

    @AField({ soql: 'egl_IVASS_03__c' })
    egl_IVASS_03__c: string;

    @AField({ soql: 'egl_commodity_supplytype__c' })
    egl_commodity_supplytype__c: AptCommodityTypeOfSale;

    @AField({ soql: 'egl_Insurance_Type__c' })
    egl_Insurance_Type__c: AptCommodityTypeOfSale;

    @AField({ soql: 'egl_POD__c' })
    egl_POD__c: AptCommodityTypeOfSale;

    @AField({ soql: 'egl_PDR__c' })
    egl_PDR__c: AptCommodityTypeOfSale;

    @AField({ soql: 'egl_isresidential__c' })
    egl_isresidential__c: AptCommodityTypeOfSale;

    @AField({ soql: 'egl_selling_order_number__c' })
    egl_selling_order_number__c: string;

    @AField({ soql: 'egl_Place_Name__c' })
    egl_Place_Name__c: string;

    @AField({ soql: 'egl_Address__c' })
    egl_Address__c: string;

    @AField({ soql: 'egl_Civic_Number__c' })
    egl_Civic_Number__c: string;

    @AField({ soql: 'egl_City__c' })
    egl_City__c: string;

    @AField({ soql: 'egl_Province__c' })
    egl_Province__c: string;

    @AField({ soql: 'egl_Postal_Code__c' })
    egl_Postal_Code__c: string;

    @AField({ soql: 'egl_partnership__c' })
    egl_partnership__c: string;

    @AField({ soql: 'egl_Payment_Frequency__c' })
    egl_Payment_Frequency__c: AptPaymentFrequency;

    @AField({ soql: 'egl_Payment_Type__c' })
    egl_Payment_Type__c: AptPaymentType;

    @AField({ soql: 'egl_combined_sale_insurance__c' })
    egl_combined_sale_insurance__c: 'No' | string;
}
