import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'egl-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
    @Input() icon: string;
    @Input() showIcon = true;
    @Input() iconColor: string;
    @Input() title: string;
    @Input() description: string;
    @Input() txtBtnSx: string; // Bottone sx
    @Input() txtBtnCn: string; // Bottone centrale
    @Input() txtBtnDx: string; // Bottone dx
    @Input() modalRef: BsModalRef;
    @Input() yellowBtn: string;
    @Input() bluBtn: string;
    @Input() type: number;
    @Output() btnSxClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() btnCnClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() btnDxClick: EventEmitter<any> = new EventEmitter<any>();
    @Input() alignLeft = false;
    @Input() closeModalAfterClick = true;

    private postClick(): void {
        if (this.modalRef && this.closeModalAfterClick) {
            this.modalRef.hide();
        }
    }
    onBtnSxClick(): void {
        this.btnSxClick.emit();
        this.postClick();
    }
    onBtnDxClick(): void {
        this.btnDxClick.emit();
        this.postClick();
    }
    onBtnMdClick(): void {
        this.btnCnClick.emit();
        this.postClick();
    }
    onCloseBtn() {
        if (this.modalRef) {
            this.modalRef.hide();
        }
    }
}
