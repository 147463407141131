import { MonitoraggioConditions } from '../../modules/back-office/models/monitoraggio-filter-list';
import { GestioneConditions } from '../../modules/back-office/models/gestione-filter-list';
import { CarrelliFilterList } from '../../modules/back-office/models/carrelli-filter-list';
import { AptSalesProcess } from '../../common/enums/apttus/apt-sales-process';
import { Tabs } from '../../common/enums/shared/tabs';

export class BoState {
    selectedTab: 'MON' | 'CART' | 'GEST';
    monitoraggioFilter: MonitoraggioConditions;
    gestioneFilter: GestioneConditions;
    carrelliFilter: CarrelliFilterList;
    selectedOpMode: AptSalesProcess;
    selectedQuoteId: string;

    constructor(tab?: Tabs) {
        this.selectedTab = tab || 'MON';
        this.selectedOpMode = AptSalesProcess.SwitchIn;
    }
}
