import { AttributeRuleService, Product, ProductAttributeRule, ProductAttributeValue } from '@congacommerce/ecommerce';
import { Injectable } from '@angular/core';
import { ACondition, AFilter, APageInfo, SalesforceUtils } from '@congacommerce/core';
import * as _ from 'lodash';
import { zip, of, BehaviorSubject, Observable } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { MemoizeAll } from 'lodash-decorators';

@Injectable({
    providedIn: 'root',
})
export class EglAttributeRuleService extends AttributeRuleService {
    getAttributeRulesForProducts(productList: string[] | Product[], attributes?: any, cart?: any) {
        return this.getCustomAttributeRulesForProducts(productList).pipe(
            map((res) => [res[0], res[1], res[2]]),
            map(([attrRules, attrMetadata, _productList]) => {
                attributes = {
                    ...attributes,
                    Apttus_Config2__ProductConfiguration: cart,
                };
                _productList.forEach((product) => {
                    const mergeActionResult = {};
                    _.forEach(attrRules, (attrRule) => {
                        const ruleCriteriaConditions = _.get(attrRule, 'ConditionCriteriaExpression');
                        let areActionsValid = true;
                        if (ruleCriteriaConditions && attributes) {
                            const conditions = _.split(
                                ruleCriteriaConditions.replaceAll('$.Apttus_Config2__AttributeValueId__r.', ''),
                                '&&'
                            );

                            if (conditions.find((c) => c.includes('Apttus_Config2__ProductConfiguration__c.'))) {
                                conditions.forEach((condition: any, i) => {
                                    if (condition.includes('Apttus_Config2__ProductConfiguration__c.')) {
                                        conditions[i] = condition.replaceAll('__c', '');
                                    }
                                });
                            }
                            conditions.forEach((condition) => {
                                if (condition.includes('==')) {
                                    let ruleDetails = _.split(condition, '==');
                                    if (ruleDetails && ruleDetails.length > 1) {
                                        if (
                                            _.get(attributes, ruleDetails[0].trim(), '')?.trim() !==
                                            _.trim(_.trimEnd(_.replace(ruleDetails[1].trim(), 'TEXT(', ''), ')'), "'")
                                        ) {
                                            areActionsValid = false;
                                        }
                                    }
                                } else if (condition.includes('!=')) {
                                    let ruleDetails = _.split(condition, '!=');
                                    if (ruleDetails && ruleDetails.length > 1) {
                                        if (
                                            !(
                                                _.get(attributes, ruleDetails[0].trim(), '')?.trim() !==
                                                _.trim(
                                                    _.trimEnd(_.replace(ruleDetails[1].trim(), 'TEXT(', ''), ')'),
                                                    "'"
                                                )
                                            )
                                        ) {
                                            areActionsValid = false;
                                        }
                                    }
                                }
                            });
                        }

                        if (areActionsValid) {
                            const ruleActions = attrRule.ProductAttributeRuleActions || [];
                            ruleActions.forEach((ruleAction, index, list) => {
                                const apiName =
                                    ruleAction.Field.indexOf('.') > -1
                                        ? ruleAction.Field.split('.').pop()
                                        : ruleAction.Field;
                                const fieldType = attrMetadata.fields.find((r) => r.name === apiName).type;
                                const isSelectType = fieldType === 'multipicklist' || fieldType === 'picklist';
                                if (ruleAction.Action === 'Allow' && !isSelectType) {
                                    list.splice(index, 1);
                                } else {
                                    if (
                                        mergeActionResult.hasOwnProperty(apiName) &&
                                        _.get(mergeActionResult, `${apiName}`)
                                    ) {
                                        const attributeResult = mergeActionResult[apiName];
                                        attributeResult.isHiddenAction = attributeResult.isHiddenAction
                                            ? attributeResult.isHiddenAction
                                            : this.getActionFlag(ruleAction.Action, 'Hidden');
                                        attributeResult.isReadOnlyAction = attributeResult.isReadOnlyAction
                                            ? attributeResult.isReadOnlyAction
                                            : this.getActionFlag(ruleAction.Action, 'Disabled');
                                        attributeResult.isRequiredAction = attributeResult.isRequiredAction
                                            ? attributeResult.isRequiredAction
                                            : this.getActionFlag(ruleAction.Action, 'Required');
                                        if (
                                            this.getActionFlag(ruleAction.Action, 'Allow') &&
                                            !attributeResult.isConstraintAction
                                        ) {
                                            attributeResult.isConstraintAction = true;
                                            attributeResult.values = ruleAction.Values;
                                        }
                                        if (
                                            this.getActionFlag(ruleAction.Action, 'Default') &&
                                            !attributeResult.isDefaultAction
                                        ) {
                                            attributeResult.isDefaultAction = true;
                                            attributeResult.values = ruleAction.Values;
                                        }
                                        if (
                                            this.getActionFlag(ruleAction.Action, 'Reset') &&
                                            !attributeResult.isReset &&
                                            ruleAction.Values.length > 0
                                        ) {
                                            attributeResult.isReset = true;
                                            attributeResult.resetValues = ruleAction.Values;
                                        }
                                    } else {
                                        const attributeResult = {
                                            field: apiName,
                                            isConstraintAction: this.getActionFlag(ruleAction.Action, 'Allow'),
                                            isDefaultAction: this.getActionFlag(ruleAction.Action, 'Default'),
                                            isHiddenAction: this.getActionFlag(ruleAction.Action, 'Hidden'),
                                            isReadOnlyAction: this.getActionFlag(ruleAction.Action, 'Disabled'),
                                            isRequiredAction: this.getActionFlag(ruleAction.Action, 'Required'),
                                            isReset: this.getResetFlag(ruleAction.Action, ruleAction.Values),
                                            values: ruleAction.Values,
                                            defaultValue: SalesforceUtils.getDefaultValue(
                                                _.find(attrMetadata.fields, (field) => field.name === apiName)
                                            ),
                                            resetValues: this.getResetFlag(ruleAction.Action, ruleAction.Values)
                                                ? ruleAction.Values
                                                : [],
                                        };
                                        mergeActionResult[apiName] = attributeResult;
                                    }
                                }
                            });
                        }
                    });
                    _.set(product, '_metadata.rules', Object.values(mergeActionResult));
                });
                return _.flatten(_.map(_productList, (r) => _.get(r, '_metadata.rules')));
            })
        );
    }

    /**
     * Process the ABC constraint rule action, given attribute rule and matrix constraints.
     * @param product instance of product object.
     * @returns Observable array of processed attribute rules for the given product.
     */
    @MemoizeAll((items) => {
        return _.join(
            items.map((i) => i.Id),
            '_'
        );
    })
    getCustomAttributeRulesForProducts(productList: Array<string> | Array<Product>): Observable<Array<any>> {
        const subject = new BehaviorSubject<Array<any>>(null);
        if (productList) {
            // First query retrieves list of potential rules that the product list is a member of
            productList = (<any>productList).filter((p) => p != null);
            const product$ = (<Array<any>>productList).every((item: any) => typeof (<any>item) === 'string')
                ? this.productService.get(<Array<string>>productList)
                : of(<Array<Product>>productList);
            product$
                .pipe(
                    mergeMap((_productList: Array<Product>) => {
                        const familyList = _productList.map((p) => _.get(p, 'Family')).filter((r) => r != null);
                        const groupList = _.flatten(_productList.map((p) => _.get(p, 'ProductGroups', [])))
                            .map((group) => _.get(group, 'ProductGroupId'))
                            .filter((x) => x != null);
                        let filters = [];

                        filters = [
                            new AFilter(
                                ProductAttributeRule,
                                [
                                    new ACondition(ProductAttributeRule, 'ProductScope', 'In', ['', 'All']),
                                    new ACondition(
                                        ProductAttributeRule,
                                        'ProductScope',
                                        'Includes',
                                        _productList.map((p) => p.Id)
                                    ),
                                ],
                                null,
                                'OR'
                            ),
                        ];
                        if (_.get(familyList, 'length') > 0) {
                            filters.push(
                                new AFilter(
                                    ProductAttributeRule,
                                    [
                                        new ACondition(ProductAttributeRule, 'ProductFamilyScope', 'In', ['', 'All']),
                                        new ACondition(
                                            ProductAttributeRule,
                                            'ProductFamilyScope',
                                            'Includes',
                                            familyList
                                        ),
                                    ],
                                    null,
                                    'OR'
                                )
                            );
                        }
                        if (_.get(groupList, 'length') > 0) {
                            filters.push(
                                new AFilter(
                                    ProductAttributeRule,
                                    [
                                        new ACondition(ProductAttributeRule, 'ProductGroupScope', 'In', ['', 'All']),
                                        new ACondition(
                                            ProductAttributeRule,
                                            'ProductGroupScope',
                                            'Includes',
                                            groupList
                                        ),
                                    ],
                                    null,
                                    'OR'
                                )
                            );
                        }

                        return zip(
                            this.where(
                                [new ACondition(this.type, 'Active', 'Equal', true)],
                                'AND',
                                filters,
                                null,
                                new APageInfo(50, 1),
                                null
                            ),
                            this.describe(ProductAttributeValue),
                            of(_productList)
                        );
                    }),
                    take(1)
                )
                .subscribe((results) => subject.next(results));
        }

        return subject.pipe(filter((r) => r != null));
    }
}
