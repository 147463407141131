import { EventEmitter, Injectable } from '@angular/core';
import { MonoTypeOperatorFunction, Observable, of } from 'rxjs';
import { catchError, debounceTime, filter, finalize, map, mergeMap, scan, tap, timeout } from 'rxjs/operators';

enum TraceServerity {
    None,
    Info,
    Warn,
    Error,
    Debug,
}
@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    private static logSeverity = TraceServerity.Warn;
    private static DEFAULT_MESSAGE = 'Attendere';
    private static readonly RESET = Symbol('reset');
    private static readonly ADD = Symbol('add');
    private static readonly REMOVE = Symbol('remove');
    private static readonly TOGGLE = Symbol('toggle');
    private static readonly UPDATE = Symbol('update');

    private static loaderStatus = new EventEmitter<{ symbol: string | symbol; message?: string }>();
    private static LOADER_TIMEOUT_SEC = 60;
    private static DEBOUNCE_TIME_MS = 50;

    private static SYMBOL_MAP: { [key in symbol]: number } = {
        [LoadingService.RESET]: Number.NEGATIVE_INFINITY,
        [LoadingService.ADD]: +1,
        [LoadingService.REMOVE]: -1,
        [LoadingService.UPDATE]: 0,
        [LoadingService.TOGGLE]: NaN,
    };

    private static loaderStatusListener = LoadingService.loaderStatus
        .pipe(
            map((msg) => ({
                message: msg.message,
                counter: LoadingService.SYMBOL_MAP[msg.symbol],
            })),
            tap((msg) => LoadingService.conosoleDebug(JSON.stringify(msg))),
            scan((accumulator, newEvent) => {
                const counter = isNaN(newEvent?.counter)
                    ? +!accumulator?.counter
                    : accumulator?.counter + newEvent?.counter;
                const absCounter = Math.max(0, counter);
                if (counter < 0) {
                    LoadingService.conosoleWarn(`### loaderSrv: counter < 0`);
                }
                const outObj = {
                    message: absCounter ? newEvent?.message || accumulator?.message : null,
                    counter: absCounter,
                };
                LoadingService.conosoleDebug(
                    `accumulator.counter  ${accumulator?.counter} -> counter ${outObj.counter} | message ${outObj.message}`
                );
                return outObj;
            }),
            debounceTime(LoadingService.DEBOUNCE_TIME_MS),
            mergeMap((data) =>
                data.counter > 0
                    ? of(data).pipe(timeout(LoadingService.LOADER_TIMEOUT_SEC * 1000 - LoadingService.DEBOUNCE_TIME_MS))
                    : of(data)
            ),
            map(({ message, counter }) => (counter ? LoadingService.formatMessage(message) : null)),
            catchError(() => of(null)),
            filter(() => !!document.getElementById('block-ui-wrapper'))
        )
        .subscribe((message: string) => {
            if (
                message &&
                message !== LoadingService.formatMessage(LoadingService.DEFAULT_MESSAGE) &&
                message !== LoadingService.currentMessage
            )
                LoadingService.conosoleInfo(`Loading message: ${message}`);

            if (message && !LoadingService.isActive) {
                // show
                document.getElementById('block-ui-wrapper').classList.add('active');
                document.getElementById('block-ui-message').textContent = message;
            } else if (message) {
                // update
                document.getElementById('block-ui-message').textContent = message;
            } else {
                // hide
                document.getElementById('block-ui-wrapper').classList.remove('active');
            }
        });

    static get isActive(): boolean {
        return document.getElementById('block-ui-wrapper').classList.contains('active');
    }

    static get currentMessage(): string {
        return document.getElementById('block-ui-message').textContent;
    }

    static loaderOperator<T>(message?: string): MonoTypeOperatorFunction<T> {
        this.show(message);
        return (source: Observable<T>) => source.pipe(finalize(() => this.hide()));
    }

    static hide(): void {
        this.conosoleDebug('calling hide');
        this.loaderStatus.emit({ symbol: this.REMOVE });
    }

    static abort(): void {
        this.conosoleDebug('calling abort');
        this.loaderStatus.emit({ symbol: this.RESET });
    }

    static toggle(): void {
        this.conosoleDebug('calling toggle');
        this.loaderStatus.emit({ symbol: this.TOGGLE });
    }

    static show(msg?: string): void {
        this.conosoleDebug('calling show');
        this.loaderStatus.emit({ symbol: this.ADD, message: msg });
    }

    static update(msg: string): void {
        this.conosoleDebug('calling update');
        this.loaderStatus.emit({ symbol: this.UPDATE, message: msg });
    }

    private static formatMessage(msg?: string): string {
        return `${(msg || this.DEFAULT_MESSAGE).replace(/\.{3}$/, '')}...`;
    }

    private static conosoleDebug(msg?: string): void {
        if (this.logSeverity >= TraceServerity.Debug) {
            console.info(`### ${msg}`);
        }
    }
    private static conosoleInfo(msg?: string): void {
        if (this.logSeverity >= TraceServerity.Info) {
            console.info(`### ${msg}`);
        }
    }

    private static conosoleWarn(msg?: string): void {
        if (this.logSeverity >= TraceServerity.Warn) {
            console.warn(`### ${msg}`);
        }
    }
}
