/* tslint:disable:no-console */
import { Injectable, Injector } from '@angular/core';
import { ContractCode } from '../../../store/models/order-entry-state';
import { Store } from '@ngrx/store';
import { selectPlicoCode } from '../../../store/selectors/order-entry.selectors';
import { Dictionary } from '../../interfaces/dictionary';
import { ApplicationInsights, ICustomProperties } from '@microsoft/applicationinsights-web';
import { selectApplicationLocation } from '../../../store/selectors/app.selectors';
import { take } from 'rxjs/operators';
import { EglState } from '../../../store/reducers';
import { combineLatest, zip } from 'rxjs';
import { selectAgentInfo, selectCurrentVirtualAgent } from '../../../store/selectors/user.selectors';
import { PrivateConfigurationService } from './private-configuration.service';

@Injectable({
    providedIn: 'root',
})
export class AppInsightsService {
    private appInsights: ApplicationInsights;
    private plicoCode: string;
    private cartId: string;
    private disabled = false;
    private staticProperties: any = {};

    constructor(private injector: Injector, private configSrv: PrivateConfigurationService) {
        this.configSrv.loadConfigCompleted.pipe(take(1)).subscribe(() => this.init());
    }

    init(): void {
        const conf = this.configSrv.config;
        this.disabled = this.configSrv.config.disabledAppInsight;
        if (conf && !this.disabled) {
            this.appInsights = new ApplicationInsights({
                config: {
                    appId: 'SalesApp',
                    instrumentationKey: conf.appInsights.instrumentationKey,
                    enableAutoRouteTracking: true, // option to log all route changes,
                    enableRequestHeaderTracking: true,
                    enableResponseHeaderTracking: true,
                    autoTrackPageVisitTime: false, // tracking tempo di  permanenza nella pagina
                },
            });
            this.appInsights.loadAppInsights();
            console.log('appInsights initialized');
            // this.logMetric('app-configuration', loading, { platform });
            // this.logEvent('init session', { domainName });
            this.populateStaticProperties();
        }

        this.store.select(selectPlicoCode).subscribe((contractCode: ContractCode) => {
            this.plicoCode = contractCode ? `_${contractCode.code}` : '';
        });
    }

    setUserId(cartId: string): void {
        if (!this.appInsights || this.disabled) {
            return;
        }
        this.cartId = cartId;
        this.appInsights.clearAuthenticatedUserContext();
        this.appInsights.setAuthenticatedUserContext(this.transactionId);
        this.staticProperties.cartId = cartId;
    }

    logPageView(name: string, url: string, duration?: number): void {
        if (!this.appInsights || this.disabled) {
            return;
        }
        // option to call manually
        this.appInsights.trackPageView({
            name,
            uri: url,
        });
    }

    logEvent(name: string, properties?: { [key: string]: any }): void {
        try {
            if (!this.appInsights || this.disabled) {
                return;
            }
            properties = this.getMergedProperties(properties);
            this.appInsights.trackEvent({ name }, properties);
        } catch (e) {
            console.error('AppInsightsService ERROR', e, false);
        }
    }

    customLogEvent(evtName: string, evtKey: string, evtValue: string | Dictionary<any>): void {
        try {
            if (!this.appInsights || this.disabled) {
                return;
            }
            let evtDict: Dictionary<string | Dictionary<string>> = {};
            evtDict[evtKey] = evtValue;
            evtDict = this.getMergedProperties(evtDict);
            this.appInsights.trackEvent({ name: evtName }, evtDict);
        } catch (e) {
            console.error('AppInsightsService ERROR', e, false);
        }
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }): void {
        try {
            let consoleName = name;
            if (!(consoleName || '').startsWith('Telemetry: ')) {
                consoleName = 'Telemetry: ' + name;
            }
            if (!this.appInsights || this.disabled) {
                console.info(`[EGL]: ${consoleName}: ${average} sec.`);
                return;
            }
            properties = this.getMergedProperties(properties) as ICustomProperties;
            this.appInsights.trackMetric({ name, average: average ? parseFloat(average.toFixed(2)) : 0 }, properties);
            console.info(`[EGL]: ${consoleName}: ${average} sec.`);
        } catch (e) {
            console.error('AppInsightsService ERROR', e, false);
        }
    }

    logTrace(message: string, properties?: { [key: string]: any }): void {
        try {
            if (!this.appInsights || this.disabled) {
                return;
            }
            properties = this.getMergedProperties(properties);
            this.appInsights.trackTrace({ message }, properties);
        } catch (e) {
            console.error('AppInsightsService ERROR', e, false);
        }
    }

    logException(exception: Error, severityLevel?: number): void {
        if (!this.appInsights || this.disabled) {
            return;
        }
        this.appInsights.trackException({ exception, severityLevel });
    }

    get transactionId(): string {
        return `${btoa(this.cartId).substring(0, 15)}_${this.cartId}${this.plicoCode}`;
    }

    private get store(): Store<EglState> {
        return this.injector.get(Store);
    }

    /**
     * @description: Unisce l'oggetto staticProperties e properties se presente, se quest'ultimo manca ritorna solo staticProperties.
     * @param: Properties
     * @return: ritorna l'oggetto properties unito (staticProperties e properties da argomento) o solamente l'oggetto staticProperties
     */
    private getMergedProperties(properties?: any): any {
        if (properties) {
            return { ...this.staticProperties, ...properties };
        }

        return this.staticProperties;
    }

    /**
     * @description: inserisce informazioni utili ai log
     * @return: void
     */
    private populateStaticProperties(): void {
        combineLatest([
            this.store.select(selectApplicationLocation),
            this.store.select(selectAgentInfo),
            this.store.select(selectCurrentVirtualAgent),
        ])
            .pipe(take(1))
            .subscribe(([appLocation, agentInfo, virtualAgent]) => {
                this.staticProperties.appLocation = appLocation;
                this.staticProperties.virtualAgentCode = virtualAgent?.AgentCode;
                this.staticProperties.virtualAgentCoCode = virtualAgent?.CurrentCode;
                this.staticProperties.virtualAgency = virtualAgent?.VirtualAgency?.Code;
                this.staticProperties.login = agentInfo?.DomainName;
            });
    }
}
