import { Component, EventEmitter, Input, OnDestroy, Output, Renderer2 } from '@angular/core';
import { SegoeMDL2Font } from '../../../enums/shared/segoe-mdl2-font';
@Component({
    selector: 'egl-modal-with-footer',
    templateUrl: './egl-modal-with-footer.component.html',
    styleUrls: ['./egl-modal-with-footer.component.scss'],
})
export class EglModalWithFooterComponent implements OnDestroy {
    containerClass: string;
    modalId: string;
    isOpen: boolean;
    @Input() title: string;
    @Input() buttonLabel: string;
    @Input() secondButtonLabel?: string;
    @Input() thirdButtonLabel?: string;
    @Input() closeOnConfirm: boolean;
    @Input() confirmDisabled: boolean;
    @Input() backDisabled?: boolean;
    @Input() hideConfirm = false;
    @Output() onClose = new EventEmitter<void>();
    @Output() onShow = new EventEmitter<void>();
    @Output() onConfirm = new EventEmitter<void>();
    @Output() onDismiss = new EventEmitter<void>();
    @Output() genericEvent = new EventEmitter<void>();
    @Output() onBack = new EventEmitter<void>();
    @Input() path?: string;
    @Input() withCircle?: boolean;
    @Input() isBlue: boolean;
    readonly icons = SegoeMDL2Font;
    selectedValue: any;

    constructor(private renderer: Renderer2) {
        this.isOpen = false;
    }
    ngOnDestroy(): void {
        this.renderer.removeClass(document.body, 'modal-open');
    }

    show(): void {
        this.isOpen = true;
        this.renderer.addClass(document.body, 'modal-open');
        this.onShow.emit();
    }

    hide(): void {
        this.isOpen = false;
        this.renderer.removeClass(document.body, 'modal-open');
        this.onClose.emit();
    }

    confirm(): void {
        if (this.closeOnConfirm) {
            this.hide();
        }
        this.onConfirm.emit();
    }

    toggle(): void {
        if (this.isShown) {
            this.hide();
        } else {
            this.show();
        }
    }

    dismiss(): void {
        this.hide();
        this.onDismiss.emit();
    }

    genericAction(): void {
        this.genericEvent.emit();
    }

    back(): void {
        this.onBack.emit();
    }

    /**
     *
     */
    get isShown(): boolean {
        return this.isOpen;
    }
}
