export class EligibilityCheckRequest {
    useCase: UseCaseType;
    agency: string;
    salesChannel: string;
    operationType: string;
    skills?: [];
    responsibleSkills?: [];
    inStore?: boolean;
    zipCode?: string;
    category?: string;
    warrants?: [];
    commodity?: string;
    combinedSaleInsurance?: boolean;
    cartId?: string;
    isSiebelCustomer: boolean;
    customerCode: string;
    supplyCode?: string;
    unsolved: boolean;
    productCode?: string;
    email: boolean;
    records?: Array<EligibilityCheckRecord>;
}

export enum UseCaseType {
    Cart = 1,
    BulkOrdering = 2,
    DiscountList = 3,
}

export class EligibilityCheckRecord {
    CANALE: string;
    CODICE_CAMPAGNA: string;
    CODICE_CLIENTE: string;
    CODICE_FORNITURA: string;
    CODICE_SCONTO: string;
    NOME_SCONTO: string;
    DATA_INIZIO_VALIDITA_LISTA: string;
    DATA_FINE_VALIDITA_LISTA: string;
}
