import { D365Service } from './common/services/d365/d365.service';
import { LoggerService } from './common/services/shared/logger.service';
import { LoadingService } from './common/services/shared/loading.service';
import { combineLatest } from 'rxjs';
import { catchError, filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { RoutesPaths } from './common/config/routes-paths';
import * as _ from 'lodash';
import { AppInsightsService } from './common/services/shared/app-insights.service';
import { Injector } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PrivateConfigurationService } from './common/services/shared/private-configuration.service';
import { activationCartFromUrl } from './common/functions/misc.functions';
import { TelemetryMetric, TelemetryMetricService } from './common/services/app/telemetry-metric.service';

export function AppInitializer(
    d365Srv: D365Service,
    logger: LoggerService,
    injector: Injector,
    appInsightsSrv: AppInsightsService,
    cnSrv: PrivateConfigurationService,
    telemetrySrv: TelemetryMetricService
) {
    return async (): Promise<boolean | void> => {
        try {
            const globalTelemetry = telemetrySrv.getIstance('first navigation end');
            const router = injector.get(Router);
            const cartId = activationCartFromUrl();
            localStorage.removeItem('account');
            if (cartId) {
                localStorage.setItem('local-cart', cartId);
            }

            if (parent.window !== window) {
                const configTelemetry = telemetrySrv.getIstance('load d365 configurations');
                if (localStorage.getItem('access_token') && location.hash.indexOf('access_token') !== -1) {
                    location.hash = '';
                }
                initializerLoader(router, globalTelemetry);
                return cnSrv.loadConfigCompleted
                    .pipe(
                        take(1),
                        tap(() => console.log('OK loadConfigCompleted')),
                        mergeMap(() =>
                            combineLatest([
                                d365Srv
                                    .requestApplicationLocationAsync()
                                    .pipe(tap(() => console.log('OK requestApplicationLocationAsync'))),
                                d365Srv.getAgentInfoAsync().pipe(tap(() => console.log('OK getAgentInfoAsync'))),
                            ])
                        ),
                        filter((res) => !_.isEmpty(res[0]) && !_.isEmpty(res[1])),
                        map(([appLocation, agentInfo]) => ({
                            appLocation,
                            agentInfo,
                            config: cnSrv.config,
                        })),
                        take(1),
                        tap((res) => {
                            configTelemetry.logElapsed();
                            if (!res.config.consoleLogs && window) {
                                console.log('console logs disabled');
                                Object.keys(window.console).forEach((e) => (window.console[e] = () => {}));
                            }
                        }),
                        mergeMap((res) => {
                            const agentDomainName = res.agentInfo?.DomainName?.toLowerCase();
                            if (res.config?.offline && res.config?.offlineWhitelist?.indexOf(agentDomainName) === -1) {
                                return router.navigate([RoutesPaths.Offline], {
                                    queryParams: { message: res.config?.offlineMessage },
                                });
                            }
                            if (
                                !res.agentInfo?.Agent?.Current ||
                                !res.agentInfo?.UserConfiguration?.LastUsedCustomerSegment
                            ) {
                                return router.navigate([RoutesPaths.SettingsNoBack]);
                            }
                            return Promise.resolve(true);
                        })
                    )
                    .toPromise()
                    .catch((e) => {
                        logger.error(null, 'Error loading configuration', e, false, true);
                    });
            } else {
                const urlHash = location.hash;
                if (urlHash.indexOf('#access_token') !== -1) {
                    LoadingService.update('Apertura App in corso...');
                    logger.warn('Oauth Token / urlHash: ' + urlHash);
                    logger.warn('urlHash: ' + urlHash.slice(1));
                    // const tokenBase64 = 'basetoken' + window.btoa(urlHash);
                    // const crmApplicationUri = 'ms-dynamicsxrm://?id=' + tokenBase64;
                    const crmApplicationUri = 'ms-dynamicsxrm://?' + urlHash.slice(1);
                    logger.warn('crmApplicationUri: ' + crmApplicationUri);
                    location.href = new URL(crmApplicationUri).toString();
                } else {
                    logger.error(null, 'Visualizzazione standalone non supportata', null, true);
                }
                LoadingService.hide();
                return Promise.reject(null);
            }
        } catch (e) {
            logger.error(null, 'Error loading configuration', e, false, true);
            LoadingService.hide();
        }

        function initializerLoader(router: Router, telemetry: TelemetryMetric) {
            logger.info('loading configuration...');
            LoadingService.show('Inizializzazione');

            router.events
                .pipe(
                    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
                    take(1)
                )
                .subscribe(() => {
                    console.log('### navigation end');
                    LoadingService.hide();
                    telemetry.logElapsed();
                });
        }
    };
}
