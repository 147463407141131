/* tslint:disable:variable-name */
import { AField, AObject, ATable } from '@congacommerce/core';
import { AptPartnerships } from '../../../../../modules/common/partnership/enums/apt-partnerships';
import { AptBillType } from '../../../../enums/apttus/apt-bill-type';
import { AptPaymentInstrument } from '../../../../enums/apttus/apt-payment-instrument';
import { AptCommodityTypeOfSale } from '../../../../enums/apttus/apt-commodity-typeof-sale';

@ATable({
    sobjectName: 'Apttus_QPConfig__ProposalProductAttributeValue__c',
})
export class EglQuotePAVLight extends AObject {
    constructor() {
        super();
    }

    @AField({ soql: 'egl_Commodity_Sector__c' })
    egl_Commodity_Sector__c: string;

    @AField({ soql: 'egl_Customer_ATECO_Code__c' })
    egl_Customer_ATECO_Code__c: string;

    @AField({ soql: 'egl_POD__c' })
    egl_POD__c: string;

    @AField({ soql: 'egl_PDR__c' })
    egl_PDR__c: string;

    @AField({ soql: 'egl_Address__c' })
    egl_Address__c: string;

    @AField({ soql: 'egl_Civic_Number__c' })
    egl_Civic_Number__c: string;

    @AField({ soql: 'egl_City__c' })
    egl_City__c: string;

    @AField({ soql: 'egl_Province__c' })
    egl_Province__c: string;

    @AField({ soql: 'egl_Postal_Code__c' })
    egl_Postal_Code__c: string;

    @AField({ soql: 'egl_gas_consumption__c' })
    egl_gas_consumption__c: number;

    @AField({ soql: 'egl_product_address1_prefix__c' })
    egl_product_address1_prefix__c: string;

    @AField({ soql: 'egl_power_consumption__c' })
    egl_power_consumption__c: number;

    @AField({ soql: 'egl_Invoice_Shipping_Method__c' }) // modalita spedizione
    egl_Invoice_Shipping_Method__c: AptBillType = null;

    @AField({ soql: 'egl_Payment_Instrument__c' })
    egl_Payment_Instrument__c: AptPaymentInstrument = null;

    @AField({ soql: 'egl_Hours_Bundle__c' }) // bioraria
    egl_Hours_Bundle__c: string;

    @AField({ soql: 'egl_green_option__c' })
    egl_green_option__c: string;

    @AField({ soql: 'egl_commodity_supplytype__c' })
    egl_commodity_supplytype__c: AptCommodityTypeOfSale;

    @AField({ soql: 'egl_commercial_branch__c' }) // TIPO ABITAZIONE
    egl_commercial_branch__c: string;

    @AField({ soql: 'egl_isresidential__c' }) // residenzial
    egl_isresidential__c: boolean;

    @AField({ soql: 'egl_winback__c' })
    egl_winback__c: 'Sì' | 'No';

    @AField({ soql: 'egl_commodity_distributor__c' }) // Codice distributore
    egl_commodity_distributor__c: string;

    @AField({ soql: 'egl_partnership__c' }) // Partnership
    egl_partnership__c: AptPartnerships;
}
