import { Injectable } from '@angular/core';
import { ApiService } from '../../../../common/services/shared/api.service';
import { CheckIbanResponse } from '../models/check-iban-response';
import { CheckPdfRequest } from '../../../switch-in/order-entry/models/check-pdf-request';
import { CheckPdfResponse, EsitoResponse } from '../../../switch-in/order-entry/models/check-pdf-response';
import { EgonResponse } from '../../../switch-in/order-entry/models/egon-response';
import { UploadIdentityCardRequest } from '../steps/06-firma/models/upload-identity-card.request';
import { UploadIdentityCardResponse } from '../steps/06-firma/models/upload-identity-card.response';
import { OcrInviaResponse } from '../../../switch-in/order-entry/models/ocr-invia-response';
import { OcrFeedbackRequest } from '../../../switch-in/order-entry/models/ocr-feedback-request';
import { OcrFeedbackResponse } from '../../../switch-in/order-entry/models/ocr-feedback-response';
import { Observable, of } from 'rxjs';
import { CreateQuoteEntitiesResponse } from '../models/create-quote-entities-response';
import { UtilityService } from '../../../../common/services/shared/utility.service';
import { LoggerService } from '../../../../common/services/shared/logger.service';
import { BaseApiResponse, StatusResponse } from '../../../../common/interfaces/base-api-response';
import { AptCommodityTypeOfSale } from '../../../../common/enums/apttus/apt-commodity-typeof-sale';
import { PaperCheckResponse } from '../models/paper-check-response';
import { HttpHeaders } from '@angular/common/http';
import { CheckPodPdrResponse } from '../../../switch-in/order-entry/models/check-pod-pdr-response';
import { ApexApi, ApiMngApi, ApttusApi, BaseProvider, EgonApi } from '../../../../common/providers/base-provider';
import { CheckCapRequest } from '../../../switch-in/order-entry/models/check-cap-request';
import { CheckCapResponse } from '../../../switch-in/order-entry/models/check-cap-response';
import { SelfCertificationJsonRequest } from '../steps/06-firma/models/self-certification';
import { PrivateConfigurationService } from '../../../../common/services/shared/private-configuration.service';
import { CheckCartDualResponse } from '../models/cart-dual-response';
import { TermCostsResponse } from '../../../termination/order-entry/models/term-costs-response';
import { ProductCostsRequest } from '../../../termination/order-entry/models/term-costs-request';
import { ProductsAffinityForComuneIstatResponse } from '../../../../common/models/app/products-affinity-for-comune-istat-response';
import {
    CheckPodPdrActivationResponse,
    ActivationResponse,
    ActStatus,
    AddressActResponse,
} from '../../../switch-in/order-entry/models/check-pod-pdr-activation-response';
import { Indirizzo } from '../../../switch-in/order-entry/models/indirizzi';
import { FeatureToggleService } from '../../../../common/services/shared/feature-toggle.service';
import { AptSalesProcess } from '../../../../common/enums/apttus/apt-sales-process';
import { CheckPdfApttusRequest } from '../../../switch-in/order-entry/models/check-pdf-apttus-request';
import {
    CheckPdfApttusResponse,
    CheckPdfApttusResponseListaProcessi,
    CheckPdfApttusResponseResult,
} from '../../../switch-in/order-entry/models/check-pdf-apttus-response';
import { catchError, map, retry } from 'rxjs/operators';
import { CommodityForCombinedSaleResponse } from '../models/check-commodity-response';
import { PodActivationInfo, PdrActivationInfo, PdrInfoLocation } from '../../../../store/models/order-entry-state';
import { RataFissaResponse } from '../models/rata-fissa-response';
import { ScoreCardResponse } from '../../../switch-in/order-entry/models/credit-check-response';

@Injectable({
    providedIn: 'root',
})
export class OrderEntryProvider extends BaseProvider {
    constructor(
        private api: ApiService,
        private utilitySrv: UtilityService,
        private logger: LoggerService,
        protected configSrv: PrivateConfigurationService,
        private toggleService: FeatureToggleService
    ) {
        super(configSrv);
    }

    /**
     * @description Esegue la chiamata al nuovo servizio ScoreCard (cerved/cribis)
     * @param productConfigurationId L'id del carrello
     * @returns Observable<ScoreCardResponse>
     */
    scoreCardCheck(productConfigurationId: string): Observable<ScoreCardResponse> {
        const options = {
            headers: new HttpHeaders({
                'no-loading': 'true',
            }),
        };
        return this.api.postAsync<ScoreCardResponse>(
            this.getApexApiUrl(ApexApi.ScoreCard),
            {
                productConfigurationId,
            },
            0,
            options
        );
    }

    /**
     * @description Esegue la chiamata all'API di APIManagement per la verifica della validità dell'IBAN e dell'Insoluto NDS associato
     * @param ibanVal L'iban da verificare
     * @param subjectType tipologia soggetto se PF o PG
     * @returns Promise<CheckIbanResponse>
     */
    async ibanCheck(
        IBAN: string,
        subjectType: 'PF' | 'PG',
        vatRegistrationNo: string,
        provinceCode: string,
        skipInsoluto: boolean
    ): Promise<CheckIbanResponse> {
        const body = this.toggleService.isDomiciliazioneEnabled
            ? { IBAN, subjectType, vatRegistrationNo, provinceCode }
            : { IBAN, skipInsoluto };
        return this.api.postAsync<CheckIbanResponse>(this.getApiMngApiUrl(ApiMngApi.CheckIban), body).toPromise();
    }

    async ocrInvia(files: File[]): Promise<OcrInviaResponse> {
        const formData = new FormData();
        if (files) {
            files.forEach((x) => {
                formData.append('files', x);
            });
        }

        return this.api
            .postAsync<OcrInviaResponse>(this.getApiMngApiUrl(ApiMngApi.OcrInvia), formData)
            .toPromise()
            .catch(() => {
                return null;
            });
    }

    async ocrFeedback(req: OcrFeedbackRequest): Promise<OcrFeedbackResponse> {
        return this.api
            .postAsync<OcrFeedbackResponse>(this.getApiMngApiUrl(ApiMngApi.OcrFeedback), req)
            .toPromise()
            .catch(() => {
                return null;
            });
    }

    async suggestStreet(street: string, onlyIta: boolean): Promise<EgonResponse[]> {
        const params: any = {};
        params.app_key = this.egonAppKey;
        params.query = street;

        if (onlyIta) {
            params.restrict_level = 'country';
            params.restrict_id = '38000000001'; // egon id to country = ITA
        }
        return this.api
            .getAsync<EgonResponse[]>(this.getEgonApiUrl(EgonApi.SuggestStreet), null, params)
            .toPromise()
            .catch(() => {
                return null;
            });
    }

    async suggestCity(city: string, onlyIta: boolean): Promise<EgonResponse[]> {
        const params: any = {};
        params.app_key = this.egonAppKey;
        params.query = city;

        if (onlyIta) {
            params.restrict_level = 'country';
            params.restrict_id = '38000000001'; // egon id to country = ITA
        }
        return this.api
            .getAsync<EgonResponse[]>(this.getEgonApiUrl(EgonApi.SuggestCity), null, params)
            .toPromise()
            .catch(() => {
                return null;
            });
    }

    async uploadIdentityCardOrAttach(req: UploadIdentityCardRequest): Promise<UploadIdentityCardResponse> {
        if (!req && !req.NumeroPlico) {
            const res = new UploadIdentityCardResponse();
            res.status = StatusResponse.Failed;
            return res;
        }
        const jBody = {
            FileNameUnico: '',
            FileNameFronte: '',
            FileNameRetro: '',
        };
        const formData = new FormData();
        if (req.Fronte && req.Retro) {
            const tmpFronte = this.utilitySrv.renameFile(req.Fronte, `${req.NumeroPlico}_F`, true);
            const tmpRetro = this.utilitySrv.renameFile(req.Retro, `${req.NumeroPlico}_R`, true);
            formData.append('Fronte', tmpFronte);
            formData.append('Retro', tmpRetro);
            jBody.FileNameFronte = tmpFronte.name;
            jBody.FileNameRetro = tmpRetro.name;
        } else {
            const tmpFile = this.utilitySrv.renameFile(req.Unico, `${req.NumeroPlico}_CI`, true);
            formData.append('Unico', tmpFile);
            jBody.FileNameUnico = tmpFile.name;
        }
        formData.append('CartID', req.CartID);
        formData.append('jsonBody', JSON.stringify(jBody));
        return this.api
            .postAsync<UploadIdentityCardResponse>(this.getApiMngApiUrl(ApiMngApi.UploadIdentityCard), formData)
            .toPromise()
            .catch(() => null);
    }

    /**
     * @description: invoca ApiManagement per il check sul Punto di Fornitura
     * @param tipoFornitura: 'Power' | 'Gas'
     * @param podPdr: numero di POD o PDR
     * @param isWinback: boolean
     * @return: {
     *      isOk: boolean       // indica l'esito del check
     *      message?: string    // in caso di esito KO, riporta il messaggio di errore
     *  }
     */
    async checkPdf(tipoFornitura: string, podPdr: string, isWinback: boolean): Promise<CheckPdf> {
        const result: CheckPdf = {
            isOk: undefined,
        };

        if (isWinback) {
            // skip in caso di winback
            result.isOk = true;
            return result;
        }
        const req: CheckPdfRequest = {
            codicePdrPod: podPdr.toUpperCase(),
            tipoFornitura: tipoFornitura,
        };
        const res = await this.api
            .postAsync<CheckPdfResponse>(this.getApiMngApiUrl(ApiMngApi.CheckPdf), req)
            .toPromise();
        if (res?.status === StatusResponse.Success && res?.response?.esito === EsitoResponse.OK) {
            result.isOk = true;
        } else {
            const type = tipoFornitura === AptCommodityTypeOfSale.Power ? 'POD' : 'PDR';
            this.logger.warn(`ATTENZIONE! - Il ${type} inserito non risulta disponibile`);

            result.isOk = false;
            result.modalitaOperativa = res?.response?.modalitaOperativa;
            result.descrizioneEsito = res?.response?.descrizioneEsito;
        }

        return result;
    }

    /**
     * @description: invoca Apttus per il check sulla Matrice di Prevalenza
     * @param salesProcess: salesProcess
     * @param podPdr: numero di POD o PDR
     * @param assetId?: assetId (opzionale)
     * @return: {
     *      isOk: boolean       // indica l'esito del check
     *      message?: string    // in caso di esito KO, riporta il messaggio di errore
     *      ListaProcessi?: CheckPdfApttusResponseListaProcessi     // lista dei processi associati ad un Pod/Pdr (es: SWIN e Cessazione)
     *  }
     */
    async checkPdfApttus(salesProcess: AptSalesProcess, podPdr: string, assetId?: string): Promise<CheckPdfApttus> {
        const result: CheckPdfApttus = {
            isOk: undefined,
        };
        const req = new CheckPdfApttusRequest(podPdr.toUpperCase(), salesProcess);
        const res = await this.api
            .postAsync<CheckPdfApttusResponse>(this.getApexApiUrl(ApexApi.CheckAsset), req)
            .toPromise();
        if (res?.Result === CheckPdfApttusResponseResult.Success) {
            result.isOk = true;
            result.ListaProcessi = res.ListaProcessi;
        } else {
            result.isOk = false;
            result.descrizioneEsito = res?.ErrorMessage;
            result.ListaProcessi = res.ListaProcessi;
        }
        return result;
    }

    /**
     * @description: invoca Apttus per il check sulla vendibilità dei prodotti nel carrello in base al CAP inserito
     * @param cartId: Id del carrello
     * @param zipCode: cap di fornitura da verificare
     * @return: {
     *      result: "ok" | "ko"
     *  }
     */
    async checkCap(cartId: string, zipCode: string): Promise<CheckCapResponse> {
        const req: CheckCapRequest = {
            cartId: cartId,
            zipCode: zipCode,
        };
        return await this.api
            .postAsync<CheckCapResponse>(this.getApttusApiUrl(ApttusApi.CheckCap), req)
            .toPromise()
            .catch(() => ({
                Status: '001',
                Result: EsitoResponse.OK,
                Error: null,
            }));
    }

    /**
     * @description: invoca una APEX API che fa upsert di Account, Contact e AccountLocation
     * @param productconfigurationid: ID del cart
     * @return:  Observable<CreateQuoteEntitiesResponse>
     */
    createQuoteEntities(productconfigurationid: string): Observable<CreateQuoteEntitiesResponse> {
        const body = { productconfigurationid };
        const options = {
            headers: new HttpHeaders({
                'no-loading': 'true',
            }),
        };
        return this.api
            .postAsync<CreateQuoteEntitiesResponse>(
                this.getApexApiUrl(ApexApi.CreateQuoteEntities),
                body,
                undefined,
                options
            )
            .pipe(
                map((r) => {
                    if (r.ErrorMessage) {
                        this.logger.error(r.ErrorMessage);
                    }
                    return r;
                })
            );
    }

    /**
     * @description: verifica se la modalità di firma cartacea è compatibile con il carrello corrente
     * @param cartId: ID del cart
     * @return: PaperCheckResponse
     */
    paperCheck(productconfigurationid: string): Observable<PaperCheckResponse> {
        return this.api.getAsync<PaperCheckResponse>(this.getApexApiUrl(ApexApi.PaperCheck), null, {
            productconfigurationid,
        });
    }

    /**
     * @description Controlla la validità del codice POD/PDR fornito
     * @param code Il codice POD/PDR da controllare
     * @param codeType Il tipo del codice fornito (PDR => GAS \ POD => Power)
     * @param codiceAgente Codice dell'agente
     * @param quoteId (Opzionale) Id della quote
     * @returns
     */
    checkPodPdr(
        code: string,
        codeType: 'PDR' | 'POD',
        codiceAgente: string,
        quoteId?: string
    ): Promise<CheckPodPdrResponse> {
        const request = {
            source: 'SALESUP',
            codiceAgente,
            quoteId,
        };
        request[codeType] = code;
        const codeApi = `Check${codeType}`;
        return this.api.postAsync<CheckPodPdrResponse>(this.getApiMngApiUrl(ApiMngApi[codeApi]), request).toPromise();
    }

    /**
     * @description Controlla la validità del codice POD/PDR fornito per Attivazione
     * @param code Il codice POD/PDR da controllare
     * @param codeType Il tipo del codice fornito (PDR => GAS \ POD => Power)
     * @param codiceAgente Codice dell'agente
     * @param quoteId (Opzionale) Id della quote
     * @returns
     */
    checkPodPdrActivation(
        input: string | Indirizzo,
        codeType?: 'PDR' | 'POD' | 'MatrContatRich',
        codiceAgente?: string
    ): Observable<{
        status: ActStatus;
        power: PodActivationInfo;
        gas: PdrActivationInfo & { pdrFromMeter: string };
        addressMeters?: PdrInfoLocation[];
    }> {
        const request = {
            source: 'SALESUP',
            codiceAgente,
            ...(typeof input === 'object'
                ? {
                      PrIndForn: input?.prov,
                      SiglaTopIndForn: input?.toponomastica,
                      CapIndForn: input?.cap,
                      IndForn: input?.via,
                      CivicoForn: input?.civico,
                      IstatComuneIndForn: `${input?.codiceIstatProvincia}${input?.codiceIstatComune}`,
                  }
                : {}),
        };
        const API_MAP: { [key in 'PDR' | 'POD' | 'MatrContatRich' | 'Address']: string } = {
            PDR: 'CheckPDRActivation',
            POD: 'CheckPODActivation',
            MatrContatRich: 'CheckPDRActivation',
            Address: 'CheckPDRActivation',
        };
        if (codeType && typeof input === 'string') {
            request[codeType] = input;
        }

        const codeApi = API_MAP[codeType || 'Address'];
        return this.api
            .postAsync<CheckPodPdrActivationResponse>(this.getApiMngApiUrl(ApiMngApi[codeApi]), request)
            .pipe(
                map(this.mapCheckPodPdrActivationStatus),
                map(({ status, errorManagement, response }) => {
                    if (status !== StatusResponse.Success) {
                        throw new Error(errorManagement?.errorDescription);
                    }
                    return response;
                }),
                // Temporary fix GAS
                map(({ Stato, ...data }) => ({
                    ...data,
                    Stato: /inesistente/i.test(data?.interrogaPDRResponse?.EsitoRichiesta?.DesErrore)
                        ? ActStatus.Inesistente
                        : Stato,
                })),
                // PDR not found for given address
                map(({ Stato, interrogaIndirizzoResponse, ...data }) => ({
                    ...data,
                    interrogaIndirizzoResponse,
                    Stato:
                        (codeType || 'Address') === 'Address' && !interrogaIndirizzoResponse?.DatiPDR?.length
                            ? ActStatus.ErroreIndirizzo
                            : Stato,
                })),
                map(({ Stato, interrogaPODResponse, ...otherResponse }) => ({
                    status: Stato,
                    power: this.mapPodActivationInfo({
                        ...(interrogaPODResponse?.EsitoRichiesta?.DatiTecnici?.POD || {}),
                        ...(interrogaPODResponse?.EsitoRichiesta?.Fornitura || {}),
                    }),
                    gas: this.mapPdrActivationInfo(otherResponse),
                    addressMeters: this.mapAddressMeters(otherResponse),
                })),
                retry(2)
            );
    }

    private mapAddressMeters({ interrogaIndirizzoResponse }: { interrogaIndirizzoResponse?: AddressActResponse }) {
        return interrogaIndirizzoResponse?.DatiPDR;
    }

    private mapPodActivationInfo({
        TENSIONE,
        ULTIMA_POT_DISP,
        CAP,
        CIV,
        LOCALITA,
        INTERNO,
        ISTAT,
        PIANO,
        PROV,
        SCALA,
        TOPONIMO,
        UbicazioneFornituraPod,
        VIA,
    }: {
        [key: string]: string;
    }) {
        return {
            tensione: TENSIONE,
            ultimaPotDisp: ULTIMA_POT_DISP,
            fornitura: {
                cap: CAP,
                civ: CIV,
                interno: INTERNO,
                istat: ISTAT,
                localita: LOCALITA,
                piano: PIANO,
                prov: PROV,
                scala: SCALA,
                toponimo: TOPONIMO,
                ubicazioneFornituraPod: UbicazioneFornituraPod,
                via: VIA,
            },
        };
    }

    /**
     * @description Esegue la mappatura della response CheckPodPdrActivationResponse verso la struttura dati usata dal frontend
     * @param rawData La response dell'API con i dati grezzi
     * @returns PdrActivationInfo
     */
    private mapPdrActivationInfo(response: Partial<ActivationResponse>): PdrActivationInfo & { pdrFromMeter: string } {
        const getFieldValue = (parentField: string, fieldName: string): any =>
            ['interrogaPDRResponse', 'interrogaMisuratoreResponse', 'interrogaIndirizzoResponse'].reduce(
                (result: any, container) =>
                    result ||
                    ([]
                        .concat(((response || {})[container] || {})[parentField])
                        .find((parent) => (parent || {})[fieldName]) || {})[fieldName],
                null
            );

        return {
            pdrFromMeter: getFieldValue('DatiPDR', 'PDRDistrEsito'),
            meterNumber: getFieldValue('DatiMisuratore', 'MatrContEsito') || '',
            meterClass: getFieldValue('DatiMisuratore', 'ClasseContEsito') || 'G4',
            vendorCode: getFieldValue('DatiMessaggio', 'CodDistr'),
            potentiality: getFieldValue('DatiPDR', 'PotenzialitaPdrEsito') || '',
            fornitura: response?.interrogaPDRResponse?.DatiPDR || new PdrInfoLocation(),
        };
    }

    private mapCheckPodPdrActivationStatus(res: CheckPodPdrActivationResponse): CheckPodPdrActivationResponse {
        const ERROR_MAP = {
            '009': ActStatus.Attivo,
        };
        return {
            ...(res || ({} as CheckPodPdrActivationResponse)),
            response: {
                ...(res?.response || ({} as ActivationResponse)),
                Stato: Object.values(ActStatus).includes(res?.response?.Stato)
                    ? res?.response?.Stato
                    : ERROR_MAP[res?.response?.interrogaPDRResponse?.EsitoRichiesta?.CodErrore] || ActStatus.Errore,
            },
        };
    }

    /**
     * @description Carica il file dell'autocertificazione
     * @param req Corpo della richiesta (contiene il tipo di autocertificazione, il codice plico e il quote id)
     * @param file Il file da caricare
     * @returns Promise<BaseApiResponse>
     */
    async uploadSelfCertification(req: SelfCertificationJsonRequest, file: File): Promise<BaseApiResponse> {
        if (!req.CodicePlico || !file) {
            const res = new BaseApiResponse();
            res.status = StatusResponse.Failed;
            res.errorManagement = { errorDescription: 'Codice plico o file mancanti', errorCode: 'SUP001' };
            return res;
        }
        const formData = new FormData();
        const tmpFile = this.utilitySrv.renameFile(file, `${req.CodicePlico}_AC`, true);
        formData.append('fileToUpload', tmpFile);
        formData.append('jsonBody', JSON.stringify(req));
        return this.api
            .postAsync<UploadIdentityCardResponse>(this.getApiMngApiUrl(ApiMngApi.UploadSelfCertification), formData)
            .toPromise()
            .catch(() => null);
    }

    /**
     * @description Interroga apttus per verificare se la composizione del carrello è dual
     * @param cartId id del carrello
     * @returns Promise<CheckCartDualResponse>
     */
    async checkCartDual(cartId: string): Promise<CheckCartDualResponse | null> {
        return await this.api
            .postAsync<CheckCartDualResponse>(this.getApexApiUrl(ApexApi.CheckCartDual), { cartId: cartId }, undefined)
            .pipe(
                catchError(() => of(null)),
                map((x: CheckCartDualResponse) => x)
            )
            .toPromise();
    }

    /**
     * @description Recupera i costi di cessazione da Neta
     * @param termReq object con i parametri della request
     * @returns Promise<TermCostsResponse>
     */
    productCostsRetrieve(termReq: ProductCostsRequest): Observable<TermCostsResponse> {
        return this.api.postAsync<TermCostsResponse>(this.getApiMngApiUrl(ApiMngApi.TerminationCostsRetrieve), termReq);
    }

    /**
     * @description Recupera le commodity associate ad una quote di una vendita abbinata
     * @param insuranceProductCode product code della polizza
     * @param customerFiscalCode codice fiscale del cliente
     * @param commodityQuoteId id della quote
     * @param commodityType categoria della commodity
     * @returns Promise<CommodityForCombinedSaleResponse>
     */
    getCommodityForCombinedSale(
        insuranceProductCode: string,
        customerFiscalCode?: string,
        commodityQuoteId?: string,
        commodityType?: string
    ): Observable<CommodityForCombinedSaleResponse> {
        return this.api.postAsync<CommodityForCombinedSaleResponse>(
            this.getApexApiUrl(ApexApi.CommodityForCombinedSale),
            {
                insuranceProductCode: insuranceProductCode,
                customerFiscalCode: customerFiscalCode,
                commodityQuoteId: commodityQuoteId,
                commodityType: commodityType,
            },
            undefined
        );
    }
    /**
     * @description Effettua il controllo del codice coupon e lo applica al carrello
     * @param productConfigurationId id del carrello
     * @param couponCode codice coupon
     * @returns Promise<BaseApiResponse>
     */
    async checkCoupon(productConfigurationId: string, couponCode: string): Promise<BaseApiResponse> {
        return await this.api
            .postAsync<BaseApiResponse>(this.getApiMngApiUrl(ApiMngApi.CheckCoupon), {
                productConfigurationId,
                couponCode,
            })
            .pipe(
                map((response: any) => {
                    if (response.error) {
                        return { ...response.error };
                    } else {
                        return { ...response };
                    }
                })
            )
            .toPromise();
    }

    checkComuneAffinityByIstat = (codiceIstat: string): Observable<ProductsAffinityForComuneIstatResponse> => {
        return this.api.postAsync<ProductsAffinityForComuneIstatResponse>(
            this.getApiMngApiUrl(ApiMngApi.ComuneAffinityByIstat),
            { codiceIstat }
        );
    };

    /**
     * @description: invoca Apttus per calcolare la data di esecuzione del CP di un prodotto RATA FISSA
     * @param cartId: Id del carrello
     * @return:
     */
    async calculateRataFissa(cartId: string): Promise<RataFissaResponse> {
        const req = {
            cartId: cartId,
        };
        let res = new RataFissaResponse();
        await this.api
            .postAsync<RataFissaResponse>(this.getApexApiUrl(ApexApi.CheckRataFissa), req)
            .toPromise()
            .then((r) => (res = { ...r }));

        return res;
    }
}

class CheckPdf {
    isOk: boolean;
    modalitaOperativa?: string;
    descrizioneEsito?: string;
}

class CheckPdfApttus {
    isOk: boolean;
    ListaProcessi?: CheckPdfApttusResponseListaProcessi[];
    descrizioneEsito?: string;
}
