<br /><br />
<h3>ConvertCartToQuote V2</h3>
<hr />
<!-- <input type="checkbox" id="isV1" name="isV1" [(ngModel)]="cart" /> -->
<!-- <label for="isV1">&nbsp;Call v1</label> -->

<textarea
    rows="4"
    cols="80"
    [(ngModel)]="textareaState"
    placeholder="Put here your salesup state OR your cartId in the following format -> '#cart_id' (eg: #a4C3O00000060aGUAQ)"
></textarea>

<br /><br />
<input (click)="mapRequest()" type="submit" value="Dispatch, Create Req" />
<input (click)="createQuote()" type="submit" value="Create Quote" />
