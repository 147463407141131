import { NgModule } from '@angular/core';
import { DisableControlDirective } from './disable-control';
import { DragDropDirective } from './drag-drop.directive';
import { PreventCopyPasteDirective } from './prevent-copy-paste.directive';
import { LowercaseControlDirective } from './lowercase-control.directive';
import { UppercaseControlDirective } from './uppercase-control.directive';
import { NgInitDirective } from './ng-init.directive';
import { NumbersOnlyDirective } from './numbers-only.directive';
import { SafeHtml } from './safehtml.pipe';
import { CnfInputDirective } from './cnf-input/cnf-input.directive';

@NgModule({
    exports: [
        NgInitDirective,
        UppercaseControlDirective,
        DragDropDirective,
        LowercaseControlDirective,
        PreventCopyPasteDirective,
        SafeHtml,
        DisableControlDirective,
        NumbersOnlyDirective,
        CnfInputDirective,
    ],
    declarations: [
        NgInitDirective,
        UppercaseControlDirective,
        DragDropDirective,
        LowercaseControlDirective,
        PreventCopyPasteDirective,
        SafeHtml,
        DisableControlDirective,
        NumbersOnlyDirective,
        CnfInputDirective,
    ],
})
export class DirectiveModule {}
