export enum AptOperationType {
    'DOI',
    'CHECKDOI',
    'PRECHECK',
    'CANCELLA',
    'RIFIUTA',
    'DA_VALIDARE_BO',
    'FROM_DOI_TO_VO',
    'CHECK',
    'COMPLETAMENTO_SENZA_FIRMA',
    'FROM_CHECKCALL_TO_CHECKDOI',
    'FROM_CHECKDOI_TO_CHECKCALL',
}
