/**
 * @author Marco Ricupero ft Andreea Stegariu, Felice Lombardi
 * @version 1.6
 */
import { OrderEntryPathConfiguration, RailwayFn } from './dragon-router.type';
import { RoutesPaths } from '../../../config/routes-paths';
import { Regex } from '../../../config/regex';
import { CartToQuoteService } from '../../apttus/cart-to-quote/cart-to-quote.service';
import { map } from 'rxjs/operators';
import { FlowType } from '../../../../store/models/flow-type';
import { ACTIVATION_FLOW_TYPES, TERMINATION_FLOW_TYPES } from '../../../map/mapping.config';

const CART_PAGE_MATCHER = /^\/?carts\/active/;

const dragonSaveQuoteV2: RailwayFn = ({ injector }) =>
    injector
        .get(CartToQuoteService)
        .saveQuoteV2_Wrapper()
        .pipe(
            map((quote) => ({
                qId: quote.Id,
                qName: quote.Name,
            }))
        );

/**
 * Definire le configurazioni in base ai flow types
 * è possibile configurare sequenze parziali
 * L'elenco di configurazioni ha una priorità dall'alto in basso, mantenere in coda le configurazioni generiche
 * Il primo elemento della railway NON è una destinazione ma solo un match per andare alla rotta successiva
 * Si consiglia di utilizzare espressioni regolari per matchare entry point complessi o come primo record della railway
 */
export const ORDER_ENTRY_PATHS: OrderEntryPathConfiguration[] = [
    {
        flowTypes: TERMINATION_FLOW_TYPES,
        railway: [
            CART_PAGE_MATCHER,
            RoutesPaths.TerminationCosts,
            RoutesPaths.TerminationContacts,
            RoutesPaths.TerminationMortisCausa,
            RoutesPaths.TerminationDate,
            RoutesPaths.TerminationAppointment,
            RoutesPaths.TerminationRecap,
            dragonSaveQuoteV2,
            RoutesPaths.ThankYouPage,
        ],
    },
    {
        flowTypes: ACTIVATION_FLOW_TYPES,
        railway: [
            CART_PAGE_MATCHER,
            RoutesPaths.TroubleShootingAttivazione,
            RoutesPaths.DatiGenerici,
            RoutesPaths.DatiContatto,
            RoutesPaths.CodiceAteco,
            RoutesPaths.Indirizzi,
            RoutesPaths.DatiPod,
            RoutesPaths.ConsumoLuce,
            RoutesPaths.DatiPdrAttivazione,
            RoutesPaths.ConsumoGas,
            RoutesPaths.CostiAttivazione,
            RoutesPaths.DatiPagamento,
            RoutesPaths.AttivazioneTipologiaImmobile,
            RoutesPaths.DecretoCasa,
            RoutesPaths.InserimentoManualeDocumento,
            RoutesPaths.AttivazioneDataAppuntamento,
            RoutesPaths.AttivazioneDatiAppuntamento,
            RoutesPaths.Riepilogo,
            RoutesPaths.SceltaFirma,
            RoutesPaths.PrivacyDatiPersonali,
            RoutesPaths.FirmaCartacea,
            RoutesPaths.FirmaDoiFull,
            RoutesPaths.FirmaVocalOrder,
            dragonSaveQuoteV2,
            RoutesPaths.FirmaPreviaStampa,
            RoutesPaths.ThankYouPage,
        ],
    },
    // {
    //     flowTypes: [FlowType.SwitchIn],
    //     railway: [
    //         CART_PAGE_MATCHER,
    //         RoutesPaths.ModalitaAcquisizione,
    //         RoutesPaths.DatiGenerici,
    //         RoutesPaths.PsFastwebPersonalData,
    //         RoutesPaths.PsFastwebActiveContracts,
    //         RoutesPaths.DatiContatto,
    //         RoutesPaths.Indirizzi,
    //         RoutesPaths.CodiceAteco,
    //         RoutesPaths.PotenzaImpegnata,
    //         RoutesPaths.ConsumoLuce,
    //         RoutesPaths.DatiPod,
    //         RoutesPaths.ConsumoGas,
    //         RoutesPaths.DatiPdr,
    //         RoutesPaths.DatiPagamento,
    //         RoutesPaths.Riepilogo,
    //         RoutesPaths.SceltaFirma,
    //         RoutesPaths.UploadDocumento,
    //         RoutesPaths.TipoEsecuzione,
    //         RoutesPaths.PrivacyDatiPersonali,
    //         RoutesPaths.FirmaCartacea,
    //         RoutesPaths.FirmaVocalOrder,
    //         RoutesPaths.FirmaDoiFull,
    //         RoutesPaths.SceltaContatto,
    //         async ({ injector }) => {
    //             const quote = await injector.get(CartToQuoteService).saveQuoteV2_Wrapper('v2');
    //             if (!quote) {
    //                 throw new Error();
    //             }
    //             return {
    //                 qId: quote.Id,
    //                 qName: quote.Name,
    //             };
    //         },
    //         RoutesPaths.ThankYouPage,
    //     ],
    // },
    {
        flowTypes: [FlowType.SwitchInAmmVip],
        railway: [
            CART_PAGE_MATCHER,
            RoutesPaths.ModalitaAcquisizione,
            RoutesPaths.DatiGenerici,
            RoutesPaths.PsFastwebPersonalData,
            RoutesPaths.PsFastwebActiveContracts,
            RoutesPaths.DatiContatto,
            RoutesPaths.Indirizzi,
            RoutesPaths.CodiceAteco,
            RoutesPaths.PotenzaImpegnata,
            RoutesPaths.ConsumoLuce,
            RoutesPaths.DatiPod,
            RoutesPaths.ConsumoGas,
            RoutesPaths.DatiPdr,
            RoutesPaths.DatiTecniciAmministrativi,
            RoutesPaths.DatiPagamento,
            RoutesPaths.Riepilogo,
            RoutesPaths.SceltaFirma,
            RoutesPaths.TipoEsecuzione,
            RoutesPaths.PrivacyDatiPersonali,
            RoutesPaths.FirmaCartacea,
            RoutesPaths.FirmaDoiFull,
            RoutesPaths.SceltaContatto,
            dragonSaveQuoteV2,
            RoutesPaths.ThankYouPage,
        ],
    },
    {
        flowTypes: [
            FlowType.SwitchInAmmRecupero2A,
            FlowType.SwitchInAmmRecupero2B,
            FlowType.SwitchInAmmRecupero2C,
            FlowType.SwitchInAmmDelibera153,
        ],
        railway: [
            CART_PAGE_MATCHER,
            RoutesPaths.DatiGenerici,
            RoutesPaths.PsFastwebPersonalData,
            RoutesPaths.PsFastwebActiveContracts,
            RoutesPaths.DatiContatto,
            RoutesPaths.Indirizzi,
            RoutesPaths.CodiceAteco,
            RoutesPaths.PotenzaImpegnata,
            RoutesPaths.ConsumoLuce,
            RoutesPaths.DatiPod,
            RoutesPaths.ConsumoGas,
            RoutesPaths.DatiPdr,
            RoutesPaths.DatiTecniciAmministrativi,
            RoutesPaths.DatiPagamento,
            RoutesPaths.Riepilogo,
            RoutesPaths.SceltaFirma,
            RoutesPaths.TipoEsecuzione,
            RoutesPaths.PrivacyDatiPersonali,
            dragonSaveQuoteV2,
            RoutesPaths.ThankYouPage,
        ],
    },

    // Mantenere questa configurazione sempre alla fine
    {
        flowTypes: [FlowType.DomiciliazioneModifica, FlowType.DomiciliazioneRevoca, FlowType.DomiciliazioneAttivazione],
        railway: [
            Regex.domiciliationInitRoute,
            RoutesPaths.DomiciliationResume,
            dragonSaveQuoteV2,
            RoutesPaths.ThankYouPage,
        ],
    },
    {
        flowTypes: [FlowType.DomiciliazioneAttivazioneInserimentoIban, FlowType.DomiciliazioneModificaInserimentoIban],
        railway: [
            Regex.domiciliationInitRoute,
            RoutesPaths.DomiciliationResume,
            RoutesPaths.SceltaFirma,
            RoutesPaths.PrivacyDatiPersonali,
            RoutesPaths.FirmaCartacea,
            RoutesPaths.FirmaDoiFull,
            RoutesPaths.FirmaVocalOrder,
            dragonSaveQuoteV2,
            RoutesPaths.FirmaPreviaStampa,
            RoutesPaths.ThankYouPage,
        ],
    },
    {
        // Fallback processi non ancora migrati al dragon-router per navigazione dati-contatto > indirizzi
        flowTypes: [...Object.values(FlowType)],
        railway: [RoutesPaths.DatiContatto, RoutesPaths.Indirizzi],
    },
    {
        // Fallback processi non ancora migrati al dragon-router per navigazione riepilogo > scelta-firma
        flowTypes: [...Object.values(FlowType)],
        railway: [RoutesPaths.Riepilogo, RoutesPaths.SceltaFirma],
    },
];
