import { AvaConfiguration } from './ava-configurations';
import versionInfo from '../../version-info.json';

export const environment: AvaConfiguration = {
    appVersion: `${versionInfo.version}.pre-prod4`,
    buildNumber: versionInfo.buildNumber,
    production: false,
    defaultImageSrc: './assets/images/default.png',
    defaultCountry: 'IT',
    defaultLanguage: 'it',
    enableErrorLogging: false,
    enableErrorReporting: false,
    enableMultiCurrency: false,
    enableQueryLogs: true,
    enablePerformanceLogs: true,
    defaultCurrency: 'EUR',
    bufferTime: 10,
    maxBufferSize: 5,
    disableBuffer: false,
    subqueryLimit: 10,
    disableCache: false,
    encryptResponse: false,
    cartRetryLimit: 3,
    productIdentifier: 'Id',
    type: 'Salesforce',
    debounceTime: 1000,
    proxy: 'https://apttus-proxy.herokuapp.com',
    useIndexedDB: true,
    expandDepth: 8,
    skipPricing: true,
    skipRules: false,
    pricingMode: 'turbo',
    packageNamespace: 'Apttus_WebStore',
    storefront: 'EGL',
    organizationId: '00D5r0000008jIZ', // preprod 4
    endpoint: 'https://preprod4-egl-apttus.CS160.force.com', // preprod 4
    auth: {
        clientId: 'bf25457c-f850-4a09-96cf-a443f5f3e60d',
        tenantId: 'c16e514b-893e-4a01-9a30-b8fef514a650',
        redirectUri: 'https://salesapp-evq4.enigaseluce.com',
    },
};
