import { AptOperationType } from '../../enums/apttus/apt-operation-type';

export class ApttusDoOperationRequest {
    Quote: Quote;
    constructor(id: string, type: AptOperationType | string, params?: any) {
        this.Quote = new Quote(id, type, params);
    }
}

export class ApttusDoOperationResponse {
    ErrorMessage: string;
    Result: '001' | '002';
    Status: string;
}

class Quote {
    QuoteId: string;
    Operation: AptOperationType | string;
    Body?: any;
    constructor(id: string, type: AptOperationType | string, body?: any) {
        this.QuoteId = id;
        this.Operation = type;
        this.Body = body;
    }
}
