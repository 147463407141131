import { Injectable } from '@angular/core';
import { AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { Observable, of, timer } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { D365SignatureType } from './enums/d365/d365-signature-type';
import { flowTypeToD365OperationMode } from './functions/remap.functions';
import { BaseD365Response } from './interfaces/base-d365-response';
import { CommonProvider } from './providers/common-provider';
import { D365Service } from './services/d365/d365.service';
import { FlowType } from '../store/models/flow-type';

@Injectable()
export class AsyncFormValidators {
    constructor(private commonPrv: CommonProvider, private d365Service: D365Service) {}

    /**
     * @description Controlla se il dominio della mail o l'intera mail inserita nel control è presente nella blacklist effettuando un check verso Apttus
     * @returns null se il valore non è in blacklist o se il control non ha una mail scritta dentro. In caso di KO fa ritornare { isBlacklisted: true }
     */
    mailDomainBlackListed(): (control: AbstractControl) => Observable<{ [key: string]: any } | null> {
        return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
            const mail: string = control.value;
            return timer(700).pipe(
                switchMap(() => {
                    if (!mail) {
                        return of(null);
                    }
                    return this.commonPrv.isEmailBlacklisted(mail).pipe(
                        map((res) => {
                            if (res?.Status === 'Success') {
                                return null;
                            }
                            return { isBlacklisted: true };
                        })
                    );
                })
            );
        };
    }

    plicoCodeValidator(signatureType: D365SignatureType, vaChannelCode: string, flowType: FlowType): AsyncValidatorFn {
        return async (control: AbstractControl): Promise<{ [key: string]: any } | null> => {
            if (!control.disabled) {
                return this.d365Service
                    .checkCodicePlico(
                        control.value,
                        vaChannelCode,
                        signatureType,
                        flowTypeToD365OperationMode(flowType)
                    )
                    .then((res: BaseD365Response) => {
                        return res.Result === 'KO' ? { codicePlico: res.ErrorMessage } : null;
                    })
                    .catch(() => {
                        return { codicePlico: false };
                    });
            }
            return null;
        };
    }
}
