<input type="hidden" [id]="easterDevInp" (click)="setVisibiltyBar(true, 60)" />
<div *ngIf="domainEnabled && visible" class="dev-bar" [ngClass]="localhost ? 'bk-blue' : 'bk-red'">
    <button (click)="setVisibiltyBar(false)">hide</button>

    <button class="icon__container" (click)="goToHome()" style="background-color: indianred">
        <span [innerHTML]="homeIcon" class="icon"></span>
    </button>
    &nbsp;DEV BAR&nbsp;
    <select id="routes" (change)="onChangeRoute($event)">
        <option *ngFor="let route of routes" value="{{ route }}">
            {{ route }}
        </option>
    </select>

    <button (click)="showMoreConfig()">MORE</button>
    <input type="text" [(ngModel)]="url" style="width: 100%" />
    <button (click)="onGoBtn(false)">navigate</button>
    <button (click)="onGoBtn(true)">location.href</button>
</div>

<egl-modal-with-footer
    class="dev-bar-modal"
    *ngIf="domainEnabled && visible"
    #moreConfigModal
    [title]="'More configurations'"
    [buttonLabel]="'Close'"
    [closeOnConfirm]="true"
    (onConfirm)="moreConfigModal.hide()"
>
    <input type="checkbox" id="isSiebelCustomer" name="isSiebelCustomer" (click)="onChangeIsSiebelCustomer($event)" />
    <label for="isSiebelCustomer">&nbsp;isSiebelCustomer</label>
    <br />
    <input type="checkbox" id="isMicroBusiness" name="isMicroBusiness" (click)="onChangeIsMicroBusiness($event)" />
    <label for="isMicroBusiness">&nbsp;isMicroBusiness</label>

    <br />
    <label for="flowTypes">FlowType:&nbsp; </label>
    <select
        id="flowTypes"
        name="flowTypes"
        (change)="onChangeFlowType($event.target.value)"
        [(ngModel)]="currentFlowType"
    >
        <option *ngFor="let ft of flowTypes" value="{{ ft }}">
            {{ ft }}
        </option>
    </select>
    {{ opModeLabelKey | translate }}
    <br />
    <input type="button" (click)="forceConfigurationsReload()" value="Reload privateConfig" />
    <input type="button" (click)="onHideBlockUI()" value="BlockUI" />
    <input type="button" (click)="logSupState()" value="Log SupState" />
    <input type="button" (click)="test()" value="Test" />

    <egl-new-convert-cart-to-quote-test-page></egl-new-convert-cart-to-quote-test-page>
</egl-modal-with-footer>
