import { Indirizzi, Indirizzo } from '../../modules/switch-in/order-entry/models/indirizzi';
import { FormDatiPagamento } from '../../modules/common/order-entry/models/form-dati-pagamento';
import { FotoDocumenti, SelfCertificationData } from '../../modules/common/order-entry/models/foto-documenti';
import { AptProductType } from '../../common/enums/apttus/apt-product-type';
import { AptProductFamily } from '../../common/enums/apttus/apt-product-family';
import { AptBillType } from '../../common/enums/apttus/apt-bill-type';
import { AptDoiStatusType } from '../../common/enums/apttus/apt-doi-status-type';
import { AptDoiConfirmModeType } from '../../common/enums/apttus/apt-doi-confirm-mode-type';
import { D365SignatureType } from '../../common/enums/d365/d365-signature-type';
import { AptQuoteStatus } from '../../common/enums/apttus/apt-quote-status';
import { AptQuoteSubStatus } from '../../common/enums/apttus/apt-quote-sub-status';
import { D365ConfirmationType } from '../../common/enums/d365/d365-confirmation-type';
import { AptCommodityTypeOfSale } from '../../common/enums/apttus/apt-commodity-typeof-sale';
import { VirtualAgentBackOffice } from '../../common/models/user/agent';
import { AptAddressType } from '../../common/enums/apttus/apt-address-type';
import { HistorizedAgencyBranch } from '../../common/models/user/historized-agency-branch';
import { OcrData } from '../../modules/switch-in/order-entry/models/ocr-data';
import { AptPaymentInstrument } from '../../common/enums/apttus/apt-payment-instrument';
import { EglCartPAVLight } from '../../common/models/apttus/tables/pav/egl-cart-p-a-v-light';
import { AptLineStatus } from '../../common/enums/apttus/apt-line-status';
import { AptContactRole } from '../../common/enums/apttus/apt-mb-representative';
import { Moment } from 'moment';
import { QuestionIvassState } from '../../modules/switch-in/products/models/ivass-question';
import { PartnerShipInfo } from '../../modules/common/partnership/models/partnership-info';
import { PaymentAsset } from '../../common/models/app/payment-tools.response';
import { AptSaleabilityOperationType } from '../../common/enums/apttus/apt-sales-process';
import { AptPaymentFrequency } from '../../common/enums/apttus/apt-payment-frequency';
import { AptPaymentType } from '../../common/enums/apttus/apt-payment-type';
import { FlowType } from './flow-type';
import { AptPowerVoltage } from '../../common/enums/apttus/apt-power-voltage';
import { PdrType } from '../../common/enums/shared/pdr-type';
import { GasMeterClass } from '../../common/enums/shared/gas-meter-class';
import { YesNo } from '../../common/enums/shared/yes-no';

export class OrderEntryState {
    flowType: FlowType;
    partNumber?: SalesProcessPartNumber;
    operationType: AptSaleabilityOperationType;
    assetIntegrationId: string;
    pod: string;
    pdr: string;
    cartInfo: CartInfo;
    infoProdotti?: ProductInfo[];
    indirizzi?: Indirizzi;
    datiPagamento?: FormDatiPagamento;
    fotoDocumenti: FotoDocumenti;
    realEstateOwnership: TitolaritaImmobile;
    firma?: Firma;
    mp3Info?: Mp3Info;
    uploadPdfPlicoInfo?: PlicoPdfInfo;
    numeroPlico: ContractCode;
    numeroDomiciliazione: string;
    tipoEsecuzione: TipoEsecuzione;
    fornitureAttive: FornituraEsistente[];
    selectFornitureAttiveSelezionate: FornituraEsistente[];
    privacyTrattDatiPers: DomandePrivacy;
    quoteStateModel?: QuoteStateModel;
    verificaContatto: VerificaContatto;
    agencyBranchForMonitoring?: string;
    branchAgenziaAgente?: HistorizedAgencyBranch;
    skipPod = false;
    skipPdr = false;
    skipAteco: boolean;
    isWinBack = false;
    winBackType = WinBackType.None;
    plicoUrl: string;
    anagraficaMb: DatiAnagraficiMB;
    atecoMb: AtecoMB;
    ocrData: OcrData;
    potenzaImpegnataMb: PotenzaImpegnataMB;
    fatturazioneElettronica: FatturazioneElettronica;
    // troubleshootingPowerDone = false;
    // troubleshootingGasDone = false;
    impersonatedAgent?: VirtualAgentBackOffice;
    invalidCf?: InvalidCf;
    creditCheckStatus?: CreditCheckStatus;
    deferredSaleEnabled: boolean;
    powerConsumption: PowerConsumption;
    powerHomeDropdownOption: PowerGasHomeOptions;
    gasConsumption: GasConsumption;
    gasHomeDropdownOption: PowerGasHomeOptions;
    potenzaImpegnata: string;
    responsabilitaCreditCheckModal: boolean;
    termination: Termination;
    activation: Activation;
    costs?: CostData;
    selfCertification: SelfCertificationData;
    ivassAnswers: QuestionIvassState[];
    insuranceDummyProductId: string;
    partnership: PartnerShipInfo;
    quoteId?: string;
    combinedSaleQuoteId?: string;
    combinedSale: boolean;
    commodityCartId?: string;
    coupon?: string;
    administrativeTechnicalData: AdministrativeTechnicalData;
    domiciliationStandAlone: Domiciliation;
    incident: Incident;
    deliveryChannel: AptBillType; // in casi come la domiciliazione il campo arriva valorizzato da apttus (state di partenza)
    supplyClass: string;
    requiredPower: string;

    constructor() {
        this.flowType = FlowType.SwitchIn;
        this.privacyTrattDatiPers = {};
        this.firma = new Firma();
        this.mp3Info = new Mp3Info();
        this.quoteStateModel = new QuoteStateModel();
        this.cartInfo = new CartInfo();
        this.indirizzi = new Indirizzi();
        this.impersonatedAgent = null;
        this.creditCheckStatus = new CreditCheckStatus();
    }
}

export class ContractCode {
    code: string;
    channelName: string;
    signatureType: D365SignatureType;
}

export class CartInfo {
    cartId: string;
    ownerDagCode: string;
}
export class TipoEsecuzione {
    passaggioRapidoCommodity?: boolean;
    dataStimataCommodity?: Date;
    passaggioRapidoManutenzione?: boolean;
    dataStimataManutenzione?: Date;
    isRataFissaDatesEquals?: boolean;
    dataStandardCommodity?: Date;
    dataAnticipataCommodity?: Date;
    isRataFissaCP?: boolean;
}

export class ProductInfo {
    id: string;
    code: string;
    nome: string;
    productType: AptProductType;
    family?: AptProductFamily;
    configuration: ProductConf;
    sku: string;
    uniqueProductCode: string;
    powerOrGas: AptCommodityTypeOfSale;
    addressType: AptAddressType;
    lineItemId: string;
    status: AptLineStatus;
    optionsConfig: string[];
    discountsPromo: string[];
    privacyRequired: boolean;
    isExpired: boolean;
    /**Indica se si tratta di un prodotto "A Nuovo" oppure di un prodotto "A Vecchio" */
    isAtNew: boolean;
    podPdr: string;
    supplyAddress: string;
    commAddress: string;
    partnership?: string;
    supplyCode: string;

    constructor(
        id: string,
        lineItemId: string,
        type: AptProductType,
        family: AptProductFamily,
        name: string,
        code: string,
        addressType: AptAddressType,
        pav: EglCartPAVLight,
        privacyRequired: boolean
    ) {
        const addressTypeT = family !== AptProductFamily.Options ? addressType || AptAddressType.Fornitura : null;
        this.id = id;
        this.lineItemId = lineItemId;
        this.productType = type;
        this.nome = name;
        this.code = code;
        this.family = family;
        this.addressType = addressTypeT;
        this.configuration = {
            paymentInstrument: pav.egl_Payment_Instrument__c,
            billType: pav.egl_Invoice_Shipping_Method__c,
            orderNumber: pav.egl_selling_order_number__c,
            paymentFrequency: pav.egl_Payment_Frequency__c,
            paymentType: pav.egl_Payment_Type__c,
            isCombinedSaleInsurance: pav.egl_combined_sale_insurance__c !== 'No',
        };

        if (family === AptProductFamily.Commodity) {
            this.powerOrGas = pav.egl_commodity_supplytype__c;
        } else if (family === AptProductFamily.Assicurazione) {
            this.powerOrGas = pav.egl_Insurance_Type__c;
        }
        this.podPdr = pav.egl_commodity_supplytype__c === AptCommodityTypeOfSale.Gas ? pav.egl_PDR__c : pav.egl_POD__c;
        if (pav?.egl_Place_Name__c && pav?.egl_Address__c) {
            this.supplyAddress = `${pav.egl_Place_Name__c} ${pav.egl_Address__c} ${pav.egl_Civic_Number__c}, ${pav.egl_City__c}, ${pav.egl_Province__c}, ${pav.egl_Postal_Code__c}`;
        }
        this.optionsConfig = [];
        this.discountsPromo = [];
        this.privacyRequired = privacyRequired;
        if (pav.egl_partnership__c) {
            this.partnership = pav.egl_partnership__c.toLowerCase();
        }
    }
}

export class ProductConf {
    billType: AptBillType;
    paymentInstrument: AptPaymentInstrument;
    orderNumber: string;
    paymentFrequency: AptPaymentFrequency;
    paymentType: AptPaymentType;
    isCombinedSaleInsurance: boolean;
}

export class VerificaContatto {
    tipoVerifica: 'sms' | 'email' | 'both';
    dataOraInvio: Date;
}

export class PowerConsumption {
    value?: number;
    declared?: number;
    transcodingValue?: number;
    constructor(value?: number, declared?: number, transcodingValue?: number) {
        this.value = value;
        this.declared = declared;
        this.transcodingValue = transcodingValue;
    }
}

export class GasConsumption {
    typeOfUse = '';
    consumption: number;
    constructor(type?: string, consumption?: number) {
        if (type) {
            this.typeOfUse = type;
        }
        if (consumption) {
            this.consumption = +consumption;
        }
    }
}

export class FornituraEsistente {
    assetIntegrationId: string;
    powerOrGas: AptCommodityTypeOfSale;
    podPdr: string;
    pdf: string;
    indirizzoCompleto: string;
    toponimo: string;
    provincia: string;
    cap: string;
    civico: string;
    via: string;
    citta: string;
    isWinBack: boolean;
    isResidente: boolean;
    dataPresuntoSwitchOut: string;
    stato: string; // 'ATTIVO' indica se la fornitura è attiva
    atecoCode: string;
    datiInizio: string;
    codiceIstat: string;
}

class PowerAdministrativeTechnicalData {
    potenzaDisponibile: number;
    potenzaImpegnata: number;
    tensione: AptPowerVoltage;

    constructor(data: PowerAdministrativeTechnicalData) {
        this.potenzaDisponibile = data?.potenzaDisponibile || null;
        this.potenzaImpegnata = data?.potenzaImpegnata || null;
        this.tensione = data?.tensione || null;
    }
}

class GasAdministrativeTechnicalData {
    // distributore: string;
    // pivaDistributore: string;
    tipologiaPdr: PdrType;
    matricolaMisuratore: string;
    cifreMisuratore: number;
    letturaMisuratore: number;
    classeMisuratore: GasMeterClass;
    gruppoMisuratoreIntegrato: YesNo;
    coefficienteCorrettivoC?: number;
    cifreCorrettore?: number;
    matricolaCorrettore?: string;
    letturaCorrettore?: number;

    constructor(data?: GasAdministrativeTechnicalData) {
        // this.distributore = data?.distributore || null;
        // this.pivaDistributore = data?.pivaDistributore || null;
        this.tipologiaPdr = data?.tipologiaPdr || null;
        this.matricolaMisuratore = data?.matricolaMisuratore || null;
        this.cifreMisuratore = data?.cifreMisuratore || null;
        this.letturaMisuratore = data?.letturaMisuratore || null;
        this.classeMisuratore = data?.classeMisuratore || null;
        this.gruppoMisuratoreIntegrato = data?.gruppoMisuratoreIntegrato || null;
        this.coefficienteCorrettivoC = data?.coefficienteCorrettivoC || null;
        this.cifreCorrettore = data?.cifreCorrettore || null;
        this.matricolaCorrettore = data?.matricolaCorrettore || null;
        this.letturaCorrettore = data?.letturaCorrettore || null;
    }
}

export class AdministrativeTechnicalData {
    power?: PowerAdministrativeTechnicalData;
    gas?: GasAdministrativeTechnicalData;
    constructor(
        type: AptCommodityTypeOfSale,
        powerData?: PowerAdministrativeTechnicalData,
        gasData?: GasAdministrativeTechnicalData
    ) {
        if (type === AptCommodityTypeOfSale.Gas) {
            this.gas = new GasAdministrativeTechnicalData(gasData);
        } else if (type === AptCommodityTypeOfSale.Power) {
            this.power = new PowerAdministrativeTechnicalData(powerData);
        } else {
            this.gas = new GasAdministrativeTechnicalData(gasData);
            this.power = new PowerAdministrativeTechnicalData(powerData);
        }
    }
}

export interface DomandePrivacy {
    [Key: string]: boolean;
    wantsEditConsents?: boolean;
}

export class Firma {
    d365SignatureType?: D365SignatureType; // Modalità firma
    confirmationType?: D365ConfirmationType; // Modalità conferma
    canBeModified?: boolean;
    signedDate?: Date | string = null; // Data firma
    doiStatus?: AptDoiStatusType; // DOI status - non settare
    confirmMode?: AptDoiConfirmModeType; // Modalità conferma
    checkCallBlocking?: boolean; // Check call bloccante
}

export class Mp3Info {
    uploaded: boolean;
    uploadDate: Date;
    type: 'DOI' | 'VO';
    size: number;
    fileName: string;
}

export class PlicoPdfInfo {
    uploaded: boolean;
    uploadDate: Date;
    size: number;
    fileName: string;
    plicoCode: string;
}

export class QuoteStateModel {
    status?: AptQuoteStatus | string;
    subStatus?: AptQuoteSubStatus | string;
    constructor(status?: AptQuoteStatus, subStatus?: AptQuoteSubStatus) {
        if (status && subStatus) {
            this.status = status;
            this.subStatus = subStatus;
        } else {
            this.status = AptQuoteStatus.InCompilazione;
        }
    }
}

export class DatiAnagraficiMB {
    isAtoka: boolean;
    piva: string;
    cf: string;
    companyName: string;
    legalForm: string;
    companyAddress: Indirizzo;
    nameLegal: string;
    surnameLegal: string;
    cfLegal: string;
    representativeType: AptContactRole = AptContactRole.RappresentanteLegale;
}

export class AtecoMB {
    codeAtecoPower: string;
    codeAtecoGas: string;
    validated: boolean;
    attivita?: string;
    settore?: string;
    denominazione?: string;
}

export class PotenzaImpegnataMB {
    consumoInpt: number;
    potenzaImpegnataCmb: string;
    potenzaDisponibileInp: string;
    livelloTensione: string;
    potenzaImpInputManuale: string;
}

export class FatturazioneElettronica {
    codiceDestinatario?: string;
    pec: string;
}

export class InvalidCf {
    siebelOldCf: string;
    siebelCustomerCode: string;
}

export class CreditCheckStatus {
    canProceed?: boolean; //TODO Lorenzo - Quando verrà dismesso il vecchio Credit Check, fai diventare obbligatorio
    errorCode?: string;
    /**Indica il codice fiscale o la P.Iva al quale è associato il risultato del credit check */
    code?: string;
    /**Indica la data nella quale è stato fatto il credit check */
    checkDate?: Moment;
    ccDetails: CreditCheckDetails;
    taxVatDetails?: TaxVatDetails;
    callScorecard: boolean;
    scorecardCallInProgress: boolean;
}
export interface CreditCheckDetails {
    blacklist: string;
    blacklist2: string;
    newBlacklist: string;
    whitelist: string;
    /**Esito complessivo di Insoluto NDS (egl_unsolved__c) */
    unsolvedNds?: string;
    /**Esito di Insoluto NDS relativo al solo IBAN (egl_unsolved_iban__c) */
    unsolvedIbanNds?: string;
    avgInvoiceAmount: string;
    paymentScore?: string;
    /**Cribis = Scorecard = Cerved */
    cribis?: string;
    ceaseReasonCode?: string;
    residualNds: string;
    /**Soglia numerica (stringhizzata) relativa all'insoluto NDS del CF (insoluto_zuora_cf) - Usata da Apttus direttamente dallo state per logiche di RETRY */
    unsolvedTaxCodeNds: string;
    /**Esito di Insoluto NDS relativo al solo CF/P.IVA (egl_unsolved_fc_vat__c) */
    unsolvedTaxCodeNdsResult: string;
}
export class TaxVatDetails {
    sdi?: string;
    scipafi?: string;
}

export enum TitolaritaImmobile {
    Proprietario = 'Proprietario',
    Affittuario = 'Affittuario',
    Altro = 'Altro',
}
export class Termination {
    cartId: string;
    isInvoiceDeliveryAddressUpdated: boolean;
    isMortisCausa: boolean;
    termDate: string;
    termContacts: TermContacts[];
    termAppointment: Appointment;
    termCosts: TermCosts[];
}

export class Appointment {
    presence: boolean;
    altContactFName?: string;
    altContactLName?: string;
    altContactPrefix?: string;
    altContactPhone?: string;
    timeSlot?: string;
    interphoneAvailable?: boolean;
    interphoneNotes?: string;
    meters?: Meter[];
    csv: AppointmentCsv[];
}

export class TermContacts {
    oldContact: string;
    confirmContact: boolean;
    changedContact: boolean;
}

export class TermCosts {
    idAsset: string;
    priceSDR: string;
    priceOPS: string;
}

export class Meter {
    lineItemId: string;
    isAvailable?: boolean;
    location?: string;
    notes?: string;
    name?: string;
    category?: string;
}

export class AppointmentCsv {
    lineItemId: string;
    content: string;
}

export class Domiciliation {
    billingPreferenceId?: string;
    oldIbanPaymentTool?: string; //IBAN prevalorizzato in caso di MODIFICA o REVOCA DOMICILIAZIONE. Deve essere prevalorizzato dalla createState di apttus. In caso di  ATTIVAZIONE DOMICILIAZIONE = null:
    assets?: PaymentAsset[]; //prevalorizzato  dalla createState di apttus. Contiene gli asset interessati dalla DOMICILIAZIONE
}

export class Activation {
    podActivationInfo?: PodActivationInfo;
    pdrActivationInfo?: PdrActivationInfo;
    propertyTypeSelected?: PropertyTypes;
    actDate?: Date;
    immediateEffect?: boolean;
    actiAppointment?: Appointment;
}

export class PodActivationInfo {
    tensione?: string;
    ultimaPotDisp?: string;
    nuovaPotSel?: string;
    fornitura?: PodInfoLocation;
}

export class PodInfoLocation {
    ubicazioneFornituraPod?: string;
    toponimo?: string;
    via?: string;
    civ?: string;
    cap?: string;
    localita?: string;
    prov?: string;
    scala?: string;
    piano?: string;
    interno?: string;
    istat?: string;
}

export class PdrActivationInfo {
    meterNumber: string;
    potentiality: string;
    meterClass: string;
    vendorCode: string;
    fornitura: PdrInfoLocation;
}

export class PdrInfoLocation {
    AccessLettPdrEsito?: string;
    AccessPdrEsito?: string;
    CapPdrEsito: string; // cap
    CivicoPdrEsito: string; // number
    ClienteFinalePdrEsito?: string;
    ComunePdrEsito: string; //city
    DataAggDistrPdrEsito?: string;
    DataCostrPdrEsito?: string;
    ErogabPdrEsito?: string;
    ImpDistrPdrEsito?: string;
    IndirPdrEsito: string; // street
    IntPdrEsito?: string;
    IstatComunePdrEsito: string;
    PDRDistrEsito: string;
    PianoPdrEsito?: string;
    PotenzialitaPdrEsito?: string;
    PressContPdrEsito?: string;
    PrPdrEsito: string; // provincia
    SiglaTopPdrEsito: string; // Toponimo
    TipoAllPdrEsito?: string;
    TipoServPdrEsito?: string;
    UbicPdrEsito?: string;
    ScalaPdrEsito?: string;
}

export class CostRecord {
    id: string;
    name?: string;
    eniCosts: number;
    otherCosts: number;
    total: number;
}
export class CostData {
    records: CostRecord[];
    total?: number;
}

export class Incident {
    selectedTicketNumber: string;
}

export enum WinBackType {
    None = 'None',
    Gas = 'Gas',
    Power = 'Power',
    Both = 'Both',
}

export type HandledSalesProcessType =
    | 'CP'
    | FlowType.SwitchIn
    | FlowType.SwitchInVoltura
    | FlowType.Attivazione
    | FlowType.Cessazione
    | FlowType.VariazioneCommerciale;

export enum SalesProcessPartNumber {
    GAS_ACTIVATION_WITH_METER = 'S-PR-001',
    GAS_ACTIVATION_WITHOUT_METER = 'S-PR-003',
    POWER_ACTIVATION_SIMPLE = 'S-PW-001',
}

export enum PowerGasHomeOptions {
    First = '1',
    Second = '2',
}

export enum PropertyTypes {
    Singolo = 'Appartamento singolo',
    Stabile = 'Intero stabile',
}
