import { createAction, props } from '@ngrx/store';
import { EglPartnershipData } from '../../modules/common/partnership/models/partnership-info';
import { partnershipActionString as p } from './action-types';

export const setFastwebTaxVatCode = createAction(p.SET_FASTWEB_TAX_VAT_CODE, props<{ code: string }>());
export const setFastwebContractInfo = createAction(
    p.SET_FASTWEB_CONTRACT_INFO,
    props<{ cluster: string; accountNumber: string }>()
);
export const setEglPartnershipData = createAction(p.SET_EGL_PARTNERSHIP_DATA, props<{ data: EglPartnershipData }>());
