import { AttributeValues } from './base-attribute-values';

export class GasAttributeValues extends AttributeValues {
    attributesMapping(): void {
        super.attributesMapping();

        const { pdr, gasConsumption, selectFornitureAttiveSelezionate, activation, administrativeTechnicalData } =
            this.orderEntry;

        this.attr['egl_PDR__c'] = pdr;
        this.attr['egl_typeofusage__c'] = gasConsumption?.typeOfUse;
        this.attr['egl_gas_consumption__c'] = gasConsumption?.consumption;
        this.attr['egl_annual_consumption__c'] = gasConsumption?.consumption;
        this.attr['egl_gas_consumption_declared__c'] = gasConsumption?.consumption;
        this.attr['egl_gas_potential_consumption__c'] = gasConsumption?.consumption || 29;
        this.attr['egl_switchoutdate__c'] = selectFornitureAttiveSelezionate?.find(
            (f) => f.podPdr === pdr
        )?.dataPresuntoSwitchOut;
        this.attr['egl_pdr_serial_number__c'] = activation?.pdrActivationInfo?.meterNumber;
        this.attr['egl_pdr_type__c'] = administrativeTechnicalData?.gas?.tipologiaPdr;
        this.attr['egl_serial_number_distibutor__c'] = administrativeTechnicalData?.gas?.matricolaMisuratore;
        this.attr['egl_digits_meter__c'] = administrativeTechnicalData?.gas?.cifreMisuratore;
        this.attr['egl_meter_reading__c'] = administrativeTechnicalData?.gas?.letturaMisuratore;
        this.attr['egl_meter_class__c'] = administrativeTechnicalData?.gas?.classeMisuratore;
        this.attr['egl_meter_integrated__c'] = administrativeTechnicalData?.gas?.gruppoMisuratoreIntegrato;
        this.attr['egl_C_coefficient_corrective__c'] = administrativeTechnicalData?.gas?.coefficienteCorrettivoC;
        this.attr['egl_digits_corrective__c'] = administrativeTechnicalData?.gas?.cifreCorrettore;
        this.attr['egl_serial_number_corrector__c'] = administrativeTechnicalData?.gas?.matricolaCorrettore;
        this.attr['egl_corrector_reading__c'] = administrativeTechnicalData?.gas?.letturaCorrettore;
    }
}
