import { AptSignatureMode } from '../enums/apttus/apt-signature-mode';
import { D365SignatureType } from '../enums/d365/d365-signature-type';

export const D365_APT_SIGNATURE_MODE_MAP = {
    [D365SignatureType.Doi]: AptSignatureMode.Doi,
    [D365SignatureType.Cartacea]: AptSignatureMode.Cartaceo,
    [D365SignatureType.PaperPrintedBySystem]: AptSignatureMode.CartaceoPreviaStampa,
    [D365SignatureType.VocalOrder]: AptSignatureMode.VocalOrder,
    [D365SignatureType.Grafometrica]: AptSignatureMode.Grafometrica,
    [D365SignatureType.DoiLight]: AptSignatureMode.DoiSenzaVocalOrderLight,
    [D365SignatureType.NessunaFirma]: AptSignatureMode.DoiSenzaVocalOrderLight,

    [999999999]: AptSignatureMode.DoiSenzaMp3Light,
};
