import { AField, AObject, ATable } from '@congacommerce/core';

@ATable({
    sobjectName: 'egl_address',
})
export class EglAddress extends AObject {
    @AField({ soql: 'egl_fulladdress' })
    egl_fulladdress: string;

    @AField({ soql: 'egl_postalcode' })
    egl_postalcode: string;

    @AField({ soql: 'egl_city' })
    egl_city: string;

    @AField({ soql: 'egl_province' })
    egl_province: string;

    @AField({ soql: 'egl_address_certified' })
    egl_address_certified: boolean;

    @AField({ soql: 'egl_address_line1' })
    egl_address_line1: string;

    @AField({ soql: 'egl_address_line2' })
    egl_address_line2: string;

    @AField({ soql: 'egl_address_prefix' })
    egl_address_prefix: string;

    @AField({ soql: 'egl_address_validated' })
    egl_address_validated: false;

    @AField({ soql: 'egl_country' })
    egl_country: string;

    @AField({ soql: 'egl_externalid' })
    egl_externalid: string;

    @AField({ soql: 'egl_istat' })
    egl_istat: string;

    @AField({ soql: 'egl_streetmap_code' })
    egl_streetmap_code: string;
}
