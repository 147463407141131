import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AssetDetailResponse } from '../models/asset-detail.response';
import { ApiMngApi, ApttusApi, BaseProvider } from '../../../common/providers/base-provider';
import { ApiService } from '../../../common/services/shared/api.service';
import { ProductListByAssetIdResponse } from '../models/product-list-by-assetid.response';
import { CPAddProductToCartResponse } from '../models/cp-add-product-to-cart.response';
import { EglQuoteLight } from '../../../common/models/apttus/tables/quote/egl-quote-light';
import { Skill } from '../../../common/models/user/agent';
import { PrivateConfigurationService } from '../../../common/services/shared/private-configuration.service';

@Injectable({ providedIn: 'root' })
export class CpProvider extends BaseProvider {
    constructor(private api: ApiService, protected configSrv: PrivateConfigurationService) {
        super(configSrv);
    }

    /**
     * @description: Recupera le informazioni per creare la scheda asset
     * @param assetId: id dell'asset
     * @return: Observable<AssetDetailResponse>
     */
    getAssetDetail(assetId: string): Observable<AssetDetailResponse> {
        const req = {
            assetIntegrationId: assetId,
        };

        return this.api.postAsync<AssetDetailResponse>(this.getApiMngApiUrl(ApiMngApi.GetAssetDetails), req);
    }

    /**
     * @description: Recupera le una lista di prodotti compatibili per un dato asset id (solo CP Commerciale)
     * @param assetId: id dell'asset
     * @return: Observable<ProductListByAssetIdResponse>
     */
    getProductListByAssetId(
        assetId: string,
        salesChannel: string,
        agencyCode: string,
        responsibleSkills: Skill[],
        skills: Skill[],
        inStore: boolean
    ): Observable<ProductListByAssetIdResponse> {
        const req = {
            SalesChannel: salesChannel,
            ResponsibleSkills: responsibleSkills,
            Skills: skills,
            InStore: inStore,
            Agency: agencyCode,
            Warrants: [],
            AssetIntegrationId: assetId,
        };

        return this.api.postAsync<ProductListByAssetIdResponse>(
            this.getApiMngApiUrl(ApiMngApi.GetProductListById),
            req
        );
    }

    addProductToCart(assetId: string, productId: string, cartId: string): Observable<CPAddProductToCartResponse> {
        const req = {
            productConfigurationId: cartId,
            assetId: assetId,
            productId: productId,
        };

        return this.api.postAsync<CPAddProductToCartResponse>(this.getApiMngApiUrl(ApiMngApi.CpChangeCommerciale), req);
    }

    /**
     * @description: Crea la quote usando la Hadless API di Apttus
     * @param productId: id del cart
     * @return: Observable<{ title: string; status: number; data: EglQuoteLight }>
     */
    createProposal(
        cartId: string,
        quote: EglQuoteLight
    ): Observable<{ title: string; status: number; data: EglQuoteLight }> {
        return this.api.postAsync<{ title: string; status: number; data: EglQuoteLight }>(
            this.getApttusApiUrl(ApttusApi.CreateProposal).replace('{cartId}', cartId),
            quote
        );
    }

    /**
     * @description: Aggiorna una entità usando la Hadless API di Apttus
     * @param entities: entità da aggiornare
     * @param entityName: nome entituà da aggiornare
     * @return: Observable<{ title: string; status: number; data: EglQuoteLight }>
     */
    updateHadless<T>(entities: Array<T>, entityName: string): Observable<{ title: string; status: number; data: T }> {
        entities.forEach(
            (e: T) =>
                (e['attributes'] = {
                    type: entityName,
                })
        );
        return this.api.putAsync<{ title: string; status: number; data: T }>(
            this.getApttusApiUrl(ApttusApi.Update).replace('{entityName}', entityName),
            entities
        );
    }
}
