<div class="egl-textfield__container">
    <div *ngIf="isOpen" class="egl-modal-with-footer__container egl-modal-with-footer--open">
        <div class="egl-modal-with-footer__overlay" (click)="dismiss()"></div>
        <div class="egl-modal-with-footer">
            <div class="egl-modal-with-footer__header">
                <egl-icon
                    *ngIf="path"
                    [path]="path"
                    [withCircle]="withCircle"
                    class="egl-modal-with-footer__icon"
                    [width]="32"
                    [height]="32"
                ></egl-icon>
                <h3 class="egl-modal-with-footer__title">{{ title }}</h3>
                <button type="button" class="egl-modal-with-footer__close" (click)="dismiss()">
                    <span [innerHTML]="icons.Cancel"></span>
                </button>
            </div>
            <ng-content></ng-content>

            <div class="egl-modal-with-footer__footer" *ngIf="!hideConfirm">
                <button
                    *ngIf="secondButtonLabel"
                    type="button"
                    class="egl-modal-with-footer__cta-secondary cta-secondary"
                    [disabled]="backDisabled"
                    (click)="back()"
                >
                    {{ secondButtonLabel }}
                </button>
                <div class="egl-modal-with-footer__btn-container">
                    <button
                        *ngIf="thirdButtonLabel"
                        type="button"
                        class="egl-modal-with-footer__btn-third cta-secondary"
                        [disabled]="backDisabled"
                        (click)="genericAction()"
                    >
                        {{ thirdButtonLabel }}
                    </button>
                    <button type="button" class="cta-primary" [disabled]="confirmDisabled" (click)="confirm()">
                        {{ buttonLabel }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
