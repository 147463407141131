import { Injectable } from '@angular/core';
import {
    isChangeProduct,
    isFlowTypeActivation,
    isFlowTypeSwitchIn,
    isFlowTypeVoltura,
} from '../../functions/verifications.functions';
import { PrivateConfigurationService } from './private-configuration.service';
import { FlowType } from '../../../store/models/flow-type';
import { get } from 'lodash';
import { ToggleFeature } from '../../models/app/app-config';

@Injectable({
    providedIn: 'root',
})
export class FeatureToggleService {
    config: ToggleFeature;
    constructor(private configSrv: PrivateConfigurationService) {
        this.configSrv.loadConfigCompleted.subscribe((config) => {
            this.config = config.toggleFeature;
        });
    }

    isFlowTypeEnabled(f: FlowType): boolean {
        return this.isKeyEnabled(
            Object.entries(FLOW_TYPE_TOGGLE_MAP).find(([togglePath, matchFunc]) => matchFunc(f))[0]
        );
    }

    get(key: string[] | string, defaultValue?: any): any {
        return get(this.config, key, defaultValue);
    }

    get isSwitchInE2EEnabled(): boolean {
        return this.isKeyEnabled(this.config?.switche2e);
    }

    get isVolturaSwitchEnabled(): boolean {
        return this.isKeyEnabled(this.config?.volturaSwitch);
    }

    get isVolturaSempliceEnabled(): boolean {
        return this.isKeyEnabled(this.config?.voltura);
    }

    get isActivationEnabled(): boolean {
        return this.isKeyEnabled(this.config?.attivazione);
    }

    get isTerminationEnabled(): boolean {
        return this.isKeyEnabled(this.config?.cessazione);
    }

    get isVariazioneCommercialeEnabled(): boolean {
        return this.isKeyEnabled(this.config?.variazioneCommerciale);
    }

    get isPartnershipImproveEnabled(): boolean {
        return this.isKeyEnabled(this.config?.partnershipImprove);
    }

    get isCombinedSaleEnabled(): boolean {
        return this.isKeyEnabled(this.config?.combinedSale);
    }

    get isDomiciliazioneEnabled(): boolean {
        return this.isKeyEnabled(this.config?.domiciliazione);
    }

    get isCustomer360Enabled(): boolean {
        return this.isKeyEnabled(this.config?.customer360);
    }

    get isCouponEnabled(): boolean {
        return this.isKeyEnabled(this.config?.coupon);
    }

    private isKeyEnabled(key: string | boolean) {
        if (typeof key === 'string') {
            return !(this.configSrv.get(key) === false);
        } else {
            return !(key === false);
        }
    }
}

type FlowTypePrivateConfig = {
    [kay: string]: (f: FlowType) => boolean;
};

const FLOW_TYPE_TOGGLE_MAP: FlowTypePrivateConfig = {
    'toggleFeature.switchin': isFlowTypeSwitchIn,
    'toggleFeature.volturaSwitch': isFlowTypeVoltura,
    'toggleFeature.voltura': isFlowTypeVoltura,
    'toggleFeature.attivazione': isFlowTypeActivation,
    'toggleFeature.cambioProdotto': isChangeProduct,
};
