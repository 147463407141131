export enum D365SignatureType {
    PaperPrintedBySystem = 100000000,
    Cartacea = 100000001,
    Grafometrica = 100000002,
    VocalOrder = 100000003,
    Doi = 100000004,
    DoiLight = 100000005,
    NessunaFirma = 100000006,
}

export const D365_SIGNATURE_TYPES = Object.values(D365SignatureType).filter(
    (signatureType): signatureType is D365SignatureType => typeof signatureType !== 'string'
);
