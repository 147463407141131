/* tslint:disable:variable-name */
import { AField, AObject, ATable } from '@congacommerce/core';
import { AptCustomerType } from '../../../../enums/apttus/apt-customer-type';
import { EglCartItemLight } from './egl-cart-item-light';
import { Type } from 'class-transformer';
import { EglContactLight } from '../user/egl-contact-light';
import { AptSalesProcess } from '../../../../enums/apttus/apt-sales-process';
import { ArrayType } from '../../../../functions/decorators';

@ATable({
    sobjectName: 'Apttus_Config2__ProductConfiguration__c',
})
export class EglCartLight extends AObject {
    constructor() {
        super();
        this.IsPricePending = null;
        this.EffectiveDate = null;
        this.Status = null;
        this.egl_customer_type = null;
        this.egl_leadid = null;
        this.egl_agency_code = null;
        this.egl_sales_channel = null;
        this.LineItems = null;
    }
    @AField({ soql: 'IsPricePending' }) // EffectiveDate
    IsPricePending: boolean;

    @AField({ soql: 'EffectiveDate' })
    EffectiveDate: Date;

    @AField({ soql: 'Status' })
    Status: string;

    @AField({ soql: 'egl_leadid__c' }) // LEAD ID
    egl_leadid: string;

    @AField({ soql: 'egl_customer_type__c' })
    egl_customer_type: AptCustomerType; // 'Residenziale' |'Microbusiness';

    @AField({ soql: 'PriceListId' })
    PriceListId: string;

    //////////////////////////////////////////////////////////// CHANNEL AND AGENCY

    @AField({ soql: 'egl_agency_code__c' }) // Codice Agenzia
    egl_agency_code: string;

    @AField({ soql: 'egl_sales_channel__c' }) // Canale di vendita
    egl_sales_channel: string;

    @AField({
        soql: 'Apttus_Config2__LineItems__r',
        expand: 'shallow',
    })
    @ArrayType(() => EglCartItemLight)
    LineItems: Array<EglCartItemLight>;

    @AField({
        soql: 'egl_primary_contact__r',
        expand: 'deep',
    })
    @Type(() => EglContactLight)
    egl_primary_contact: EglContactLight;

    @AField({ soql: 'egl_contract_code__c' })
    egl_contract_code: string; // Numero Plico: nel flusso del CP

    @AField({ soql: 'egl_sales_process__c' })
    egl_sales_process: AptSalesProcess;

    @AField({ soql: 'Apttus_Config2__PricingDate__c' })
    pricingDate: string;

    //////////////////////////////////////////////////////////// BASE LISTA
    @AField({ soql: 'egl_tag__c' }) // Base lista
    egl_tag: string;

    @AField({ soql: 'egl_DAG_code__c' }) // DAG Code
    egl_DAG_code: string;

    @AField({ soql: 'egl_cart_items__c' })
    egl_cart_items: number;
}
