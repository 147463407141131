export enum AptProductType {
    Undefined = 'Undefined',
    Accessorio = 'Accessorio',
    AssicurazioneAncillare = 'Assicurazione Ancillare',
    AssicurazioneIntermediata = 'Assicurazione Intermediata / RUI',
    BeneFisico = 'Bene Fisico',
    Sconti = 'Sconti',
    Servizio = 'Servizio',
    TariffaCommodityGas = 'Tariffa Commodity Gas',
    ProdottoCommodityGas = 'Prodotto Commodity Gas',
    ProdottoAtomicoCommodityGas = 'Prodotto Atomico Commodity Gas',
    ProdottoAtomicoCommodityLuce = 'Prodotto Atomico Commodity Luce',
    TariffaCommodityLuce = 'Tariffa Commodity Luce',
    ProdottoCommodityLuce = 'Prodotto Commodity Luce',
    Manutenzione = 'Manutenzione',
    SmartHome = 'Smart Home',
    FornituraLuce = 'Fornitura Luce',
    FornituraGas = 'Fornitura Gas',
    ScontoStandAloneLuce = 'Sconto Stand Alone Luce',
    ScontoStandAloneGas = 'Sconto Stand Alone Gas',
}
