import {
    selectAgentInfo,
    selectCartSegment,
    selectCurrentVirtualAgent,
    selectUserState,
} from '../../../../../store/selectors/user.selectors';
import { selectFlowType, selectSalesProcess } from '../../../../../store/selectors/order-entry.selectors';
import { convertSegmentD365toApt } from '../../../../functions/remap.functions';
import { EglCartExtended } from '../../../../models/apttus/tables/cart/egl-cart-extended';
import { combineLatest, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, concatMap, finalize, map, mergeMap, mergeMapTo, take } from 'rxjs/operators';
import { EglState } from '../../../../../store/reducers';
import { Store } from '@ngrx/store';
import { CartService } from '@congacommerce/ecommerce';
import { LoggerService } from '../../../shared/logger.service';
import { resetOrderEntry, setFlowType } from '../../../../../store/actions/order-entry.actions';
import { resetUserState } from '../../../../../store/actions/user.actions';
import { AptSalesProcess } from '../../../../enums/apttus/apt-sales-process';
import { D365CustomerSegment } from '../../../../enums/d365/d365-customer-segment';
import { VirtualAgent, AgentInfo } from '../../../../models/user/agent';
import { EglCartExtendedService } from './egl-cart-extended.service';
import { LoadingService } from '../../../shared/loading.service';
import { AptProductType } from '../../../../../common/enums/apttus/apt-product-type';
import { CommonProvider } from '../../../../providers/common-provider';
import { EligibilityCheckResponse } from '../../../../models/apex-rest/eligibility-check-response';
import { UseCaseType } from '../../../../models/apex-rest/eligibility-check-request';
import { StatusApexResponse } from '../../../../interfaces/base-apex-api-respose';
import { ApiService } from '@congacommerce/core';
import { D365Service } from '../../../d365/d365.service';
import _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class CartUtilityService {
    constructor(
        private store: Store<EglState>,
        private cartSrv: CartService,
        private apiService: ApiService,
        private commonProvider: CommonProvider,
        private cartExtendedSrv: EglCartExtendedService,
        private d365Service: D365Service,
        private logger: LoggerService
    ) {}

    /**
     * Return a new istance of EglCartExtended builded using EglState object
     * @returns a new istance of EglCartExtended builded using EglState object
     */
    public getNewIstanceOfCartFromState(cart = new EglCartExtended()): Observable<EglCartExtended> {
        return combineLatest([
            this.store.select(selectCurrentVirtualAgent),
            this.store.select(selectAgentInfo),
            this.store.select(selectSalesProcess),
            this.store.select(selectCartSegment),
        ]).pipe(
            take(1),
            map(([va, agentInfo, salesProcess, segment]) => {
                if (cart) {
                    cart = Object.assign(new EglCartExtended(), cart);
                }
                return this.mapCartProps(cart, va, agentInfo, segment, salesProcess);
            })
        );
    }

    private mapCartProps(
        cart: EglCartExtended,
        va: VirtualAgent,
        agentInfo: AgentInfo,
        segment: D365CustomerSegment,
        salesProcess: AptSalesProcess
    ): EglCartExtended {
        cart.egl_sales_process = salesProcess || AptSalesProcess.SwitchIn;
        if (va) {
            cart.egl_agency_code = va.VirtualAgency?.Code;
            cart.egl_sales_channel = va.VirtualAgency?.Channel?.Code;
        }
        if (agentInfo) {
            cart.egl_DAG_code = agentInfo.Agent.Code;
        }
        if (segment) {
            cart.egl_customer_type = convertSegmentD365toApt(segment);
        }
        return cart;
    }

    /**
     * Create and active a new cart using an istance created based from EglState; after that OrderEntryState will be reset.
     * @param deleteCurrentCart default = true. If you want to delete current cart before active new cart
     * @param skipRedirectToDashboard default = false. If you want to redirect to HomePage after cart created
     * @returns
     */
    newCartFromState(deleteCurrentCart = true): Observable<EglCartExtended> {
        const oldCartId = CartService.getCurrentCartId();
        return this.getMyCartOnce().pipe(
            concatMap((currCart: EglCartExtended) => (deleteCurrentCart ? this.cartSrv.deleteCart(currCart) : of())),
            concatMap(() => this.getNewIstanceOfCartFromState()),
            concatMap((newCartIstance) =>
                combineLatest([
                    this.store.select(selectFlowType).pipe(take(1)),
                    this.cartSrv.createNewCart(newCartIstance, undefined, true),
                ])
            ),
            map(([flowType, createdCart]) => {
                this.store.dispatch(resetOrderEntry());
                this.store.dispatch(resetUserState());
                this.store.dispatch(setFlowType({ payload: flowType }));

                this.logger.info(`created new cart. (cart switched from ${oldCartId} to ${createdCart.Id}`);
                return createdCart as EglCartExtended;
            }),
            LoadingService.loaderOperator('Crazione nuovo carrello'),
            catchError((e) => {
                this.logger.error('newCartFromState', 'error during create new cart', e);
                return of(null);
            })
        );
    }

    /**
     * if current cart not contains PrimayLines then update cart using EglState; else create new cart using EglState
     * @param deleteCurrentCart default = true. If current cart contains PrimayLines specify if you want to delete current cart before active new cart.
     * @param skipRedirectToDashboard default = false. If current cart contains PrimayLines specify if you want to redirect to HomePage after cart created.
     * @returns result of created/updated cart
     */
    updateOrCreateNewCart(deleteCurrentCart = true): Observable<EglCartExtended> {
        return this.currentCartIsEmpty().pipe(
            mergeMap((isEmpty) => (isEmpty ? this.updateCartFromState() : this.newCartFromState(deleteCurrentCart)))
        );
    }

    /**
     * Verify if curret cart contains PrimaryLines
     * @returns
     */
    currentCartIsEmpty(): Observable<boolean> {
        return this.getMyCartOnce().pipe(map((x) => x?.LineItems?.find((l) => l?.IsPrimaryLine) === undefined));
    }

    /**
     * update current cart overriding cart properties obtains from getNewIstanceOfCartFromState()
     * @returns result of update
     */
    updateCartFromState(): Observable<EglCartExtended> {
        return this.getMyCartOnce().pipe(
            concatMap((currentCart) => this.getNewIstanceOfCartFromState(currentCart)),
            concatMap((cart: EglCartExtended) => this.cartExtendedSrv.update([cart]).pipe(map((c) => c[0]))),
            map((updatedCart) => (this.cartSrv.refreshCart(), updatedCart)),
            map((updatedCart: EglCartExtended) => {
                this.logger.info(`cart updated. ${updatedCart.Id}`);
                console.info(updatedCart);
                return updatedCart;
            })
        );
    }

    /**
     * Verifica se eventuali sconti applicata a carrello sono eleggibili (esempio in campaign) e in caso di esito negativo li rimuove dal carrello
     * @returns Ritorna eventulmente l'elenco delle righe eliminate dal carrello
     */
    checkEligibility(): Observable<EglCartExtended[]> {
        return combineLatest([this.store.select(selectUserState), this.getMyCartOnce()]).pipe(
            mergeMap(([user, cart]) => {
                if (!_.isNil(user.contact)) {
                    let discountLineItems = cart.LineItems.filter(
                        (x) =>
                            x.Product?.ProductType === AptProductType.ScontoStandAloneGas ||
                            x.Product?.ProductType === AptProductType.ScontoStandAloneLuce
                    );
                    if (discountLineItems.length > 0) {
                        return this.d365Service.getCampaignRecords(user.contact.egl_customercode).pipe(
                            mergeMap((campaignList) => {
                                return this.commonProvider
                                    .discountEligibilityCheck(UseCaseType.Cart, campaignList, cart.Id)
                                    .pipe(
                                        mergeMap((res: EligibilityCheckResponse) => {
                                            return of(discountLineItems);
                                        })
                                    );
                            })
                        );
                    }
                }
                return of(null);
            })
        );
    }

    private deleteCartLineItemByIds(lineItemIds: string[]) {
        return this.apiService.post(
            '/carts/' + CartService.getCurrentCartId() + '/items/delete?price=skip',
            lineItemIds.map((c) => {
                Id: c;
            })
        );
    }

    private getMyCartOnce(): Observable<EglCartExtended> {
        return this.cartSrv.getMyCart().pipe(
            take(1),
            map((c: EglCartExtended) => c)
        );
    }
}
