import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { CartService } from '@congacommerce/ecommerce';
import { Observable, BehaviorSubject, Subscription, of } from 'rxjs';
import { take, filter, mergeMap } from 'rxjs/operators';
import { isNil } from 'lodash';
import { LoggerService } from '../../../../common/services/shared/logger.service';
import { LoadingService } from '../../../../common/services/shared/loading.service';
import { EglCartExtended } from '../../../../common/models/apttus/tables/cart/egl-cart-extended';
import { EglSalesupStateService } from '../../../../common/services/apttus/tables/egl-salesup-state.service';
import { ApttusService } from '../../../../common/services/apttus/apttus.service';
import { RoutesPaths } from '../../../../common/config/routes-paths';

@Injectable({
    providedIn: 'root',
})

/**
 * @description Consente di inizializzare e impostare lo stato di un carrello in base al suo identificativo recuperato dal parametro @acart indicato in route.queryParams
 */
export class ActivationCartResolver implements Resolve<any> {
    private subject = new BehaviorSubject<boolean>(null);
    private subscription: Subscription;
    constructor(
        private cartService: CartService,
        private logger: LoggerService,
        private salesupStateSrv: EglSalesupStateService,
        private apttusSrv: ApttusService,
        private router: Router
    ) {}

    init(): BehaviorSubject<boolean> {
        return this.subject;
    }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        if (!isNil(this.subscription)) this.subscription.unsubscribe();

        let cartId = route.queryParams?.acart as string;
        const currentCartId = CartService.getCurrentCartId();

        if (!isNil(cartId) && (currentCartId != cartId || !this.router.getCurrentNavigation().previousNavigation))
            this.loadCartById(cartId);
        else this.subject.next(false);

        return this.subject.pipe(
            filter((s) => s != null),
            take(1)
        );
    }
    /**
     * @description Consente di inizializzare e impostare lo state di un carrello in base al suo identificativo.
     * @param cartId IIdentificativo del carrello da inizializzare.
     * @returns void
     */
    private loadCartById(cartId: string): void {
        this.subscription = this.cartService
            .getCartWithId(cartId)
            .pipe(
                mergeMap((cart: EglCartExtended) => {
                    if (cart) {
                        CartService.setCurrentCartId(cart.Id);
                        this.apttusSrv.setCartId(cart.Id);
                        this.cartService.publish(cart);
                        return this.salesupStateSrv.restoreSupStateByCartId(cartId, {
                            cartSegment: cart.egl_customer_type,
                        });
                    } else {
                        this.logger.error(
                            'Attivazione carrello',
                            `Carrello ${cartId} inesistente o fuori matrice di visibilità `,
                            null,
                            true
                        );
                        this.router.navigate([RoutesPaths.Error500]);
                        return of(null);
                    }
                }),
                LoadingService.loaderOperator('Attivazione carrello in corso')
            )
            .subscribe(
                () => {
                    this.subject.next(true);
                },
                (err) => {
                    this.logger.error(null, 'Error loading the cart', err, true);
                }
            );
    }
}
