/**
 * @author Marco Ricupero ft Andreea Stegariu, Felice Lombardi
 * @version 1.7
 */
import { Injectable, Injector, NgZone } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, concat, from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { RoutesPaths } from '../../../../common/config/routes-paths';
import { EglState } from '../../../../store/reducers';
import { selectFlowType } from '../../../../store/selectors/order-entry.selectors';
import { AppState } from '../../../../store/models/app-state';
import { RouterServiceInterface } from './router-service.interface';
import { RouterService } from '../router.service';
import { LoggerService } from '../logger.service';
import { ApttusService } from '../../apttus/apttus.service';
import { EglSalesupStateService } from '../../apttus/tables/egl-salesup-state.service';
import { PrivateConfigurationService } from '../private-configuration.service';
import {
    DragonRouteConfiguration,
    DragonRouterPageConfig,
    OrderEntryPathConfiguration,
    RailwayFn,
    RailwayStop,
} from './dragon-router.type';
import { ORDER_ENTRY_PATHS } from './dragon-router-config';
import { CartToQuoteService } from '../../apttus/cart-to-quote/cart-to-quote.service';
import { GLOBAL_SKIPPING_RULES } from './dragon-router-skipping-rules';
import { D365Service } from '../../d365/d365.service';

@Injectable({
    providedIn: 'root',
})
export class DragonRouterService extends RouterService implements RouterServiceInterface {
    private readonly VERIFIED_ROUTE_CONFIGS: DragonRouteConfiguration[] =
        this.checkOrderEntryConfigs(ORDER_ENTRY_PATHS);
    constructor(
        private eglState: Store<EglState>,
        private injector: Injector,
        private activatedRoute: ActivatedRoute,
        router: Router,
        ngZone: NgZone,
        appState: Store<AppState>,
        protected logger: LoggerService,
        location: Location,
        apttusSrv: ApttusService,
        configSrv: PrivateConfigurationService,
        eglSalesupStateService: EglSalesupStateService,
        quoteSrv: CartToQuoteService,
        d365Srv: D365Service
    ) {
        super(
            router,
            ngZone,
            appState,
            logger,
            location,
            apttusSrv,
            configSrv,
            eglSalesupStateService,
            quoteSrv,
            d365Srv
        );
    }

    private checkOrderEntryConfigs(orderEntryConfigs: OrderEntryPathConfiguration[]): DragonRouteConfiguration[] {
        return (orderEntryConfigs || [])
            .map((config, index) => this.checkOrderEntryConfig(config, index))
            .filter(({ flowTypes, railway }) => flowTypes.size && railway.size);
    }

    private checkOrderEntryConfig(
        { flowTypes, railway, ...params }: OrderEntryPathConfiguration,
        index?: number
    ): DragonRouteConfiguration {
        const flowTypeSet = new Set(flowTypes || []);
        const railwaySet = new Set(railway || []);

        if (flowTypeSet.size !== (flowTypes || []).length) {
            this.logger.warn(
                `[Dragon Router] Configuration #${index} has duplicate flowType and it was removed at runtime`
            );
        }

        if (railwaySet.size !== (railway || []).length) {
            this.logger.warn(
                `[Dragon Router] Configuration #${index} has duplicate railway item and it was removed at runtime`
            );
        }

        return {
            flowTypes: flowTypeSet,
            railway: railwaySet,
            ...params,
        };
    }

    private railwaySkipAdapter(railwayStop: DragonRouterPageConfig): Observable<boolean> {
        const skipResult = railwayStop.skip
            ? railwayStop.skip({
                  eglState: this.eglState,
                  configSrv: this.configSrv,
              })
            : false; // Nothing to skip;

        // turning skipping function result into an observable
        return (
            (typeof skipResult === 'boolean' ? of(skipResult) : from(skipResult))
                // Limiting the obtained observable to only 1 result to prevent to trigger the navigation flow when not desired
                .pipe(
                    take(1),
                    catchError((err) => {
                        this.logger.error('[DragonRouter]', 'Skipping rule error', err, false, railwayStop.path);
                        // On error the page will be skipped
                        return of(true);
                    })
                )
        );
    }

    private isDragonRouterPageConfig(railwayStop: RailwayStop): railwayStop is DragonRouterPageConfig {
        return !(railwayStop instanceof RegExp || typeof railwayStop === 'function');
    }

    private isDestinationRailwayStop(
        railwayStop: RailwayStop | RoutesPaths
    ): railwayStop is DragonRouterPageConfig | RoutesPaths {
        return typeof railwayStop === 'string' || this.isDragonRouterPageConfig(railwayStop);
    }

    private filterRailwayBySkippingRules(railway: RailwayStop[]): Observable<RailwayStop[]> {
        return combineLatest(
            railway.map((railwayStop) =>
                this.isDragonRouterPageConfig(railwayStop) ? this.railwaySkipAdapter(railwayStop) : of(false)
            )
        ).pipe(
            take(1),
            map((railwayStopSkips) => railway.filter((e, i) => !railwayStopSkips[i]))
        );
    }

    private mapRouteConfigPath(
        railwayStop: RoutesPaths | DragonRouterPageConfig,
        rootPath: String
    ): DragonRouterPageConfig {
        return typeof railwayStop === 'string'
            ? {
                  component: null,
                  path: railwayStop,
                  skip: GLOBAL_SKIPPING_RULES[railwayStop]?.skip,
              }
            : {
                  ...railwayStop,
                  path: (railwayStop as DragonRouterPageConfig).path,
              };
    }

    async back(): Promise<void> {
        this.location.back();
    }

    private checkCurrentRoute(railwayStop: RailwayStop, routeFragment: string): boolean {
        switch (railwayStop instanceof RegExp ? 'regexp' : typeof railwayStop) {
            case 'regexp': {
                return (railwayStop as RegExp).test(routeFragment);
            }
            case 'object': {
                return routeFragment.includes((railwayStop as DragonRouterPageConfig).path);
            }
        }
        return false;
    }

    private findDestinationRouteAndFunctions(
        railway: RailwayStop[],
        routeFragment: string
    ): {
        railwayFunctions: RailwayFn[];
        destination: DragonRouterPageConfig;
    } {
        // Individuo la Pagina corrente all'interno della sequenza
        const currentStopIndex = railway.findIndex((railwayStop) => this.checkCurrentRoute(railwayStop, routeFragment));
        // Se non trovo la rotta corrente nella railway in input resistuisco null
        if (currentStopIndex < 0) {
            return null;
        }

        // Individuo la prima rotta di destinazione (RoutePath) successiva alla rotta corrente
        const nextStopIndex =
            currentStopIndex +
            1 +
            railway.slice(currentStopIndex + 1).findIndex((railwayStop) => this.isDragonRouterPageConfig(railwayStop));
        // Se non trovo una rotta successiva restituisco null
        if (nextStopIndex <= currentStopIndex) {
            return null;
        }

        // Costruisco la response con l'elenco di eventuali funzioni da eseguire e il path della destinazione in coda
        return {
            // Filtro la porzione del railway tra la rotta corrente
            railwayFunctions: railway
                .slice(currentStopIndex + 1, nextStopIndex)
                // e la destinazione e la filtro restituendo solo funzioni
                .filter((railwayStop) => typeof railwayStop === 'function') as RailwayFn[],

            // Inserisco in coda all'array in uscita il path della pagina di destinazione
            destination: railway[nextStopIndex] as DragonRouterPageConfig,
        };
    }

    next(queryParams?: Params): Promise<boolean> {
        // retrieving flowType
        return this.eglState
            .select(selectFlowType)
            .pipe(
                take(1),
                mergeMap((flowType) =>
                    combineLatest(
                        // Filtering out route configurations
                        this.VERIFIED_ROUTE_CONFIGS.filter(({ flowTypes }) => flowTypes.has(flowType))
                            // Filtering out railwaystops (pages) by skipping rules
                            .map(({ railway, path }) =>
                                this.filterRailwayBySkippingRules(
                                    // Mapping route paths & DragonRouterPageConfig to DragonRouterPageConfig enriched with rootPath
                                    Array.from(railway).map((railwayStop) =>
                                        this.isDestinationRailwayStop(railwayStop)
                                            ? // Enriching configurations with rootPath
                                              this.mapRouteConfigPath(railwayStop, path)
                                            : railwayStop
                                    )
                                )
                            )
                            // Handling unmanaged routes
                            .concat(of([]))
                    ).pipe(
                        // Gathering info with cleaned router fragment
                        map((railways) => ({
                            flowType,
                            routeFragment: this.router.url.replace(/^(\/)/, ''),
                            railways,
                        })),
                        // Seeking for matching regExp or path before target path
                        map(({ railways, routeFragment }) =>
                            // Looking for a DragonRouterPageConfig after a matching regexp or DragonRouterPageConfig
                            railways.reduce<{
                                railwayFunctions: RailwayFn[];
                                destination: DragonRouterPageConfig;
                            }>(
                                (targetStops, railway) =>
                                    targetStops || this.findDestinationRouteAndFunctions(railway, routeFragment),
                                null
                            )
                        ),
                        map((destinationData) => destinationData || { railwayFunctions: null, destination: null }),
                        mergeMap(({ railwayFunctions, destination }) =>
                            !railwayFunctions?.length
                                ? of(destination)
                                : concat(
                                      ...railwayFunctions.map((railwayFn: RailwayFn) => {
                                          const fnResponse = railwayFn({ injector: this.injector });
                                          return fnResponse instanceof Promise || fnResponse instanceof Observable
                                              ? from(fnResponse)
                                              : of(fnResponse);
                                      })
                                  ).pipe(
                                      map((params) => ({
                                          ...destination,
                                          params: {
                                              ...(destination?.params || {}),
                                              ...(params || {}),
                                          },
                                      }))
                                  )
                        ),
                        // Enriching default queryParams with passed ones
                        map((nextRoute) => ({
                            path: nextRoute?.path,
                            params: {
                                ...(nextRoute?.params || {}),
                                ...(queryParams || {}),
                            },
                        })),
                        take(1),
                        // Redirect on target found otherwise fallback using legacy router
                        switchMap(({ path, params }) => (path ? this.navigateToPath(path, params) : super.next(params)))
                    )
                ),
                catchError((error) => {
                    this.logger.error(error);
                    return this.navigateToPath(RoutesPaths.Error500);
                })
            )
            .toPromise();
    }

    private replacePathParams(path: string, params: { [key: string]: any }): string {
        return Object.entries(params).reduce((targetPath, [key, value]) => targetPath.replace(`:${key}`, value), path);
    }

    private navigateToPath(path: string, params: Params = {}) {
        return combineLatest([this.eglState.select(selectFlowType), this.activatedRoute.queryParams]).pipe(
            take(1),
            mergeMap(([flowType, queryParams]) =>
                this.router.navigate(
                    [
                        this.replacePathParams(path, {
                            ...params,
                            flowType,
                        }),
                    ],
                    {
                        queryParams: {
                            ...(queryParams || {}),
                            ...(params || {}),
                        },
                    }
                )
            )
        );
    }
}
