import { Injectable } from '@angular/core';
import { MonoTypeOperatorFunction, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AppInsightsService } from '../shared/app-insights.service';

@Injectable({
    providedIn: 'root',
})
export class TelemetryMetricService {
    constructor(private appInsightsSrv: AppInsightsService) {}
    getIstance(tagName: string): TelemetryMetric {
        return new TelemetryMetric((tagName || 'untagged').replace(/\s/g, '-'), this.appInsightsSrv);
    }

    rxTelemetry<T>(tagName: string | ((response: T) => string)): MonoTypeOperatorFunction<T> {
        const telemetryMeter = this.getIstance(typeof tagName === 'string' ? tagName : tagName(null));
        return (source: Observable<T>) =>
            source.pipe(
                tap((response) => {
                    if (typeof tagName === 'function') {
                        telemetryMeter.tag = tagName(response);
                    }
                    telemetryMeter.logElapsed();
                }),
                catchError((err) => {
                    telemetryMeter.tag += ' [ERROR]';
                    telemetryMeter.logElapsed();
                    throw err;
                })
            );
    }
}

export class TelemetryMetric {
    private _start: number;
    private _end: number;
    private _logger: AppInsightsService;
    public tag: string;

    constructor(tag: string, logger: AppInsightsService) {
        this._logger = logger;
        this.tag = tag || '';
        this._start = new Date().getTime();
    }

    private get consoleElapsedString(): string {
        return `Telemetry: ${this.tag}`;
    }

    logElapsed(): number {
        if (!this._end) {
            this._end = new Date().getTime();
        }
        const elapsed = (this._end - this._start) / 1000;
        if (this._logger) {
            this._logger.logMetric(this.tag, elapsed);
        } else {
            console.info(`${this.consoleElapsedString} in ${elapsed} sec.`);
        }
        return elapsed;
    }
}
