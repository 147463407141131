<div class="egl-progress-bar__wrapper" [hidden]="isHidden">
    <div class="egl-progress-bar__container">
        <div
            class="egl-progress-bar"
            role="progressbar"
            [ngStyle]="{ width: currentStepPercent + '%' }"
            aria-valuenow="currentStepPercent"
            aria-valuemin="0"
            aria-valuemax="100"
        ></div>
    </div>

    <p class="egl-progress-bar__step-description" *ngIf="currentStepDescription">
        <egl-icon [path]="'single-light-right'" [width]="10" [height]="10" class="egl-progress-bar__icon"></egl-icon>
        <span [innerHTML]="currentStepDescription"></span>
    </p>
</div>
