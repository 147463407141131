import * as fromUser from './user.selectors';
import * as fromOrder from './order-entry.selectors';
import * as fromBo from './bo.selectors';
import * as fromApp from './app.selectors';
import { UserState } from '../models/user-state';
import { OrderEntryState } from '../models/order-entry-state';
import { createSelector } from '@ngrx/store';
import { D365ChannelCode } from '../../common/enums/d365/d365-channel-code';
import { FullState } from '../models/full-state';
import { containsProductGas } from '../../common/functions/verifications.functions';
import { D365CustomerSegment } from '../../common/enums/d365/d365-customer-segment';

export const selectUserOrderState = createSelector(
    fromUser.selectUserState,
    fromOrder.selectOrderEntryState,
    (userS: UserState, orderS: OrderEntryState) => ({
        user: userS,
        orderEntry: orderS,
    })
);

export const selectFullState = createSelector(
    fromUser.selectUserState,
    fromOrder.selectOrderEntryState,
    fromUser.selectCurrentVirtualAgent,
    fromOrder.selectIsDigitalCommunications,
    fromOrder.selectSalesProcess,
    fromOrder.selectDeferredSaleStatus,
    (user, orderEntry, selectedVirtualAgent, isDigitalCommunications, salesProcess, isDelayInsertion): FullState => ({
        user,
        orderEntry,
        selectedVirtualAgent,
        isDigitalCommunications,
        salesProcess,
        isDelayInsertion,
    })
);

export const selectCurrentVaProductTypes = createSelector(
    fromUser.selectCurrentVirtualAgent,
    fromOrder.selectProductsTypes,
    (va, types) => ({ currentVirtualAg: va, productsTypes: types.allTypes })
);

export const selectChannelAndProfile = createSelector(
    fromUser.selectChannelCode,
    fromUser.selectAgentInfo,
    (ch, ag) => ({
        channel: ch as D365ChannelCode,
        agProfileCode: ag.ProfileCode,
    })
);

export const selectOcrFeedback = createSelector(
    fromOrder.selectOcrData,
    fromUser.selectContactLead,
    fromOrder.selectPod,
    fromOrder.selectPdr,
    (ocr, contactLead: any, pod: string, pdr: string) => ({
        name: contactLead?.customer?.firstname,
        lastname: contactLead?.customer?.lastname,
        mobilephone: contactLead?.customer?.mobilephone,
        email: contactLead?.customer?.emailaddress1,
        cf: contactLead?.customer?.egl_taxcode,
        pod,
        pdr,
        ocrData: ocr,
    })
);

export const selectSceltaFirmaData = createSelector(
    fromUser.selectCurrentVirtualAgent,
    fromUser.selectAgentInfo,
    fromApp.selectApplicationLocation,
    fromOrder.selectOrderEntryState,
    fromOrder.selectProductsInfo,
    (cva, agi, locat, orderState, prods) => ({
        currentVa: cva,
        agentInfo: agi,
        firma: orderState.firma,
        prodInfos: prods,
        appLocation: locat,
        plicoCode: orderState.numeroPlico,
        skipPod: orderState.skipPod,
        skipPdr: orderState.skipPdr,
        skipAteco: orderState.skipAteco,
        flowType: orderState.flowType,
        quoteStateModel: orderState.quoteStateModel,
    })
);

export const selectRawState = createSelector(
    fromApp.selectAppState,
    fromUser.selectUserState,
    fromOrder.selectOrderEntryState,
    fromBo.selectBoState,
    (app, user, orderEntry, bo) => ({
        app,
        user,
        orderEntry,
        bo,
    })
);

// export const selectIsPlicoApiStubbed = createSelector(
//     fromApp.selectFlowTypesWithStubbedApi,
//     fromOrder.selectFlowType,
//     (flowTypesWithStubbedApi: string[], flowType: FlowType) => {
//         const stubbedFlowTypes = (flowTypesWithStubbedApi || []).map((ft: string) => ft.toUpperCase());
//         if (stubbedFlowTypes.indexOf('ALL') !== -1 || stubbedFlowTypes.indexOf(flowType.toUpperCase()) !== -1) {
//             return true;
//         }
//         return false;
//     }
// );

export const selectTerminationData = createSelector(
    fromOrder.selectOrderEntryState,
    fromUser.selectContact,

    (oe, contact) => ({
        productsInfo: oe.infoProdotti,
        isMortisCausa: oe.termination?.isMortisCausa || false,
        termDate: oe.termination?.termDate,
        termAppointment: oe.termination?.termAppointment,
        termCosts: oe.termination?.termCosts,
        contact: contact,
        indirizzi: oe.indirizzi,
        commAddress: oe.indirizzi?.indirizzoComunicazioni?.toponomastica
            ? `${oe.indirizzi?.indirizzoComunicazioni?.toponomastica} ${oe.indirizzi?.indirizzoComunicazioni?.via} ${oe.indirizzi?.indirizzoComunicazioni?.civico}, ${oe.indirizzi?.indirizzoComunicazioni?.city}, ${oe.indirizzi?.indirizzoComunicazioni?.province}, ${oe.indirizzi?.indirizzoComunicazioni?.cap}`
            : '',
        customerCode: contact?.egl_customercode || '',
    })
);

export const selectSkipResidentialAddress = createSelector(
    fromOrder.selectProductsTypes,
    fromUser.selectCartSegment,
    (products, cartSegment) => {
        if (products?.productsCount === 1 && (products.containsGas || products.containsRcDanni)) {
            return true;
        }
        if (cartSegment === D365CustomerSegment.Microbusiness) {
            return true;
        }
        return false;
    }
);

export const selectCartToQuoteStateInfo = createSelector(
    selectFullState,
    selectSkipResidentialAddress,
    (fullState, skipHomeAddr) => ({
        fullState,
        skipHomeAddr,
    })
);
