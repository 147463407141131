<ng-container>
    <div class="navbar" *ngIf="show">
        <div class="d-flex w-100 justify-content-between">
            <!--GOTO BACK-->
            <div class="egl-header_left-btn-container b-rigth" (click)="goBack()" [hidden]="!showBack">
                <egl-icon [path]="'medium-light-left'" [width]="15" [height]="14"></egl-icon>
            </div>

            <!-- SURVEY ICON -->
            <div class="egl-header_left-btn-container" *ngIf="isSurveyAvailable" (click)="openSurveyModal()">
                <egl-icon [path]="'survey'" [width]="32" [height]="32" class="egl-header_survey-icon"></egl-icon>
                {{ 'SURVEY.GENERAL_TITLE' | translate }}
            </div>

            <!--DROPDOWN SWI-->
            <ng-container
                ><egl-header-dropdown
                    *ngFor="let item of headersElements"
                    [lov]="item.elements"
                    [placeholder]="item.placeholder"
                    (select)="onDropdownSelect($event)"
                    (discard)="onDiscardSelect($event)"
                ></egl-header-dropdown
            ></ng-container>

            <!--LOGO ENI-->
            <div class="brand w-50">
                <img *ngIf="showLogo" src="../../../../../assets/images/logo2@2x.png" alt="" />
            </div>

            <div class="w-25">
                <div class="user-and-cart">
                    <!-- USER PROFILE -->
                    <div class="user-info-container">
                        <egl-user-info class="float-right"></egl-user-info>
                    </div>

                    <!-- CART -->
                    <div class="mini-cart-container" *ngIf="showMiniCart">
                        <egl-mini-cart></egl-mini-cart>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <egl-contact-bar></egl-contact-bar>

    <egl-progress-bar></egl-progress-bar>
</ng-container>

<egl-modal-with-footer
    #confirmModal
    (onConfirm)="modalConfirm.onConfirmFunction()"
    [title]="modalConfirm.title"
    [buttonLabel]="modalConfirm.confirmBtn"
    [closeOnConfirm]="true"
>
    <p [innerHTML]="modalConfirm.message"></p>
</egl-modal-with-footer>
