import { createReducer, on } from '@ngrx/store';
import * as a from '../actions/order-entry.actions';
import { OrderEntryState, Firma } from '../models/order-entry-state';
import * as _ from 'lodash';
import { Action } from '../../common/interfaces/action';
import { partnershipsAssociations } from './partnership.associations';
import { flowTypeToPartNumber } from '../../common/functions/remap.functions';
export const initialState: OrderEntryState = new OrderEntryState();

const reducer = createReducer(
    initialState,
    on(a.setOrderEntryState, (state, { s }) => s),
    on(a.setCartInfo, (state, { p }) => ({
        ...state,
        cartInfo: p,
    })),
    on(a.setIndirizzi, (state, { i }) => ({
        ...state,
        indirizzi: i,
    })),
    on(a.setPod, (state, { p }) => ({
        ...state,
        pod: p,
    })),
    on(a.setPdr, (state, { p }) => ({
        ...state,
        pdr: p,
    })),
    on(a.setPowerConsumption, (state, { c }) => ({
        ...state,
        powerConsumption: c,
    })),
    on(a.setPowerHomeDropdownOption, (state, { c }) => ({
        ...state,
        powerHomeDropdownOption: c,
    })),
    on(a.setGasConsumption, (state, { c }) => ({
        ...state,
        gasConsumption: c,
    })),
    on(a.setGasHomeDropdownOption, (state, { c }) => ({
        ...state,
        gasHomeDropdownOption: c,
    })),
    on(a.setTitolaritaImmobile, (state, { c }) => ({
        ...state,
        realEstateOwnership: c,
    })),
    on(a.setDatiPagamento, (state, { p }) => ({
        ...state,
        datiPagamento: p,
    })),
    on(a.setFotoDocumenti, (state, { d }) => ({
        ...state,
        fotoDocumenti: d,
    })),
    on(a.setFirma, (state, { payload }) => ({
        ...state,
        firma: payload,
    })),
    on(a.setMP3Info, (state, { payload }) => ({
        ...state,
        mp3Info: payload,
    })),
    on(a.setPdfPlicoInfo, (state, { payload }) => ({
        ...state,
        uploadPdfPlicoInfo: payload,
    })),
    on(a.setProductsInfo, (state, { payload, responsabilita = false }) => ({
        ...state,
        infoProdotti: payload,
        responsabilitaCreditCheckModal: responsabilita,
    })),
    on(a.setNumeroPlico, (state, { payload }) => ({
        ...state,
        numeroPlico: payload,
    })),
    on(a.setPassaggioRapido, (state, { payload }) => ({
        ...state,
        tipoEsecuzione: payload,
    })),

    on(a.setFornitureEsistentiSelezionate, (state, { s }) => ({
        ...state,
        selectFornitureAttiveSelezionate: s,
    })),
    on(a.setFornitureAttive, (state, { s }) => ({
        ...state,
        fornitureAttive: s,
    })),
    on(a.setPrivacyTratDatiPers, (state, { p }) => ({
        ...state,
        privacyTrattDatiPers: p,
    })),
    on(a.setQuoteStateModel, (state, { s }) => ({
        ...state,
        quoteStateModel: s,
    })),
    on(a.setVerificaContatto, (state, { s }) => ({
        ...state,
        verificaContatto: s,
    })),
    on(a.resetOrderEntry, (state) => ({
        ...new OrderEntryState(),
    })),
    on(a.setAgentBranch, (state, { h }) => ({
        ...state,
        branchAgenziaAgente: h,
    })),
    on(a.initSign, (state) => ({
        ...state,
        numeroPlico: null,
        uploadPdfPlicoInfo: null,
        mp3Info: null,
        firma: new Firma(),
    })),
    on(a.setSkipPod, (state, { s }) => ({
        ...state,
        skipPod: s,
    })),
    on(a.setSkipPdr, (state, { s }) => ({
        ...state,
        skipPdr: s,
    })),
    on(a.setSkipAteco, (state, { s }) => ({
        ...state,
        skipAteco: s,
    })),
    on(a.setIsWinBack, (state, { payload }) => ({
        ...state,
        isWinBack: payload,
    })),
    on(a.setWinBackType, (state, { payload }) => ({
        ...state,
        winBackType: payload,
    })),
    on(a.setPlicoUrl, (state, { payload }) => ({
        ...state,
        plicoUrl: payload,
    })),
    on(a.setAnagraficaMB, (state, { payload }) => ({
        ...state,
        anagraficaMb: payload,
    })),
    on(a.setSegmentIds, (state, { i }) => ({
        ...state,
        segmentIds: i,
    })),
    on(a.setOcrData, (state, { payload }) => ({
        ...state,
        ocrData: payload,
    })),
    on(a.setAtecoMB, (state, { payload }) => ({
        ...state,
        atecoMb: payload,
    })),
    on(a.setPotenzaImpegnataMB, (state, { payload }) => ({
        ...state,
        potenzaImpegnataMb: payload,
    })),
    on(a.setFatturazioneElettronica, (state, { payload }) => ({
        ...state,
        fatturazioneElettronica: payload,
    })),
    on(a.setImpersonatedAgent, (state, { payload }) => ({
        ...state,
        impersonatedAgent: { ...state.impersonatedAgent, ...payload },
    })),
    on(a.setInvalidCf, (state, { payload }) => ({
        ...state,
        invalidCf: payload,
    })),
    on(a.setResponsabilitaCC, (state, { payload }) => ({
        ...state,
        responsabilitaCreditCheckModal: payload,
    })),
    on(a.setFlowType, (state, { payload }) => ({
        ...state,
        partNumber: flowTypeToPartNumber(payload),
        flowType: payload,
        isDefaultFlowType: false,
    })),
    on(a.setQuoteId, (state, { q }) => ({
        ...state,
        quoteId: q,
    })),
    on(a.setAssetId, (state, { payload }) => ({
        ...state,
        assetId: payload,
    })),
    on(a.setNumeroDomiciliazione, (state, { code }) => ({
        ...state,
        numeroDomiciliazione: code,
    })),
    on(a.setDomiciliazioneStandalone, (state, { d }) => ({
        ...state,
        domiciliationStandalone: d,
    })),
    on(a.setDeferredSaleStatus, (state, { enabled }) => ({
        ...state,
        deferredSaleEnabled: enabled,
    })),
    on(a.setCreditCheckStatus, (state, { s }) => ({
        ...state,
        creditCheckStatus: s,
    })),
    on(a.setInsolutoNDS, (state, { i }) => ({
        ...state,
        creditCheckStatus: {
            ...state.creditCheckStatus,
            ccDetails: {
                ...state.creditCheckStatus?.ccDetails,
                unsolvedNds: i,
            },
        },
    })),
    on(a.setTaxVatStatus, (state, { s }) => ({
        ...state,
        creditCheckStatus: {
            ...state.creditCheckStatus,
            ccDetails: {
                ...state.creditCheckStatus?.ccDetails,
            },
            taxVatDetails: s,
        },
    })),
    on(a.setScoreCardStatus, (state, { s }) => ({
        ...state,
        creditCheckStatus: {
            ...state.creditCheckStatus,
            ccDetails: {
                ...state.creditCheckStatus?.ccDetails,
                cribis: s,
            },
            taxVatDetails: {
                ...state.creditCheckStatus.taxVatDetails,
            },
        },
    })),
    on(a.setScorecardCallInProgress, (state, { s }) => ({
        ...state,
        creditCheckStatus: {
            ...state.creditCheckStatus,
            ccDetails: {
                ...state.creditCheckStatus?.ccDetails,
            },
            taxVatDetails: {
                ...state.creditCheckStatus.taxVatDetails,
            },
            scorecardCallInProgress: s,
        },
    })),
    on(a.setEsitoCreditCheckStatus, (state, { s }) => ({
        ...state,
        creditCheckStatus: {
            ...state.creditCheckStatus,
            ccDetails: {
                ...state.creditCheckStatus?.ccDetails,
            },
            taxVatDetails: {
                ...state.creditCheckStatus.taxVatDetails,
            },
            esito: s,
        },
    })),
    on(a.setPaymentScore, (state, { p }) => ({
        ...state,
        creditCheckStatus: {
            ...state.creditCheckStatus,
            ccDetails: {
                ...state.creditCheckStatus?.ccDetails,
                paymentScore: p,
            },
            taxVatDetails: {
                ...state.creditCheckStatus.taxVatDetails,
            },
        },
    })),
    on(a.setCeaseReasonCode, (state, { c }) => ({
        ...state,
        creditCheckStatus: {
            ...state.creditCheckStatus,
            ccDetails: {
                ...state.creditCheckStatus?.ccDetails,
                ceaseReasonCode: c,
            },
            taxVatDetails: {
                ...state.creditCheckStatus.taxVatDetails,
            },
        },
    })),
    on(a.setIbanInsolutoNDS, (state, { i }) => ({
        ...state,
        creditCheckStatus: {
            ...state.creditCheckStatus,
            ccDetails: {
                ...state.creditCheckStatus?.ccDetails,
                unsolvedIbanNds: i,
            },
        },
    })),
    on(a.setIvassAnswers, (state, { p }) => ({
        ...state,
        ivassAnswers: p,
    })),
    on(a.setInsuranceDummyProductId, (state, { p }) => ({
        ...state,
        insuranceDummyProductId: p,
    })),
    on(a.setAgencyBranchForMonitoring, (state, { a }) => ({
        ...state,
        agencyBranchForMonitoring: a,
    })),
    on(a.setIsMortisCausa, (state, { isMortisCausa }) => ({
        ...state,
        termination: { ...state.termination, isMortisCausa },
    })),
    on(a.setTerminationCartId, (state, { t }) => ({
        ...state,
        termination: {
            ...state.termination,
            cartId: t,
        },
    })),
    on(a.setTerminationDate, (state, { s }) => ({
        ...state,
        termination: {
            ...state.termination,
            termDate: s,
        },
    })),
    on(a.setSelfCertification, (state, { d }) => ({
        ...state,
        selfCertification: d,
    })),
    on(a.setTerminationTermContacts, (state, { p }) => ({
        ...state,
        termination: {
            ...state.termination,
            termContacts: p,
        },
    })),
    // Termination & Activation
    on(a.setAppointment, (state, { app, orderEntryFlowSection }) => ({
        ...state,
        [orderEntryFlowSection]: {
            ...(state[orderEntryFlowSection] || {}),
            [orderEntryFlowSection.substring(0, 4) + 'Appointment']: app,
        },
    })),
    on(a.setTerminationCosts, (state, { tc }) => ({
        ...state,
        termination: {
            ...state.termination,
            termCosts: tc,
        },
    })),
    on(a.setAdministrativeTechnicalData, (state, { p }) => ({
        ...state,
        administrativeTechnicalData: p,
    })),
    on(a.setPodActivationInfo, (state, { ai }) => ({
        ...state,
        activation: {
            ...state.activation,
            podActivationInfo: {
                ...(ai || {}),
                nuovaPotSel: ai?.nuovaPotSel || state?.activation?.podActivationInfo?.nuovaPotSel,
            },
        },
        costs: null,
    })),
    on(a.setPotencyActivation, (state, { pot }) => ({
        ...state,
        activation: {
            ...(state.activation || {}),
            podActivationInfo: {
                ...(state.activation?.podActivationInfo || {}),
                nuovaPotSel: pot,
            },
        },
    })),
    on(a.setPdrActivationInfo, (state, { ai }) => ({
        ...state,
        activation: {
            ...(state.activation || {}),
            pdrActivationInfo: ai,
        },
        costs: null,
    })),
    on(a.setActivationDate, (state, { date, immediateEffect }) => ({
        ...state,
        activation: {
            ...(state.activation || {}),
            actDate: date,
            immediateEffect,
        },
    })),
    on(a.setPropertyTypeSelected, (state, { propertyTypeSelected }) => ({
        ...state,
        activation: {
            ...(state.activation || {}),
            propertyTypeSelected,
        },
    })),
    on(a.setCosts, (state, { costs }) => ({
        ...state,
        costs,
    })),
    on(a.setCombinedSaleQuoteId, (state, { quoteId }) => ({
        ...state,
        combinedSaleQuoteId: quoteId,
    })),
    on(a.setCombinedSale, (state, { combinedSale }) => ({
        ...state,
        combinedSale,
    })),
    on(a.setCommodityCartId, (state, { commodityCartId }) => ({
        ...state,
        commodityCartId,
    })),
    on(a.setCoupon, (state, { coupon }) => ({
        ...state,
        coupon: coupon,
    })),
    on(a.setIncident, (state, { t }) => ({
        ...state,
        incident: t,
    })),
    ...partnershipsAssociations
);

export function orderEntryReducer(state: OrderEntryState | undefined, action: Action): OrderEntryState {
    return reducer(state, action);
}
