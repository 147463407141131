export enum AptQuoteSubStatus {
    AttesaInvioLetteraDiAccettazione = 'INVIO LETTERA DI ACCETTAZIONE',
    AttesaSottomissioneDocumenti = 'ATTESA SOTTOMISSIONE DOCUMENTI',
    CheckCallNonEseguita = 'CHECK CALL NON ESEGUITA',
    CaricamentoDocumentiScaduto = 'CARICAMENTO DOCUMENTI SCADUTO',
    CompletamentoConFirma = 'COMPLETAMENTO CON FIRMA',
    CompletamentoSenzaFirma = 'COMPLETAMENTO SENZA FIRMA',
    ConfermaCheckDoi = 'CONFERMA CHECK DOI',
    ConfermaDoi = 'CONFERMA DOI',
    ContrattoNonConcluso = 'CONTRATTO NON CONCLUSO',
    ControlloDatiSii = 'CONTROLLO DATI SII',
    CreditCheckKo = 'CREDIT CHECK KO',
    DaInviareACabinaDiRegia = 'DA INVIARE A CABINA DI REGIA',
    DatiCorrettiDalSii = 'DATI CORRETTI DAL SII',
    DatiPrecheckNonValidi = 'DATI PRECHECK NON VALIDI',
    DoiConfermaKO = 'DOI CONFERMA KO',
    DoiConfermaScaduto = 'DOI CONFERMA SCADUTO',
    DoiFirmaKo = 'DOI FIRMA KO',
    ErratoInserimento = 'ERRATO INSERIMENTO',
    FirmaDoi = 'FIRMA DOI',
    FirmaGrafometrica = 'FIRMA GRAFOMETRICA',
    GrafometricaFirmaKO = 'GRAFOMETRICA FIRMA KO',
    InAttesaArchiviazione = 'IN ATTESA ARCHIVIAZIONE',
    InAttesaConfermaBackoffice = 'IN ATTESA CONFERMA BACKOFFICE',
    InAttesaMp3 = 'IN ATTESA MP3',
    InvioPlicoKo = 'INVIO PLICO KO ',
    LetteraAccettazioneKo = 'LETTERA ACCETTAZIONE KO',
    LetteraAccettazioneOk = 'LETTERA ACCETTAZIONE OK',
    MancataFinalizzazioneFirmaDoiDaCliente = 'MANCATA FINALIZZAZIONE FIRMA DOI DA CLIENTE',
    PodPdrMancante = 'POD/PDR MANCANTE',
    PossibileFrode = 'POSSIBILE FRODE',
    PrecheckKo = 'PRECHECK KO',
    PrecheckKoDefinitivo = 'PRECHECK KO DEFINITIVO',
    PrecheckKoPdrAttivo = 'PRECHECK KO PDR ATTIVO',
    PrecheckKoPerAttivazione = 'PRECHECK KO PER ATTIVAZIONE',
    PrecheckKoPerDatiAnagraficiErrati = 'PRECHECK KO PER DATI ANAGRAFICI ERRATI',
    PrecheckKoPerMotiviTecnici = 'PRECHECK KO PER MOTIVI TECNICI',
    PresaInCaricoOk = 'PRESA IN CARICO OK',
    RilavorazioneDoiFirmaExpired = 'RILAVORAZIONE DOI FIRMA EXPIRED',
    DoiScadutoClienteNonInteragito = 'DOI SCADUTO - CLIENTE NON INTERAGITO',
    CheckDoiScadutoClienteNonInteragito = 'CHECK DOI SCADUTO - CLIENTE NON INTERAGITO',
    VerificaDatiInseriti = 'VERIFICA DATI INSERITI',
    DoiConfermaInvioKO = 'DOI CONFERMA INVIO KO',
    DoiFirmaInvioKO = 'DOI FIRMA INVIO KO',
    InvioPlicoKO = 'INVIO PLICO KO',
    FirmaSemidigital = 'FIRMA SEMIDIGITAL',
    AttesaSottomissioneOrdine = 'ATTESA SOTTOMISSIONE ORDINE',
}
